import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign: [],
  level: null,
  hotel_id: [],
  group_id: [],
  segment_list: [],
};
export const searchAdsSlice = createSlice({
  name: "searchAdsSlice",
  initialState: initialState,
  reducers: {
    updateCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    updateLevel: (state, action) => {
      state.level = action.payload;
    },
    updateHoteId: (state, action) => {
      state.hotel_id = action.payload;
    },
    updateGroupId: (state, action) => {
      state.group_id = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
  },
});

export const {
  updateCampaign,
  updateGroupId,
  updateHoteId,
  updateLevel,
  updateSegmentList,
} = searchAdsSlice.actions;
export default searchAdsSlice.reducer;
