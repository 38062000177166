  import React, { useState, useEffect, useCallback, useRef } from 'react';
  import { Grid, TextField, Select, MenuItem, Button, Typography, Checkbox, FormControl, FormHelperText, ListItemText } from '@mui/material';
  import { validateReportForm } from '../../validation/reportValidation';
  import { sanitizeString } from '../../../../utils/functions/sanitize';
  import { fetchService } from '../../Services/fetchService';
  import ERROR_MESSAGES from '../../Constant/errorMessages';
  import Tags from "@yaireo/tagify/dist/react.tagify";
  import { fetchTimezones, fetchFtp, fetchHotels, fetchGroups, fetchUserInfo, fetchWhiteLabelEmail } from '../../Services/apiServices';
  import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
  import { useParams } from "react-router-dom";
  import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
  import DmpIcons from "components/DmpIcons/icons";
  import DateInputWithCustomIcon from "components/DateInputWithCustomIcon/DateInputWithCustomIcon";
  import dayjs from 'dayjs';
  import SlidingPanel from 'react-sliding-side-panel';
  import LevelSelection  from '../../components/LevelSelection';
  import CustomFormControl from "../../../alert-center/Dropdown/CustomFormControl";
  import {Box, Chip, Stack, FormControlLabel, FormGroup} from "@mui/material";
  import '../../reportCenter.scss'

  const Reservations = ({ formData,  showSnackbar, onClose, isEdit= false }) => { 
    const [reportRecipients, setreportRecipients] = useState([]);
    const [timezones, setTimezones] = useState([]);
    const [ftp, setftp] = useState([]);
    const [whiteLabelEmail, setWhiteLabelEmail] = useState([]); 
    const [errors, setErrors] = useState({});
    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]); 
    const [userinfo, setUserinfo] = useState([]); 
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]); 
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    const [includesColumn, setIncludesColumn] = useState(formData?.columns_show || []);
    const [reservationColumn, setReservationColumn] = useState(formData?.billing_mode || []);
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
    const [dayOfWeek, setDayOfWeek] = useState(formData?.day || []);
    const token = sessionStorage.getItem('token');
    const { idaccount } = useParams();
    const DayOfWeek= ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THRUSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
    

    const columnShowOptions= [      
      { value: "idhotel", label: "WIHP ID" },
      { value: "hotelPmsId", label: "BE ID" },
      { value: "metaioReportsHotelId", label: "Partner ID" },
      { value: "hotelGroup", label: "Hotel Group" },
      { value: "hotelGroupId", label: "Hotel group ID" },
      { value: "billingMode", label: "Billing mode" },
      { value: "bookingStatus", label: "Booking status" },
      { value: "metaclickDatetime", label: "Last click" },
      { value: "bookingDatetime", label: "Booking" },
      { value: "checkInDate", label: "Check In" },
      { value: "checkOutDate", label: "Check out" },
      { value: "nights", label: "Nights" }, 
      { value: "idplatform", label: "Metasearch" }, 
      { value: "adstype", label: "Ads type" }, 
      { value: "currency", label: "Reservation currency" }, 
      { value: "amount", label: "Reservation amount" }, 
      { value: "amount_eur", label: "Amount EUR" }, 
      { value: "amount_usd", label: "Amount USD" }, 
      { value: "idcountryGroup", label: "Market" }
    ]

    const reservationColumnOptions =[
      { value: "CPC", label: "Cost Per Click (CPC)" },
      { value: "CPA", label: "Commission (COM)" },
      { value: "CON", label: "Pay Per Conversion (CONV)" },
      { value: "FRE", label: "Free Campaign (FREE)" },
      { value: "BA", label: "Tripadvisor Business Advantage (BA)" },
      { value: "ABO", label: "Direct Booking (ABO)" },
      { value: "PPS", label: "Pay-Per-Stay (PPS)" },
    ]

    useEffect(() => {
      if (formData?.recipients) {
          setreportRecipients(
              formData.recipients.map((email) => ({ value: email }))
          );
      }
      if (formData?.columns_show) {
        setIncludesColumn(formData.columns_show || []);
      }
      if (formData?.billing_mode) {
        setReservationColumn(formData.billing_mode || []);
      }
      if (formData?.day) {
        setDayOfWeek(formData.day || []);
      }
  }, [formData]);

    const [reportData, setReportData] = useState({
      name: formData?.name || "",
      report_id: formData?.report_id || "",
      account: idaccount,
      level: formData?.level || "",
      level_choices: formData?.level_choices || [],
      date_range: formData?.date_range || "",
      period_length: formData?.period_length || "",
      delivery_frequency: formData?.delivery_frequency || "",
      columns_show: formData?.columns_show || [],
      billing_mode: formData?.billing_mode || [],
      convert_to:formData?.convert_to || "",
      day_month: formData?.day_month || "",
      commissions: formData?.commissions || "",
      date_based_on: formData?.date_based_on || "BOOKING_DATETIME",
      day : formData?.day || [],
      time_of_day: formData?.time_of_day || "",
      time_zone: formData?.time_zone || "",
      export_method: formData?.export_method || "",
      delevery_method_ftp:formData?.delevery_method_ftp || "",
      folder: formData?.folder || "",
      recipients :formData?.recipients || [],
      subject: formData?.subject || "",
      export_name: formData?.export_name || "",
      export_file_type: formData?.export_file_type || "",
      attached_file: formData?.attached_file || "",
    });

    const handleCreateReport = async () => {
      const sanitizedData = {
        report_id: reportData.id,
        account: parseInt(idaccount, 10),
        name: sanitizeString(reportData.name),
        level: sanitizeString(reportData.level),
        level_choices: reportData.level_choices,
        date_range: reportData.date_range,
        delivery_frequency: reportData.delivery_frequency,
        period_length:  reportData.period_length,
        custom_start_date:reportData.custom_start_date,
        commissions:reportData.commissions,
        columns_show: reportData.columns_show,
        billing_mode: reportData.billing_mode,
        custom_end_date: reportData.custom_end_date,
        group_data_by_time: reportData.group_data_by_time,
        date_based_on: reportData.date_based_on,
        convert_to: reportData.convert_to,
        day_month: reportData.day_month,
        day : reportData.day,
        group_data_by : reportData.group_data_by,
        remove_empty_rows : reportData.remove_empty_rows,
        time_of_day: reportData.time_of_day,
        time_zone: reportData.time_zone,
        export_method: reportData.export_method,
        delevery_method_ftp:reportData.delevery_method_ftp,
        folder: reportData.folder,
        recipients :reportData.recipients,
        subject: reportData.subject,
        export_name: reportData.export_name,
        export_file_type: reportData.export_file_type,
        attached_file: reportData.attached_file,
        excel_second_tab: reportData.excel_second_tab,
        whitelabel_email : reportData.whitelabel_email,
        excelSecondTabStatus : reportData.excelSecondTabStatus,   
      };
     
    
      const validationErrors = validateReportForm(sanitizedData);

      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);  
      }

      try {
        const API_URL = isEdit ? APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/reservation/edit" : APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/reservation/add";
        const API_METHOD = isEdit ? "PATCH" : "POST";
        const API_RESPONSE = isEdit ? "RESOURCE_UPDATED" : "RESOURCE_CREATED";
        const API_MESSAGE = isEdit ? "updated" : "created";
        

        const response = await fetch(API_URL, {
          method: API_METHOD,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sanitizedData),
        });

        const data = await response.json();

        if (data.status_code === 200 && data.data.message === API_RESPONSE) {
          if (showSnackbar) {
            showSnackbar(`Report ${API_MESSAGE} successfully! Report ID: ${data.data.report_id}`, "success");
          }
          if (onClose) {
            onClose();
          }
        }else if (data.error && data.error.length > 0) {
          const fieldErrors = {};
          data.error.forEach((err) => {
            const translatedMessage = ERROR_MESSAGES[err.message] || err.message;
            if(err.property_path) {
              fieldErrors[err.property_path] = translatedMessage;
              if (err.property_path === 'level_choices') {
                fieldErrors['level'] = translatedMessage;
              }
            } else {
              fieldErrors["unspecified"] = translatedMessage;
            }
            setErrors(fieldErrors);
              
          });
        }
      } catch (error) {
        if (showSnackbar) {
          showSnackbar("Something went wrong while creating the report.", "error");
        }
        console.error('Error creating report:', error);
      }
    };

    useEffect(() => {
      const fetchData = async () => {
        const fetchServiceInstance = fetchService;

        try {
          // Parallel API calls
          const [timezones, ftpData, hotels, groups, userInfo, whiteLabelEmails] = await Promise.all([
            fetchTimezones(fetchServiceInstance, setErrors),
            fetchFtp(fetchServiceInstance, idaccount, setErrors),
            fetchHotels(fetchServiceInstance, idaccount, setErrors),
            fetchGroups(fetchServiceInstance, idaccount, setErrors),
            fetchUserInfo(fetchServiceInstance, setErrors),
            fetchWhiteLabelEmail(fetchServiceInstance, idaccount, setErrors),
          ]);

          // Update state only for successful calls
          setTimezones(timezones || []);
          setftp(ftpData || []);
          setHotels(hotels || []);
          setGroups(groups || []);
          setUserinfo(userInfo?.[0] || null);
          setWhiteLabelEmail(whiteLabelEmails || []);
        } catch (error) {
          console.error('Error during data fetching:', error);
        }
      };

      fetchData();
    }, [idaccount]);

    useEffect(() => {
      setReportData({
        ...reportData,
        ...formData,
      });
    }, [formData]);


    useEffect(() => {
      if (!reportData.level) {
        setReportData((prevData) => ({ ...prevData, level: 'ACCOUNT' }));  
      }
      if (!reportData.export_method) {
        setReportData((prevData) => ({ ...prevData, export_method: "EMAIL" })); 
      }
      if (!reportData.export_file_type) {
        setReportData((prevData) => ({ ...prevData, export_file_type: "CSV" })); 
      }
      if (!reportData.attached_file) {
        setReportData((prevData) => ({ ...prevData, attached_file: "ZIP_COMPRESSION" })); 
      }
      if (!reportData.date_range) {
        setReportData((prevData) => ({ ...prevData, date_range: "CURRENT_MONTH" })); 
      }
      if (!reportData.delivery_frequency) {
        setReportData((prevData) => ({ ...prevData, delivery_frequency: "ONCE" })); 
      }
      if (!reportData.convert_to) {
        setReportData((prevData) => ({ ...prevData, convert_to: "EUR" })); 
      }
      
    }, [reportData.level, setReportData]);

    const handleRecipientsChange = useCallback((e) => {
      let parsedValue = [];
      try {
          parsedValue = JSON.parse(e.detail.value);
      } catch (error) {
          console.error("Parsing error:", error);
      }

    const updatedRecipients = parsedValue
          .map(tag => tag.value.split(',').map(email => email.trim()))
          .flat();

      setReportData((prevData) => ({
          ...prevData,
          recipients: updatedRecipients,
      }));
      setreportRecipients(updatedRecipients);
    }, [setReportData]);

    const handleLevelChange = (newLevel) => {
      setReportData((prevState) => ({
        ...prevState,
        level: newLevel,
        level_choices: newLevel === 'HOTEL' ? selectedHotels : newLevel === 'GROUP' ? selectedGroups : [],
      }));
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setReportData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    
    };

    const handleOpenModal = () => {
      setIsSlidingPanelOpen(true)
    };

    const handleDelete = (type, valueToDelete) => {
      if (type == "columns_show") {
        const updatedColumns = includesColumn.filter((value) => value !== valueToDelete);
        setIncludesColumn(updatedColumns);
        setReportData((prev) => ({
          ...prev,
          columns_show: updatedColumns,
        }));
      } 
      if (type == "billing_mode") {
        const updatedColumns = reservationColumn.filter((value) => value !== valueToDelete);
        setReservationColumn(updatedColumns);
        setReportData((prev) => ({
          ...prev,
          billing_mode: updatedColumns,
        }));
      } 
      if (type == "day") {
        const updatedColumns = dayOfWeek.filter((value) => value !== valueToDelete);
        setDayOfWeek(updatedColumns);
        setReportData((prev) => ({
          ...prev,
          day: updatedColumns,
        }));
      } 
      
    };

    useEffect(() => {
      if (reportData.level === "HOTEL" && reportData.level_choices?.length > 0) {
        const preselectedHotels = reportData.level_choices
          .map((id) => hotels.find((hotel) => hotel.id === id))
          .filter((hotel) => hotel); 
        setSelectedHotels(preselectedHotels);
      }
      if (reportData.level === "GROUP" && reportData.level_choices?.length > 0) {
        const preselectedGroups = reportData.level_choices
          .map((id) => groups.find((group) => group.id === id))
          .filter((group) => group); 
        setSelectedGroups(preselectedGroups);
      }
    }, [reportData.level, reportData.level_choices, groups, hotels]);

    const handleHotelSelect = useCallback((newSelectedHotels) => {
      const selectedValues = newSelectedHotels.map((h) => h.id ); 
      setSelectedHotels(newSelectedHotels);
      setReportData((prevData) => ({
        ...prevData,
        level_choices: selectedValues,
      }));
    }, []);

    const handleGroupSelect = useCallback((newSelectedGroups) => {
      const selectedValues = newSelectedGroups.map((h) => h.id ); 
      setSelectedGroups(newSelectedGroups);
      setReportData((prevData) => ({
        ...prevData,
        level_choices: selectedValues,
      }));
    }, []);

    const handleRemoveHotel = (hotelId) => {
      setSelectedHotels((prevHotels) => {
        const updatedHotels = prevHotels.filter((h) => h.id !== hotelId);
        setReportData((prevData) => ({
          ...prevData,
          level_choices: updatedHotels.map((h) => h.id),
        }));
        return updatedHotels;
      });
    };

  const handleRemoveGroup = (groupId) => {
    setSelectedGroups((prevGroupId) => {
      const updatedGroups = prevGroupId.filter((g) => g.id !== groupId);
      setReportData((prevData) => ({
        ...prevData,
        level_choices: updatedGroups.map((g) => g.id),
      }));
      return updatedGroups;
    });
  };

  const handleToggleSettings = () => {
      setShowAdditionalSettings(!showAdditionalSettings);
  };

    return (
      <div className="report-form-container">
        <Grid item xs={12}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                  fullWidth
                  name="name"
                  className="form-input"
                  placeholder='Report Name:'
                  value={reportData.name}
                  onChange={handleInputChange}
                  error={!!errors.name} 
                  helperText={errors.name || ''}
                />
              </Grid>
          </Grid>
          <Grid item xs={3} container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth error={!!errors.level}>
                  <Typography variant="body2">Report Level</Typography>
                  <Select
                    fullWidth
                    value={reportData.level}
                    name="level"
                    className="form-select"
                    onChange={(e) => {
                      handleInputChange(e);
                      handleLevelChange(e.target.value);
                    }}
                    error={!!errors.level} 
                  >
                    <MenuItem value=""><em>Select Report Level</em></MenuItem>
                    <MenuItem value="ACCOUNT">Account</MenuItem>
                    {userinfo?.canUseGroup && (
                      <MenuItem value="GROUP">Group</MenuItem>
                    )}
                    <MenuItem value="HOTEL">Hotel</MenuItem>
                  </Select>
                  {errors.level && <FormHelperText>{errors.level}</FormHelperText>}
                </FormControl>
              </Grid>
              {(reportData.level === 'HOTEL' ||  reportData.level === 'GROUP') && (
                <Grid item xs={12}>
                  <div>
                    <label style={{ fontSize: "14px" }}>Selected {reportData.level === 'HOTEL' ? 'Hotel' : 'Group' }</label>
                    {reportData.level === 'HOTEL' && selectedHotels.length > 0 && hotels.length > 0 && (
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                          {selectedHotels.filter(hotel => hotel).map((hotel) => (
                              <Chip
                                  key={hotel.id}
                                  label={hotel.name}
                                  onDelete={() => handleRemoveHotel(hotel.id)}
                                  deleteIcon={<DmpIcons.ActionsIcons.DeleteIcon
                                      style={{ width: "12px", height: "12px" }}
                                  />}
                                  color="primary"
                                  style={{ margin: '4px', fontSize: "12px", fontFamily: "Inter", fontWeight: 400 }}
                              />
                          ))}
                      </Stack>
                    )}  
                    {reportData.level === 'GROUP' && selectedGroups.length > 0 && groups.length > 0 && (
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                          {selectedGroups.filter(group => group).map((group) => (
                              <Chip
                                  key={group.id}
                                  label={group.name}
                                  onDelete={() => handleRemoveGroup(group.id)}
                                  deleteIcon={<DmpIcons.ActionsIcons.DeleteIcon
                                      style={{ width: "12px", height: "12px" }}
                                  />}
                                  color="primary"
                                  style={{ margin: '4px', fontSize: "12px", fontFamily: "Inter", fontWeight: 400 }}
                              />
                          ))}
                      </Stack>
                    )}
                  </div>
                  <CustomButtonWithIcon style={{ marginTop: '6px'}}
                      color="#b0acac"
                      hoverColor="#12794F"
                      titleColor="#373F41"
                      hoverTitleColor={"white"}
                      Icon={DmpIcons.ActionsIconsSVG.AddIconSVG}
                      iconSize={"14px"}
                      width={"120px"}
                      height={"24px"}
                      borderRadius="20px"
                      textStyle={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '14.52px',
                          textAlign: 'center',
                      }}                  
                      onClick={handleOpenModal}>
                      Add {reportData.level === 'HOTEL' ? 'Hotel' : 'Group' }
                  </CustomButtonWithIcon>
                </Grid>
              )}

                {/* Hotel Selection Dialog */}
                <SlidingPanel type="right" isOpen={isSlidingPanelOpen} size={ 40} backdropClicked={() => setIsSlidingPanelOpen(false)}>
                  {reportData.level === 'HOTEL' && 
                    <LevelSelection
                      level="HOTEL"
                      levelChoices={selectedHotels}
                      data={hotels}
                      onHotelSelect={handleHotelSelect}
                      onClose={() => setIsSlidingPanelOpen(false)}
                    />
                  }
                  {reportData.level === 'GROUP' && 
                    <LevelSelection
                      level="GROUP"
                      levelChoices={selectedGroups}
                      data={groups}
                      onHotelSelect={handleGroupSelect}
                      onClose={() => setIsSlidingPanelOpen(false)}
                    />
                  }
                </SlidingPanel>
            </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth error={!!errors.date_based_on}>
              <Typography variant="body2">Reservation Date</Typography>
              <Select
                value={reportData.date_based_on}
                name="date_based_on"
                className="form-select"
                onChange={(e) => {
                  handleInputChange(e);
                  setReportData({ ...reportData, date_based_on: e.target.value });
                }}
              >
                <MenuItem value="BOOKING_DATETIME">Booking Date</MenuItem>
                <MenuItem value="CHECK_OUT_DATE">Check Out</MenuItem>
              </Select>
              {errors.date_based_on && <FormHelperText>{errors.date_based_on}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid container spacing={2} item xs={3}>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.date_range}>
              <Typography variant="body2">Date Range</Typography>
              <Select
                value={reportData.date_range}
                name="date_range"
                className="form-select"
                onChange={(e) => {
                  handleInputChange(e);
                  setReportData({ ...reportData, date_range: e.target.value });
                }}
              >
                <MenuItem value="CURRENT_MONTH">Current Month</MenuItem>
                <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                <MenuItem value="ALL_FUTURE_MONTHS">All Future Months</MenuItem>
                <MenuItem value="LAST_12_MONTHS">Last 12 Months</MenuItem>
                <MenuItem value="CUSTOM">Custom</MenuItem>
              </Select>
              {errors.date_range && <FormHelperText>{errors.date_range}</FormHelperText>}
            </FormControl>
          </Grid>
          {reportData.date_range === 'CUSTOM' && ( 
                <>
                  <Grid item xs={6} className='report-custom-date'>
                    <FormControl fullWidth >
                      <Typography variant="body2">Date from</Typography>
                        <DateInputWithCustomIcon
                            format={'DD/MM/YYYY'}
                            value={dayjs(reportData.custom_start_date)}
                            onChange={(startDate) => {
                              const date = new Date(startDate);
                              const formattedDate = date.toISOString().split('T')[0]; 
                              setReportData({ ...reportData, custom_start_date: formattedDate });
                            }}
                            icon={DmpIcons.ActionsIcons.CalendarIcon}
                        />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className='report-custom-date'>
                    <FormControl fullWidth>
                      <Typography variant="body2">Date to</Typography>
                      <DateInputWithCustomIcon
                            format={'DD/MM/YYYY'}
                            value={dayjs(reportData.custom_end_date)}
                            onChange={(endDate) => {
                              const date = new Date(endDate);
                              const formattedDate = date.toISOString().split('T')[0]; 
                              setReportData({ ...reportData, custom_end_date: formattedDate });
                            }}
                            icon={DmpIcons.ActionsIcons.CalendarIcon}
                        />
                    </FormControl>
                  </Grid>
                </>
              )}
            {reportData.date_range === 'ONE_DAY' && (
              <>
              <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="body2">Day</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.period_length || ""}
                      onChange={(e) => setReportData({ ...reportData, period_length: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select Day
                      </MenuItem>
                      {Array.from({ length: 31 }, (_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          {/* Row 2 */}
          <Grid container item spacing={2} xs={12}>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.columns_show}>
                <Typography variant="body2">Includes Columns</Typography>
                <Select
                    fullWidth
                    multiple
                    value={reportData.columns_show || []} 
                    name="columns_show"
                    className="form-select"
                    displayEmpty={true}
                    onChange={(e) => {
                      const { value } = e.target;
                      setReportData({ 
                        ...reportData, 
                        columns_show: value 
                      });
                      setIncludesColumn(value)
                    }}
                    renderValue={(selected) =>
                      selected.length
                        ? selected
                            .map((value) => {
                              const option = columnShowOptions.find((option) => option.value === value);
                              return option ? option.label : value;
                            })
                            .join(", ")
                        :  <span style={{
                          fontSize: "small",
                          color: "#bab7b7"
                      }}>None Selected</span>
                    }
                  >
                      <MenuItem disabled value="None Selected"><em>None Selected</em></MenuItem>
                        {columnShowOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox  checked={reportData.columns_show.includes(option.value)} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                </Select>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                    {includesColumn && includesColumn.map((value) => {
                        const option = columnShowOptions.find(opt => opt.value === value);
                        const label = option ? option.label : value;
                        return (
                            <Chip
                                key={value}
                                label={label}
                                onDelete={() => handleDelete("columns_show",value)}
                                size="small"
                            />
                        );
                    })}
                </Box>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.billing_mode}>
                <Typography variant="body2">Reservation Type</Typography>
                <Select
                    fullWidth
                    multiple
                    value={reportData.billing_mode || []} 
                    name="billing_mode"
                    className="form-select"
                    displayEmpty={true}
                    onChange={(e) => {
                      const { value } = e.target;
                      setReportData({ 
                        ...reportData, 
                        billing_mode: value 
                      });
                      setReservationColumn(value)
                    }}
                    renderValue={(selected) =>
                      selected.length
                        ? selected
                            .map((value) => {
                              const option = reservationColumnOptions.find((option) => option.value === value);
                              return option ? option.label : value;
                            })
                            .join(", ")
                        :  <span style={{
                          fontSize: "small",
                          color: "#bab7b7"
                      }}>None Selected</span>
                    }
                  >
                      <MenuItem disabled value="None Selected"><em>None Selected</em></MenuItem>
                        {reservationColumnOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox  checked={reportData.billing_mode.includes(option.value)} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                </Select>
                <Box  sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                    {reservationColumn && reservationColumn.map((value) => {
                        const option = reservationColumnOptions.find(opt => opt.value === value);
                        const label = option ? option.label : value;
                        return (
                            <Chip
                                key={value}
                                label={label}
                                onDelete={() => handleDelete("billing_mode",value)}
                                size="small"
                            />
                        );
                    })}
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          
          {/* Row 3 */}
          <Grid item xs={3} container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.export_method}>
                <Typography variant="body2">Choose Delivery Method</Typography>
                <Select
                  fullWidth
                  value={reportData.export_method}
                  name="export_method"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_method: e.target.value });
                  }}
                >
                  <MenuItem value="EMAIL">Email</MenuItem>
                  {ftp.length != 0 && ( <MenuItem value="FTP">FTP</MenuItem> )}
                </Select>
                {errors.export_method && <FormHelperText>{errors.export_method}</FormHelperText>}
              </FormControl>
            </Grid>
            {reportData.export_method === 'FTP' && (
                <>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <Typography variant="body2">Choose FTP</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.delevery_method_ftp || ''}
                      onChange={(e) => setReportData({ ...reportData, delevery_method_ftp: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select FTP
                      </MenuItem>
                      {ftp.length > 0 ? (
                        ftp.map((ftp) => (
                          <MenuItem key={ftp.id} value={ftp.id}>
                            {ftp.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No ftp Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2"> FTP Folder</Typography>
                      <TextField
                          name="folder"
                          fullWidth
                          className="form-input"
                          value={reportData.folder || ''}
                          onChange={(e) => setReportData({ ...reportData, folder: String(e.target.value) })}
                          displayempty="true"
                        />
                    </FormControl>
                  </Grid>
                </>
              )}
          </Grid>

          <Grid container item xs={8}>
            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.delivery_frequency}>
                <Typography variant="body2">Delivery Frequency</Typography>
                <Select
                  fullWidth
                  value={reportData.delivery_frequency}
                  name="delivery_frequency"
                  className="form-select"
                 onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, delivery_frequency: e.target.value });
                  }}
                  error={!!errors.delivery_frequency}
                >
                  <MenuItem value=""><em>Select Frequency</em></MenuItem>
                  <MenuItem value="ONCE">Once</MenuItem>
                  <MenuItem value="DAILY">Daily</MenuItem>
                  <MenuItem value="WEEKLY">Weekly</MenuItem>
                  <MenuItem value="MONTHLY">Monthly</MenuItem>
                </Select>
                {errors.delivery_frequency && <FormHelperText>{errors.delivery_frequency}</FormHelperText>}
              </FormControl>
              {reportData.delivery_frequency === 'DAILY' && (
                  <>
                  <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors.time_of_day}>
                        <Typography variant="body2">Time of Day</Typography>
                        <CustomFormControl
                            className="custom-form-select"
                            value={reportData.time_of_day}
                            onChange={(e) => setReportData({ ...reportData, time_of_day: String(e.target.value) })}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return (
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7"
                                        }}>Select a day</span>
                                    );
                                }
                                return selected;
                            }}
                            required
                            error={errors.time_of_day}
                            helperText={errors.time_of_day ? "This field is required" : ""}
                        >
                            <MenuItem disabled value="">
                                <span style={{
                                    fontSize: "12px",
                                    color: "#bab7b7",
                                    marginLeft: "4px"
                                }}>Select a day</span>
                            </MenuItem>
                            {Array.from({ length: 31 }, (_, i) => i + 1).map((num) => (
                                <MenuItem key={num} value={num} sx={{ fontSize: '0.75rem' }}>
                                    {num}
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                        {errors.time_of_day && <FormHelperText>{errors.time_of_day}</FormHelperText>}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.time_zone}>
                      <Typography variant="body2">Time zone</Typography>
                      <Select
                        className="form-select"
                        value={reportData.time_zone}
                        onChange={(e) => setReportData({ ...reportData, time_zone: String(e.target.value) })}
                         displayEmpty={true}
                         renderValue={(selected) => {
                          if(!selected) {
                            return (
                              <span style={{
                                  fontSize: "small",
                                  color: "#bab7b7"
                              }}>Select time Zone</span>
                            );
                          } 
                          const option = timezones.find(
                              (opt) => opt.value === selected
                          );
                          return option ? option.label : selected;

                      }}
                      error={!!errors.time_zone}
                      >
                        <MenuItem value="" disabled>
                          Select Time Zone
                        </MenuItem>
                        {timezones.length > 0 ? (
                          timezones.map((timezone) => (
                            <MenuItem key={timezone.id} value={timezone.id}>
                              {timezone.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No Timezones Available</MenuItem>
                        )}
                      </Select>
                      {errors.time_zone && <FormHelperText>{errors.time_zone}</FormHelperText>}
                    </FormControl>
                    </Grid>
                  </>
                )}
              {reportData.delivery_frequency === 'WEEKLY' && (
                  <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.day}>
                      <Typography variant="body2">Day of Week</Typography>
                      <CustomFormControl
                        className="custom-form-select"
                        multiple
                        value={reportData.day || []}
                        onChange={(e) => {
                          const { value } = e.target;
                          setReportData({ 
                            ...reportData, 
                            day: value 
                          });
                          setDayOfWeek(value)
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                              return (
                                  <span style={{ fontSize: "small", color: "#bab7b7" }}>Choose Day</span>
                              );
                          }
                          return selected
                              .map((value) => {
                                  const option = DayOfWeek.find(
                                      (opt) => opt === value
                                  );
                                  return option ? option : value;
                              })
                              .join(", ");
                      }}
                        required
                        error={errors.day}
                        helperText={errors.day ? "This field is required" : ""}
                        size="small"
                        MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    >
                        <MenuItem disabled value="">
                            <span
                                style={{
                                    fontSize: "12px",
                                    color: "#bab7b7",
                                    marginLeft: "4px",
                                }}
                            >
                              Choose Day
                            </span>
                        </MenuItem>
                        {DayOfWeek.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                sx={{ fontSize: "12px", color: "#373F41" }}
                            >
                                <Checkbox
                                    checked={reportData.day.includes(option)}
                                    sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 12,
                                        },
                                    }}
                                />
                                <ListItemText
                                    primary={option}
                                    primaryTypographyProps={{
                                        style: { fontSize: "12px", fontFamily: "Inter", color: "#373F41" },
                                    }}
                                />
                            </MenuItem>
                        ))}
                    </CustomFormControl>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 2 }}>
                        {reportData.day && reportData.day.map((value) => {
                            const option = DayOfWeek.find(opt => opt === value);
                            const label = option ? option : value;
                            return (
                                <Chip
                                    key={value}
                                    label={label}
                                    onDelete={() => handleDelete("day",value)} 
                                    size="small"
                                />
                            );
                        })}
                    </Box>
                    </FormControl>
                  </Grid>
                )}
                {reportData.delivery_frequency === 'MONTHLY' && (
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2">Day of Month</Typography>
                      <Select
                        fullWidth
                        className="form-select"
                        value={reportData.day_month || ''}
                        onChange={(e) => setReportData({ ...reportData, day_month: String(e.target.value) })}
                        displayEmpty={true}
                        renderValue={(selected) => {
                          if (!selected) {
                              return (
                                  <span style={{
                                      fontSize: "small",
                                      color: "#bab7b7"
                                  }}>Select a day</span>
                              );
                          }
                          return selected;
                      }}
                      >
                        <MenuItem value="" disabled>
                          Select Day
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                          <MenuItem  value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
            </Grid>
          </Grid>

           {/* Row 2 */}
           <Grid item xs={3} container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.convert_to}>
                <Typography variant="body2">Convert To</Typography>
                <Select
                  value={reportData.convert_to}
                  name="convert_to"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, convert_to: e.target.value });
                  }}
                >
                  <MenuItem disabled value="None Selected">None Selected</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
                {errors.convert_to && <FormHelperText>{errors.convert_to}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth error={!!errors.commissions}>
                <Typography variant="body2">Include commissions amount</Typography>
                <Select
                  value={reportData.commissions || "None Selected"}
                  name="commissions"
                  displayEmpty={true}
                  className="form-select"
                  renderValue={(selected) => {
                    if (!selected) {
                        return (
                            <span style={{
                                fontSize: "small",
                                color: "#bab7b7"
                            }}>None Selected</span>
                        );
                    }
                    const labelMapping = {
                      "META_COMMISSION": "Meta commission",
                      "META_&_DMP_COMMISSION": "Meta & DMP commission",
                      "PARTNER_COMMISSION": "Partner commission",
                    };
                    
                
                    return labelMapping[selected] || selected;
                }}
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, commissions: e.target.value });
                  }}
                >
                  <MenuItem disabled value="">None Selected</MenuItem>
                  <MenuItem value="META_COMMISSION">Meta commission</MenuItem>
                  <MenuItem value="META_&_DMP_COMMISSION">Meta & DMP commission</MenuItem>
                  <MenuItem value="PARTNER_COMMISSION">Partner commission</MenuItem>
                </Select>
                {errors.commissions && <FormHelperText>{errors.commissions}</FormHelperText>}
              </FormControl>
            </Grid>
                   

          {/* Row 3 */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={6}>
              <FormControl fullWidth error={!!errors.recipients}>
                <Typography variant="body2">Recipients</Typography>
               <Tags
                      whitelist={reportRecipients}
                      placeholder="Recipients"
                      settings={{
                          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      }}
                      value={reportRecipients}
                      defaultValue={reportRecipients.join(',')}
                      onChange={handleRecipientsChange}
                  />
                {errors.recipients && <FormHelperText>{errors.recipients}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          {/* Subject */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid  item xs={6}>
              <FormControl fullWidth error={!!errors.subject}>
                  <Typography variant="body2">Subject</Typography>
                  <TextField
                    name="subject"
                    fullWidth
                    placeholder="Subject"
                    className="form-input"
                    value={reportData.subject}
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, subject: e.target.value });
                    }}
                  />
                  {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File name */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid  item xs={6}>
              <FormControl fullWidth error={!!errors.export_name}>
                <Typography variant="body2">Export File Name</Typography>
                <TextField
                  name="export_name"
                  fullWidth
                  placeholder="[TYPE_REPORT]-[ID_REPORT]-[ACCOUNT]-[DATETIME]"
                  className="form-input"
                  value={reportData.export_name}
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_name: e.target.value });
                  }}
                />
                {errors.export_name && <FormHelperText>{errors.export_name}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

           {/* Export File Type */}
           <Grid container item spacing={3}  xs={12}>
            <Grid item xs={3}>
              <FormControl fullWidth error={!!errors.export_file_type}>
                <Typography variant="body2">Export File Type</Typography>
                <Select
                  fullWidth
                  value={reportData.export_file_type}
                  name="export_file_type"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_file_type: e.target.value });
                  }}
                  error={!!errors.export_file_type}
                >
                  <MenuItem value="CSV">CSV</MenuItem>
                  <MenuItem value="XLSV">Excel</MenuItem>
                </Select>
                {errors.export_file_type && (
                  <FormHelperText>{errors.export_file_type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          
          <Grid container sx={{ alignItems: 'flex-end' }}  item xs={12}>
              {showAdditionalSettings && (
              <>
                <Grid item xs={3}>
                  <FormControl fullWidth error={!!errors.attached_file}>
                    <Typography variant="body2">Attached File</Typography>
                    <Select
                      fullWidth
                      value={reportData.attached_file}
                      name="attached_file"
                      className="form-select"
                      onChange={(e) => {
                        handleInputChange(e);
                        setReportData({ ...reportData, attached_file: e.target.value });
                      }}
                      error={!!errors.attached_file}
                    >
                        <MenuItem value="ZIP_COMPRESSION">Zip file</MenuItem>
                        <MenuItem value="NO_COMPRESSION">Uncompressed file</MenuItem>
                        <MenuItem value="NO_ATTACHMENT">No attachment</MenuItem>
                    </Select>
                    {errors.attached_file && (
                      <FormHelperText>{errors.attached_file}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                { whiteLabelEmail.length != 0 &&  ( 
                  <Grid item xs={4} sx={{ marginLeft: '2rem' }}>
                    <FormControl fullWidth>
                      <Typography variant="body2">WhiteLabel Email</Typography>
                      <Select
                        fullWidth
                        className="form-select"
                        value={reportData.whitelabel_email || 'None Selected'}
                        onChange={(e) => setReportData({ ...reportData, whitelabel_email: String(e.target.value) })}
                        displayempty="true"
                      >
                      <MenuItem value="None Selected" disabled>
                        None Selected
                      </MenuItem>
                      {whiteLabelEmail.length > 0 ? (
                        whiteLabelEmail.map((whiteLabelEmail) => (
                          <MenuItem key={whiteLabelEmail.id} value={whiteLabelEmail.id}>
                            {whiteLabelEmail.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No Whitelabel Email Available</MenuItem>
                      )}
                    </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
              )}
          </Grid>

          </Grid>
          <Grid className="report-form-footer-section">
            <Grid container  spacing={2} item xs={12}  sx={{ alignItems: "baseline", marginLeft: "0rem" }} > 
              <div className="button-container">
                  <CustomButtonWithIcon className="report-form-button" onClick={handleCreateReport} >
                      {isEdit ? 'Update Report' : 'Create Report'} 
                  </CustomButtonWithIcon>
                  <CustomButtonWithIcon className="report-form-button" onClick={onClose} >
                      Cancel
                  </CustomButtonWithIcon>
              </div>
              <Grid item xs={8} sx={{ justifyItems: 'flex-end' }}  >
                <Grid item xs={12}>
                  <Button className="additional-settings" onClick={handleToggleSettings}  sx={{ marginRight: 2 }} >   <img className='setting-icon' src={DmpIcons.ActionsIconsSVG.CogsIconSVG}  alt="Setting" />  Additional Settings</Button>
                </Grid>
              </Grid>
            </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <FormControl className="error-message" fullWidth error={!!errors.export_method}>
                {errors.unspecified && <FormHelperText>{errors.unspecified}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>        
        </Grid>
        </Grid>
      </div>
    );
  };

  export default Reservations;
