import React from 'react';
import Button from '@mui/material/Button';

const CustomButtonWithIconComponent = ({
                                           color = 'primary',
                                           borderColor = 'transparent',
                                           hoverColor,
                                           width = 'auto',
                                           height = 'auto',
                                           borderRadius = '4px',
                                           title,
                                           titleColor = 'white',
                                           hoverTitleColor,
                                           icon: Icon,
                                           iconBorder = '0.5px solid #b0acac',
                                           iconSize = '14px',
                                           textStyle = {},
                                           onClick,
                                           disabled = false,
                                           children,
                                           key,
                                           ...props
                                       }) => {
    const iconSpacing = `calc(${iconSize} + 16px)`;

    return (
        <Button
            {...props}
            onClick={onClick}
            title={title}
            disabled={disabled}
            key={key}
            sx={{
                border: `1px solid ${borderColor}`,
                backgroundColor: color,
                color: titleColor,
                width: width,
                height: height,
                borderRadius: borderRadius,
                textTransform: 'none',
                transition: 'background-color 0.3s',
                display: 'flex',
                alignItems: 'center',
                justifyContent: Icon ? 'flex-start' : 'center',
                position: 'relative',
                paddingLeft: Icon ? iconSpacing : '16px',
                paddingRight: '16px',
                '&:hover': {
                    backgroundColor: hoverColor || color,
                    color: hoverTitleColor || titleColor,
                    border: `1px solid ${hoverColor || color}`,
                },
            }}
        >
            {Icon && (
                <span
                    style={{
                        boxSizing: 'border-box',
                        position: 'absolute',
                        left: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: iconBorder,
                        borderRadius: '50%',
                        width: iconSize,
                        height: iconSize,
                    }}
                >
          <Icon />
        </span>
            )}
            <span style={textStyle}>{children}</span>
        </Button>
    );
};

export default CustomButtonWithIconComponent;
