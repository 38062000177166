import React, {useCallback, useEffect, useMemo, useState} from "react";
import { DatePicker } from "../../components/DatePicker";
import {Box, Grid, TableContainer} from "@mui/material";
import "./new-homepage.scss";
import { useSelector } from "react-redux";
import DefaultLayout from "layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import {createAccountDatePayload} from "./utils/payload/accountDatePayload";
import {useParams} from "react-router-dom";
import {getKpiReporting} from "./utils/api/kpiReporting";
import MetricsComponent from "../../features/new-homepage/MetricsComponent";
import {getMetasearchReporting} from "./utils/api/metasearchReporting";
import ReportingMetricsTable from "../../features/new-homepage/ReportingMetricsTable";
import ChartComponent from "../../features/new-homepage/ChartComponent";
import {getChartsReporting} from "./utils/api/chartsReporting";
import DeviceComponent from "../../features/new-homepage/DeviceComponent";
import {createChannelAccountDatePayload} from "./utils/payload/channelAccountDatePayload";
import {getDeviceReporting} from "./utils/api/deviceReporting";
import {getTopHotelsReporting} from "./utils/api/topHotelsReporting";
import TopHotelsComponent from "../../features/new-homepage/TopHotelsComponent";
import TopMarketsComponent from "../../features/new-homepage/TopMarketsComponent";
import {getTopMarketsReporting} from "./utils/api/topMarketsReporting";

const NewHomepage = () => {
  const { idaccount } = useParams();
  const { calender } = useSelector((state) => state);
  const [deviceIdChannel, setDeviceIdChannel] = useState("GHA_META");
  const [topHotelsIdChannel, setTopHotelsIdChannel] = useState("GHA_META");
  const [topMarketsIdChannel, setTopMarketsIdChannel] = useState("GHA_META");
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);

  const [metrics, setMetrics] = useState([]);
  const [reportingMetrics, setReportingMetrics] = useState([]);
  const [chartsMetrics, setChartsMetrics] = useState([]);
  const [deviceMetrics, setDeviceMetrics] = useState([]);
  const [topHotelsMetrics, setTopHotelsMetrics] = useState([]);
  const [topMarketsMetrics, setTopMarketsMetrics] = useState([]);

  const accountDatePayload = useMemo(
      () => createAccountDatePayload(idaccount, calender),
      [idaccount, calender]
  );

  const channelAccountDeviceDatePayload = useMemo(() => {
    return createChannelAccountDatePayload(idaccount, deviceIdChannel, calender);
  }, [idaccount, deviceIdChannel, calender]);

  const channelAccountTopHotelsDatePayload = useMemo(() => {
    return createChannelAccountDatePayload(idaccount, topHotelsIdChannel, calender);
  }, [idaccount, topHotelsIdChannel, calender]);

  const channelAccountTopMarketsDatePayload = useMemo(() => {
    return createChannelAccountDatePayload(idaccount, topMarketsIdChannel, calender);
  }, [idaccount, topMarketsIdChannel, calender]);

  const handleDeviceDropdownChange = async (event) => {
    setDeviceMetrics([]);
    const newChannel = event.target.value;
    setDeviceIdChannel(newChannel);

    const payload = createChannelAccountDatePayload(idaccount, newChannel, calender);
    const response = await getDeviceReporting(payload);

    if (response.success) {
      setDeviceMetrics(response.data);
    } else {
      console.error("Error fetching device reporting data:", response.error);
    }
  };

  const handleTopHotelsDropdownChange = async (event) => {
    setTopHotelsMetrics([]);
    const newChannel = event.target.value;
    setTopHotelsIdChannel(newChannel);

    const payload = createChannelAccountDatePayload(idaccount, newChannel, calender);
    const response = await getTopHotelsReporting(payload);

    if (response.success) {
      setTopHotelsMetrics(response.data);
    } else {
      console.error("Error fetching device reporting data:", response.error);
    }
  };

  const handleTopMarketsDropdownChange = async (event) => {
    setTopMarketsMetrics([]);
    const newChannel = event.target.value;
    setTopMarketsIdChannel(newChannel);

    const payload = createChannelAccountDatePayload(idaccount, newChannel, calender);
    const response = await getTopMarketsReporting(payload);

    if (response.success) {
      setTopMarketsMetrics(response.data);
    } else {
      console.error("Error fetching device reporting data:", response.error);
    }
  };

  const fetchData = useCallback(async () => {
    if (!accountDatePayload || !channelAccountDeviceDatePayload ||
        !channelAccountTopHotelsDatePayload || !channelAccountTopMarketsDatePayload) {
      return;
    }

    setTopHotelsMetrics([]);
    setDeviceMetrics([]);
    setChartsMetrics([]);
    setTopMarketsMetrics([]);
    try {
      const [kpiResponse, metasearchResponse, chartsResponse, deviceResponse, topHotelsResponse, topMarketsResponse] = await Promise.all([
        getKpiReporting(accountDatePayload),
        getMetasearchReporting(accountDatePayload),
        getChartsReporting(accountDatePayload),
        getDeviceReporting(channelAccountDeviceDatePayload),
        getTopHotelsReporting(channelAccountTopHotelsDatePayload),
        getTopMarketsReporting(channelAccountTopMarketsDatePayload)
      ]);

      if (kpiResponse.success) setMetrics(kpiResponse.data);
      else console.error("Error fetching KPI data:", kpiResponse.error);

      if (metasearchResponse.success) setReportingMetrics(metasearchResponse.data);
      else console.error("Error fetching Metasearch data:", metasearchResponse.error);

      if (chartsResponse.success) setChartsMetrics(chartsResponse.data);
      else console.error("Error fetching Charts data:", chartsResponse.error);

      if (deviceResponse.success) setDeviceMetrics(deviceResponse.data);
      else console.error("Error fetching device reporting data:", deviceResponse.error);

      if (topHotelsResponse.success) setTopHotelsMetrics(topHotelsResponse.data);
      else console.error("Error fetching hotel reporting data:", topHotelsResponse.error);

      if (topMarketsResponse.success) setTopMarketsMetrics(topMarketsResponse.data)
      else console.error("Error fetching markets reporting data:", topHotelsResponse.error);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accountDatePayload, channelAccountDeviceDatePayload, channelAccountTopHotelsDatePayload, channelAccountTopMarketsDatePayload]);

  useEffect(() => {
    fetchData();
  }, [calender.currentDateRange.startDate, calender.currentDateRange.endDate, calender.comparitionDateRange.startDate,calender.comparitionDateRange.endDate]);

  return (
      <>
        <DefaultLayout page={null}>
          <Box sx={{ display: "flex", justifyContent: "space-between", marginRight: "18px" }}>
            <PageTitles pageTitle="Digital Marketing Platform" />
            <DatePicker />
          </Box>

          <Box sx={{ backgroundColor: "#F5F5F5", paddingTop: 2, paddingInline: "18px" }}>
            <MetricsComponent
                metrics={metrics}
                calender={calender}
            />

            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'stretch', paddingInline: '20px', paddingTop: '10px' }}>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <ReportingMetricsTable metrics={reportingMetrics} />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
                <Box style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: isChartCollapsed ? "none" : "block", flex: 1 }}>
                    <ChartComponent
                        menuOptions={chartsMetrics.metrics}
                        comparitionData={chartsMetrics.compareChartData}
                        rawChartData={chartsMetrics.chartData}
                        axisData={chartsMetrics.chartAxisDate}
                    />
                  </div>
                  <TableContainer setIsChartCollapsed={setIsChartCollapsed} isChartCollapsed={isChartCollapsed} />
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: 'flex', alignItems: 'stretch', paddingInline: '20px', paddingBlock: '10px' }}>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <DeviceComponent
                    selectedOption={deviceIdChannel}
                    handleDropdownChange={handleDeviceDropdownChange}
                    deviceMetrics={deviceMetrics}
                />
                <TopHotelsComponent
                    selectedOption={topHotelsIdChannel}
                    handleDropdownChange={handleTopHotelsDropdownChange}
                    topHotelsMetrics={topHotelsMetrics}
                />
              </Grid>
              <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                 <TopMarketsComponent
                     selectedOption={topMarketsIdChannel}
                     handleDropdownChange={handleTopMarketsDropdownChange}
                     topMarketsMetrics={topMarketsMetrics}
                 />
              </Grid>
            </Grid>
          </Box>
        </DefaultLayout>
      </>
  );
};

export default NewHomepage;