import axios from "axios";
import {APP_GLOBAL_REPORTING_API_ENDPOINT} from "../../../../constants/api_urls";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Fetches device reporting data from the API.
 *
 * @async
 * @function getDeviceReporting
 * @param {Object} payload - The query parameters for the API request.
 * @param {string} payload.deviceId - (Optional) The ID of the device to filter by.
 * @param {string} payload.startDate - (Optional) The start date for the report range.
 * @param {string} payload.endDate - (Optional) The end date for the report range.
 * @returns {Promise<Object>} The result of the API call.
 * @property {boolean} success - Indicates if the API call was successful.
 * @property {Object} [data] - The data returned from the API if successful.
 * @property {Object} [error] - The error object if the API call failed.
 *
 */
export const getTopMarketsReporting = async (payload) => {
    const jwtToken = getUserToken();
    try {
        const response = await axios.get(
            `${APP_GLOBAL_REPORTING_API_ENDPOINT}/api/dashboard/top-markets/`,
            {
                params: payload,
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        return { success: false, error };
    }
};

