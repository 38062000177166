import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./KeywordsTabs.scss";
import { validateKeywords, validateOneKeyword } from "../Utils/validators";
import CustomFormControl from "../../../alert-center/Dropdown/CustomFormControl";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const KeywordsAccordion = ({
  keywords,
  setKeywords,
  negativeKeywords,
  setNegativeKeywords,
  errors,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [keywordStatus, setkeywordStatus] = useState(null);

  const handleKeywordsBlur = async (index, type, text) => {
    const list = type === "keyword" ? keywords : negativeKeywords;
    const result = await validateOneKeyword({
      keywordText: text,
      matchType: list?.find((_, i) => i === index)?.matchType,
    });
    setkeywordStatus(
      result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid keyword" }
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddKeyword = () => {
    const newEntry = { matchType: "", keyword: "" };
    if (activeTab === 0) {
      setKeywords([...keywords, newEntry]);
    } else {
      setNegativeKeywords([...negativeKeywords, newEntry]);
    }
  };

  const handleRemoveKeyword = (index, type) => {
    if (type === "keyword") {
      setKeywords(keywords.filter((_, i) => i !== index));
    } else if (type === "negative") {
      setNegativeKeywords(negativeKeywords.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (index, type, field, value) => {
    const updateKeywords = (list) =>
      list.map((item, i) => (i === index ? { ...item, [field]: value } : item));

    if (type === "keyword") {
      setKeywords(updateKeywords(keywords));
    } else if (type === "negative") {
      setNegativeKeywords(updateKeywords(negativeKeywords));
    }
  };

  const renderKeywordFields = (keywordsArray, type, errorArray) =>
    keywordsArray.map((item, index) => (
      <Box key={index} className="keyword-field">
        <CustomFormControl
          error={errorArray && errorArray[index]}
          value={item.matchType}
          onChange={(e) =>
            handleInputChange(index, type, "matchType", e.target.value)
          }
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return (
                <span style={{ fontSize: "small", color: "#bab7b7" }}>
                  Select Match Type
                </span>
              );
            }
            const matchTypeLabels = {
              EXACT: "Exact",
              BROAD: "Broad Match",
              PHRASE: "Phrase Match",
            };
            return matchTypeLabels[selected] || selected;
          }}
        >
          <MenuItem value="" disabled>
            <span style={{ fontSize: "12px", color: "#373F41" }}>
              Select Match Type
            </span>
          </MenuItem>
          <MenuItem value="EXACT">
            <span style={{ fontSize: "12px", color: "#373F41" }}>Exact</span>
          </MenuItem>
          <MenuItem value="BROAD">
            <span style={{ fontSize: "12px", color: "#373F41" }}>
              Broad Match
            </span>
          </MenuItem>
          <MenuItem value="PHRASE">
            <span style={{ fontSize: "12px", color: "#373F41" }}>
              Phrase Match
            </span>
          </MenuItem>
        </CustomFormControl>

        <CustomOutlinedInput
          value={item.keyword}
          onChange={(e) =>
            handleInputChange(index, type, "keyword", e.target.value)
          }
          onBlur={(e) => handleKeywordsBlur(index, type, e.target.value)}
          placeholder="Enter keyword"
          size="small"
          className="keyword-input no-border-focus"
          inputProps={{ maxLength: 35 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span style={{ fontSize: "12px" }}>
                  {`${item.keyword?.length}/35`}
                </span>
              </InputAdornment>
            ),
          }}
          helperText={keywordStatus ? keywordStatus.error || "Keyword" : ""}
          error={keywordStatus && keywordStatus.success === false}
        />
        <IconButton
          onClick={() => handleRemoveKeyword(index, type)}
          className="remove-button"
        >
          <RemoveIcon />
        </IconButton>
      </Box>
    ));

  return (
    <Box className="search-ads-form-keywords">
      <Box className="tabs-container">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: 0 }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className="custom-tabs"
            TabIndicatorProps={{ style: { display: "none" } }}
            sx={{ minHeight: "unset" }}
          >
            <Tab
              label="Keywords"
              className={
                activeTab === 0 ? "keyword-tab active-tab" : "keyword-tab"
              }
              sx={{ padding: 0, minHeight: "unset" }}
            />
            <Tab
              label="Negative Keywords"
              className={
                activeTab === 1
                  ? "negative-keyword-tab active-tab"
                  : "negative-keyword-tab"
              }
              sx={{ padding: 0, minHeight: "unset" }}
            />
          </Tabs>
          <IconButton
            onClick={handleAddKeyword}
            color="primary"
            className="add-button"
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box className="keywords-content" p={2}>
        {activeTab === 0 &&
          renderKeywordFields(keywords, "keyword", errors.keywords)}
        {activeTab === 1 &&
          renderKeywordFields(
            negativeKeywords,
            "negative",
            errors.negativeKeywords
          )}
      </Box>
    </Box>
  );
};

export default KeywordsAccordion;
