import React, { useState } from "react";
import { Typography, Box, TextField, Grid } from "@mui/material";
import Section from "./Templates/Section";
import "./AdsCreativeAccordion.scss";
import SiteLinkExtensionSection from "./Templates/SiteLinkExtensionSection";
import CallsSection from "./Templates/CallsSection";
import {
  validateBusinessName,
  validateCallout,
  validateDescription,
  validateFinalURL,
  validateHeadline,
  validatePath,
  validatePhoneNumber,
  validateSiteLink,
  validateSnippet,
} from "../Utils/validators";

import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const generateId = () => Date.now() + Math.random().toString(36).substr(2, 9);

const AdsCreativeAccordion = ({
  headlines,
  setHeadlines,
  descriptions,
  setDescriptions,
  siteLinks,
  setSiteLinks,
  callOuts,
  setCallOuts,
  structuredSnippets,
  setStructuredSnippets,
  finalURL,
  setFinalURL,
  businessName,
  setBusinessName,
  calls,
  setCalls,
  displayPaths,
  setDisplayPaths,
  isEdit = false,
}) => {
  const [headlineStatus, setHeadlineStatus] = useState({});
  const [descriptionStatus, setDescriptionStatus] = useState({});
  const [businessNameStatus, setBusinessNameStatus] = useState(null);
  const [finalURLStatus, setFinalURLStatus] = useState(null);
  const [calloutStatus, setCalloutStatus] = useState({});
  const [snippetStatus, setSnippetStatus] = useState({});
  const [callStatus, setCallStatus] = useState({});
  const [siteLinkStatus, setSiteLinkStatus] = useState({});
  const [displayPathStatus, setDisplayPathStatus] = useState({});

  const addHeadline = () =>
    setHeadlines([...headlines, { id: generateId(), text: "" }]);
  const addDescription = () =>
    setDescriptions([...descriptions, { id: generateId(), text: "" }]);
  const addSiteLink = () =>
    setSiteLinks([...siteLinks, { id: generateId(), text: "" }]);
  const addCallOut = () =>
    setCallOuts([...callOuts, { id: generateId(), text: "" }]);
  const addStructuredSnippet = () =>
    setStructuredSnippets([
      ...structuredSnippets,
      { id: generateId(), text: "" },
    ]);

  const handleInputChange = (setter) => (id, key, value) => {
    setter((items) =>
      items.map((item) => (item.id === id ? { ...item, [key]: value } : item))
    );
  };

  const handleRemove = (setter) => (id) => {
    setter((items) => items.filter((item) => item.id !== id));
  };

  const handleHeadlineBlur = async (id, text) => {
    if (!text.trim()) return;

    const result = await validateHeadline(text);
    setHeadlineStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid headline" },
    }));
  };

  const handleDescriptionBlur = async (id, text) => {
    if (!text.trim()) return;

    const result = await validateDescription(text);
    setDescriptionStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid description" },
    }));
  };

  const handleSnippetBlur = async (id, text) => {
    if (!text.trim()) return;

    const result = await validateSnippet(text);
    setSnippetStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid description" },
    }));
  };

  const handleDisplayPathsBlur = async (id, text) => {
    if (!text.trim()) return;

    const result = await validatePath({
      ...displayPaths.reduce((acc, curr) => {
        acc[curr.id] = curr.text;
        return acc;
      }, {}),
    });
    setDisplayPathStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid display path" },
    }));
  };

  const handleBusinessNameBlur = async (text) => {
    if (!text.trim()) return;

    const result = await validateBusinessName(text);
    setBusinessNameStatus(
      result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid business name" }
    );
  };

  const handleFinalURLBlur = async (text) => {
    if (!text.trim()) return;

    const result = await validateFinalURL(text);
    setFinalURLStatus(
      result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid final URL" }
    );
  };

  const handleCalloutBlur = async (id, text) => {
    if (!text.trim()) return;

    const result = await validateCallout(text);
    setCalloutStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid callout" },
    }));
  };

  const handleSiteLinkBlur = async (id) => {
    const siteLink = siteLinks?.find((s) => s?.id === id);
    const result = await validateSiteLink({
      siteLinkText: siteLink?.sitelinkText,
      siteLinkDescription1: siteLink?.description1,
      siteLinkDescription2: siteLink?.description2,
      siteLinkFinalUrl: siteLink?.finalURL,
    });
    setSiteLinkStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid site link" },
    }));
  };

  const handleCallsBlur = async (id) => {
    const call = calls?.find((c) => c?.id === id);
    const result = await validatePhoneNumber({
      phoneCountryCode: call?.countryCode,
      phoneNumber: call?.phoneNumber,
    });
    setCallStatus((prev) => ({
      ...prev,
      [id]: result.success
        ? { success: true, data: result?.data }
        : { success: false, error: result.error || "Invalid phone number" },
    }));
  };

  return (
    <Box className="adsCreativeContainer">
      <Box className="property-section">
        <Box display="flex" alignItems="center" className="header-box">
          <Typography variant="subtitle1">PROPERTY</Typography>
        </Box>
        <Box display="flex" gap={2} mt={1}>
          {/* Champ "Final URL" avec boîte invisible à droite */}
          <Box display="flex" alignItems="center" flexGrow={1}>
            <CustomOutlinedInput
              placeholder="Final URL"
              className="no-border-focus"
              fullWidth
              value={finalURL}
              onChange={(e) => setFinalURL(e.target.value)}
              onBlur={() => handleFinalURLBlur(finalURL)}
              helperText={
                finalURLStatus
                  ? finalURLStatus.error || "Final URL"
                  : "Final URL"
              }
              error={finalURLStatus && finalURLStatus.success === false}
              success={finalURLStatus && finalURLStatus.success === true}
            />
            {/* Boîte invisible pour réserver de l'espace à droite */}
            <Box width="28px" height="28px" />
          </Box>

          {/* Champ "Business Name" avec boîte invisible à droite */}
          <Box display="flex" alignItems="center" flexGrow={1}>
            <CustomOutlinedInput
              placeholder="Business Name"
              className="no-border-focus"
              fullWidth
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              onBlur={() => handleBusinessNameBlur(businessName)}
              helperText={
                businessNameStatus
                  ? businessNameStatus.error || "Business Name"
                  : "Business Name"
              }
              error={businessNameStatus && businessNameStatus.success === false}
              success={
                businessNameStatus && businessNameStatus.success === true
              }
            />
            {/* Boîte invisible pour réserver de l'espace à droite */}
            <Box width="28px" height="28px" />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} alignItems="flex-start">
        <Section
          title="HEADLINE"
          items={headlines}
          addItem={addHeadline}
          handleInputChange={handleInputChange(setHeadlines)}
          handleRemove={handleRemove(setHeadlines)}
          maxLengthInput={35}
          isEdit={isEdit}
          onBlur={handleHeadlineBlur}
          status={headlineStatus}
        />
        <Section
          title="DESCRIPTION"
          items={descriptions}
          addItem={addDescription}
          handleInputChange={handleInputChange(setDescriptions)}
          handleRemove={handleRemove(setDescriptions)}
          maxLengthInput={90}
          isEdit={isEdit}
          onBlur={handleDescriptionBlur}
          status={descriptionStatus}
        />
        <SiteLinkExtensionSection
          title="SITE LINK EXTENSION"
          items={siteLinks}
          addItem={addSiteLink}
          handleInputChange={handleInputChange(setSiteLinks)}
          handleRemove={handleRemove(setSiteLinks)}
          onBlur={handleSiteLinkBlur}
          status={siteLinkStatus}
        />
        <Section
          title="CALL OUT EXTENSION"
          items={callOuts}
          addItem={addCallOut}
          handleInputChange={handleInputChange(setCallOuts)}
          handleRemove={handleRemove(setCallOuts)}
          onBlur={handleCalloutBlur}
          status={calloutStatus}
        />
        <Section
          title="STRUCTURED SNIPPET"
          items={structuredSnippets}
          addItem={addStructuredSnippet}
          handleInputChange={handleInputChange(setStructuredSnippets)}
          handleRemove={handleRemove(setStructuredSnippets)}
          onBlur={handleSnippetBlur}
          status={snippetStatus}
        />
        <CallsSection
          calls={calls}
          handleInputChange={handleInputChange(setCalls)}
          onBlur={handleCallsBlur}
          callStatus={callStatus}
        />
        <Section
          title="DISPLAY PATH"
          items={displayPaths}
          handleInputChange={handleInputChange(setDisplayPaths)}
          onBlur={handleDisplayPathsBlur}
          status={displayPathStatus}
        />
      </Grid>
    </Box>
  );
};

export default AdsCreativeAccordion;
