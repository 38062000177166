import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Builder, Query, Utils as QbUtils } from '@react-awesome-query-builder/ui';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import { sqlToJsonLogic } from '../../Functions/index';
import "./form.scss";
import Tags from "@yaireo/tagify/dist/react.tagify";
import {FormControl, MenuItem, Typography, RadioGroup, FormControlLabel, Radio, Checkbox, Box, Chip} from '@mui/material';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Icons from "components/DmpIcons/icons";
import { useParams } from "react-router-dom";
import CustomFormControl from "../../Dropdown/CustomFormControl";
import { APP_BIDDING_CENTER_API_ENDPOINT } from "../../../../constants/api_urls";
import QueryBuilderConfig from "./QueryBuilderConfig";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";



const BiddingRuleTripadvisor = ({ biddingRuleData, onClose, actionType }) => {
    const [biddingRuleName, setBiddingRuleName] = useState(biddingRuleData?.bidding_rule_name || '');
    const [biddingRuleShortName, setBiddingRuleShortName] = useState(biddingRuleData?.bidding_rule_shortname || '');
    const [metricComparison, setMetricComparison] = useState(biddingRuleData?.metrics_compare || '');
    const [biddingFrequencyType, setBiddingFrequencyType] = useState(biddingRuleData?.bidding_frequency_type || 'DAILY');
    const [biddingFrequencyDaily, setBiddingFrequencyDaily] = useState(biddingRuleData?.bidding_frequency_daily || '');
    const [biddingFrequencyWeek, setBiddingFrequencyWeek] = useState(biddingRuleData?.bidding_frequency_week || []);
    const [rulesTriggered, setRulesTriggered] = useState(biddingRuleData?.bid_change_type || null);
    const [rulesNotTriggered, setRulesNotTriggered] = useState(biddingRuleData?.bid_change_type_inv || null);
    const [alertRecipients, setAlertRecipients] = useState(biddingRuleData?.alert_recipient || []);
    const [bidMax, setBidMax] = useState(biddingRuleData?.bid_max || "");
    const [bidMin, setBidMin] = useState(biddingRuleData?.bid_min || "");
    const [bidChange, setBidChange] = useState(biddingRuleData?.bid_change ?? "");
    const [bidChangeInv, setBidChangeInv] = useState(biddingRuleData?.bid_change_inv ?? "");
    const [alertNotif, setAlertNotif] = useState(biddingRuleData.alert_notif || 0);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [tree, setTree] = useState(null);

    const [errors, setErrors] = useState({
        biddingRuleName: false,
        biddingRuleShortName: false,
        metricComparison: false,
        rulesTriggered: false,
        rulesNotTriggered: false,
        bidChange: false,
        bidChangeInv: false,
        bidMax: false,
        bidMin: false,
        alertRecipients: false,
        biddingFrequencyDaily: false,
        biddingFrequencyWeek: false,
    });

    const { idaccount } = useParams();
    const apiEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/google-hotel-ads-metrics-bidding-rules`;
    let apiActionEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/google-hotel-ads-add-bidding-rules`;
    const apiListTemplatesEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/list-template/4`;
    let method = 'POST';
    const jwtToken = sessionStorage.getItem('token');

    if (actionType === 'edit') {
        apiActionEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/google-hotel-ads-edit-bidding-rules`;
        method = 'PUT';
    }

    const tagifyRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [valueFromToLines, setValueFromToLines] = useState([]);
    const [valueFromToLinesNotTriggered, setValueFromToLinesNotTriggered] = useState([]);

    const rulesDropdownValue = [
        { title: "Increase Multiplier by %", abbr: "Incr by %", value: 0 },
        { title: "Decrease Multiplier by %", abbr: "Decr by %", value: 1 },
    ];



    const daysOfWeek = [
        { title: 'Monday', abbr: 'Mo', value: 1 },
        { title: 'Tuesday', abbr: 'Tu', value: 2 },
        { title: 'Wednesday', abbr: 'We', value: 3 },
        { title: 'Thursday', abbr: 'Th', value: 4 },
        { title: 'Friday', abbr: 'Fr', value: 5 },
        { title: 'Saturday', abbr: 'Sa', value: 6 },
        { title: 'Sunday', abbr: 'Su', value: 7 },
    ];

    const biddingFrequencyOptions = [
        { title: 'Daily', value: 'DAILY', abbr: 'Daily' },
        { title: 'Day of Week', value: 'DAY_OF_WEEK', abbr: 'Day of week' },
    ];

    const metricComparisonOptions = Array.from({ length: 10 }, (_, i) => ({ title: `${i + 1}` }));

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setBiddingFrequencyWeek(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (biddingRuleData) {
            console.log("Bidding Rule Data:", biddingRuleData);
            setBiddingRuleName(biddingRuleData.bidding_rule_name || '');
            setBiddingRuleShortName(biddingRuleData.bidding_rule_shortname || '');
            setMetricComparison(biddingRuleData.metrics_compare || '');
            if (biddingRuleData.bidding_frequency_type === "0") {
                setBiddingFrequencyType("DAILY");
                setBiddingFrequencyDaily(biddingRuleData.bidding_frequency_daily.toString() || '');
                setBiddingFrequencyWeek([]); // Réinitialiser la fréquence hebdomadaire si type est DAILY
            } else if (biddingRuleData.bidding_frequency_type === "1") {
                setBiddingFrequencyType("DAY_OF_WEEK");
                setBiddingFrequencyDaily(''); // Réinitialiser la fréquence journalière si type est DAY_OF_WEEK
                const weekDays = biddingRuleData.bidding_frequency_week[0]
                    .split(',')
                    .map(Number);
                setBiddingFrequencyWeek(weekDays);
            }
            setRulesTriggered(biddingRuleData.bid_change_type ?? null);
            setRulesNotTriggered(biddingRuleData.bid_change_type_inv ?? null);

            const recipients = biddingRuleData.alert_recipient || [];
            const splitRecipients = recipients.flatMap(recipient => recipient.split(',').map(email => email.trim()));
            setAlertRecipients(splitRecipients);
            setBidMax(biddingRuleData.bid_max || "");
            setBidMin(biddingRuleData.bid_min || "");
            setBidChange(biddingRuleData.bid_change ?? "");
            setBidChangeInv(biddingRuleData.bid_change_inv ?? "");
            setAlertNotif(biddingRuleData.alert_notif || 0);

            if (biddingRuleData.steps_rule_triggered) {
                const updatedLinesTriggered = biddingRuleData.steps_rule_triggered.map((step, index) => ({
                    id: index + 1,
                    valueToApply: step.value_to_apply,
                    stepStart: step.step_start,
                    stepEnd: step.step_end,
                }));
                setValueFromToLines(updatedLinesTriggered);
            }

            // Update valueFromToLinesNotTriggered
            if (biddingRuleData.steps_rule_not_triggered) {
                const updatedLinesNotTriggered = biddingRuleData.steps_rule_not_triggered.map((step, index) => ({
                    id: index + 1,
                    valueToApply: step.value_to_apply,
                    stepStart: step.step_start,
                    stepEnd: step.step_end,
                }));
                setValueFromToLinesNotTriggered(updatedLinesNotTriggered);
            }
        }
    }, [biddingRuleData]);

    const isGroupSelected = (ruleValue, groupValues) => {
        return groupValues.includes(ruleValue);
    };

    const availableRules = (selectedRule, otherRule) => {
        const increaseRulesValues = [0]; // Increased by %, Increased by fixed value
        const decreaseRulesValues = [1]; // Decreased by %, Decreased by fixed value

        if (isGroupSelected(otherRule, increaseRulesValues)) {
            return rulesDropdownValue.filter((rule) =>
                decreaseRulesValues.includes(rule.value)
            );
        }

        if (isGroupSelected(otherRule, decreaseRulesValues)) {
            return rulesDropdownValue.filter((rule) =>
                increaseRulesValues.includes(rule.value)
            );
        }

        return rulesDropdownValue;
    };

    const availableRulesTriggered = availableRules(rulesTriggered, rulesNotTriggered);
    const availableRulesNotTriggered = availableRules(rulesNotTriggered, rulesTriggered);

    const addLine = () => {
        setValueFromToLines([...valueFromToLines, { id: valueFromToLines.length + 1, valueToApply: '', stepStart: '', stepEnd: '' }]);
    };

    const removeLine = (id) => {
        setValueFromToLines(valueFromToLines.filter(line => line.id !== id));
    };

    const addLineNotTriggered = () => {
        setValueFromToLinesNotTriggered([...valueFromToLinesNotTriggered, { id: valueFromToLinesNotTriggered.length + 1, valueToApply: '', stepStart: '', stepEnd: '' }]);
    };

    const removeLineNotTriggered = (id) => {
        setValueFromToLinesNotTriggered(valueFromToLinesNotTriggered.filter(line => line.id !== id));
    };

    const handleValueFromToLineChangeNotTriggered = (index, field, value) => {
        const updatedLines = [...valueFromToLinesNotTriggered];
        updatedLines[index][field] = value;
        setValueFromToLinesNotTriggered(updatedLines);
    };

    const handleValueFromToLineChange = (index, field, value) => {
        const updatedLines = [...valueFromToLines];
        updatedLines[index][field] = value;
        setValueFromToLines(updatedLines);
    };

    const dailyFrequencyOptions = Array.from({ length: 10 }, (_, i) => ({
        title: `${i + 1}`,
        abbr: `${i + 1}`,
    }));

    // Étape 2: Utiliser la configuration personnalisée
    const [config, setConfig] = useState(QueryBuilderConfig);

    const loadQueryBuilderFromSQL = (sqlRule) => {
        const tree = QbUtils.loadFromJsonLogic(sqlToJsonLogic(sqlRule), config);
        setTree(tree);
    }

    const handleBiddingFrequencyTypeChange = (e) => {
        const value = e.target.value;
        setBiddingFrequencyType(value);
        // Reset the other field's state
        setBiddingFrequencyDaily('');
        setBiddingFrequencyWeek([]);
    };

    useEffect(() => {
        console.log("biddingRuleData : " + JSON.stringify(biddingRuleData))
        if (rulesTriggered && config.fields && Object.keys(config.fields).length > 0) {
            const generatedTree = QbUtils.loadFromJsonLogic(sqlToJsonLogic(biddingRuleData.rules), config);
            setTree(generatedTree);
        } else {
            console.log("tree initialization")
            const emptyTree = QbUtils.checkTree(QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }), config)
            console.log("emptyTree : " + emptyTree)
            setTree(emptyTree); // Initialiser un arbre vide pour un ajout
        }
    }, [biddingRuleData, config]);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                const fields = generateFieldsFromMetrics(result);
                console.log('Metrics:', JSON.stringify(result.label));
                setConfig(prevConfig => ({
                    ...QueryBuilderConfig,
                    fields: fields,
                    settings: {
                        ...prevConfig.settings,
                        showNot: false,
                        canReorder: false,
                        groupOperators: ['AND', 'OR'],
                        forceShowConj: true,
                    },
                }));

            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchMetrics();
    }, [apiEndpoint, jwtToken]);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch(apiListTemplatesEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                const templatesData = data.map(template => ({
                    name: template.template_name,
                    rule: template.rules_sql
                }));
                setTemplates(templatesData);
                console.log('Templates and Rules:', templatesData);

            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchTemplates();
    }, [apiListTemplatesEndpoint, jwtToken]); // Correction de la dépendance apiListTemplatesEndpoint

    const generateFieldsFromMetrics = (metrics) => {
        const fields = {};
        metrics.forEach((metric) => {
            fields[metric.id] = {
                label: metric.label,
                type: 'number',
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
                operators: metric.operators,
                widgets: {
                    number: {
                        operators: metric.operators,
                    }
                }
            };
        });
        console.log('Fields:', fields);
        return fields;
    };

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value); // Parser la chaîne JSON
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value.split(',').map(email => email.trim())).flat();
        setAlertRecipients(updatedRecipients);
    }, []);

    const submitTripadvisorBiddingRule = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                onClose();
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                setSnackbarMessage('Error saving alert: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                const newErrors = {
                    biddingRuleName: !biddingRuleName,
                    biddingRuleShortName: !biddingRuleShortName,
                    metricComparison: !metricComparison,
                    rulesTriggered: !rulesTriggered,
                    rulesNotTriggered: !rulesNotTriggered,
                    bidChange: !bidChange,
                    bidChangeInv: !bidChangeInv,
                    bidMax: !bidMax,
                    bidMin: !bidMin,
                    alertRecipients: alertRecipients.length === 0,
                    biddingFrequencyWeek: biddingFrequencyType === 'DAY_OF_WEEK' && biddingFrequencyWeek.length === 0,
                    biddingFrequencyDaily: biddingFrequencyType === 'DAILY' && !biddingFrequencyDaily,
                };
                setErrors(newErrors);
            });
    };

    const handleSubmit = async () => {
        console.log("submit triggered")
        const sqlFormat = QbUtils.sqlFormat(tree, config);

        const stepsRuleTriggered = valueFromToLines.map(line => ({
            step_start: line.stepStart,
            step_end: line.stepEnd,
            value_to_apply: line.valueToApply,
        }));

        const stepsRulesNotTriggered = valueFromToLinesNotTriggered.map(line => ({
            step_start: line.stepStart,
            step_end: line.stepEnd,
            value_to_apply: line.valueToApply,
        }));

        if (biddingFrequencyType === "DAY_OF_WEEK") {
            setBiddingFrequencyDaily(1);
        }
        if (biddingFrequencyType === "DAILY") {
            setBiddingFrequencyWeek([]);
        }

        const body = {
            id_account: idaccount,
            status: "ENABLED",
            bidding_rule_name: biddingRuleName,
            bidding_rule_shortname: biddingRuleShortName,
            alert_notif: alertNotif,
            alert_recipient: alertRecipients,
            bidding_frequency_type: biddingFrequencyType,
            bidding_frequency_daily: biddingFrequencyDaily,
            bidding_frequency_week: biddingFrequencyWeek,
            bid_max: bidMax,
            bid_min: bidMin,
            max_bid: 1,
            max_bid_inv: 1,
            bid_change: bidChange,
            bid_change_type: rulesTriggered,
            bid_change_inv: bidChangeInv,
            bid_change_type_inv: rulesNotTriggered,
            rules: sqlFormat,
            metrics_compare: metricComparison,
            steps_rule_triggered: stepsRuleTriggered,
            steps_rule_not_triggered: stepsRulesNotTriggered,
        };

        if (actionType === "edit") {
            body.id = biddingRuleData.id;
        }
            submitTripadvisorBiddingRule(body);
    };


    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        console.log("Updated Tree:", JSON.stringify(QbUtils.getTree(immutableTree), null, 2));
    };

    useEffect(() => {
        console.log("Bidding Rule Data:", {
            biddingRuleName,
            biddingRuleShortName,
            metricComparison,
            biddingFrequencyType,
            biddingFrequencyDaily,
            biddingFrequencyWeek,
            rulesTriggered,
            rulesNotTriggered,
            alertRecipients,
            bidChange,
            bidChangeInv,
            bidMin,
            bidMax,
            alertNotif
        });
    }, [biddingRuleName, biddingRuleShortName, rulesTriggered, rulesNotTriggered, biddingFrequencyType, bidChange, bidChangeInv, biddingFrequencyDaily, biddingFrequencyWeek, metricComparison, alertRecipients, bidMin, bidMax, alertNotif]);

    const handleTemplatesChange = (event, value) => {
        setSelectedTemplate(value);
        if (value) {
            const sqlRule = value.rule;
            loadQueryBuilderFromSQL(sqlRule);
        }
    };

    return (
        <div className="bidding-rule-form">
            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Bidding Rule Name and Short Name */}
            <div className="bidding-rule-name-container">
                <div className="bidding-rule-full-name">
                    <div>
                        <label>BIDDING RULE NAME</label>
                    </div>
                    <TextField
                        placeholder="Rule Name:"
                        variant="standard"
                        value={biddingRuleName}
                        required
                        error={errors.biddingRuleName}
                        helperText={errors.biddingRuleName ? 'This field is required' : ''}
                        onChange={(e) => setBiddingRuleName(e.target.value)}
                        sx={{ '& ::placeholder': { fontSize: '12px' },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                            },
                        }}
                    />
                </div>
                <div className="bidding-rule-short-name">
                    <div>
                        <label>SHORT NAME</label>
                    </div>
                    <TextField
                        placeholder="Short Name: (displayed in campaign settings)"
                        variant="standard"
                        value={biddingRuleShortName}
                        required
                        error={errors.biddingRuleShortName}
                        helperText={errors.biddingRuleShortName ? 'This field is required' : ''}
                        onChange={(e) => setBiddingRuleShortName(e.target.value)}
                        sx={{ '& ::placeholder': { fontSize: '12px' },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                            },
                        }}
                    />
                </div>
            </div>

            {/* Metric Analysis and Bidding Frequency */}
            <div className="bidding-rule-metric-analysis-container">
                <div className="bidding-frequency-container">
                    <div className="metric-analysis">
                        <Typography sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            fontFamily: "Inter, Serif",
                            lineHeight: "12.1px",
                        }}>
                            METRIC ANALYSIS PERIOD
                        </Typography>

                        <div className="bidding-auto-complete">
                            {/* Utilisation de CustomFormControl */}
                            <CustomFormControl
                                value={metricComparison || ''}
                                onChange={(event) => setMetricComparison(event.target.value)}
                                displayEmpty
                                error={errors.metricComparison}
                                helperText={errors.metricComparison ? 'This field is required' : ''}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return (
                                            <span style={{
                                                fontSize: "small",
                                                color: "#bab7b7"
                                            }}># of days</span>
                                        );
                                    }
                                    return `${selected} day(s)`;
                                }}
                            >
                                <MenuItem disabled value="">
                                    <span style={{
                                        fontSize: "12px",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}># of days</span>
                                </MenuItem>
                                {metricComparisonOptions.map((option) => (
                                    <MenuItem key={option.title} value={option.title} sx={{ fontSize: '0.75rem' }}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </CustomFormControl>
                        </div>
                    </div>

                    <div className="bidding-frequency-checkboxes">
                        <div className="bidding-frequency-label">
                            <label>BIDDING FREQUENCY</label>
                        </div>
                        <div className="bidding-frequency-checkboxes-container">
                            <CustomFormControl
                                value={biddingFrequencyType || ''} // Associe l'état biddingFrequencyType
                                onChange={(event) => setBiddingFrequencyType(event.target.value)} // Met à jour l'état
                                displayEmpty
                                error={errors.biddingFrequencyType}
                                helperText={errors.biddingFrequencyType ? 'This field is required' : ''}
                                renderValue={(selected) => {
                                    if (!selected) { // Vérifie si aucune valeur n'est sélectionnée
                                        return (
                                            <span style={{
                                                fontSize: "small",
                                                color: "#bab7b7"
                                            }}>days</span>
                                        );
                                    }

                                    // Trouve l'option correspondant à la valeur sélectionnée
                                    const option = biddingFrequencyOptions.find((opt) => opt.value === selected);
                                    return option ? option.abbr : selected; // Affiche l'abréviation ou la valeur brute
                                }}
                            >
                                <MenuItem disabled value="">
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}>Daily frequency</span>
                                </MenuItem>
                                {biddingFrequencyOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{ fontSize: '0.75rem' }}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </CustomFormControl>

                        </div>
                    </div>

                    <div className="frequency-fields">
                        {biddingFrequencyType === 'DAY_OF_WEEK' && (
                            <div className="frequency-field">
                                <div className="bidding-frequency-label">
                                    <label>DAYS OF WEEK</label>
                                </div>
                                <CustomFormControl
                                    multiple
                                    value={biddingFrequencyWeek}
                                    onChange={handleChange}
                                    displayEmpty
                                    error={errors.biddingFrequencyWeek}
                                    helperText={errors.biddingFrequencyWeek ? 'This field is required' : ''}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <span style={{
                                                    fontSize: "small",
                                                    color: "#bab7b7"
                                                }}>days</span>
                                            );
                                        }

                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => {
                                                    const day = daysOfWeek.find((day) => day.value === value);
                                                    return (
                                                        <Chip
                                                            key={value}
                                                            label={day ? day.abbr : value}
                                                            size="small"
                                                            sx={{ fontSize: '12px', maxHeight: '15px', fontFamily: 'Inter, Serif' }}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        );
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{
                                            fontSize: "0.75rem",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>days</span>
                                    </MenuItem>
                                    {daysOfWeek.map((day) => (
                                        <MenuItem key={day.value} value={day.value} sx={{ fontSize: '0.75rem' }}>
                                            {day.title}
                                        </MenuItem>
                                    ))}
                                </CustomFormControl>
                            </div>
                        )}

                        {biddingFrequencyType === 'DAILY' && (
                            <div className="frequency-field">

                                <div className="bidding-frequency-label">
                                    <label>DAILY</label>
                                </div>

                                <CustomFormControl
                                    value={biddingFrequencyDaily || ''}
                                    onChange={(event) => setBiddingFrequencyDaily(event.target.value)}
                                    displayEmpty
                                    error={errors.biddingFrequencyDaily}
                                    helperText={errors.biddingFrequencyDaily ? 'This field is required' : ''}
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return (
                                                <span style={{
                                                    fontSize: "small",
                                                    color: "#bab7b7"
                                                }}>Select Frequency</span>
                                            );
                                        }
                                        return selected;
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>Select Frequency</span>
                                    </MenuItem>
                                    {dailyFrequencyOptions.map((option) => (
                                        <MenuItem key={option.title} value={option.title} sx={{ fontSize: '0.75rem' }}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </CustomFormControl>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Bidding Rules Triggered */}
            <div className="bidding-rules-triggered-container">
                <label>RULES TRIGGERED</label>
                <div className="bidding-rules-triggered-container-fields">
                    <div className="bidding-auto-complete">
                        {/* Utilisation de CustomFormControl */}
                        <CustomFormControl
                            value={rulesTriggered !== null ? rulesTriggered : ''}
                            onChange={(event) => setRulesTriggered(event.target.value)}
                            displayEmpty
                            error={errors.rulesTriggered}
                            helperText={errors.rulesTriggered ? 'This field is required' : ''}
                            renderValue={(selected) => {
                                if (selected === '') {
                                    return (
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7"
                                        }}>Select Rule</span>
                                    );
                                }
                                const selectedOption = availableRulesTriggered.find(option => option.value === selected);
                                return selectedOption ? selectedOption.title : '';
                            }}
                        >
                            <MenuItem disabled value="">
                                <span style={{
                                    fontSize: "small",
                                    color: "#bab7b7",
                                    marginLeft: "4px"
                                }}>Select Rule</span>
                            </MenuItem>
                            {availableRulesTriggered.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '0.75rem' }}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                    </div>

                    {/* ... (le reste du code pour les champs de valeur) */}
                    <div className="value-selector-container">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <TextField
                                placeholder="Value"
                                variant="standard"
                                value={bidChange ?? ''}
                                error={errors.bidChange}
                                helperText={errors.bidChange ? 'This field is required' : ''}
                                onChange={(e) => setBidChange(e.target.value)}
                                sx={{
                                    width: '60px',
                                    bottom: '5px',
                                    '& ::placeholder': { fontSize: '12px' },
                                    '& .MuiInputBase-input': {
                                        fontSize: '12px',
                                    },
                                }}
                                required
                            />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingBottom: '9px' }}>
                                <button onClick={addLine}>
                                    <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add" style={{ width: '8px', height: '8px' }} />
                                </button>
                                {valueFromToLines.length > 0 && (
                                    <button onClick={() => removeLine(valueFromToLines[valueFromToLines.length - 1].id)}>
                                        <img src={Icons.ActionsIconsSVG.LessIconSVG} alt="Remove" style={{ width: '8px', height: '8px' }} />
                                    </button>
                                )}
                            </div>
                        </div>

                        {valueFromToLines.length > 0 && (
                            <label>When bid or multiplier is between:</label>
                        )}
                        {valueFromToLines.map((line, index) => (
                            <div className="value-selector" key={line.id}>
                                <TextField
                                    placeholder="Value"
                                    variant="standard"
                                    value={line.valueToApply || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'valueToApply', e.target.value)}
                                    error={errors.bidChange}
                                    helperText={errors.bidChange ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                                <TextField
                                    placeholder="From"
                                    variant="standard"
                                    value={line.stepStart || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'stepStart', e.target.value)}
                                    error={errors.bidChange}
                                    helperText={errors.bidChange ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                                <TextField
                                    placeholder="To"
                                    variant="standard"
                                    value={line.stepEnd || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'stepEnd', e.target.value)}
                                    error={errors.bidChange}
                                    helperText={errors.bidChange ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Bidding Rules Not Triggered */}
            <div className="bidding-rules-not-triggered-container">
                <label>RULES NOT TRIGGERED</label>
                <div className="bidding-rules-triggered-container-fields">
                    <div className="bidding-auto-complete">
                        {/* Utilisation de CustomFormControl */}
                        <CustomFormControl
                            value={rulesNotTriggered !== null ? rulesNotTriggered : ''}
                            onChange={(event) => setRulesNotTriggered(event.target.value)}
                            displayEmpty
                            error={errors.rulesNotTriggered}
                            helperText={errors.rulesNotTriggered ? 'This field is required' : ''}
                            renderValue={(selected) => {
                                if (selected === '') {
                                    return (
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7"
                                        }}>Select Rule</span>
                                    );
                                }
                                const selectedOption = availableRulesNotTriggered.find(option => option.value === selected);
                                return selectedOption ? selectedOption.title : '';
                            }}
                        >
                            <MenuItem disabled value="">
                                <span style={{
                                    fontSize: "small",
                                    color: "#bab7b7",
                                    marginLeft: "4px"
                                }}>Select Rule</span>
                            </MenuItem>
                            {availableRulesNotTriggered.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '0.75rem' }}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                    </div>

                    {/* ... (le reste du code pour les champs de valeur) */}
                    <div className="value-selector-container">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <TextField
                                placeholder="Value"
                                variant="standard"
                                value={bidChangeInv ?? ''}
                                onChange={(e) => setBidChangeInv(e.target.value)}
                                error={errors.bidChangeInv}
                                helperText={errors.bidChangeInv ? 'This field is required' : ''}
                                sx={{
                                    width: '60px',
                                    bottom: '5px',
                                    '& ::placeholder': { fontSize: '12px' },
                                    '& .MuiInputBase-input': {
                                        fontSize: '12px',
                                    },
                                }}
                                required
                            />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingBottom: '9px' }}>
                                <button onClick={addLineNotTriggered}>
                                    <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add" style={{ width: '8px', height: '8px' }} />
                                </button>
                                {valueFromToLinesNotTriggered.length > 0 && (
                                    <button onClick={() => removeLineNotTriggered(valueFromToLinesNotTriggered[valueFromToLinesNotTriggered.length - 1].id)}>
                                        <img src={Icons.ActionsIconsSVG.LessIconSVG} alt="Remove" style={{ width: '8px', height: '8px' }} />
                                    </button>
                                )}
                            </div>
                        </div>

                        {valueFromToLinesNotTriggered.length > 0 && (
                            <label>When bid or multiplier is between:</label>
                        )}
                        {valueFromToLinesNotTriggered.map((line, index) => (
                            <div className="value-selector" key={line.id}>
                                <TextField
                                    placeholder="Value"
                                    variant="standard"
                                    value={line.valueToApply || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'valueToApply', e.target.value)}
                                    error={errors.bidChangeInv}
                                    helperText={errors.bidChangeInv ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                                <TextField
                                    placeholder="From"
                                    variant="standard"
                                    value={line.stepStart || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'stepStart', e.target.value)}
                                    error={errors.bidChangeInv}
                                    helperText={errors.bidChangeInv ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                                <TextField
                                    placeholder="To"
                                    variant="standard"
                                    value={line.stepEnd || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'stepEnd', e.target.value)}
                                    error={errors.bidChangeInv}
                                    helperText={errors.bidChangeInv ? 'This field is required' : ''}
                                    sx={{
                                        width: '60px',
                                        '& .MuiInputBase-input': {
                                            fontSize: '12px',
                                        },
                                        '&::placeholder': {
                                            fontSize: '12px',
                                        },
                                    }}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Min and Max Values */}
            <div className="value-container">
                <div className="value-row">
                    <div>
                        <label>MIN METASEARCH VALUE</label>
                    </div>
                    <TextField
                        placeholder="Min value"
                        variant="standard"
                        value={bidMin}
                        required
                        error={errors.bidMin}
                        helperText={errors.bidMin ? 'This field is required' : ''}
                        sx={{ '& ::placeholder': { fontSize: '12px' },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                            }, }}
                        onChange={(e) => setBidMin(e.target.value)}
                    />
                </div>
                <div className="value-row">
                    <div>
                        <label>MAX METASEARCH VALUE </label>
                    </div>
                    <TextField
                        placeholder="Max value"
                        variant="standard"
                        value={bidMax}
                        required
                        error={errors.bidMax}
                        helperText={errors.bidMax ? 'This field is required' : ''}
                        sx={{ '& ::placeholder': { fontSize: '12px' },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                            }, }}
                        onChange={(e) => setBidMax(e.target.value)}
                    />
                </div>
            </div>

            {/* Import Bidding Rules */}
            <div className="import-bidding-rule-container">
                <label>IMPORT BIDDING RULES</label>
                <div className="bidding-auto-complete">
                    {/* Utilisation de CustomFormControl */}
                    <CustomFormControl
                        value={selectedTemplate ? selectedTemplate.name : ''}
                        onChange={(event) => {
                            const newValue = templates.find(template => template.name === event.target.value);
                            handleTemplatesChange(null, newValue);
                        }}
                        error={errors.rulesTriggered}
                        helperText={errors.rulesTriggered ? 'This field is required' : ''}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7"
                                    }}>Bidding Rule</span>
                                );
                            }
                            return selected;
                        }}
                    >
                        <MenuItem disabled value="">
                            <span style={{
                                fontSize: "small",
                                color: "#bab7b7",
                                marginLeft: "4px"
                            }}>Bidding Rule</span>
                        </MenuItem>
                        {templates.map((option) => (
                            <MenuItem key={option.name} value={option.name} sx={{ fontSize: '0.75rem' }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </CustomFormControl>
                </div>
            </div>

            {/* Query Builder */}
            <div className="query-builder-container">
                <label>METRIC ALERT RULES</label>
                <div className="query-builder">
                    {tree && (
                        <Query
                            {...config}
                            value={tree || null}
                            onChange={onChange}
                            renderBuilder={(props) => <Builder {...props} />}
                        />
                    )}
                </div>
            </div>

            <div className="email-container">
                <label>RECIPIENTS</label>
                <div className="email-container-fields">
                    <Box sx={{ display: "flex", gap: "3px", flexDirection: "column" }}>
                        <Tags
                            ref={tagifyRef}
                            whitelist={alertRecipients}
                            placeholder='Recipients'
                            settings={{
                                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            }}
                            onChange={handleRecipientsChange}
                            defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                            className={errors.alertRecipients ? 'error-tagify' : ''}
                        />
                        {errors.alertRecipients && <span style={{ color: '#d32f2f', fontSize: '12px' }}>This field is required</span>}
                        </Box>
                        <div className="email-checkbox-container">
                        <Checkbox
                            size={"small"}
                            checked={alertNotif === 1}
                            onChange={(e) => setAlertNotif(e.target.checked ? 1 : 0)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}
                        />
                        <label className="email-checkbox-label" >Send me an email when this bidding rule triggers</label>
                    </div>

                </div>

            </div>

            {/* Buttons */}
            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Rule
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>
        </div>
    );
};

export default BiddingRuleTripadvisor;
