export const DeviceAvailableMetrics = {
    "clicks_kpi": "Clicks",
    "cost_kpi": "Cost",
    "generated_kpi": "Generated",
    "cpc_kpi": "CPC",
    "conversion_kpi": "Conversion",
    "roas_kpi": "ROAS"
};

export const TopHotelsAvailableMetrics = {
    "clicks_kpi": "Clicks",
    "cost_kpi": "Cost",
    "generated_kpi": "Generated",
    "cpc_kpi": "CPC",
    "roas_kpi": "ROAS"
};

export const TopMarketsAvailableMetrics = {
    "clicks": "Clicks",
    "cost": "Cost",
    "generated": "Generated",
    "cpc": "CPC",
    "roas": "ROAS"
};