import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import TopMarketsMap from "./TopMarketsMap";
import TopMarketsTable from "./TopMarketsTable";
import { TopMarketsOptions } from "../Utils/Options";
import worldMap from "./Utils/features.json";
import "./TopMarketsComponent.scss";

function TopMarketsComponent({ selectedOption, handleDropdownChange, topMarketsMetrics = {} }) {
    const [marketOption, setMarketOption] = useState("clicks");
    const selectedMarkets = topMarketsMetrics[`top_markets_by_${marketOption}`] || [];

    return (
        <Box className="top-markets-component">
            <Grid container spacing={2} className={`top-markets-container ${Object.keys(topMarketsMetrics).length === 0 ? 'loading' : ''}`}>

                {/* Dropdown for Metrics */}
                <Grid item xs={12} className="dropdown-container">
                    <select
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        className="dropdown"
                    >
                        {TopMarketsOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        ))}
                    </select>
                </Grid>

                {/* Map Component */}
                <TopMarketsMap
                    topMarketsMetrics={topMarketsMetrics}
                    selectedMarkets={selectedMarkets}
                    worldMap={worldMap}
                />

                {/* Table Component */}
                <TopMarketsTable
                    marketOption={marketOption}
                    selectedMarkets={selectedMarkets}
                    topMarketsMetrics={topMarketsMetrics}
                    selectedOption={selectedOption}
                    handleMarketOptionChange={setMarketOption}
                />
            </Grid>
        </Box>
    );
}

export default TopMarketsComponent;
