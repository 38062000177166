import React, { useState } from "react";
import { Link } from "@mui/material";
import LoginForm from "./LoginForm";
import Layout from "../Login/Layout";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { APP_LOGIN_API_ENDPOINT } from "../../constants/api_urls";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = APP_LOGIN_API_ENDPOINT;
      const { status, data } = await axios.post(url, {
        username,
        password,
      });

      if (status === 200) {
        const { refresh_token = "", token = "" } = data || {};
        sessionStorage.setItem("refresh_token", refresh_token);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("username", username);


        localStorage.setItem("user_refresh_token", refresh_token);
        localStorage.setItem("user_token", token);
        localStorage.setItem("user_username", username);

        setIsLoggedIn(true);
        setUsername("");
        setPassword("");
      } else {
        // setLoginError("Oops! You have entered an incorrect password");
        setUsername("");
        setPassword("");
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoginError(error.response.data.message);
      setUsername("");
      setPassword("");
    }
  };

  if (isLoggedIn) {
    // Redirect to Dashboard component if user is logged in

    navigate("/home/1590");
  }
  return (
    <Layout>
      <div className="box-2">
        <div className={"login-heading quency-cf-normal"}>Sign In</div>
        <LoginForm
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          handleSubmit={handleSubmit}
          loginError={loginError}
          setLoginError={setLoginError}
        />
        <Link
          component={RouterLink}
          to="/reset-password"
          sx={{
            textDecoration: "none",
            textTransform: "none",
            textAlign: "right",
            mt: "10px",
            ml: "218px",
            color: "#BAB7B7",
            fontFamily: "Inter",
            fontWeight: "400",
              fontSize: "10px",
              lineHeight: "12.1px",
            position: "relative",
            top: "30px",
            width: "82px",
            height: "12px",
            gap: "0px",
            whiteSpace: "nowrap",
          }}
        >
          Forgot Password?
        </Link>
      </div>
    </Layout>
  );
};

export default Login;
