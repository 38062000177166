import React from 'react';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Icons from 'components/DmpIcons/icons';

const CustomDateInput = ({
                             value,
                             onChange,
                             sx,
                             format = "MM/DD/YYYY",
                             icon: Icon = Icons.ActionsIcons.CalendarIcon,
                         }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
                <DatePicker
                    format={format}
                    value={value}
                    onChange={onChange}
                    slots={{
                        openPickerIcon: Icon,
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                            justifyContent: "flex-end",
                            flexDirection: "row-reverse",
                            padding: 0,
                        },
                        "& .MuiInputBase-input": {
                            padding: "0px",
                            paddingBlock: "5px",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "14.52px",
                            maxWidth: "12ch",
                            "&::placeholder": {
                                fontSize: "12px",
                                paddingBlock: 0,
                                marginBlock: 0,
                            },
                        },
                        "& .MuiButtonBase-root.MuiIconButton-root": {
                            padding: 0,
                            marginRight: "0.5rem",
                        },
                        ...sx,
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default CustomDateInput;
