import React, {useMemo, useState, useEffect} from "react";
import CustomTable from '../../alert-center/HotelsListTable/AlertTableDesignedWithCheckboxes';
import DmpIcons from "components/DmpIcons/icons";

const LevelSelection = ({ level, levelChoices, data, onHotelSelect, onClose}) => {

    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});

    const columnsHotel = useMemo(() => [
        { accessorKey: 'id', header: 'ID', maxSize: 40 },
        { accessorKey: 'name', header: 'Name', maxSize: 220 },
        { accessorKey: 'country', header: 'Country', maxSize: 40 },
    ], []);

    const columnsGroup = useMemo(() => [
      { accessorKey: 'id', header: 'ID', maxSize: 50 },
      { accessorKey: 'name', header: 'Name', maxSize: 220 },
  ], []);


    useEffect(() => {
        if (levelChoices?.length > 0) {
          const initialSelectedRows = {};
          levelChoices.forEach((hotel) => {
            initialSelectedRows[hotel.id] = true;
          });
          setSelectedRows(initialSelectedRows);
        }
      }, [levelChoices]);
    
      useEffect(() => {
        const selectedHotels = data.filter((hotel) => selectedRows[hotel.id]);
        onHotelSelect(selectedHotels);
      }, [selectedRows, data, onHotelSelect]);

  return (
    <div className="report-level-choice">
      <div className="hotel-table-container">
          <br />
          <div className="header-top">
              <div
                  onClick={onClose}
                  style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '28px',
                      height: '28px',
                      borderRadius: '50%',
                      border: '1px solid #000',
                      padding: '5px',
                      cursor: 'pointer',
                      marginRight: '10px',
                      boxSizing: 'border-box'
                  }}
              >
                  <img
                      src={DmpIcons.ActionsIconsSVG.ClosePanelIconSVG}
                      alt="Close"
                  />
              </div>
              <h2 style={{
                  fontSize: '20px',
                  textAlign: 'left',
                  fontWeight: 400,
                  fontFamily: 'Quincy CF, serif',
              }}>
                  Select {level === 'HOTEL' ? 'hotels' : 'groups' }
              </h2>
          </div>
          {level == 'HOTEL' && (
          <>
            <CustomTable
              columns={columnsHotel}
              data={data}
              loading={loading}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              uniqueId="id"
            />
          </>
        )}
        {level == 'GROUP' && (
          <>
            <CustomTable
              columns={columnsGroup}
              data={data}
              loading={loading}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              uniqueId="id"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LevelSelection;