import React from "react";
import {
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SignUpFormSem.scss";
import {REACT_APP_PRODUCT_SIGNUP_SEM_IFRAME_URL} from "../../../constants/api_urls";

const SignUpFormSem = ({ isOpen, onClose }) => {
  return (
    <Box className={`signUpFormSem ${isOpen ? "open" : ""}`}>
      <Box
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={onClose} className="closeButton">
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="body1" className="title">
            Request Demo
          </Typography>
        </Box>
      </Box>
      <Box className="content">
        <iframe
            src={REACT_APP_PRODUCT_SIGNUP_SEM_IFRAME_URL}
            style={{width: '100%', height: '100%', border: 'none'}}
            title="pMax Product SignUp"
        ></iframe>
      </Box>
    </Box>
  );
};

export default SignUpFormSem;
