import React from "react";
import { Grid, Box } from "@mui/material";
import { TopMarketsAvailableMetrics } from "../../Utils/AvailableMetrics";

function TopMarketsTable({ marketOption, selectedMarkets, topMarketsMetrics, selectedOption, handleMarketOptionChange }) {
    return (
        <Grid item xs={12} className="top-markets-table-container">
            <Grid container>
                <Grid item xs={12}>
                    <Box className="top-markets-table-header">
                        <Box className="market-option-container">
                            <Box className="market-option-name">
                                {selectedOption.replace(/_/g, ' ')}
                            </Box>
                            <select
                                value={marketOption}
                                onChange={(e) => handleMarketOptionChange(e.target.value)}
                                className="dropdown"
                            >
                                {Object.entries(TopMarketsAvailableMetrics).map(([key, label]) => (
                                    <option key={key} value={key}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        {/* First 5 Markets */}
                        <Grid item xs={6} className="top-markets-table">
                            {selectedMarkets?.slice(0, 5).length > 0 ? (
                                selectedMarkets.slice(0, 5).map((market, index) => (
                                    <Box key={`market-${index}`} className="market-row">
                                        <Box key={`market-${index}-name`}>
                                            {market.market_name}
                                        </Box>
                                        <Box key={`market-${index}-value`}>
                                            {['cost', 'generated', 'cpc'].some(opt => marketOption.includes(opt))
                                                ? `${topMarketsMetrics.currency_symbol}${market[`${marketOption}_kpi`] || 'N/A'}`
                                                : market[`${marketOption}_kpi`] || 'N/A'}
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Box className="market-row">No Data Available</Box>
                            )}
                        </Grid>

                        {/* Last 5 Markets */}
                        <Grid item xs={6} className="top-markets-table">
                            {selectedMarkets?.slice(-5).map((market, index) => (
                                <Box key={`market-${index}`} className="market-row">
                                    <Box key={`market-${index}-name`}>
                                        {market.market_name}
                                    </Box>
                                    <Box key={`market-${index}-value`}>
                                        {['cost', 'generated', 'cpc'].some(opt => marketOption.includes(opt))
                                            ? `${topMarketsMetrics.currency_symbol}${market[`${marketOption}_kpi`] || 'N/A'}`
                                            : market[`${marketOption}_kpi`] || 'N/A'}
                                    </Box>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default TopMarketsTable;
