import React from 'react';
import AlertMetasearchBingForm from './MetricsMetasearch/AlertMetasearchBingForm';
import KayakAlertForm from './MetricsMetasearch/AlertMetasearchKayakForm';
import AlertGoogleHotelAdsAllTypeForm from "./MetricsMetasearch/AlertMetasearchGoogleHotelAdsAllTypeForm";
import AlertGoogleHotelAdsMetaForm from "./MetricsMetasearch/AlertMetasearchGoogleHotelAdsMetaForm";
import AlertGoogleHotelAdsPpaForm from "./MetricsMetasearch/AlertMetasearchGoogleHotelAdsPpaForm";
import AlertMetasearchTripadvisorMetaForm from "./MetricsMetasearch/AlertMetasearchTripadvisorMetaForm";
import AlertMetasearchTripadvisorSpForm from "./MetricsMetasearch/AlertMetasearchTripadvisorSpForm";
import AlertMetasearchTrivagoMetaForm from "./MetricsMetasearch/AlertMetasearchTrivagoMetaForm";
import AlertMetasearchTrivagoSlForm from "./MetricsMetasearch/AlertMetasearchTrivagoSlForm";
import AlertMetasearchSkyscannerForm from "./MetricsMetasearch/AlertMetasearchSkyscannerForm";
import AlertMetasearchWegoForm from "./MetricsMetasearch/AlertMetasearchWegoForm";
import AlertMetasearchBudget from "./MetricsMetasearch/AlertMetasearchBudget";
import AlertGoogleSearchAdsBudget from "./MetricsMetasearch/AlertGoogleSearchAdsBudget";

const AlertManager = ({alertData, actionType, onClose }) => {


    console.log("Manager data : " + JSON.stringify(alertData));

    const getAlertForm = () => {
        switch (alertData.alertType) {

            case 'ALERT_METASEARCH_BING':
                return <AlertMetasearchBingForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_KAYAK':
                return <KayakAlertForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_GOOGLE_HOTEL_ADS_ALL_ADS_TYPE':
                return <AlertGoogleHotelAdsAllTypeForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_GOOGLE_HOTELS_ADS_META':
                return <AlertGoogleHotelAdsMetaForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_GOOGLE_HOTELS_ADS_PPA':
                return <AlertGoogleHotelAdsPpaForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_TRIPADVISOR_META':
                return <AlertMetasearchTripadvisorMetaForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_TRIPADVISOR_SP':
                return <AlertMetasearchTripadvisorSpForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_TRIVAGO_META':
                return <AlertMetasearchTrivagoMetaForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_TRIVAGO_SL':
                return <AlertMetasearchTrivagoSlForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_SKYSCANNER':
                return <AlertMetasearchSkyscannerForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_WEGO_META':
                return <AlertMetasearchWegoForm
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_METASEARCH_BUDGET':
                return <AlertMetasearchBudget
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            case 'ALERT_GOOGLE_SEARCH_ADS_BUDGET':
                return <AlertGoogleSearchAdsBudget
                    alertData={alertData}
                    actionType={actionType}
                    onClose={onClose}/>;

            default:
                return <p>Type d'alerte non supporté.</p>;
        }
    };

    return (
        <div>
            {getAlertForm()}
        </div>
    );
};

export default AlertManager;
