import React from 'react';
import {Typography, Button, Grid, TextField, InputLabel} from '@mui/material';

const LoginForm = ({username, setUsername, password, setPassword, handleSubmit, loginError, setLoginError}) => {
    const handleChange = () => {
        setLoginError(null);
    };
    return (<form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="username" shrink>
                        User Name
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        id="username"
                        required
                        value={username}
                        autoComplete='off'
                        onChange={(e) => {
                            setUsername(e.target.value);
                            handleChange();
                        }}
                        InputProps={{
                            sx: {
                                height: '30px',
                                width: '298px',
                                backgroundColor: 'transparent', // Remove background color
                                '& input': {
                                    backgroundColor: 'transparent', // Ensures input itself has no background
                                    boxShadow: 'none', // Remove any shadows from autofill
                                    padding: '6px 8px', // Adjust padding for consistent height
                                    height: '19px'
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            sx: {
                                marginTop: 0, padding: 0,
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={12}> {/* Full width for Password */}
                    <InputLabel htmlFor="password" shrink>
                        Password
                    </InputLabel>
                    <TextField
                        variant="outlined"
                        id="password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            handleChange();
                        }}
                        InputProps={{
                            sx: {
                                height: '30px',
                                width: '298px',
                                backgroundColor: 'transparent', // Remove background color
                                '& input': {
                                    backgroundColor: 'transparent', // Ensures input itself has no background
                                    boxShadow: 'none', // Remove any shadows from autofill
                                    padding: '6px 8px', // Adjust padding for consistent height
                                    height: '19px'
                                },
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            sx: {
                                marginTop: 0,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="column">
                <Grid item xs={12} style={{minHeight: "80px"}}>
                    {loginError && (<Typography variant="body2" color="error"
                                                sx={{
                                                    color: "#ED8E95",
                                                    fontFamily: "Inter",
                                                    fontSize: "11px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "normal"
                                                }}>
                        {loginError}
                        </Typography>)}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{
                            width: "100px",
                            height: "34px",
                            flexShrink: "0",
                            borderRadius: "10px",
                            background: "#61D5A9",
                            marginTop: '-35px',
                            fontFamily: "Inter",
                            fontSize:"12px",
                            fontWeight:"400",
                        }}
                    >
                        Sign In
                    </Button>
                </Grid>
            </Grid>

        </form>);
};

export default LoginForm;
