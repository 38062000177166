import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotels: [],
  markets: [],
};
export const editBidSlice = createSlice({
  name: "editBidSlice",
  initialState: initialState,
  reducers: {
    updateEditBidHotels: (state, action) => {
      state.hotels = action.payload;
    },
    updateEditBidMarkets: (state, action) => {
      state.markets = action.payload;
    },
  },
});

export const { updateEditBidHotels, updateEditBidMarkets } =
  editBidSlice.actions;
export default editBidSlice.reducer;
