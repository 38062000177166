import React, { useState, useEffect } from 'react';
import {Drawer, Box, Typography, IconButton, Backdrop, CircularProgress, Alert} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserInfoAccordion from "../Accordion/User/UserInfoAccordion";
import AccountsAccessAccordion from "../Accordion/Accounts/AccountsAccessAccordion";
import SubUserApi from '../../../pages/subUsersManagement/SubUserApi';
import ConfirmationModal from '../Modal/ConfirmationModal';
import HotelsAccessAccordion from "../Accordion/Hotels/HotelsAccessAccordion";
import Icons from "components/DmpIcons/icons";
import DmpIcons from "components/DmpIcons/icons";

const SlideOutPanel = ({
    open,
    onClose,
    user,
    mode,
    onAddUserSuccess,
    onErrorMessage,
    onSuccessMessage
}) => {
    const [expanded, setExpanded] = useState('panel1');
    const [accountsData, setAccountsData] = useState([]);
    const [hotelsData, setHotelsData] = useState([]);
    const [availableAccounts, setAvailableAccounts] = useState([]);
    const [selectedAccountHotels, setSelectedAccountHotels] = useState([]);
    const [availableHotels, setAvailableHotels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [allAccounts, setAllAccounts] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchAccessList = async () => {
        if (user && mode === 'edit') {
            try {
                setLoading(true);
                const [accounts, accessList] = await Promise.all([
                    SubUserApi.accessMainUserAccount(),
                    SubUserApi.getAccessList(user.id)
                ]);
                setAccountsData(accessList.accounts || []);
                setHotelsData(accessList.hotels || []);
                setAllAccounts(accounts);

                const assignedAccountIds = accessList.accounts.map(acc => acc.id);
                const unassignedAccounts = accounts.filter(acc => !assignedAccountIds.includes(acc.id));
                setAvailableAccounts(unassignedAccounts);
            } catch (error) {
                onErrorMessage('Failed to load accounts and hotels. Please try again later.');
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedAccount(null);
            setHotelsData([]);
            setExpanded('panel1');
            setErrorMessage(null);
            fetchAccessList();
        }
    }, [open, user, mode]);

    const handleEditAccount = async (account) => {
        try {
            setLoading(true);
            const accountWithUser = { ...account, id_user: user.id };
            await SubUserApi.addAccount(accountWithUser);
            onSuccessMessage('Account updated successfully');
            await fetchAccessList();
        } catch (error) {
            onErrorMessage('Failed to update account.');
        }  finally {
            setLoading(false);
        }
    };

    const handleUserSubmit = async ({ userData, username, isEditMode }) => {
        try {
            setLoading(true);
            if (isEditMode) {
                await SubUserApi.updateSubUser(user.id, userData);
                onSuccessMessage('User updated successfully');
            } else {
                await SubUserApi.createSubUser({ username, ...userData });
                onSuccessMessage('User added successfully');
            }
            onAddUserSuccess();
        } catch (error) {
            setErrorMessage(error);
            onErrorMessage(error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddAccount = async (account) => {
        try {
            setLoading(true);
            const accountWithUser = {
                ...account,
                id_user: user.id,
                all_hotels: 0,
                readwrite_option: 0,
                hotels_list_ids: [],
                id_account: account.id
            };
            await SubUserApi.addAccount(accountWithUser);
            onSuccessMessage('Account added successfully');
            await fetchAccessList();
        } catch (error) {
            onErrorMessage('Failed to add account.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAccount = async (account) => {
        try {
            // setLoading(true);
            setSelectedAccount(account);
            setIsDeleteModalOpen(true);
        } catch (error) {
            setLoading(false);
            onErrorMessage('Failed to select account for deletion.');
        }
    };

    const confirmDeleteAccount = async () => {
        if (!selectedAccount) return;
        try {
            setLoading(true);
            await SubUserApi.deleteAccount(user.id, selectedAccount.id);
            setIsDeleteModalOpen(false);
            setSelectedAccount(null);
            onSuccessMessage('Account deleted successfully');
            await fetchAccessList();
        } catch (error) {
            onErrorMessage('Failed to delete the account.');
        } finally {
            setLoading(false);
        }
    };

    const handleAccountClick = (account) => {
        setSelectedAccount(account);

        const selectedAccountHotelIds = hotelsData
            .filter(hotel => hotel.idaccount === account.id)
            .map(hotel => hotel.id);

        setSelectedAccountHotels(selectedAccountHotelIds);

        const accountInAllAccounts = allAccounts.find(acc => acc.id === account.id);
        if (accountInAllAccounts && accountInAllAccounts.hotels) {
            setAvailableHotels(accountInAllAccounts.hotels);
        }
        setExpanded('panel3');
    };

    const handleCancelHotels = () => {
        setSelectedAccount(null);
        setExpanded('panel2');
    };

    const handleSaveHotels = async (selectedHotelIds) => {
        try {
            setLoading(true);
            const accountWithUser = {
                ...selectedAccount,
                id_user: user.id,
                all_hotels: selectedHotelIds.length === availableHotels.length ? 1 : 0,
                readwrite_option: selectedAccount.allHotels === 'WRITE' ? 1 : 0,
                hotels_list_ids: selectedHotelIds,
                id_account: selectedAccount.id
            };
            await SubUserApi.addAccount(accountWithUser);
            onSuccessMessage('Account updated successfully');
            await fetchAccessList();
        } catch (error) {
            onErrorMessage('Failed to save the selected hotels.');
        } finally {
            setLoading(false);
        }
    };
console.log(user);
    return (
        <Drawer anchor="right" open={open} onClose={!loading ? onClose : undefined}>
            <Box sx={{
                width: mode === 'edit' ? '730px' : '444px',
                p: 2,
                backgroundColor: '#F5F5F5',
                height: '100%',
                position: 'relative'
            }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
                    <IconButton onClick={!loading ? onClose : undefined} sx={{marginRight: '8px',border: '1px solid',borderColor: '#000',borderRadius: '50%',padding: '8px'
                    }}  disabled={loading}>
                        <img
                            src={DmpIcons.ActionsIconsSVG.ClosePanelIconSVG}
                            alt="Close"
                        />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontWeight: '400',fontSize:'20px', lineHeight:'20.6px', fontFamily: "Quincy CF" }}>
                        {mode === 'edit' ? 'User Management' : 'Create a New User'}
                    </Typography>
                </Box>


                {errorMessage && (
                    <Box sx={{ display: 'flex', alignItems: 'center'}}>
                        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Box>
                )}

                <UserInfoAccordion
                    expanded={expanded}
                    handleChange={handleChange}
                    user={user}
                    mode={mode}
                    onClose={onClose}
                    handleSubmit={handleUserSubmit}
                />

                {mode === 'edit' && (
                    <AccountsAccessAccordion
                        expanded={expanded}
                        handleChange={handleChange}
                        accountsData={accountsData}
                        availableAccounts={availableAccounts}
                        onEditAccount={handleEditAccount}
                        onDeleteAccount={handleDeleteAccount}
                        onAddAccount={handleAddAccount}
                        onAccountClick={handleAccountClick}
                        selectedAccount={selectedAccount}
                        hotelsData={hotelsData}
                        loading={loading}
                    />
                )}

                {mode === 'edit' && selectedAccount && (
                    <HotelsAccessAccordion
                        expanded={expanded}
                        handleChange={handleChange}
                        hotelsData={availableHotels}
                        preselectedHotels={selectedAccountHotels}
                        onSaveHotels={handleSaveHotels}
                        onCancel={handleCancelHotels}
                        accountName={selectedAccount?.name || 'Unnamed Account'}
                    />
                )}

                <ConfirmationModal
                    open={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onConfirm={confirmDeleteAccount}
                    message={`Are you sure you want to delete the account?`}
                />
            </Box>
        </Drawer>
    );
};

export default SlideOutPanel;
