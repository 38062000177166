import React from "react";
import {Grid, Box, CircularProgress} from "@mui/material";
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip} from "recharts";
import { CHART_OPTIONS } from "../../Utils/ChartOptions";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p>{`Hotel: ${payload[0].payload.name}`}</p>
                <p>{`Value: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const PieChartDevice = ({ deviceMetrics, selectedMetric, handleMetricChange, chartData }) => (
    <Grid item xs={4}>
        <Grid container>
            <Grid item xs={12}>
                <Box className="metric-dropdown-container dropdown-container">
                    <select
                        id="metricDropdown"
                        value={selectedMetric}
                        onChange={handleMetricChange}
                        className="dropdown metric-dropdown"
                    >
                        {CHART_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="chart-container">
                    {Object.keys(deviceMetrics).length === 0 ? (
                        <CircularProgress className="loader" />
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                                <Pie
                                    data={chartData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={50}
                                    innerRadius={30}
                                    paddingAngle={2}
                                    labelLine={false}
                                    label={({ cx, cy, midAngle, innerRadius, outerRadius, percentage }) => {
                                        const RADIAN = Math.PI / 180;
                                        const radius = innerRadius + (outerRadius - innerRadius) * 1.7;
                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                        return (
                                            <text
                                                x={x}
                                                y={y}
                                                fill="#000"
                                                textAnchor="middle"
                                                dominantBaseline="central"
                                                className="text-percentage"
                                            >
                                                {`${percentage}%`}
                                            </text>
                                        );
                                    }}
                                >
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                        </ResponsiveContainer>
                    )}
                </Box>
            </Grid>
        </Grid>
    </Grid>
);

export default PieChartDevice;