import "./biddingRules.scss";
import { useSelector } from "react-redux";
import TrivagoSponsoredListingBiddingRules from "../trivagoSponsorListing/biddingRules";
import TrivagoBiddingRules from "../trivago/biddingRules";
import TripAdvisorMetaBiddingRules from "../tripadvisor/biddingRules";
import TripAdvisorSPBiddingRules from "../tripadvisorSponsorPlacement/biddingRules";
import GHABidddingRules from "../GHA/biddingRules";
import GHAPPABidddingRules from "../GHAPPA/biddingRules";
const BiddingRules = () => {
  const platform = useSelector((state) => state?.metaSearch?.platform);
  const currentPlatform = platform[0]?.id;

  switch (currentPlatform) {
    case "skyScanner":
      return <></>;
    case "trivago":
      return <TrivagoBiddingRules />;
    case "trivagoSponsoredListing":
      return <TrivagoSponsoredListingBiddingRules />;
    case "tripadvisor":
      return <TripAdvisorMetaBiddingRules />;
    case "tripadvisorSponsoredPlacement":
      return <TripAdvisorSPBiddingRules />;
    case "googlehotelads":
      return <GHABidddingRules />;
    case "googleHotelAdsPropertyPromotionAds":
      return <GHAPPABidddingRules />;
    default:
      return null;
  }
};
export default BiddingRules;
