import {Box, Typography} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import DefaultLayout from "layout/defaultLayout";
import AtomicIcon from "assets/images/PmaxAtom.svg";
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";
import React, {useState} from "react";
import "../SignUpPmaxPage/SignUpPmaxPage.scss";
import SignUpFormSem from "../../../features/product-sign-up/SignUpFormSem";

const SignUpSemPage = () => {

    const [isPanelOpen, setPanelOpen] = useState(false);
    const togglePanel = () => {
        setPanelOpen((prev) => !prev);
    };

    return (
        <DefaultLayout>
            <Box className="pMaxSignUpPage">
                <div className="header-container">
                    <div>
                        <div className="header-left">
                            <div className="icon-wrapper">
                                <IconWrapper icon={AtomicIcon}/>
                            </div>
                            <div>
                                <Typography className="quency-cf-normal heading">
                                    Google SEM
                                    <div className="sub-heading">
                                        Lorem ipsum dolor sit amet
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="summary-section">
                    <Typography gutterBottom className="summary-title">
                        SUMMARY
                    </Typography>

                    <Typography
                        variant="body1"
                        className="summary-text">
                        <p className="summary-text-bold">Boost Your Online Presence and Drive Results with SEM</p>
                        In today’s competitive digital landscape, Search Engine Marketing (SEM) is one of the most
                        powerful ways to ensure your business is seen by the right people. Our platform makes it easy
                        for you to create, manage, and optimize SEM campaigns on Google. Whether you're looking to
                        increase traffic, generate leads, or boost sales, our platform offers advanced targeting
                        options, real-time analytics, and the flexibility to scale your campaigns based on your goals
                        and budget. With seamless automation and expert support at your fingertips, you'll be able to
                        focus on what matters—growing your business.
                        <p className="summary-text-bold">Ready to Grow with SEM?</p>
                        Don’t miss out on the opportunity to put your business in front of customers actively searching
                        for what you offer. Join the hundreds of hotels already benefiting from our SEM campaigns. Our
                        platform is designed to help you achieve measurable results and maximize your ROAS. Contact us
                        today to schedule a free demo and discover how easy it is to get started with SEM. Let’s grow
                        together—reach out now to begin your journey!
                    </Typography>

                    <CustomButtonWithIcon
                        onClick={togglePanel}
                        color="#b0acac"
                        hoverColor="#61D5A9"
                        width="201px"
                        height="44px"
                        titleColor="#373F41"
                        borderRadius="20px"
                        title="Click to create a new alert"
                        iconBorder="0.5px solid #373F41"
                        textStyle={{
                            display: 'flex',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center'
                        }}
                    >
                        Request a Demo
                    </CustomButtonWithIcon>
                </div>
            </Box>
            <SignUpFormSem isOpen={isPanelOpen} onClose={togglePanel}/>
        </DefaultLayout>
    );
};
export default SignUpSemPage;
