export const tableData = [
  {
    meta: "-",
    budgetSpent: 15000,
    cost: 1800,
    generated: 25000,
    clicks: 950,
    cpc: 19.33,
    revenuePerClick: 100,
    bookings: 250,
    conversionRate: 162.67,
    roas: 22.5,
    channel: "TOTAL",
    id: "0",
  },
  {
    meta: "Meta",
    budgetSpent: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    test1: 0,
    channel: "googleHotelAds",
  },
  {
    meta: "Meta",
    budgetSpent: 220,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Meta",
    budgetSpent: 210,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Meta",
    budgetSpent: 230,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Meta",
    budgetSpent: 240,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    channel: "googleHotelAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 220,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Dest.",
    budgetSpent: 210,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Dest.",
    budgetSpent: 230,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Dest.",
    budgetSpent: 240,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    channel: "googleHotelAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 220,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Meta",
    budgetSpent: 210,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Meta",
    budgetSpent: 230,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Meta",
    budgetSpent: 240,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
];

export const metaSearchTableData = [
  {
    meta: "-",
    budgetSpent: 15000,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 1800,
    generated: 25000,
    clicks: 950,
    cpc: 19.33,
    revenuePerClick: 100,
    bookings: 250,
    conversionRate: 162.67,
    roas: 22.5,
    channel: "TOTAL",
    id: "0",
  },
  {
    meta: "Meta",
    budgetSpent: 200,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    channel: "googleHotelAds",
  },
  {
    meta: "Meta",
    budgetSpent: 220,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Meta",
    budgetSpent: 210,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Meta",
    budgetSpent: 230,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Meta",
    budgetSpent: 240,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 200,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    channel: "googleHotelAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 220,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Dest.",
    budgetSpent: 210,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Dest.",
    budgetSpent: 230,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Dest.",
    budgetSpent: 240,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 200,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 160,
    generated: 1000,
    clicks: 30,
    cpc: 5.33,
    revenuePerClick: 10,
    bookings: 5,
    conversionRate: 16.67,
    roas: 2.5,
    channel: "googleHotelAds",
  },
  {
    meta: "Dest.",
    budgetSpent: 220,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 180,
    generated: 1100,
    clicks: 33,
    cpc: 5.45,
    revenuePerClick: 10.1,
    bookings: 6,
    conversionRate: 18.18,
    roas: 2.55,
    channel: "tripadvisor",
  },
  {
    meta: "Meta",
    budgetSpent: 210,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 170,
    generated: 1050,
    clicks: 32,
    cpc: 5.31,
    revenuePerClick: 9.9,
    bookings: 5,
    conversionRate: 15.63,
    roas: 2.5,
    channel: "skyscanner",
  },
  {
    meta: "Meta",
    budgetSpent: 230,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 190,
    generated: 1150,
    clicks: 34,
    cpc: 5.59,
    revenuePerClick: 10.2,
    bookings: 7,
    conversionRate: 20.59,
    roas: 2.55,
    channel: "kayak",
  },
  {
    meta: "Meta",
    budgetSpent: 240,
    status: "status1",
    monthlyBudgetCap: 200,
    cost: 200,
    generated: 1200,
    clicks: 36,
    cpc: 5.56,
    revenuePerClick: 10.5,
    bookings: 8,
    conversionRate: 22.22,
    roas: 2.4,
    channel: "bingAds",
  },
];
