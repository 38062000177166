export const COMMONFORMFIELDS = [
  {
    id: "hotel",
    label: "Hotel",
    type: "multiSelect",
    placeholder: "Select a hotel",
  },

  {
    id: "market",
    label: "Market",
    type: "multiSelect",
    placeholder: "Select a value",
  },
];
export const ghaBiddingRulesFormFields = [
  {
    id: "DESKTOP",
    label: "Desktop",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No bidding rule",
        value: "0",
        label: "No bidding rule",
      },
    ],
  },
  {
    id: "MOBILE",
    label: "Mobile",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No bidding rule",
        value: "0",
        label: "No bidding rule",
      },
    ],
  },
  {
    id: "TABLET",
    label: "Tablet",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No bidding rule",
        value: "0",
        label: "No bidding rule",
      },
    ],
  },
];
