import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Paper } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import './UploadCSVModal.scss';

const UploadCSVModal = ({ open, onClose, onUpload, loading, error }) => {
    const [file, setFile] = useState(null);
    const [csvError, setCsvError] = useState('');
    const [selectedOption, setSelectedOption] = useState('useGroupBids');

    // React Dropzone handler
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'text/csv': ['.csv'] },
        onDrop: (acceptedFiles) => {
            const selectedFile = acceptedFiles[0];
            if (selectedFile && selectedFile.type === 'text/csv') {
                setFile(selectedFile);
                setCsvError('');
            } else {
                setFile(null);
                setCsvError('Please upload a valid CSV file.');
            }
        }
    });

    const handleProcessCsv = async () => {
        if (!file) {
            setCsvError('Please upload a CSV file before proceeding.');
            return;
        }

        const reader = new FileReader();
        reader.onload = async (event) => {
            const csvContent = event.target.result;
            const rows = csvContent.split('\n');

            const hotelIds = rows.slice(1)
                .map(row => row.split(',')[0].trim())
                .filter(id => id);

            if (hotelIds.length === 0) {
                setCsvError('The CSV file is empty or invalid.');
                return;
            }
            const success = await onUpload(hotelIds.map(id => parseInt(id, 10)), selectedOption);
            if (success) {
                onClose();
            }
        };

        reader.readAsText(file);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textTransform: 'uppercase', fontWeight:'500', fontFamily:'inter', fontSize:'14px' }}>Upload CSV File</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Drag and drop a CSV file containing hotel IDs to add them to the group or ungrouped list.
                </DialogContentText>

                <Paper
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed grey',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor: '#f7f7f7',
                        marginTop: '20px',
                    }}
                >
                    <input {...getInputProps()} />
                    <p>Drag & drop a CSV file here, or click to select one.</p>
                </Paper>

                {file && <p style={{ marginTop: '10px' }}>{file.name}</p>}

                {/* Error message for invalid CSV */}
                {csvError && <p style={{ color: 'red' }}>{csvError}</p>}
                {/* Error from parent (e.g., validation error for missing hotels) */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <div className="checkbox-options" style={{ marginTop: '20px' }}>
                    <div className="checkbox-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedOption === 'useGroupBids'}
                                onChange={() => setSelectedOption('useGroupBids')}  // Only one checkbox can be selected
                            />
                            Use Group Bids/Budgets
                        </label>
                        <p className="warning-text">
                            **Warning**: If the Group bids/budgets aren't set, then the hotel's bids/budgets will be removed and the hotel’s campaigns will close.
                        </p>
                    </div>

                    {/* Keep Hotel Bids/Budgets option */}
                    <div className="checkbox-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={selectedOption === 'keepHotelBids'}
                                onChange={() => setSelectedOption('keepHotelBids')}  // Only one checkbox can be selected
                            />
                            Keep hotel current bids/budgets
                        </label>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                            className='cancelUploadBtn'
                        >Cancel</Button>
                        <Button
                            variant="outlined"
                            onClick={handleProcessCsv}
                            disabled={!file}
                            className={`${!file ? 'inActiveUploadBtn' : 'activeUploadBtn'}`}>
                            Upload
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UploadCSVModal;
