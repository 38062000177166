const { IconButton, Icon } = require("@mui/material");

const IconWrapper = ({ icon, onClick }) => {
  return (
    <IconButton sx={{ padding: 0 }} disableRipple onClick={onClick}>
      <img src={icon} />
    </IconButton>
  );
};
export default IconWrapper;
