import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ReportsTable from "../../features/report-center/Listing";
import DefaultLayout from "../../layout/defaultLayout";
import SlidingPanel from '../../features/report-center/SlidingPanel';
import { fetchService } from '../../features/report-center/Services/fetchService';
import { Box, Paper, Grid, Snackbar,Alert } from '@mui/material';
import GlobalReportForm from "../../features/report-center/Forms/GlobalPerformance";
import GoogleFBLForm from "../../features/report-center/Forms/GoogleFBL";
import PerformancePmax from "../../features/report-center/Forms/PerformancePmax";
import SpFunnelTripAdvisor from "../../features/report-center/Forms/SpFunnelTripAdvisor";
import PriceCompetitiveness from "../../features/report-center/Forms/PriceCompetitiveness";
import PerformanceMarketPmax from "../../features/report-center/Forms/PerformanceMarketPmax";
import Billing from "../../features/report-center/Forms/Billing";
import Reservations from "../../features/report-center/Forms/Reservations";
import PerformanceGoogleMissedOpportunity from "../../features/report-center/Forms/PerformanceGoogleMissedOpportunity";
import {APP_REPORT_CENTER_API_ENDPOINT} from "../../constants/api_urls";
import PageTitles from "../../components/PageTitles";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";
import MetaSearch from 'pages/metasearch';
import { createGlobalStyle } from 'styled-components';


const ReportCenter = () => {
  const [reports, setReports] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isPannelOpen, setIsPannelOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [skipStep1, setSkipStep1] = useState(false); 
  const { idaccount } = useParams();

  const GlobalStyle = createGlobalStyle`
    select, input, option, label {
      font-size: 12px;
    }

    .MuiSelect-root, .MuiMenuItem-root, .MuiInputLabel-root, span.MuiTypography-root {
      font-size: 12px;
    }
`;

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reportResponse = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/reports/list?account=${idaccount}`);
        if (reportResponse.success) {
            setReports(reportResponse.data);
        } else {
          const errorMessage = reportResponse.errors.length > 0
            ? reportResponse.errors[0].message
            : 'Unknown error occurred';
          setError(errorMessage);
        }
        
      } catch (err) {
        setError('Failed to load reports');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);


  const handleSnackbarOpen = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

   // Handle report selection for editing
   const handleEditReport = (report) => {
    setSelectedReport(report);
    setShowForm(true); 
    setIsPannelOpen(false); 
  };

  // Handle creating a new report
  const handleCreateNewReport = () => {
    setSelectedReport(null);
    setSkipStep1(false); 
    setShowForm(true);
    setIsPannelOpen(true);
  };
   
      
  const reportComponents = {
    PERFORMANCE_GLOBAL: <GlobalReportForm onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}   setShowForm={setShowForm} />,
    PERFORMANCE_GOOGLE_FBL: <GoogleFBLForm onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    PERFORMANCE_METASEARCH: <MetaSearch onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    PERFORMANCE_PMAX: <PerformancePmax onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    SP_FUNNEL_TRIPADVISOR: <SpFunnelTripAdvisor onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    PRICE_COMPETITIVENESS: <PriceCompetitiveness onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    PERFORMANCE_MARKET_PMAX: <PerformanceMarketPmax onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    BILLING: <Billing onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
    RESERVATIONS: <Reservations onClose={setIsPannelOpen} setShowForm={setShowForm} showSnackbar={handleSnackbarOpen}  />,
    PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: <PerformanceGoogleMissedOpportunity onClose={setIsPannelOpen} showSnackbar={handleSnackbarOpen}  setShowForm={setShowForm} />,
  };


  return (
    <>
    <GlobalStyle />
    <DefaultLayout page="report-center">
      <div>
        <PageTitles pageTitle="Report Center" pageSubTitle="" />
      </div>
  
    <Box className="report-center-page">
      <Grid item xs={12}>
        <Box marginBottom={"0.4rem"} style={{ display: "flex", justifyContent: "end" }}>
        <CustomButtonWithIcon
            color="#b0acac"
            hoverColor="#61D5A9"
            width="120px"
            height="30px"
            titleColor="#373F41"
            borderRadius="20px"
            title="Click to create a new report"
            icon={DmpIcons.ActionsIcons.AddIconBlack}
            iconBorder="0.5px solid #373F41"
            textStyle={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '14.52px',
                textAlign: 'center',
            }}
            onClick={() => { handleCreateNewReport(); }} > New Report
        </CustomButtonWithIcon>
        </Box>
      </Grid>
  
      {/* Sliding Panel */}
      <SlidingPanel
        open={isPannelOpen}
        onClose={() => setIsPannelOpen(false)}
        skipStep1={false}
        showSnackbar={handleSnackbarOpen}
        initial= {true}
      />
       <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert  onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
  
      {/* Render selected report component */}
      {showForm && selectedReport && reportComponents[selectedReport]}
  
      {/* Report Listing Section */}
      <Paper className="report-listing-section" elevation={3}>
        <ReportsTable reports={reports} onEdit={handleEditReport} loading= {loading} showSnackbar={handleSnackbarOpen} />
      </Paper>
    </Box>
  </DefaultLayout>
  </>
  );
};

export default ReportCenter;