export const wegoStatusFormFields = [
  {
    id: "status",
    label: "Change status",
    type: "select",
    placeholder: "Select a status",
    options: [
      {
        id: "open",
        label: "Open",
        value: "OPEN",
      },
      {
        id: "close",
        label: "Close",
        value: "CLOSE",
      },
    ],
  },
];
