import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

const CustomTabs = ({ options, onChange, value }) => {
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "none",
          borderColor: "divider",
          "& .MuiTabs-flexContainer": {
            justifyContent: "flex-end",
            marginRight: "30px",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          sx={{
            height: "26px",
            minHeight: "26px",
            "& .MuiButtonBase-root": {
              fontSize: "12px",
              fontWeight: 400,
              textTransform: "none",
              backgroundColor: "#BAB7B7",
              borderRadius: "10px 10px 0px 0px",
              marginLeft: "4px",
              height: "26px !important",
              color: "white",
            },
            "& .Mui-selected": {
              backgroundColor: "#61D5A9",
              color: "white !important",
            },
          }}
        >
          {options.map((item, i) => {
            return (
              <Tab
                sx={{ minHeight: "26px", height: "26px" }}
                label={item.label}
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
      </Box>
      {options.map((item, i) => {
        return (
          <CustomTabPanel value={value} index={i}>
            {item.content}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};
export default CustomTabs;
