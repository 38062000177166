import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

const CustomCheckbox = ({
  title,
  checked,
  onChange,
  fontSize = 12,
  labelStyles,
  checkboxStyles,
}) => {
  return (
    <FormGroup>
      {" "}
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            style={checkboxStyles}
            sx={{
              paddingBlock: "2px",
              color: "#BAB7B7",
              "&.Mui-checked": {
                color: "#61D5A9",
              },
              "& .MuiSvgIcon-root": {
                height: "14px",
                width: "14px",
              },
            }}
            onChange={onChange}
            checked={checked}
          />
        }
        sx={{
          alignItems: "flex-start",
          "& .MuiFormControlLabel-label": {
            color: "black",
            fontSize: fontSize,
            ...labelStyles,
          },
        }}
        label={title}
      />{" "}
    </FormGroup>
  );
};
export default CustomCheckbox;
