import React, {useState} from 'react';
import { MaterialReactTable } from 'material-react-table';
import {CircularProgress, Box, Stack, Typography, Select, MenuItem, Pagination} from '@mui/material';
import './SubUserTable.scss';
import CustomPagination from "../../../../components/CustomPagination";

const generateColumns = (data, onEmailClick) => {
    if (!data || data.length === 0) {
        return [];
    }

    const keys = Object.keys(data[0]);

    return keys
        .filter((key) => key !== 'id' && key !== 'role')
        .map((key) => {
            if (key === 'username') {
                return {
                    accessorKey: key,
                    header: 'Username',
                    size: 100,
                    Cell: ({ cell, row }) => (
                        <a
                            className="username-link"
                            onClick={() => onEmailClick(row.original)}
                        >
                            {cell.getValue()}
                        </a>
                    ),
                };
            } else {
                return {
                    accessorKey: key,
                    header: key.charAt(0).toUpperCase() + key.slice(1),
                    size: 100,
                };
            }
        });
};

const SubUserTable = ({ usersData, onEmailClick, loading }) => {
    const columns = generateColumns(usersData, onEmailClick);
    // State for pagination
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Calculate paginated data
    const paginatedData = usersData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const userCount = usersData.length;
    const pageCount = Math.ceil(userCount / rowsPerPage);

    return (
        <Box sx={{ position: 'relative', maxWidth: '100%' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <MaterialReactTable
                columns={columns}
                data={paginatedData}
                enablePagination={false}
                enableSorting={true}
                enableColumnVisibility={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                muiTableContainerProps={{sx: {boxShadow: 'none',border: 'none'}}}
                muiTablePaperProps={{sx: {boxShadow: 'none', borderRadius: '0 0 14px 14px', overflow: 'hidden'}}}
                muiTableHeadCellProps={{
                    sx: {
                        textAlign: 'left',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        verticalAlign: 'middle',
                        padding: '0 16px'
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        textAlign: 'left',
                        width: '150px',
                        fontSize: '12px',
                        lineHeight: '40px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '45px',
                        padding: '0 16px',
                    },
                }}
            />

            {/* Pagination Component */}
            <CustomPagination
                pageCount={pageCount}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleRowsPerPageChange={handleRowsPerPageChange}
            />
        </Box>
    );
};

export default SubUserTable;
