import axios from 'axios';
import { getUserToken } from "../../../utils/functions/userToken";

export const fetchService = async (
  API_URL,
  API_METHOD = 'GET',
  API_BODY = null
) => {

  const token = getUserToken();
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const axiosMethods = {
    GET: () => axios.get(API_URL, config),
    POST: () => axios.post(API_URL, API_BODY, config),
    PATCH: () => axios.patch(API_URL, API_BODY, config),
    PUT: () => axios.put(API_URL, API_BODY, config),
    DELETE: () => axios.delete(API_URL, config),
  };

  try {
    const response = await (axiosMethods[API_METHOD] || axiosMethods['GET'])();

    // Handle response status codes generically
    const { status_code } = response.data;
    if (status_code === 200) {
      return {
        success: true,
        message: response.data.data.message || 'Success',
        data: response.data.data || {},
      };
    } else if (status_code >= 400) {
      return {
        success: false,
        errors: response.data.error || [{ message: 'An error occurred' }],
      };
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return {
          success: false,
          errors: [{ code: 'UNAUTHORIZED', message: 'Unauthorized access' }],
        };
      }
      return {
        success: false,
        errors: [{ code: error.response.status, message: error.response.data.message || 'Server error occurred' }],
      };
    }
    
    return {
      success: false,
      errors: [{ code: 'NETWORK_ERROR', message: 'Network or server error occurred' }],
    };
  }
};
