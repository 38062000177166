import "./multipliers.scss";
import { useSelector } from "react-redux";
import TrivagoMultipliers from "../trivago/multipliers";
import TripAdvisorMultipliers from "../tripadvisor/multipliers";
import TripAdvisorSPMultipliers from "../tripadvisorSponsorPlacement/multipliers";
import BingMultipliers from "../bing/multipliers";
import GHAMultipliers from "../GHA/multipliers";
import GHAPPAMultipliers from "../GHAPPA/multipliers";

const Multipliers = () => {
  const platform = useSelector((state) => state?.metaSearch?.platform);

  switch (platform?.[0]?.id) {
    case "tripadvisor":
      return <TripAdvisorMultipliers />;
    case "trivago":
      return <TrivagoMultipliers />;
    case "tripadvisorSponsoredPlacement":
      return <TripAdvisorSPMultipliers />;
    case "bing":
      return <BingMultipliers />;
    case "googlehotelads":
      return <GHAMultipliers />;
    case "googleHotelAdsPropertyPromotionAds":
      return <GHAPPAMultipliers />;
    default:
      return null;
  }
};
export default Multipliers;

/*
const [activeButton, setActiveButton] = useState("device");
  const [formFields, setFormFields] = useState(ghaMultiplierFormFields);
  const platform = useSelector((state) => state?.metaSearch?.platform);
  const [buttons, setButtons] = useState([]);
  const [activeMultipliers, setActiveMultipliers] = useState([]);
  const [errors, setErrors] = useState({});


  useEffect(() => {
    console.log(errors, "errors");
    if (Object.keys(errors).length !== 0) {
      const timer = setTimeout(() => {
        setErrors({});
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timeout on unmount or re-render
    }
  }, [errors]);
  const generalButtons = [
    {
      id: "device",
      label: "Device",
    },
    {
      id: "lengthOfStay",
      label: "Length of stay",
    },
    {
      id: "checkInDay",
      label: "Check in day",
    },
    {
      id: "daysToArrival",
      label: "Days to arrival",
    },
    {
      id: "dateType",
      label: "Date type",
    },
  ];
  const bingButtons = [
    {
      id: "market",
      label: "Market",
    },
    {
      id: "bingSite",
      label: "Bing site",
    },
    {
      id: "lengthOfStay",
      label: "Length of stay",
    },
    {
      id: "checkInDay",
      label: "Check in day",
    },
  ];
  const tripadvisorButtons = [
    {
      id: "tripAdvisorLengthOfStay",
      label: "Length of stay",
    },
    {
      id: "tripAdvisorCheckInDays",
      label: "Check in day",
    },
    {
      id: "tripAdvisorDaysInArrival",
      label: "Days to arrival",
    },
    {
      id: "tripAdvisorDateType",
      label: "Date type",
    },
  ];
  const platformWiseFields = useMemo(() => {
    const currentPlatform = platform[0]?.id;
    setButtons(
      currentPlatform === "trivagoSponsoredListing"
        ? []
        : currentPlatform === "bing"
        ? bingButtons
        : currentPlatform === "trivago"
        ? []
        : currentPlatform === "tripadvisor"
        ? tripadvisorButtons
        : generalButtons
    );

    switch (currentPlatform) {
      case "bing":
        return bingMultiplierFormFields;
        break;
      case "googlehotelads": {
        return ghaMultiplierFormFields;
      }
      case "googleHotelAdsPropertyPromotionAds": {
        return ghaPropertyPromotionAdsMultiplierFormFields;
      }
      case "tripadvisor": {
        return tripAdvisorMultiplierFormFields;
      }
      case "tripadvisorSponsoredPlacement": {
        return tripadvisorSponsoredPlacementMultiplierFormFields;
      }
      case "trivagoSponsoredListing": {
        return trivagoSponsoredListingMultiplierFormFields;
      }

      default:
        return [];
    }
  }, [platform]);

  const allFields = {
    device: ["mobile", "tablet", "desktop"],
    lengthOfStay: ["numberOfNights", "multiplierValue"],
    checkInDay: [
      "monday",
      "friday",
      "tuesday",
      "saturday",
      "wednesday",
      "sunday",
      "thursday",
    ],
    daysToArrival: ["days", "bidMultiplier"],
    dateType: ["default", "userSelected"],
    market: ["bidMultiplier"],
    bingSite: ["localUniverse", "bidMultiplierPercentage", "mapResults"],
    bingDateType: ["date", "bidMultiplier"],
    tripAdvisorLengthOfStay: [
      "device",
      "numberOfNights",
      "empty",
      "multiplierValue",
    ],
    tripAdvisorCheckInDays: [
      "device",
      "empty",
      "monday",
      "friday",
      "tuesday",
      "saturday",
      "wednesday",
      "sunday",
      "thursday",
    ],
    tripAdvisorDaysInArrival: ["device", "empty", "days", "bidMultiplier"],
    tripAdvisorDateType: ["device", "empty", "default", "userSelected"],
  };
  const showChips =
    activeButton === "lengthOfStay" ||
    activeButton === "daysToArrival" ||
    activeButton === "tripAdvisorDaysInArrival" ||
    activeButton === "tripAdvisorLengthOfStay";
  useEffect(() => {
    if (platform?.[0]?.id === "trivagoSponsoredListing") {
      setFormFields(trivagoSponsoredListingMultiplierFormFields);
      return;
    }
    const currentSelection = allFields[activeButton];
    const fields = platformWiseFields
      ?.filter((field) => {
        return currentSelection.findIndex((item) => item === field.id) !== -1;
      })
      .sort(
        (a, b) =>
          currentSelection.findIndex((item) => item === a.id) -
          currentSelection.findIndex((item) => item === b.id)
      );
    setFormFields(fields);
  }, [activeButton]);

  const handleAddMultiplier = (id) => {
    const rangeId =
      activeButton === "lengthOfStay" ||
      activeButton === "bingLengthOfStay" ||
      activeButton === "tripAdvisorLengthOfStay"
        ? "numberOfNights"
        : "";
    console.log("204:::::", { rangeId, activeButton });
    const range = control._formValues[rangeId];
    const percentage = control._formValues[id];
    //clearErrors("multiplierValue");
    setErrors({ ...errors, multiplierValue: "" });
    if (
      range === "" ||
      range.split("-")[0] === "" ||
      range.split("-")[1] === "" ||
      percentage === ""
    ) {
      return;
    }
    const from = range.split("-")[0];
    const to = range.split("-")[1];
    if (Number(from) > Number(to)) {
      setErrors((prev) => {
        return {
          ...prev,
          multiplierValue: "Incorrect number of nights range for start value",
        };
      });

      return;
    } else if (Number(from) >= 31 || Number(to) >= 31) {
      console.log(Number(from), Number(to), "from-to:::::::");
      setErrors((prev) => {
        return {
          ...prev,
          multiplierValue:
            Number(from) >= 31
              ? `Incorrect number of nights range for start value`
              : "Incorrect number of nights range for end value",
        };
      });

      return;
    }

    for (let i = 0; i < activeMultipliers.length; i++) {
      const tempRange = activeMultipliers[i].split(" ")[0];
      const tempFrom = tempRange.split("-")[0];
      const tempTo = tempRange.split("-")[1];
      console.log(
        "handleAdd::",
        (Number(from) >= Number(tempFrom) && Number(from) <= Number(tempTo)) ||
          (Number(to) >= Number(tempFrom) && Number(to) <= Number(tempTo))
      );

      if (
        (Number(from) >= Number(tempFrom) && Number(from) <= Number(tempTo)) ||
        (Number(to) >= Number(tempFrom) && Number(to) <= Number(tempTo))
      ) {
        setErrors((prev) => {
          return {
            ...prev,
            multiplierValue: `The range ${from}-${to} nights can't be added , it cross with range ${tempFrom}-${tempTo} nights`,
          };
        });

        return;
      }
    }
    setActiveMultipliers((prev) => [...prev, `${range} Days ${percentage}%`]);
    setValue("numberOfNights", "");
    setValue("multiplierValue", "");
    setErrors({});
  };

  const { control, register, watch, setValue, setError, clearErrors } = useForm(
    {
      defaultValues: formFields
        ?.map((i) => i.id)
        .reduce((acc, item) => {
          acc[item] = "";
          return acc;
        }, {}),
    }
  );

  const handleChange = (id, val) => {
    setValue(id, val);
  };

  return (
    <div className="multiplier-container">
      <div className="mul-title-button-container">
        <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-18px",
            marginRight: "-18px",
          }}
        >
          {buttons.map((button) => {
            return (
              <Button
                onClick={() => {
                  setActiveButton(button.id);
                }}
                sx={{
                  backgroundColor:
                    button.id === activeButton ? "#B9F2DB" : "inherit",
                  "&:hover": {
                    backgroundColor:
                      activeButton === button.id ? "#B9F2DB" : "inherit",
                  },
                }}
                variant="outlined"
                className="topBtn"
                disableFocusRipple
              >
                {button.label}
              </Button>
            );
          })}
        </div>
      </div>
      <div>
        <div className="form-button-container">
          <div className="formGrid">
            <div className="mul-common-field-container">
              {commonFormFields.map((field) => {
                if (
                  field.id === "market" &&
                  platform[0]?.id === "bing" &&
                  activeButton !== "market"
                ) {
                  return <p style={{}}>THIS APPLIES TO ALL MARKETS</p>;
                }
                return (
                  <div className="form-grid-item">
                    {" "}
                    <FormFieldRenderer
                      onChange={(val) => handleChange(field.id, val)}
                      value={watch(field.id)}
                      item={field}
                    />{" "}
                  </div>
                );
              })}
            </div>
            <div
              className="mul-fields-container"
              style={{
                flex: showChips ? 2.25 : 3,
                maxWidth: "100%",
                gridTemplateColumns: `repeat(${
                  showChips
                    ? Math.ceil(formFields.length / 2)
                    : Math.ceil(formFields.length / 2) + 1
                },1fr)`,
              }}
            >
              {formFields?.map((field) => {
                if (field.id === "empty") {
                  return <div className="form-grid-item"> &nbsp;</div>;
                }
                return (
                  <div className="form-grid-item">
                    {" "}
                    <FormFieldRenderer
                      item={field}
                      value={watch(field.id)}
                      onChange={(val) => handleChange(field.id, val)}
                      handleAddMultiplier={() => handleAddMultiplier(field.id)}
                    />{" "}
                    <div>
                      {Object.entries(errors)?.find((item) => {
                        if (item[0] === field.id) {
                          return true;
                        }
                      })?.length > 0 && (
                        <p style={{ color: "red", margin: 0, padding: 0 }}>
                          {
                            Object.entries(errors)?.find((item) => {
                              if (item[0] === field.id) {
                                return true;
                              }
                            })?.[1]
                          }
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              style={{ display: !showChips ? "none" : "block" }}
              className="mul-chips-container"
            >
              <div className="active-mul-container">
                <div className="mul-form-label">Active Multipliers</div>
              </div>
              <div className="mul-chips">
                {activeMultipliers.map((item, index) => {
                  return (
                    <Chip
                      label={item}
                      variant="filled"
                      onDelete={() => {
                        const newMultipliers = [...activeMultipliers];
                        newMultipliers.splice(index, 1);
                        setActiveMultipliers(newMultipliers);
                      }}
                      deleteIcon={<img src={closeIcon} />}
                      sx={{
                        margin: "4px",
                        height: "20px",
                        fontSize: "10px",
                        backgroundColor: "#BAB7B7",
                      }}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mul-save">
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#12794F",
                  borderRadius: "20px",
                  height: "28px",
                  fontSize: "12px",
                  textTransform: "none",
                  color: "white",
                }}
                title="Save"
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

*/
