import { ReactComponent as AddIcon } from '../../assets/images/ActionIcons/Add.svg';
import AddIconSVG from '../../assets/images/ActionIcons/Add.svg';
import {ReactComponent as AddIconBlack} from '../../assets/images/ActionIcons/AddBlack.svg';
import AddIconBlackSVG from '../../assets/images/ActionIcons/AddBlack.svg';
import {ReactComponent as SearchIcon} from '../../assets/images/ActionIcons/Search.svg';
import SearchIconSVG from '../../assets/images/ActionIcons/Search.svg';

import CogsIconSVG from '../../assets/images/ActionIcons/Cogs.svg';
import {ReactComponent as CogsIcon} from '../../assets/images/ActionIcons/Cogs.svg';

import CogsGrayIconSVG from '../../assets/images/ActionIcons/CogsGray.svg';
import {ReactComponent as CogsGrayIcon} from '../../assets/images/ActionIcons/CogsGray.svg';

import {ReactComponent as CloseIcon} from '../../assets/images/ActionIcons/Close.svg';
import CloseIconSVG from '../../assets/images/ActionIcons/Close.svg';
import {ReactComponent as DeleteIcon} from '../../assets/images/ActionIcons/Delete.svg';
import DeleteIconSVG from '../../assets/images/ActionIcons/Delete.svg';
import {ReactComponent as ClosePanelIcon} from '../../assets/images/ActionIcons/ClosePanel.svg';
import ClosePanelIconSVG from '../../assets/images/ActionIcons/ClosePanel.svg';
import {ReactComponent as DownArrowIcon} from '../../assets/images/Accessibility/DownArrow.svg';
import DownArrowIconSVG from '../../assets/images/Accessibility/DownArrow.svg';
import {ReactComponent as UpArrowIcon} from '../../assets/images/Accessibility/UpArrow.svg';
import UpArrowIconSVG from '../../assets/images/Accessibility/UpArrow.svg';
import {ReactComponent as ThinDownArrowIcon} from '../../assets/images/Accessibility/ThinDownArrow.svg';
import ThinDownArrowIconSVG from '../../assets/images/Accessibility/ThinDownArrow.svg';
import {ReactComponent as ThinUpArrowIcon} from '../../assets/images/Accessibility/ThinUpArrow.svg';
import ThinUpArrowIconSVG from '../../assets/images/Accessibility/ThinUpArrow.svg';
import {ReactComponent as PauseIcon} from '../../assets/images/ActionIcons/Pause.svg';
import PauseIconSVG from '../../assets/images/ActionIcons/Pause.svg';
import {ReactComponent as TrashcanIcon} from '../../assets/images/ActionIcons/Trashcan.svg';
import TrashcanIconSVG from '../../assets/images/ActionIcons/Trashcan.svg';
import {ReactComponent as GoogleHotelAdsIcon} from '../../assets/images/MetasearchIcons/GoogleHotelAdsLogo.svg';
import GoogleHotelAdsIconSVG from '../../assets/images/MetasearchIcons/GoogleHotelAdsLogo.svg';
import {ReactComponent as TrivagoIcon} from '../../assets/images/MetasearchIcons/TrivagoLogo.svg';
import TrivagoIconSVG from '../../assets/images/MetasearchIcons/TrivagoLogo.svg';
import {ReactComponent as TripadvisorIcon} from '../../assets/images/MetasearchIcons/TripadvisorLogo.svg';
import TripadvisorIconSVG from '../../assets/images/MetasearchIcons/TripadvisorLogo.svg';
import {ReactComponent as SettingsIcon} from '../../assets/images/ActionIcons/Settings.svg';
import SettingsIconSVG from '../../assets/images/ActionIcons/Settings.svg';
import {ReactComponent as SettingsGrayIcon} from '../../assets/images/ActionIcons/SettingsGray.svg';
import SettingsGrayIconSVG from '../../assets/images/ActionIcons/SettingsGray.svg';
import {ReactComponent as LessIcon} from '../../assets/images/ActionIcons/Less.svg';
import LessIconSVG from '../../assets/images/ActionIcons/Less.svg';
import {ReactComponent as CalendarIcon} from '../../assets/images/ActionIcons/Calendar.svg';
import CalendarIconSVG from '../../assets/images/ActionIcons/Calendar.svg';


const DmpIcons = {
    "ActionsIconsSVG": {
        AddIconSVG,
        AddIconBlackSVG,
        SearchIconSVG,
        CogsIconSVG,
        CogsGrayIconSVG,
        CloseIconSVG,
        DeleteIconSVG,
        ClosePanelIconSVG,
        PauseIconSVG,
        TrashcanIconSVG,
        SettingsIconSVG,
        SettingsGrayIconSVG,
        LessIconSVG,
        CalendarIconSVG
    },
    "ActionsIcons": {
        AddIcon,
        AddIconBlack,
        SearchIcon,
        CogsIcon,
        CogsGrayIcon,
        CloseIcon,
        DeleteIcon,
        ClosePanelIcon,
        PauseIcon,
        TrashcanIcon,
        SettingsIcon,
        SettingsGrayIcon,
        LessIcon,
        CalendarIcon
    },
    "AccessibilityIconsSVG": {
        DownArrowIconSVG,
        UpArrowIconSVG,
        ThinDownArrowIconSVG,
        ThinUpArrowIconSVG,
    },

    "AccessibilityIcons": {
        DownArrowIcon,
        UpArrowIcon,
        ThinDownArrowIcon,
        ThinUpArrowIcon,
    },
    "MetasearchIconsSVG": {
        TripadvisorIconSVG,
        GoogleHotelAdsIconSVG,
        TrivagoIconSVG
    },
    "MetasearchIcons": {
        TripadvisorIcon,
        GoogleHotelAdsIcon,
        TrivagoIcon
    }

};

export default DmpIcons;