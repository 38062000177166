import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { BarChart, Bar, YAxis, Tooltip, ResponsiveContainer, XAxis } from "recharts";
import { TopHotelsAvailableMetrics } from "../../Utils/AvailableMetrics";

function TopHotelsChart({ topHotelsMetrics, chartData, CustomTooltip, setLastOption, domain }) {
    return (
        <Grid item xs={4} className="top-hotels-chart-column">
            <Grid item xs={12} className="top-hotels-dropdown-container dropdown-container">
                <select
                    className="dropdown"
                    onChange={(e) => setLastOption(e.target.value.replace('_kpi', ''))}
                >
                    {Object.entries(TopHotelsAvailableMetrics).map(([key, label]) => (
                        <option key={key} value={key}>
                            {label}
                        </option>
                    ))}
                </select>
            </Grid>
            <Grid item xs={12}>
                <Box className="top-hotels-chart-container">
                    {Object.keys(topHotelsMetrics).length === 0 ? (
                        <CircularProgress className="loader" />
                    ) : (
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={chartData}
                                margin={{
                                    top: 10,
                                    right: 10,
                                    bottom: -20,
                                    left: 0,
                                }}
                            >
                                <XAxis tick={false} />
                                <YAxis
                                    ticks={[0, domain]}
                                    allowDataOverflow={true}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="value" isAnimationActive={true} />
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}

export default TopHotelsChart;