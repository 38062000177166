export const APP_LOGIN_API_ENDPOINT = process.env.REACT_APP_LOGIN_API_ENDPOINT;
export const APP_RESET_PASSWORD_API = process.env.REACT_APP_RESET_API_PASSWORD;
export const APP_LOST_PASSWORD_ENDPOINT = process.env.REACT_APP_LOST_PASSWORD_ENDPOINT;
export const APP_ACCOUNT_API_ENDPOINT = process.env.REACT_APP_ACCOUNT_API_ENDPOINT;
export const APP_SKYSCANNER_BIDDING_API_ENDPOINT = process.env.REACT_APP_SKYSCANNER_BIDDING_API_ENDPOINT;
export const APP_TRIVAGO_BIDDING_API_ENDPOINT = process.env.REACT_APP_TRIVAGO_BIDDING_API_ENDPOINT;
export const APP_TRIPADVISOR_BIDDING_API_ENDPOINT = process.env.REACT_APP_TRIPADVISOR_BIDDING_API_ENDPOINT;
export const APP_KAYAK_BIDDING_API_ENDPOINT = process.env.REACT_APP_KAYAK_BIDDING_API_ENDPOINT;
export const APP_GHA_BIDDING_API_ENDPOINT = process.env.REACT_APP_GHA_BIDDING_API_ENDPOINT;
export const APP_BING_BIDDING_API_ENDPOINT = process.env.REACT_APP_BING_BIDDING_API_ENDPOINT;
export const APP_GROUP_EDITOR_API_ENDPOINT = process.env.REACT_APP_GROUP_EDITOR_API_ENDPOINT;
export const APP_RESERVATIONS_API_ENDPOINT = process.env.REACT_APP_RESERVATIONS_API_ENDPOINT;
export const APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT = process.env.REACT_APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT;
export const APP_WEGO_BIDDING_API_ENDPOINT = process.env.REACT_APP_WEGO_BIDDING_API_ENDPOINT;
export const APP_ALERT_CENTER_API_ENDPOINT = process.env.REACT_APP_ALERT_CENTER_API_ENDPOINT;
export const APP_BIDDING_CENTER_API_ENDPOINT = process.env.REACT_APP_BIDDING_CENTER_API_ENDPOINT;
export const APP_REPORT_CENTER_API_ENDPOINT = process.env.REACT_APP_REPORT_CENTER_API_ENDPOINT;
export const APP_SEARCH_ADS_CAMPAIGN_API_ENDPOINT = process.env.REACT_APP_SEARCH_ADS_CAMPAIGN_API_ENDPOINT;
export const APP_HOTELS_API_ENDPOINT = process.env.REACT_APP_HOTELS_API_ENDPOINT;
export const APP_METASEARCH_BUDGET_API_ENDPOINT = process.env.REACT_APP_METASEARCH_BUDGET_API_ENDPOINT;
export const APP_GLOBAL_REPORTING_API_ENDPOINT = process.env.REACT_APP_GLOBAL_REPORTING_API_ENDPOINT;
export const APP_GOOGLE_SEM_API_ENDPOINT =  process.env.REACT_APP_GOOGLE_SEM_API_ENDPOINT;
export const APP_CLOUDINARY_CLOUDNAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
export const APP_CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY;
export const APP_CLOUDINARY_API_KEY_SECRET = process.env.REACT_APP_CLOUDINARY_API_KEY_SECRET;
export const APP_CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;

export const APP_BIDDING_OFFLINE_API_ENDPOINT = process.env.REACT_APP_BIDDING_OFFLINE_API_ENDPOINT;
export const APP_GOOGLE_PMAX_API_ENDPOINT = process.env.REACT_APP_GOOGLE_PMAX_API_ENDPOINT;
export const APP_CLOUDINARY_URL_ENDPOINT = process.env.REACT_APP_CLOUDINARY_URL_ENDPOINT;
export const REACT_APP_PRODUCT_SIGNUP_PMAX_IFRAME_URL = process.env.REACT_APP_PRODUCT_SIGNUP_PMAX_IFRAME_URL;
export const REACT_APP_PRODUCT_SIGNUP_SEM_IFRAME_URL = process.env.REACT_APP_PRODUCT_SIGNUP_SEM_IFRAME_URL;
