import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel, InputAdornment,
} from "@mui/material";
import { MONTHS } from "../../../constants/months";
import CheckBoxBlankIcon from "../../../../../components/CheckBoxBlankIcon";
import CheckBoxCheckedIcon from "../../../../../components/CheckBoxCheckedIcon";
import { validateBudget } from "../../Utils/validators";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const MonthlyBudgetCap = ({
  monthlyBudget,
  setMonthlyBudget,
  targetValue,
  setTargetValue,
  errors,
  dailyBudgetCap,
  setDailyBudgetCap,
  isEdit = false,
}) => {
  const [budgetStatus, setBudgetStatus] = useState(null);
  const handleMonthlyBudgetChange = (month, value) => {
    if (/^\d*$/.test(value)) {
      setMonthlyBudget((prev) => ({
        ...prev,
        [month]: value,
      }));
    }
  };

  const currency = "€"; //@TODO: Load currency from the API

  const handleBudgetCapBlur = async () => {
    const payload = {
      default_budget: Number(targetValue),
      is_daily_budget: dailyBudgetCap,
      ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
        acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
        return acc;
      }, {}),
    };
    const result = await validateBudget(payload);
    setBudgetStatus(
      result.success
        ? { success: true, error: null }
        : { success: false, error: result.error || "Invalid budget cap value" }
    );
  };

  const handleTargetValueChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setTargetValue(value);
    }
  };

  const toggleDailyBudgetCap = () => {
    setDailyBudgetCap((prev) => !prev);
  };

  const monthsPerRow = isEdit ? 3 : 4;

  return (
    <Box className="monthlyBudgetCap">
      <Box display="flex" alignItems="flex-start" flexDirection={"column"}>
        <Typography className="sectionTitle" fontWeight="bold">
          Monthly Budget Cap
        </Typography>
        <Typography className="sectionSubtitle" variant="body2" color="textSecondary">
          Minimum budget required: €1700
        </Typography>
      </Box>

      <Box display="flex" alignItems="flex-start" p={2}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          sx={{
            paddingRight: "15px",
          }}
        >
          <CustomOutlinedInput
              inputPaddingLeft={"0px"}
              placeholder="Enter Monthly Budget Cap"
              value={targetValue}
              onChange={handleTargetValueChange}
              className="no-border-focus"
              type="number"
              fullWidth
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              minWidth="221px"
              helperText={
                budgetStatus
                    ? budgetStatus.error || "Enter Monthly Budget Cap"
                    : "Enter Monthly Budget Cap"
              }
              error={errors.targetValueError || (budgetStatus && budgetStatus.success === false)}
              success={budgetStatus && budgetStatus.success === true}
              onBlur={handleBudgetCapBlur}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body2" sx={{ fontSize: "12px" }}>
                        {currency}
                      </Typography>
                    </InputAdornment>
                ),
              }}

          />


          <FormControlLabel
            sx={{
              marginLeft: "1px",
            }}
            control={
              <Checkbox
                  size="small"
                  sx={{
                    color: "#BAB7B7",
                    "&.Mui-checked": {
                      color: "#61D5A9",
                    },
                    "& .MuiSvgIcon-root": {
                      height: "14px",
                      width: "14px",
                    },
                  }}
                  onChange={toggleDailyBudgetCap}
                  checked={dailyBudgetCap}
                  value={"DEVICE"}
              />
            }
            label="Daily Budget Cap"
          />
        </Box>

        <Box
          display="grid"
          gridTemplateColumns={`repeat(${monthsPerRow}, 1fr)`}
        >
          {MONTHS.map((month, idx) => (
            <Box
              key={month}
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ paddingRight: "16px" }}
              className="monthField"
            >
              <Typography
                  variant="body2"
                  sx={{ display: "flex", textAlign: "right", color: "#373F41", fontSize: "10px", paddingBottom: "35px", paddingLeft: "5px" }}
                  className="monthLabel"
              >
                {month}
              </Typography>
              <CustomOutlinedInput
                  inputPaddingLeft={"0px"}
                  minWidth={isEdit ? "150px" : "200px"}
                  inputPadding={"0px"}
                  value={monthlyBudget?.[month] || ""}
                  onChange={(e) => handleMonthlyBudgetChange(month, e.target.value)}
                  className="no-border-focus monthInput"
                  type="number"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onBlur={handleBudgetCapBlur}
                  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="body2" sx={{ fontSize: "12px" }}>
                            {currency}
                          </Typography>
                        </InputAdornment>
                    ),
                  }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MonthlyBudgetCap;
