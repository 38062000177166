import React, {useEffect, useState} from "react";
import DefaultLayout from "layout/defaultLayout";
import {Box, Button, Typography} from "@mui/material";
import GroupsLists from "../../features/groups-metasearch/GroupsList/GroupsLists";
import GroupsHotelsList from "../../features/groups-metasearch/GroupsHotelsList/GroupsHotelsList";
import HotelsList from "../../features/groups-metasearch/HotelsList/HotelsList";
import './groups.scss';
import {useParams} from "react-router-dom";
import {
    fetchHotelsAndGroups,
    createGroup,
    deleteGroup,
    moveHotelsToGroup,
    ungroupHotels,
    updateGroupName,
    uploadCsv
} from "./groupApi";
import PageTitles from "../../components/PageTitles";
import CustomButtonWithIcon from "../../components/CustomButtonWithIcon/Buttons";
import DmpIcons from "../../components/DmpIcons/icons"; // Import API functions
import { getUserToken } from "../../utils/functions/userToken";

const Groups = () => {
    const {idAccount} = useParams();
    const token = getUserToken();

    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [createGroupError, setCreateGroupError] = useState('');
    const [deleteError, setDeleteError] = useState('');
    const [moveHotelsError, setMoveHotelsError] = useState('');
    const [ungroupHotelsError, setUngroupHotelsError] = useState('');
    const [selectedHotelsInThird, setSelectedHotelsInThird] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [updateGroupError, setUpdateGroupError] = useState('');
    const [csvError, setCsvError] = useState('');
    const [csvLoading, setCsvLoading] = useState(false);

    // Fetch hotels and groups when component mounts
    useEffect(() => {
        const loadHotelsAndGroups = async () => {
            try {
                const data = await fetchHotelsAndGroups(idAccount, token);
                setHotels(data.hotels);
                setGroups(data.groups);
            } catch (error) {
                console.error(error);
            }
        };

        loadHotelsAndGroups();
    }, [idAccount, token]);

    const handleGroupSelect = (group) => {
        setSelectedGroup(group);
    };

    const handleCreateGroup = async (groupName) => {
        try {
            setLoading(true);
            setCreateGroupError('');
            const newGroup = await createGroup(groupName, idAccount, token);
            setGroups((prevGroups) => [...prevGroups, newGroup]);
            return true;
        } catch (error) {
            setCreateGroupError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteGroup = async () => {
        if (!selectedGroup) return;

        try {
            setLoading(true);
            await deleteGroup(idAccount, selectedGroup.id, token);
            setGroups(groups.filter(group => group.id !== selectedGroup.id));
            setHotels(hotels.map(hotel =>
                hotel.groupId === selectedGroup.id
                    ? {...hotel, groupId: null, groupName: 'Ungrouped'}
                    : hotel
            ));
            setSelectedGroup(null);
            return true;
        } catch (error) {
            setDeleteError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleMoveHotelsToGroup = async (selectedOption) => {
        if (!selectedGroup) return;

        try {
            setLoading(true);
            await moveHotelsToGroup(selectedGroup, selectedHotelsInThird, token, selectedOption === 'useGroupBids');
            setHotels(hotels.map(hotel =>
                selectedHotelsInThird.includes(hotel.id)
                    ? {...hotel, groupId: selectedGroup.id, groupName: selectedGroup.name}
                    : hotel
            ));
            setSelectedHotelsInThird([]);
        } catch (error) {
            setMoveHotelsError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUngroupHotels = async (selectedHotels) => {
        try {
            setLoading(true);
            await ungroupHotels(selectedGroup, selectedHotels, token);
            setHotels(hotels.map(hotel =>
                selectedHotels.includes(hotel.id)
                    ? {...hotel, groupId: null, groupName: 'Ungrouped'}
                    : hotel
            ));
        } catch (error) {
            setUngroupHotelsError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateGroupName = async (groupId, newGroupName) => {
        try {
            setLoading(true);
            setUpdateGroupError('');
            const success = await updateGroupName(groupId, newGroupName, token);
            if (success) {
                setGroups(groups.map(group => group.id === groupId ? {...group, name: newGroupName} : group));
                setHotels(hotels.map(hotel => hotel.groupId === groupId ? {...hotel, groupName: newGroupName} : hotel));
            }
            return true;
        } catch (error) {
            setUpdateGroupError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCsvUpload = async (hotelIds, selectedOption) => {
        try {
            setCsvLoading(true);
            setCsvError('');
            const allHotels = [...hotelsForSelectedGroup, ...hotelsInThirdComponent];
            const missingHotels = hotelIds.filter(id => !allHotels.some(hotel => hotel.id === id));

            if (missingHotels.length > 0) {
                setCsvError(`The following hotels are missing: ${missingHotels.join(', ')}`);
                return false;
            }

            const addedHotels = hotelIds.filter(id =>
                hotelsInThirdComponent.some(hotel => hotel.id === id)
            );

            const removedHotels = hotelsForSelectedGroup
                .filter(hotel => !hotelIds.includes(hotel.id))
                .map(hotel => hotel.id);

            await uploadCsv(addedHotels, removedHotels, selectedGroup, token, selectedOption);
            setHotels(hotels.map(hotel => {
                if (addedHotels.includes(hotel.id)) return {
                    ...hotel,
                    groupId: selectedGroup.id,
                    groupName: selectedGroup.name
                };
                if (removedHotels.includes(hotel.id)) return {...hotel, groupId: null, groupName: 'Ungrouped'};
                return hotel;
            }));

            return true;
        } catch (error) {
            setCsvError(error.message);
            return false;
        } finally {
            setCsvLoading(false);
        }
    };

    const hotelsForSelectedGroup = hotels.filter(hotel => hotel.groupId === selectedGroup?.id);
    const hotelsInThirdComponent = hotels.filter(hotel => hotel.groupId !== selectedGroup?.id);

    const [showModal, setShowModal] = useState(false);
    const handleAddGroupClick = () => setShowModal(true);

    return (
        <DefaultLayout page="groups">
            <PageTitles
                pageTitle="Metasearch"
                pageSubTitle="Groups"
            />
            <div style={{width: '99%', display: "flex", justifyContent: 'flex-end', margin: "0 0 10px 0"}}>
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#61D5A9"
                        width="120px"
                        height="30px"
                        titleColor="#373F41"
                        borderRadius="20px"
                        title="Click to create a new alert"
                        icon={DmpIcons.ActionsIcons.AddIconBlack}
                        iconBorder="0.5px solid #373F41"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={handleAddGroupClick}
                    >
                        New Group
                    </CustomButtonWithIcon>
            </div>
            <Box sx={{height: '80%', width: '99.2%', display: "flex", gap: "18px"}} className="groups-container">
                <GroupsLists
                    groups={groups}
                    setGroups={setGroups}
                    onSelectGroup={handleGroupSelect} // Pass handleGroupSelect here
                    idAccount={idAccount}
                    createGroup={handleCreateGroup}
                    loading={loading}
                    createGroupError={createGroupError}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />

                <GroupsHotelsList
                    selectedGroup={selectedGroup}
                    groupHotels={hotelsForSelectedGroup}
                    handleUngroupSelection={handleUngroupHotels}
                    updateGroupName={handleUpdateGroupName}
                    loading={loading}
                    error={ungroupHotelsError}
                    onConfirmDelete={handleDeleteGroup}
                    deleteError={deleteError}
                    updateGroupError={updateGroupError}
                    onUploadCsv={handleCsvUpload}
                    csvError={csvError}
                    csvLoading={csvLoading}
                />

                <HotelsList
                    mergedHotels={hotelsInThirdComponent}
                    selectedHotelsInThird={selectedHotelsInThird}
                    setSelectedHotelsInThird={setSelectedHotelsInThird}
                    moveHotelsToGroup={handleMoveHotelsToGroup}
                    selectedGroup={selectedGroup}
                    loading={loading}
                    error={moveHotelsError}
                />
            </Box>
        </DefaultLayout>
    );
};

export default Groups;
