import "./biddingOptions.scss";
import { useSelector } from "react-redux";
import SkyScanner from "../skyScanner/biddingoptions";
import Trivago from "../trivago/biddingoptions";
import TrivagoSponsoredListing from "../trivagoSponsorListing/biddingoptions";
import TripAdvisor from "../tripadvisor/biddingOptions";
import GHA from "../GHA/biddingOptions";
import Bing from "../bing/biddingOptions";
import Kayak from "../kayak/biddingOptions";
import GhaPpaBiddingOptions from "../GHAPPA/biddingOptions";
import TripAdvisorSP from "../tripadvisorSponsorPlacement/biddingOptions";
import Wego from "../wego/status";

const BiddingOptions = () => {
  const platform = useSelector((state) => state?.metaSearch?.platform?.[0]?.id);

  switch (platform) {
    case "skyScanner":
      return <SkyScanner />;
    case "trivago":
      return <Trivago />;
    case "trivagoSponsoredListing":
      return <TrivagoSponsoredListing />;
    case "tripadvisor":
      return <TripAdvisor />;
    case "tripadvisorSponsoredPlacement":
      return <TripAdvisorSP />;
    case "googlehotelads":
      return <GHA />;
    case "googleHotelAdsPropertyPromotionAds":
      return <GhaPpaBiddingOptions />;
    case "bing":
      return <Bing />;
    case "kayak":
      return <Kayak />;
    case "wego":
      return <Wego />;
    default:
      return null;
  }
};
export default BiddingOptions;
