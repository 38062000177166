import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openItems: {},
  activeSection: "",
};
export const sidebarSlice = createSlice({
  name: "sidebarSlice",
  initialState: initialState,
  reducers: {
    updateOpenItems: (state, action) => {
      state.openItems = action.payload;
    },
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
  },
});

export const { updateOpenItems, setActiveSection } = sidebarSlice.actions;
export default sidebarSlice.reducer;
