import axios from "axios";
import {APP_GLOBAL_REPORTING_API_ENDPOINT} from "../../../../constants/api_urls";
import {getUserToken} from "../../../../utils/functions/userToken";

/**
 * Extracts metrics from the response data.
 * @param {Object} data - The response data.
 * @returns {Array} An array of metrics.
 */
function separateMetrics(data) {
    return data.metrics || [];
}

/**
 * Removes metrics and compare-related fields from the response data.
 * @param {Object} data - The response data.
 * @returns {Object} Cleaned data without metrics and compare-related fields.
 */
function getDataWithoutMetricsAndCompare(data) {
    const cleanedData = {};
    for (const key in data) {
        if (key !== 'metrics' && !key.endsWith('_compare')) {
            cleanedData[key] = data[key];
        }
    }
    return cleanedData;
}

/**
 * Extracts compare-related fields from the response data.
 * @param {Object} data - The response data.
 * @returns {Object} Data containing only compare-related fields.
 */
function getCompareData(data) {
    const compareData = {};
    for (const key in data) {
        if (key.endsWith('_compare')) {
            const newKey = key.replace('_compare', '');
            compareData[newKey] = data[key];
        }
    }
    return compareData;
}

/**
 * Fetches chart reporting data from the API and processes the response.
 *
 * @param {Object} payload - The request parameters sent to the API.
 * @returns {Promise<Object>} An object containing success status, data or error.
 *
 * The returned data includes:
 * - metrics: Array of metric data extracted from the response.
 * - chartData: Response data excluding metrics and compare-related fields.
 * - compareChartData: Data specifically containing compare-related fields.
 */
export const getChartsReporting = async (payload) => {
    const jwtToken = getUserToken();
    try {
        const response = await axios.get(
            `${APP_GLOBAL_REPORTING_API_ENDPOINT}/api/dashboard/chart-reporting/`,
            {
                params: payload,
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return {
            success: true,
            data: {
                metrics : separateMetrics(response.data),
                chartData : getDataWithoutMetricsAndCompare(response.data),
                compareChartData: getCompareData(response.data),
                chartAxisDate: response.data.series.date_series,
            }};
    } catch (error) {
        return { success: false, error };
    }
};

