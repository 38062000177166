const GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION = [
    { id_lang: 1, language_name: 'Arabic', language_code: 'ar', criterion_id: 1019 },
    { id_lang: 2, language_name: 'Bengali', language_code: 'bn', criterion_id: 1056 },
    { id_lang: 3, language_name: 'Bulgarian', language_code: 'bg', criterion_id: 1020 },
    { id_lang: 4, language_name: 'Catalan', language_code: 'ca', criterion_id: 1038 },
    { id_lang: 5, language_name: 'Chinese (simplified)', language_code: 'zh_CN', criterion_id: 1017 },
    { id_lang: 6, language_name: 'Chinese (traditional)', language_code: 'zh_TW', criterion_id: 1018 },
    { id_lang: 7, language_name: 'Croatian', language_code: 'hr', criterion_id: 1039 },
    { id_lang: 8, language_name: 'Czech', language_code: 'cs', criterion_id: 1021 },
    { id_lang: 9, language_name: 'Danish', language_code: 'da', criterion_id: 1009 },
    { id_lang: 10, language_name: 'Dutch', language_code: 'nl', criterion_id: 1010 },
    { id_lang: 11, language_name: 'English', language_code: 'en', criterion_id: 1000 },
    { id_lang: 12, language_name: 'Estonian', language_code: 'et', criterion_id: 1043 },
    { id_lang: 13, language_name: 'Filipino', language_code: 'tl', criterion_id: 1042 },
    { id_lang: 14, language_name: 'Finnish', language_code: 'fi', criterion_id: 1011 },
    { id_lang: 15, language_name: 'French', language_code: 'fr', criterion_id: 1002 },
    { id_lang: 16, language_name: 'German', language_code: 'de', criterion_id: 1001 },
    { id_lang: 17, language_name: 'Greek', language_code: 'el', criterion_id: 1022 },
    { id_lang: 18, language_name: 'Gujarati', language_code: 'gu', criterion_id: 1072 },
    { id_lang: 19, language_name: 'Hebrew', language_code: 'iw', criterion_id: 1027 },
    { id_lang: 20, language_name: 'Hindi', language_code: 'hi', criterion_id: 1023 },
    { id_lang: 21, language_name: 'Hungarian', language_code: 'hu', criterion_id: 1024 },
    { id_lang: 22, language_name: 'Icelandic', language_code: 'is', criterion_id: 1026 },
    { id_lang: 23, language_name: 'Indonesian', language_code: 'id', criterion_id: 1025 },
    { id_lang: 24, language_name: 'Italian', language_code: 'it', criterion_id: 1004 },
    { id_lang: 25, language_name: 'Japanese', language_code: 'ja', criterion_id: 1005 },
    { id_lang: 26, language_name: 'Kannada', language_code: 'kn', criterion_id: 1086 },
    { id_lang: 27, language_name: 'Korean', language_code: 'ko', criterion_id: 1012 },
    { id_lang: 28, language_name: 'Latvian', language_code: 'lv', criterion_id: 1028 },
    { id_lang: 29, language_name: 'Lithuanian', language_code: 'lt', criterion_id: 1029 },
    { id_lang: 30, language_name: 'Malay', language_code: 'ms', criterion_id: 1102 },
    { id_lang: 31, language_name: 'Malayalam', language_code: 'ml', criterion_id: 1098 },
    { id_lang: 32, language_name: 'Marathi', language_code: 'mr', criterion_id: 1101 },
    { id_lang: 33, language_name: 'Norwegian', language_code: 'no', criterion_id: 1013 },
    { id_lang: 34, language_name: 'Persian', language_code: 'fa', criterion_id: 1064 },
    { id_lang: 35, language_name: 'Polish', language_code: 'pl', criterion_id: 1030 },
    { id_lang: 36, language_name: 'Portuguese', language_code: 'pt', criterion_id: 1014 },
    { id_lang: 37, language_name: 'Punjabi', language_code: 'pa', criterion_id: 1110 },
    { id_lang: 38, language_name: 'Romanian', language_code: 'ro', criterion_id: 1032 },
    { id_lang: 39, language_name: 'Russian', language_code: 'ru', criterion_id: 1031 },
    { id_lang: 40, language_name: 'Serbian', language_code: 'sr', criterion_id: 1035 },
    { id_lang: 41, language_name: 'Slovak', language_code: 'sk', criterion_id: 1033 },
    { id_lang: 42, language_name: 'Slovenian', language_code: 'sl', criterion_id: 1034 },
    { id_lang: 43, language_name: 'Spanish', language_code: 'es', criterion_id: 1003 },
    { id_lang: 44, language_name: 'Swedish', language_code: 'sv', criterion_id: 1015 },
    { id_lang: 45, language_name: 'Tamil', language_code: 'ta', criterion_id: 1130 },
    { id_lang: 46, language_name: 'Telugu', language_code: 'te', criterion_id: 1131 },
    { id_lang: 47, language_name: 'Thai', language_code: 'th', criterion_id: 1044 },
    { id_lang: 48, language_name: 'Turkish', language_code: 'tr', criterion_id: 1037 },
    { id_lang: 49, language_name: 'Ukrainian', language_code: 'uk', criterion_id: 1036 },
    { id_lang: 50, language_name: 'Urdu', language_code: 'ur', criterion_id: 1041 },
    { id_lang: 51, language_name: 'Vietnamese', language_code: 'vi', criterion_id: 1040 }
];

export default GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION;
