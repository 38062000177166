import { CloseOutlined, Search } from "@mui/icons-material";
import CustomCheckbox from "components/Checkbox";
import { Menu, Box, Button, OutlinedInput } from "@mui/material";
import { useState, useMemo } from "react";

export default function CampaignFilterMenu({
  open,
  options,
  onClose,
  filterType,
  onApply,
  anchorEl,
}) {
  //Selectors
  const [selected, setSelected] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");

  const isSelectAllChecked = useMemo(() => {
    const selectedCount = selected.filter((item) => item.isSelected).length;

    return selectedCount === options.length;
  }, [selected]);
  const toggleSelectAll = () => {
    const selectedCount = selected.filter((item) => item.isSelected).length;
    if (selectedCount === options.length) {
      setSelected(
        selected.map((item) => {
          return { ...item, isSelected: false };
        })
      );
    } else {
      setSelected(
        selected.map((item) => {
          return { ...item, isSelected: true };
        })
      );
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      sx={{
        width: "100%",
        "& .MuiPaper-root": {
          borderRadius: "10px",
          minWidth: "240px !important",
        },
      }}
    >
      <div style={{ paddingInline: "10px" }}>
        <div
          style={{
            color: "var(--Grey, #C4C4C4)",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{filterType}</span>
          <span onClick={onClose}>
            <CloseOutlined sx={{ fontSize: "16px" }} />
          </span>
        </div>
        <div>
          <OutlinedInput
            sx={{
              borderRadius: "10px",
              borderColor: "#CADEDD",
              width: "195px",
              height: "28px",
              marginTop: "5px",
            }}
            size="small"
            placeholder="Search"
            startAdornment={
              <Search sx={{ color: "#CADEDD", fontSize: "18px" }} />
            }
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>

        <div style={{ display: "flex", marginTop: "5px" }}>
          <ul
            style={{
              listStyleType: "none",
              marginBlock: 0,
              padding: 0,
              paddingLeft: "4px",
              width: "100%",
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <span>
                <CustomCheckbox
                  size={"small"}
                  onChange={() => {
                    toggleSelectAll();
                  }}
                  checked={isSelectAllChecked}
                  title={"All Campaigns"}
                  labelStyles={{
                    color: "#c4c4c4",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                />
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "0px",
                  marginLeft: "5px",
                }}
              >
                {selected
                  ?.filter((campaign) =>
                    (campaign?.label)
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  ?.map((item) => (
                    <li
                      key={item.id}
                      style={{
                        borderLeft: "1px solid #CADEDD",
                        paddingLeft: "10px",

                        paddingBlock: "5px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span
                            style={{
                              flex: "0 0 50%",
                              textAlign: "right",
                              marginRight: "16px",
                            }}
                          >
                            <CustomCheckbox
                              size={"small"}
                              checked={item.isSelected}
                              title={item.label}
                              labelStyles={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                              onChange={() => {
                                setSelected(
                                  selected.map((i) => ({
                                    ...i,
                                    isSelected:
                                      i.id === item.id
                                        ? !i.isSelected
                                        : i.isSelected,
                                  }))
                                );
                              }}
                            />
                          </span>
                        </Box>
                      </div>
                    </li>
                  ))}
              </Box>
            </Box>
          </ul>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "14px",
          paddingBlock: "8px",
        }}
      >
        <span
          style={{
            flex: "0 0 50%",

            marginRight: "16px",

            fontSize: "10px",
          }}
          className="sublabel"
          onClick={() => {
            setSelected(
              selected.map((item) => {
                return { ...item, isSelected: false };
              })
            );
          }}
        >
          Unselect All
        </span>
        <Button
          variant="outlined"
          sx={{
            color: "black",
            backgroundColor: "#61D5A9",
            borderRadius: "20px",
            fontSize: "10px",
            width: "100px",
            paddingInline: "8px",

            fontFamily: "inter",
            height: "20px",
          }}
          onClick={() => {
            onApply([...selected?.filter((item) => item.isSelected)]);
            onClose();
          }}
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
}
