import React, { useState, useEffect } from 'react';
import { TableContainer,Paper,TableSortLabel, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Box,  Menu, Typography, CircularProgress, Grid, MenuItem, Dialog,DialogTitle, DialogContent,DialogContentText, DialogActions,Button, } from '@mui/material';
import SlidingPanel from './../SlidingPanel';
import { fetchService } from '../Services/fetchService';
import { REPORT_TYPE_LABELS, DELIVERY_FREQUENCY_LABELS, STATUS_LABELS, REPORT_TYPE_URLS } from '../Constant/reportMappings';
import { Snackbar, Alert } from '@mui/material';
import DmpIcons from "../../../components/DmpIcons/icons";
import SearchTableInput from "components/SearchTableInput/SearchTableInput";
import { COLUMNS_HEADER, TOOLTIP_TEXT } from '../Constant/listColumn';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from './../../../assets/images/edit.svg';
import { useParams } from "react-router-dom";
import {APP_REPORT_CENTER_API_ENDPOINT} from "./../../../constants/api_urls";
import CustomPagination from "../../../components/CustomPagination";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import './styles.scss';

const Listing = ({ reports , onEdit, loading , showSnackbar }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(reports);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedReport, setSelectedReport] = useState(null); 
  const [showForm, setShowForm] = useState(false); 
  const [reportType, setReportType] = useState('');
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeReportId, setActiveReportId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { idaccount } = useParams();

  useEffect(() => {
    setRows(reports);
  }, [reports]); 

  const totalPages = Math.max(Math.ceil(rows.length / rowsPerPage) - 1, 1);
  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const handleSettingsClick = (event, reportId) => {
    setAnchorEl(event.currentTarget);
    setActiveReportId(reportId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveReportId(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchReports = async (API_URL, reportId) => {
    try {
      const reportResponse = await fetchService(`${API_URL}?account=${idaccount}&report_id=${reportId}`);
      if (reportResponse.success) {
        setSelectedReport(reportResponse.data[0]);
      } else {
        const errorMessage = reportResponse.errors.length > 0
          ? reportResponse.errors[0].message
          : 'Unknown error occurred';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to load reports');
    }
  }

  const handleEdit = (report) => {  
    const detailURL = REPORT_TYPE_URLS[report.report_type]; 
    if (detailURL) {
      fetchReports(detailURL, report.id);
      setReportType(report.report_type);
      setShowForm(true);
    } else {
      console.error(`Unknown report type: ${report.report_type}`);
    }
  };

  const handleChangeStatus = async (reportId, status) => {
    try {
      if (status == "DELETED") {
        setSelectedReportId(reportId);
        setConfirmOpen(true);
      }
      const reportResponse = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/reports/update-status?account=${idaccount}&report_id=${reportId}&status=${status}`, "PATCH");
      if (reportResponse.success) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === reportId ? { ...row, status } : row
          )
        );
        setSnackbarMessage(`Status of report ID ${reportId} has been updated to ${status}`);
        setSnackbarOpen(true);
      } else {
        const errorMessage = reportResponse.errors.length > 0
          ? reportResponse.errors[0].message
          : 'Unknown error occurred';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to load reports');
    }
  }

  const handleExecute = async (reportId) => {
    try {
      const reportResponse = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/report/execute?report_id=${reportId}`, "PATCH");
      if (reportResponse.success) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === reportId ? { ...row, execute: false  } : row
          )
        );
        setSnackbarMessage(`Report ID ${reportId} was executed`);
        setSnackbarOpen(true);
      } else {
        const errorMessage = reportResponse.errors.length > 0
          ? reportResponse.errors[0].message
          : 'Unknown error occurred';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to load reports');
    }
  }
  
  const handleDeleteConfirm = (reportId) => {
    setSelectedReportId(reportId);
    setConfirmOpen(true);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);  
  };

  const handleConfirmClose = (confirmed) => {
    try {
      if (confirmed) {
        handleChangeStatus(selectedReportId, "DELETED").then(() => {
          setRows((prevRows) => prevRows.filter(row => row.id !== selectedReportId));
        });
      }
    } catch (err) {
      setError('Error trying to delete.');
    }
    setConfirmOpen(false);
    setSelectedReportId(null);
  };

  const calculateColumnWidth = (label) => {
    const baseWidth = 148;
    const charWidth = 10;
    return Math.max(baseWidth, label.length * charWidth);
  };

  // Sorting logic
  const sortedRows = rows.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  // Pagination logic
  const paginatedReports = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <>
      <Paper className="report-listing-table">
        <Grid item xs={12}>
          <Box className="table-header-report"  sx={{ backgroundColor: '#EBEBEB', padding: "0.5rem" }}>
            <SearchTableInput placeholder="Search" />
          </Box>
        </Grid>
        <Box sx={{position: 'relative', width: '100%'}}>
          {/* Loader Container */}
          {loading && (
              <Box className="loader">
                  <CircularProgress/>
                  <Typography variant="body2" sx={{marginLeft: 1}}>Loading...</Typography>
              </Box>
          )}

          <Box className='reportCenterContainerBox'>
              <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow sx={{height: '40px'}}>
                        {COLUMNS_HEADER.map((column) => (
                          <TableCell
                            key={column.id}
                            sortDirection={orderBy === column ? order : false}
                            sx={{
                                padding: '4px 8px',
                                fontSize: '0.75rem',
                                textAlign: 'left',
                                justifyContent:'left!important',
                                width: calculateColumnWidth(column)
                            }}
                          >
                          <Tooltip
                              title={
                                  TOOLTIP_TEXT.find((tooltip) => tooltip.id === column.id)?.label || column.id
                              }
                              placement="bottom">
                              <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={orderBy === column.id ? order : 'asc'}
                                  onClick={() => handleRequestSort(column.id)}
                                  sx={{ display: 'flex'}}>
                                  {column.label}
                              </TableSortLabel>
                          </Tooltip>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  { paginatedReports.length > 0 ? (
                      paginatedReports.map((report) => {
                        const formattedDate = new Date(report.date_created).toLocaleDateString('en-GB');
                        const isRunning = report.status === 'RUNNING';
                        const isExecute = report.execute == true;
                        return (
                          <TableRow hover tabIndex={-1} key={report.id} sx={{ height: '40px' }} >
                            <TableCell>{report.id}</TableCell>
                            <TableCell>{report.name}</TableCell>
                            <TableCell>
                              <IconButton aria-label="settings" onClick={(event) => handleSettingsClick(event, report.id)}>
                                <img src={DmpIcons.ActionsIconsSVG.CogsIconSVG} style={{width:'1.1rem',height:"1.1rem"}}  alt="Setting" />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl) && activeReportId === report.id}
                                onClose={handleMenuClose}
                                className="listing-menu-options-report"
                              >
                                <MenuItem onClick={() => { handleEdit(report); handleMenuClose(); }}>
                                <img src={EditIcon} alt="Edit" className="icon" />
                                  Edit
                                </MenuItem>
                                {isRunning ? (
                                  <MenuItem className='menu-paused' onClick={() => { handleChangeStatus(report.id, "PAUSED"); handleMenuClose(); }}>
                                    <img src={DmpIcons.ActionsIconsSVG.PauseIconSVG} alt="Pause" className="icon" />
                                    Pause
                                  </MenuItem>
                                ) : (
                                  <MenuItem onClick={() => { handleChangeStatus(report.id, "RUNNING"); handleMenuClose(); }}>
                                    <img src={DmpIcons.ActionsIconsSVG.PauseIconSVG} alt="Pause" className="icon" />
                                    Resume
                                  </MenuItem>
                                )}
                                {isExecute && (
                                  <MenuItem style={{ color: "green"}}  onClick={() => { handleExecute(report.id); handleMenuClose(); }} >
                                    <PlayArrowIcon style={{ fontSize: "16px", marginRight: "8px",color:"black" }} />
                                    Execute 
                                  </MenuItem>
                                )}
                                <MenuItem className='menu-deleted' onClick={() => { handleDeleteConfirm(report.id); handleMenuClose(); }}>
                                  <img src={DmpIcons.ActionsIconsSVG.DeleteIconSVG} alt="Delete" className="icon" />
                                  Delete 
                                </MenuItem>
                              </Menu>
                            </TableCell>
                            <TableCell>{REPORT_TYPE_LABELS[report.report_type] || report.report_type}</TableCell>
                            <TableCell>{report.meta_search}</TableCell>
                            <TableCell>{formattedDate}</TableCell>
                            <TableCell>{DELIVERY_FREQUENCY_LABELS[report.delivery_frequency] || report.delivery_frequency}</TableCell>
                            <TableCell>{STATUS_LABELS[report.status] || report.status}</TableCell>
                            <TableCell>{report.owner_username}</TableCell>
                          </TableRow>
                      );
                    })
                    ) : (
                      <TableRow sx={{ height: '40px' }}>
                        <TableCell className='no-record-avaiable' colSpan={9} sx={{ textAlign: 'center', padding: 2 }}>
                          <Typography sx={{ fontSize: '0.75rem' }}>No records available</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <Dialog open={confirmOpen} onClose={() => handleConfirmClose(false)} >
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete this report? This action cannot be undone.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                              variant="outlined"
                              onClick={() => handleConfirmClose(false)}
                              className='cancelBtn'
                          >
                              Cancel
                          </Button>
                          <Button
                              variant="outlined"
                              onClick={() => handleConfirmClose(true)}
                              className='deleteBtn'
                          >
                              Delete
                          </Button>
                      </DialogActions>
                    </Dialog>

                    </TableBody>
                  </Table>
              </TableContainer>
          </Box>
        </Box>
      </Paper>

      {/*Add Pagination component*/}
      <CustomPagination rowsPerPage={rowsPerPage} pageCount={totalPages} page={page + 1} handleChangePage={handleChangePage} handleRowsPerPageChange={handleRowsPerPageChange} />
            
      <SlidingPanel open={showForm} onClose={() => setShowForm(false)} skipStep1={false} showSnackbar={showSnackbar} report_type={reportType} reportData={selectedReport} isEdit= {true} initial={true}  /> 
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Listing;
