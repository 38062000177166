import React from "react";
import PMaxCampaignAccordion from "../googlePmaxFormComponent/PMaxCampaignAccordion";
import {Box, Typography} from "@mui/material";
import CloudinaryImageEditor from "../googlePmaxFormComponent/AdsCreative/CloudinaryImageEditor";
import PMaxAdsCreativeAccordion from "../googlePmaxFormComponent/AdsCreative/PMaxAdsCreativeAccordion";
import CampaignSettingsAccordion from "../googlePmaxFormComponent/CampaignSettings/CampaignSettingsAccordion";
import {initialMonthlyBudget} from "../googlePmaxFormComponent/Utils/structures/initialState";
import './GooglePMaxCampaignEditor.scss';

const GooglePMaxCampaignEditor = ({
    expandedPanel,
    setExpandedPanel,
    visitedPanels,
    handleNext,
    selectedCountries,
    setSelectedCountries,
    selectedLanguage,
    setSelectedLanguage,
    targetValue,
    setTargetValue,
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    monthlyBudget,
    setMonthlyBudget,
    headlines,
    setHeadlines,
    descriptions,
    setDescriptions,
    finalURL,
    setFinalURL,
    businessName,
    setBusinessName,
    longHeadlines,
    setLongHeadlines,
    idaccount,
    idhotel,
    landscape,
    setLandscape,
    square,
    setSquare,
    portrait,
    setPortrait,
    logo,
    setLogo,
    landscapeLogo,
    setLandscapeLogo,
    errors,
    onSaveSettings,
    onSaveAdsCreative,
    setDrawerOpen,
    idCampaign
}) => {
    const handleNextWithValidation = async (currentPanel) => {
        setDrawerOpen(true);
        await handleNext(currentPanel);
        setDrawerOpen(false);
    };

    return (
        <>
            <PMaxCampaignAccordion
                title="1. Campaign Settings"
                expanded={expandedPanel === "settings"}
                onChange={() => setExpandedPanel("settings")}
                onNext={() => handleNextWithValidation("settings")}
                disabled={!visitedPanels.includes("settings")}
                visited={visitedPanels.includes("settings")}
                setDrawerOpen={setDrawerOpen}
                onSave={onSaveSettings}
                isSaveButton={true}
            >
                <CampaignSettingsAccordion
                    biddingStrategy={biddingStrategy || ""}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName || ""}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa || ""}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas || ""}
                    setTargetRoas={setTargetRoas}
                    targetValue={targetValue || ""}
                    setTargetValue={setTargetValue}
                    monthlyBudget={monthlyBudget || initialMonthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    selectedLanguage={selectedLanguage || ""}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedCountries={selectedCountries || []}
                    setSelectedCountries={setSelectedCountries}
                    errors={errors || {}}
                    idaccount={idaccount}
                    idhotel={idhotel}
                    isEdit={true}
                    idCampaign={idCampaign}
                />
            </PMaxCampaignAccordion>

            <PMaxCampaignAccordion
                title="2. Ads Creative"
                expanded={expandedPanel === "adsCreative"}
                onChange={() => setExpandedPanel("adsCreative")}
                onNext={() => handleNext("adsCreative")}
                disabled={!visitedPanels.includes("adsCreative")}
                visited={visitedPanels.includes("adsCreative")}
                onSave={onSaveAdsCreative}
                isSaveButton={true}
                setDrawerOpen={setDrawerOpen}
                noNextButton={true}
            >
                <PMaxAdsCreativeAccordion
                    headlines={headlines}
                    setHeadlines={setHeadlines}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    finalURL={finalURL}
                    setFinalURL={setFinalURL}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    longHeadlines={longHeadlines}
                    setLongHeadlines={setLongHeadlines}
                    errors={errors}
                />
                <Box className="pmax-media-library-editor">
                    <Box display="flex" alignItems="center" className="header-box">
                        <Typography variant="subtitle1">MEDIA LIBRARY</Typography>
                    </Box>
                    <Box display="flex" className="content-pmax-images">
                        <CloudinaryImageEditor
                            landscape={landscape}
                            setLandscape={setLandscape}
                            square={square}
                            setSquare={setSquare}
                            portrait={portrait}
                            setPortrait={setPortrait}
                            logo={logo}
                            setLogo={setLogo}
                            landscapeLogo={landscapeLogo}
                            setLandscapeLogo={setLandscapeLogo}
                            errors={errors}
                            isEdit={true}
                        />
                    </Box>
                </Box>
            </PMaxCampaignAccordion>
        </>
    );
};

export default GooglePMaxCampaignEditor;
