import React from "react";
import { Grid } from "@mui/material";
import { TopHotelsAvailableMetrics } from "../../Utils/AvailableMetrics";
import { COLORS } from "../../Utils/Colors";

function TopHotelsTable({ selectedHotels, tableMetrics, formatValue, handleTableMetricChange}) {
    return (
        <Grid item xs={12} className="top-hotels-table">
            <table>
                <thead>
                <tr>
                    <th> </th>
                    {tableMetrics.map((metric, index) => (
                        <th key={index} align="right">
                            <select
                                value={metric}
                                onChange={(event) => handleTableMetricChange(event, index)}
                                className="dropdown"
                            >
                                {Object.entries(TopHotelsAvailableMetrics).map(([key, label]) => (
                                    <option key={key} value={key}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {selectedHotels.length > 0 ? (
                    selectedHotels.map((hotel, index) => (
                        <tr key={index}>
                            <td>
                                    <span
                                        className="hotel-color"
                                        style={{ backgroundColor: COLORS[index % COLORS.length] }}
                                    ></span>
                                {hotel.hotel_name}
                            </td>
                            {tableMetrics.map((metric, i) => (
                                <td key={i} align="right">
                                    {formatValue(metric, hotel)}
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={tableMetrics.length + 1} align="center">
                            No data available
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </Grid>
    );
}

export default TopHotelsTable;