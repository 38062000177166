import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import GlobalReportForm from "../Forms/GlobalPerformance";
import GoogleFBL from "../Forms/GoogleFBL";
import Metasearch from "../Forms/Metasearch";
import PerformancePmax from "../Forms/PerformancePmax";
import SpFunnelTripAdvisor from "../Forms/SpFunnelTripAdvisor";
import PriceCompetitiveness from "../Forms/PriceCompetitiveness";
import PerformanceMarketPmax from "../Forms/PerformanceMarketPmax";
import Billing from "../Forms/Billing";
import Reservations from "../Forms/Reservations";
import PerformanceGoogleMissedOpportunity from "../Forms/PerformanceGoogleMissedOpportunity";
import DmpIcons from "components/DmpIcons/icons";
import SlidingPanelReact from 'react-sliding-side-panel';
import { REPORT_TYPE_LABELS } from '../Constant/reportMappings';
import './styles.scss'; 

const SlidingPanel =  ({ open, onClose, skipStep1, showSnackbar, reportData , report_type, isEdit, initial }) => {
  const [expanded, setExpanded] = useState(skipStep1 ? 'panel2' : 'panel1');
  const [selectedReport, setSelectedReport] = useState(null);
  const [panel2Visible, setPanel2Visible] = useState(skipStep1);
  const [visibleStep, setVisibleStep] = useState(skipStep1 ? 2 : 1);
  const [formData, setFormData] = useState(null);
  const [isPlatformVisible, setIsPlatformVisible] = useState(true);
  const [isFormVisible, setIsFormVisible] = useState(true);


  useEffect(() => {
    if (open) {
      setExpanded('panel1');
      setPanel2Visible(false);
      setVisibleStep(1);
      setIsPlatformVisible(true);
    }
  }, [open, initial]);


  useEffect(() => {
    if (reportData) {
      setFormData(reportData);
    }
    
    if (report_type) {
      setVisibleStep(2); 
      setSelectedReport(report_type);
      setPanel2Visible(true);
      setExpanded('panel2');
    }
  }, [reportData, report_type]);

  // Selecting a report type
  const handleSelectReport = (reportType) => {
    setIsPlatformVisible((prev) => !prev);
    setSelectedReport(reportType);
    setPanel2Visible(true);
    setExpanded('panel2');
  };

  const handleFormVisibility = () => {
      setIsFormVisible((prev) => !prev);
  };

  const togglePlatformSelection = () => {
      setIsPlatformVisible((prev) => !prev);
  };

  // Reports and forms mapping
  const reportForms = {
    PERFORMANCE_GLOBAL: <GlobalReportForm formData={formData}   showSnackbar={showSnackbar}  onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_GOOGLE_FBL: <GoogleFBL formData={formData}  showSnackbar={showSnackbar} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_METASEARCH: <Metasearch formData={formData}  showSnackbar={showSnackbar} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_PMAX: <PerformancePmax formData={formData}  showSnackbar={showSnackbar} onClose={onClose}  isEdit= {isEdit} />,
    SP_FUNNEL_TRIPADVISOR: <SpFunnelTripAdvisor formData={formData}  showSnackbar={showSnackbar} onClose={onClose}  isEdit= {isEdit} />,
    PRICE_COMPETITIVENESS: <PriceCompetitiveness formData={formData}  showSnackbar={showSnackbar} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_MARKET_PMAX: <PerformanceMarketPmax formData={formData}  showSnackbar={showSnackbar} onClose={onClose} isEdit= {isEdit} />,
    BILLING: <Billing formData={formData}  showSnackbar={showSnackbar}  onClose={onClose} isEdit= {isEdit} />,
    RESERVATIONS: <Reservations formData={formData}  showSnackbar={showSnackbar}  onClose={onClose} isEdit= {isEdit} />,
    PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: <PerformanceGoogleMissedOpportunity formData={formData}  showSnackbar={showSnackbar} onClose={onClose} isEdit= {isEdit} />,
  };

  return (
    <SlidingPanelReact
        type="right"
        isOpen={open}
        size={ 50}
        backdropClicked={onClose}
    >
    <div className="panel-container">
      <div className="report-panel-container">
        <div className="platform-container" style={{ width: '100%' }}>
        <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start'}}>
          <img className='close-icon'  onClick={() => { onClose();    setVisibleStep(1);}} src={DmpIcons.ActionsIconsSVG.CloseIconSVG} alt="Close" />
          <h2  className='report-form-title'> {isEdit ? 'Edit' : 'Add '} { REPORT_TYPE_LABELS[selectedReport] } Report </h2>
        </div>
        {!isEdit && (
        <div className={`platform-selection ${isPlatformVisible ? 'expanded' : ''}`}>
            <div className={`collapsible-header ${isPlatformVisible ? 'expanded' : ''} ${panel2Visible ? 'selected' : ''} `} onClick={togglePlatformSelection}>
              <h2 className="platform-title">1. SELECT REPORT TYPE</h2>
              <span className="arrow-icon">
                  <img
                      src={DmpIcons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                      alt="Arrow Down"
                      className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                  />
                </span>
            </div>
            {isPlatformVisible && (
            <div className="collapsible-section">
              <div className='form-container'>
                <Grid item xs={12}>
                  <Grid item xs={12} container>
                      <Grid item xs={9}>
                        <Typography className='panel-report-title' variant="subtitle1">Performance Report:</Typography>
                      </Grid>
                      <Grid item xs={3} sx={{textAlign:"center"}}>
                          <Typography className="panel-report-title" variant="subtitle1"> Billing Report: </Typography>
                      </Grid>
                  </Grid>
                  <Grid item xs={12} container spacing={1}>    
                    <Grid item xs={3}>
                      <Button
                        className={`report-button ${selectedReport === "PERFORMANCE_GLOBAL" ? 'selected' : ''}`}
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_GLOBAL')} > Global </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button 
                        className={`report-button ${selectedReport === "SP_FUNNEL_TRIPADVISOR" ? 'selected' : ''}`}
                        fullWidth onClick={() => handleSelectReport('SP_FUNNEL_TRIPADVISOR')} > Sp Funnel Tripadvisor </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button 
                        className={`report-button ${selectedReport === "PERFORMANCE_MARKET_PMAX" ? 'selected' : ''}`}
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_MARKET_PMAX')} > Google PMax Market </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button 
                        className={`report-button ${selectedReport === "BILLING" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('BILLING')} >  Global </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button  
                        className={`report-button ${selectedReport === "PERFORMANCE_METASEARCH" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_METASEARCH')} > Metasearch </Button>
                    </Grid>
                    <Grid item xs={3} >
                      <Button  
                        className={`report-button ${selectedReport === "PERFORMANCE_GOOGLE_FBL" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_GOOGLE_FBL')} > Google FBL </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button  
                        className={`report-button ${selectedReport === "PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY')} > Google Missed Opportunity </Button>
                    </Grid>
                    <Grid item xs={3} sx={{textAlign:"center"}}>
                      <Typography  sx={{marginBottom:"0px"}} className="panel-report-title" variant="subtitle1">Reservation Report: </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button  
                        className={`report-button ${selectedReport === "PRICE_COMPETITIVENESS" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('PRICE_COMPETITIVENESS')} > Price Competitiveness </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button  
                        className={`report-button ${selectedReport === "PERFORMANCE_PMAX" ? 'selected' : ''}`} 
                        fullWidth onClick={() => handleSelectReport('PERFORMANCE_PMAX')} > Google PMax </Button>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        className={`report-button ${selectedReport === "RESERVATIONS" ? 'selected' : ''}`}
                        fullWidth onClick={() => handleSelectReport('RESERVATIONS')} > Global </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            )}
        </div>
        )}

        {panel2Visible && (
            <div className="report-form-accordion">
              <div className={`platform-container ${isFormVisible ? 'expanded' : ''}`} >
                  <div className="collapsible-form">
                      <div className={`collapsible-header ${isFormVisible ? 'expanded' : ''}`} onClick={handleFormVisibility}>
                          <h2 className="platform-title">2.  {isEdit ? 'Edit' : 'Add '} { REPORT_TYPE_LABELS[selectedReport] }  Report </h2>
                          <span className="arrow-icon">
                            <img
                                src={DmpIcons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                alt="Arrow Down"
                                className={isFormVisible ? 'rotate-up' : 'rotate-down'}
                            />
                          </span>
                      </div>
                      {isFormVisible && (
                            <div className='collapsible-section'>
                                  {selectedReport ? reportForms[selectedReport] : <Typography>Select a report to see the form</Typography>}
                              </div>
                          )}
                  </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
      </SlidingPanelReact>
  );
};

export default SlidingPanel;