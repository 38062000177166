import React from "react";
import DefaultLayout from "../../layout/defaultLayout";
import PageTitles from "../../components/PageTitles";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";
import SearchTableInput from "components/SearchTableInput/SearchTableInput";

const  SamplePage = () => {
    return (
        <div className="samplepage">
            <DefaultLayout page="samplepag">
                <PageTitles
                    pageTitle="Page Title"
                    pageSubTitle="Page Subtitle"
                />

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#61D5A9"
                    width="120px"
                    height="30px"
                    titleColor="#373F41"
                    borderRadius="20px"
                    icon={DmpIcons.ActionsIcons.AddIconBlack}
                    iconBorder="0.5px solid #373F41"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={() => {
                        alert("Button Clicked");
                    }}
                >Icon Title</CustomButtonWithIcon>

                <SearchTableInput
                    placeholder={"Search"}
                />



                {/*Add Other page components here*/}

            </DefaultLayout>
        </div>
    );
};
export default SamplePage;
