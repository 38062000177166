const GOOGLE_SEARCH_ADS_LOCATION_CRITERION = {
    Europe: [
        { location_criterion_id: 2008, location_canonical_name: 'Albania', location_country_code: 'AL' },
        { location_criterion_id: 2020, location_canonical_name: 'Andorra', location_country_code: 'AD' },
        { location_criterion_id: 2040, location_canonical_name: 'Austria', location_country_code: 'AT' },
        { location_criterion_id: 2112, location_canonical_name: 'Belarus', location_country_code: 'BY' },
        { location_criterion_id: 2056, location_canonical_name: 'Belgium', location_country_code: 'BE' },
        { location_criterion_id: 2070, location_canonical_name: 'Bosnia and Herzegovina', location_country_code: 'BA' },
        { location_criterion_id: 2100, location_canonical_name: 'Bulgaria', location_country_code: 'BG' },
        { location_criterion_id: 2191, location_canonical_name: 'Croatia', location_country_code: 'HR' },
        { location_criterion_id: 2196, location_canonical_name: 'Cyprus', location_country_code: 'CY' },
        { location_criterion_id: 2203, location_canonical_name: 'Czechia', location_country_code: 'CZ' },
        { location_criterion_id: 2208, location_canonical_name: 'Denmark', location_country_code: 'DK' },
        { location_criterion_id: 2233, location_canonical_name: 'Estonia', location_country_code: 'EE' },
        { location_criterion_id: 2246, location_canonical_name: 'Finland', location_country_code: 'FI' },
        { location_criterion_id: 2250, location_canonical_name: 'France', location_country_code: 'FR' },
        { location_criterion_id: 2276, location_canonical_name: 'Germany', location_country_code: 'DE' },
        { location_criterion_id: 2300, location_canonical_name: 'Greece', location_country_code: 'GR' },
        { location_criterion_id: 2831, location_canonical_name: 'Guernsey', location_country_code: 'GG' },
        { location_criterion_id: 2352, location_canonical_name: 'Iceland', location_country_code: 'IS' },
        { location_criterion_id: 2372, location_canonical_name: 'Ireland', location_country_code: 'IE' },
        { location_criterion_id: 2380, location_canonical_name: 'Italy', location_country_code: 'IT' },
        { location_criterion_id: 2833, location_canonical_name: 'Isle of Man', location_country_code: 'IM' },
        { location_criterion_id: 2832, location_canonical_name: 'Jersey', location_country_code: 'JE' },
        { location_criterion_id: 2428, location_canonical_name: 'Latvia', location_country_code: 'LV' },
        { location_criterion_id: 2438, location_canonical_name: 'Liechtenstein', location_country_code: 'LI' },
        { location_criterion_id: 2440, location_canonical_name: 'Lithuania', location_country_code: 'LT' },
        { location_criterion_id: 2442, location_canonical_name: 'Luxembourg', location_country_code: 'LU' },
        { location_criterion_id: 2807, location_canonical_name: 'North Macedonia', location_country_code: 'MK' },
        { location_criterion_id: 2470, location_canonical_name: 'Malta', location_country_code: 'MT' },
        { location_criterion_id: 2498, location_canonical_name: 'Moldova', location_country_code: 'MD' },
        { location_criterion_id: 2492, location_canonical_name: 'Monaco', location_country_code: 'MC' },
        { location_criterion_id: 2499, location_canonical_name: 'Montenegro', location_country_code: 'ME' },
        { location_criterion_id: 2528, location_canonical_name: 'Netherlands', location_country_code: 'NL' },
        { location_criterion_id: 2578, location_canonical_name: 'Norway', location_country_code: 'NO' },
        { location_criterion_id: 2616, location_canonical_name: 'Poland', location_country_code: 'PL' },
        { location_criterion_id: 2620, location_canonical_name: 'Portugal', location_country_code: 'PT' },
        { location_criterion_id: 2642, location_canonical_name: 'Romania', location_country_code: 'RO' },
        { location_criterion_id: 2674, location_canonical_name: 'San Marino', location_country_code: 'SM' },
        { location_criterion_id: 2688, location_canonical_name: 'Serbia', location_country_code: 'RS' },
        { location_criterion_id: 2703, location_canonical_name: 'Slovakia', location_country_code: 'SK' },
        { location_criterion_id: 2705, location_canonical_name: 'Slovenia', location_country_code: 'SI' },
        { location_criterion_id: 2724, location_canonical_name: 'Spain', location_country_code: 'ES' },
        { location_criterion_id: 2752, location_canonical_name: 'Sweden', location_country_code: 'SE' },
        { location_criterion_id: 2756, location_canonical_name: 'Switzerland', location_country_code: 'CH' },
        { location_criterion_id: 2804, location_canonical_name: 'Ukraine', location_country_code: 'UA' },
        { location_criterion_id: 2826, location_canonical_name: 'United Kingdom', location_country_code: 'GB' },
        { location_criterion_id: 2336, location_canonical_name: 'Vatican City', location_country_code: 'VA' }
    ],
    Asia: [
        { location_criterion_id: 2004, location_canonical_name: 'Afghanistan', location_country_code: 'AF' },
        { location_criterion_id: 2051, location_canonical_name: 'Armenia', location_country_code: 'AM' },
        { location_criterion_id: 2031, location_canonical_name: 'Azerbaijan', location_country_code: 'AZ' },
        { location_criterion_id: 2048, location_canonical_name: 'Bahrain', location_country_code: 'BH' },
        { location_criterion_id: 2050, location_canonical_name: 'Bangladesh', location_country_code: 'BD' },
        { location_criterion_id: 2064, location_canonical_name: 'Bhutan', location_country_code: 'BT' },
        { location_criterion_id: 2096, location_canonical_name: 'Brunei', location_country_code: 'BN' },
        { location_criterion_id: 2116, location_canonical_name: 'Cambodia', location_country_code: 'KH' },
        { location_criterion_id: 2156, location_canonical_name: 'China', location_country_code: 'CN' },
        { location_criterion_id: 2196, location_canonical_name: 'Cyprus', location_country_code: 'CY' },
        { location_criterion_id: 2626, location_canonical_name: 'Timor-Leste', location_country_code: 'TL' },
        { location_criterion_id: 2268, location_canonical_name: 'Georgia', location_country_code: 'GE' },
        { location_criterion_id: 2356, location_canonical_name: 'India', location_country_code: 'IN' },
        { location_criterion_id: 2360, location_canonical_name: 'Indonesia', location_country_code: 'ID' },
        { location_criterion_id: 2368, location_canonical_name: 'Iraq', location_country_code: 'IQ' },
        { location_criterion_id: 2376, location_canonical_name: 'Israel', location_country_code: 'IL' },
        { location_criterion_id: 2392, location_canonical_name: 'Japan', location_country_code: 'JP' },
        { location_criterion_id: 2400, location_canonical_name: 'Jordan', location_country_code: 'JO' },
        { location_criterion_id: 2398, location_canonical_name: 'Kazakhstan', location_country_code: 'KZ' },
        { location_criterion_id: 2414, location_canonical_name: 'Kuwait', location_country_code: 'KW' },
        { location_criterion_id: 2417, location_canonical_name: 'Kyrgyzstan', location_country_code: 'KG' },
        { location_criterion_id: 2418, location_canonical_name: 'Laos', location_country_code: 'LA' },
        { location_criterion_id: 2422, location_canonical_name: 'Lebanon', location_country_code: 'LB' },
        { location_criterion_id: 2458, location_canonical_name: 'Malaysia', location_country_code: 'MY' },
        { location_criterion_id: 2462, location_canonical_name: 'Maldives', location_country_code: 'MV' },
        { location_criterion_id: 2496, location_canonical_name: 'Mongolia', location_country_code: 'MN' },
        { location_criterion_id: 2104, location_canonical_name: 'Myanmar (Burma)', location_country_code: 'MM' },
        { location_criterion_id: 2524, location_canonical_name: 'Nepal', location_country_code: 'NP' },
        { location_criterion_id: 2070, location_canonical_name: 'North Korea', location_country_code: 'KP' },
        { location_criterion_id: 2512, location_canonical_name: 'Oman', location_country_code: 'OM' },
        { location_criterion_id: 2586, location_canonical_name: 'Pakistan', location_country_code: 'PK' },
        { location_criterion_id: 2608, location_canonical_name: 'Philippines', location_country_code: 'PH' },
        { location_criterion_id: 2634, location_canonical_name: 'Qatar', location_country_code: 'QA' },
        { location_criterion_id: 2643, location_canonical_name: 'Russia', location_country_code: 'RU' },
        { location_criterion_id: 2682, location_canonical_name: 'Saudi Arabia', location_country_code: 'SA' },
        { location_criterion_id: 2702, location_canonical_name: 'Singapore', location_country_code: 'SG' },
        { location_criterion_id: 2410, location_canonical_name: 'South Korea', location_country_code: 'KR' },
        { location_criterion_id: 2144, location_canonical_name: 'Sri Lanka', location_country_code: 'LK' },
        { location_criterion_id: 2740, location_canonical_name: 'Syria', location_country_code: 'SY' },
        { location_criterion_id: 2762, location_canonical_name: 'Tajikistan', location_country_code: 'TJ' },
        { location_criterion_id: 2764, location_canonical_name: 'Thailand', location_country_code: 'TH' },
        { location_criterion_id: 2792, location_canonical_name: 'Turkiye', location_country_code: 'TR' },
        { location_criterion_id: 2795, location_canonical_name: 'Turkmenistan', location_country_code: 'TM' },
        { location_criterion_id: 2784, location_canonical_name: 'United Arab Emirates', location_country_code: 'AE' },
        { location_criterion_id: 2860, location_canonical_name: 'Uzbekistan', location_country_code: 'UZ' },
        { location_criterion_id: 2704, location_canonical_name: 'Vietnam', location_country_code: 'VN' },
        { location_criterion_id: 2887, location_canonical_name: 'Yemen', location_country_code: 'YE' }
    ],
    "America": [
        { location_criterion_id: 2124, location_canonical_name: 'Canada', location_country_code: 'CA' },
        { location_criterion_id: 2840, location_canonical_name: 'United States', location_country_code: 'US' },
        { location_criterion_id: 2581, location_canonical_name: 'United States Minor Outlying Islands', location_country_code: 'UM' },
        { location_criterion_id: 2178, location_canonical_name: 'Greenland', location_country_code: 'GL' },
        { location_criterion_id: 2652, location_canonical_name: 'Saint Barthelemy', location_country_code: 'BL' },
        { location_criterion_id: 2663, location_canonical_name: 'Saint Martin', location_country_code: 'MF' },
        { location_criterion_id: 2534, location_canonical_name: 'Sint Maarten', location_country_code: 'SX' },
        { location_criterion_id: 2028, location_canonical_name: 'Antigua and Barbuda', location_country_code: 'AG' },
        { location_criterion_id: 2044, location_canonical_name: 'The Bahamas', location_country_code: 'BS' },
        { location_criterion_id: 2052, location_canonical_name: 'Barbados', location_country_code: 'BB' },
        { location_criterion_id: 2084, location_canonical_name: 'Belize', location_country_code: 'BZ' },
        { location_criterion_id: 2188, location_canonical_name: 'Costa Rica', location_country_code: 'CR' },
        { location_criterion_id: 2392, location_canonical_name: 'Cuba', location_country_code: 'CU' },
        { location_criterion_id: 2531, location_canonical_name: 'Curacao', location_country_code: 'CW' },
        { location_criterion_id: 2212, location_canonical_name: 'Dominica', location_country_code: 'DM' },
        { location_criterion_id: 2214, location_canonical_name: 'Dominican Republic', location_country_code: 'DO' },
        { location_criterion_id: 2222, location_canonical_name: 'El Salvador', location_country_code: 'SV' },
        { location_criterion_id: 2308, location_canonical_name: 'Grenada', location_country_code: 'GD' },
        { location_criterion_id: 2320, location_canonical_name: 'Guatemala', location_country_code: 'GT' },
        { location_criterion_id: 2328, location_canonical_name: 'Guyana', location_country_code: 'GY' },
        { location_criterion_id: 2332, location_canonical_name: 'Haiti', location_country_code: 'HT' },
        { location_criterion_id: 2340, location_canonical_name: 'Honduras', location_country_code: 'HN' },
        { location_criterion_id: 2388, location_canonical_name: 'Jamaica', location_country_code: 'JM' },
        { location_criterion_id: 2558, location_canonical_name: 'Nicaragua', location_country_code: 'NI' },
        { location_criterion_id: 2591, location_canonical_name: 'Panama', location_country_code: 'PA' },
        { location_criterion_id: 2662, location_canonical_name: 'Saint Lucia', location_country_code: 'LC' },
        { location_criterion_id: 2666, location_canonical_name: 'Saint Pierre and Miquelon', location_country_code: 'PM' },
        { location_criterion_id: 2670, location_canonical_name: 'Saint Vincent and the Grenadines', location_country_code: 'VC' },
        { location_criterion_id: 2780, location_canonical_name: 'Trinidad and Tobago', location_country_code: 'TT' },
        { location_criterion_id: 2032, location_canonical_name: 'Argentina', location_country_code: 'AR' },
        { location_criterion_id: 2068, location_canonical_name: 'Bolivia', location_country_code: 'BO' },
        { location_criterion_id: 2076, location_canonical_name: 'Brazil', location_country_code: 'BR' },
        { location_criterion_id: 2535, location_canonical_name: 'Caribbean Netherlands', location_country_code: 'BQ' },
        { location_criterion_id: 2152, location_canonical_name: 'Chile', location_country_code: 'CL' },
        { location_criterion_id: 2170, location_canonical_name: 'Colombia', location_country_code: 'CO' },
        { location_criterion_id: 2218, location_canonical_name: 'Ecuador', location_country_code: 'EC' },
        { location_criterion_id: 2258, location_canonical_name: 'French Guiana', location_country_code: 'GF' },
        { location_criterion_id: 2328, location_canonical_name: 'Guyana', location_country_code: 'GY' },
        { location_criterion_id: 2600, location_canonical_name: 'Paraguay', location_country_code: 'PY' },
        { location_criterion_id: 2604, location_canonical_name: 'Peru', location_country_code: 'PE' },
        { location_criterion_id: 2740, location_canonical_name: 'Suriname', location_country_code: 'SR' },
        { location_criterion_id: 2858, location_canonical_name: 'Uruguay', location_country_code: 'UY' },
        { location_criterion_id: 2862, location_canonical_name: 'Venezuela', location_country_code: 'VE' },
    ],
    Africa: [
        { location_criterion_id: 2012, location_canonical_name: 'Algeria', location_country_code: 'DZ' },
        { location_criterion_id: 2024, location_canonical_name: 'Angola', location_country_code: 'AO' },
        { location_criterion_id: 2204, location_canonical_name: 'Benin', location_country_code: 'BJ' },
        { location_criterion_id: 2072, location_canonical_name: 'Botswana', location_country_code: 'BW' },
        { location_criterion_id: 2854, location_canonical_name: 'Burkina Faso', location_country_code: 'BF' },
        { location_criterion_id: 2108, location_canonical_name: 'Burundi', location_country_code: 'BI' },
        { location_criterion_id: 2120, location_canonical_name: 'Cameroon', location_country_code: 'CM' },
        { location_criterion_id: 2132, location_canonical_name: 'Cape Verde', location_country_code: 'CV' },
        { location_criterion_id: 2140, location_canonical_name: 'Central African Republic', location_country_code: 'CF' },
        { location_criterion_id: 2148, location_canonical_name: 'Chad', location_country_code: 'TD' },
        { location_criterion_id: 2174, location_canonical_name: 'Comoros', location_country_code: 'KM' },
        { location_criterion_id: 2178, location_canonical_name: 'Republic of the Congo', location_country_code: 'CG' },
        { location_criterion_id: 2180, location_canonical_name: 'Democratic Republic of the Congo', location_country_code: 'CD' },
        { location_criterion_id: 2384, location_canonical_name: 'Côte d’Ivoire', location_country_code: 'CI' },
        { location_criterion_id: 2262, location_canonical_name: 'Djibouti', location_country_code: 'DJ' },
        { location_criterion_id: 2818, location_canonical_name: 'Egypt', location_country_code: 'EG' },
        { location_criterion_id: 2226, location_canonical_name: 'Equatorial Guinea', location_country_code: 'GQ' },
        { location_criterion_id: 2232, location_canonical_name: 'Eritrea', location_country_code: 'ER' },
        { location_criterion_id: 2231, location_canonical_name: 'Ethiopia', location_country_code: 'ET' },
        { location_criterion_id: 2266, location_canonical_name: 'Gabon', location_country_code: 'GA' },
        { location_criterion_id: 2270, location_canonical_name: 'The Gambia', location_country_code: 'GM' },
        { location_criterion_id: 2288, location_canonical_name: 'Ghana', location_country_code: 'GH' },
        { location_criterion_id: 2324, location_canonical_name: 'Guinea', location_country_code: 'GN' },
        { location_criterion_id: 2624, location_canonical_name: 'Guinea-Bissau', location_country_code: 'GW' },
        { location_criterion_id: 2404, location_canonical_name: 'Kenya', location_country_code: 'KE' },
        { location_criterion_id: 2426, location_canonical_name: 'Lesotho', location_country_code: 'LS' },
        { location_criterion_id: 2430, location_canonical_name: 'Liberia', location_country_code: 'LR' },
        { location_criterion_id: 2434, location_canonical_name: 'Libya', location_country_code: 'LY' },
        { location_criterion_id: 2450, location_canonical_name: 'Madagascar', location_country_code: 'MG' },
        { location_criterion_id: 2454, location_canonical_name: 'Malawi', location_country_code: 'MW' },
        { location_criterion_id: 2466, location_canonical_name: 'Mali', location_country_code: 'ML' },
        { location_criterion_id: 2478, location_canonical_name: 'Mauritania', location_country_code: 'MR' },
        { location_criterion_id: 2480, location_canonical_name: 'Mauritius', location_country_code: 'MU' },
        { location_criterion_id: 2504, location_canonical_name: 'Morocco', location_country_code: 'MA' },
        { location_criterion_id: 2508, location_canonical_name: 'Mozambique', location_country_code: 'MZ' },
        { location_criterion_id: 2516, location_canonical_name: 'Namibia', location_country_code: 'NA' },
        { location_criterion_id: 2562, location_canonical_name: 'Niger', location_country_code: 'NE' },
        { location_criterion_id: 2566, location_canonical_name: 'Nigeria', location_country_code: 'NG' },
        { location_criterion_id: 2646, location_canonical_name: 'Rwanda', location_country_code: 'RW' },
        { location_criterion_id: 2678, location_canonical_name: 'São Tomé and Príncipe', location_country_code: 'ST' },
        { location_criterion_id: 2686, location_canonical_name: 'Senegal', location_country_code: 'SN' },
        { location_criterion_id: 2690, location_canonical_name: 'Seychelles', location_country_code: 'SC' },
        { location_criterion_id: 2694, location_canonical_name: 'Sierra Leone', location_country_code: 'SL' },
        { location_criterion_id: 2706, location_canonical_name: 'Somalia', location_country_code: 'SO' },
        { location_criterion_id: 2710, location_canonical_name: 'South Africa', location_country_code: 'ZA' },
        { location_criterion_id: 2728, location_canonical_name: 'South Sudan', location_country_code: 'SS' },
        { location_criterion_id: 2736, location_canonical_name: 'Sudan', location_country_code: 'SD' },
        { location_criterion_id: 2748, location_canonical_name: 'Eswatini', location_country_code: 'SZ' },
        { location_criterion_id: 2834, location_canonical_name: 'Tanzania', location_country_code: 'TZ' },
        { location_criterion_id: 2768, location_canonical_name: 'Togo', location_country_code: 'TG' },
        { location_criterion_id: 2788, location_canonical_name: 'Tunisia', location_country_code: 'TN' },
        { location_criterion_id: 2800, location_canonical_name: 'Uganda', location_country_code: 'UG' },
        { location_criterion_id: 2894, location_canonical_name: 'Zambia', location_country_code: 'ZM' },
        { location_criterion_id: 2716, location_canonical_name: 'Zimbabwe', location_country_code: 'ZW' },
    ],
    Oceania: [
        { location_criterion_id: 2016, location_canonical_name: 'American Samoa', location_country_code: 'AS' },
        { location_criterion_id: 2036, location_canonical_name: 'Australia', location_country_code: 'AU' },
        { location_criterion_id: 2583, location_canonical_name: 'Federated States of Micronesia', location_country_code: 'FM' },
        { location_criterion_id: 2242, location_canonical_name: 'Fiji', location_country_code: 'FJ' },
        { location_criterion_id: 2244, location_canonical_name: 'French Polynesia', location_country_code: 'PF' },
        { location_criterion_id: 2334, location_canonical_name: 'Heard Island and McDonald Islands', location_country_code: 'HM' },
        { location_criterion_id: 2296, location_canonical_name: 'Kiribati', location_country_code: 'KI' },
        { location_criterion_id: 2584, location_canonical_name: 'Marshall Islands', location_country_code: 'MH' },
        { location_criterion_id: 2520, location_canonical_name: 'Nauru', location_country_code: 'NR' },
        { location_criterion_id: 2540, location_canonical_name: 'New Caledonia', location_country_code: 'NC' },
        { location_criterion_id: 2554, location_canonical_name: 'New Zealand', location_country_code: 'NZ' },
        { location_criterion_id: 2574, location_canonical_name: 'Norfolk Island', location_country_code: 'NF' },
        { location_criterion_id: 2570, location_canonical_name: 'Niue', location_country_code: 'NU' },
        { location_criterion_id: 2585, location_canonical_name: 'Palau', location_country_code: 'PW' },
        { location_criterion_id: 2598, location_canonical_name: 'Papua New Guinea', location_country_code: 'PG' },
        { location_criterion_id: 2882, location_canonical_name: 'Samoa', location_country_code: 'WS' },
        { location_criterion_id: 2090, location_canonical_name: 'Solomon Islands', location_country_code: 'SB' },
        { location_criterion_id: 2776, location_canonical_name: 'Tonga', location_country_code: 'TO' },
        { location_criterion_id: 2798, location_canonical_name: 'Tuvalu', location_country_code: 'TV' },
        { location_criterion_id: 2548, location_canonical_name: 'Vanuatu', location_country_code: 'VU' }
    ],
    Others: [
        { location_criterion_id: 2010, location_canonical_name: 'Antarctica', location_country_code: 'AQ' },
        { location_criterion_id: 2260, location_canonical_name: 'French Southern and Antarctic Lands', location_country_code: 'TF' },
        { location_criterion_id: 2654, location_canonical_name: 'Saint Helena, Ascension and Tristan da Cunha', location_country_code: 'SH' },
    ]
};

export default GOOGLE_SEARCH_ADS_LOCATION_CRITERION;