import React, { useMemo, useState } from "react";
import "./DeviceComponent.scss";
import { Box, Grid } from "@mui/material";
import TableDevice from "./TableDevice";
import PieChartDevice from "./PieChartDevice";
import { COLORS } from "../Utils/Colors";

const DeviceComponent = ({ selectedOption, handleDropdownChange, deviceMetrics }) => {
    const [selectedMetric, setSelectedMetric] = useState("clicks");
    const [tableMetrics, setTableMetrics] = useState({
        metric1: "clicks_kpi",
        metric2: "conversion_kpi",
        metric3: "roas_kpi",
    });

    const handleMetricChange = (event) => {
        setSelectedMetric(event.target.value);
    };

    const handleTableMetricChange = (event, metricKey) => {
        setTableMetrics({ ...tableMetrics, [metricKey]: event.target.value });
    };

    const totalMetricValue = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return 0;

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .reduce((total, [key]) => {
                const actualKey = key.replace("show_", "");
                const metricValue = Number(deviceMetrics[actualKey]?.[`${selectedMetric}_kpi`] || 0);
                return total + metricValue;
            }, 0);
    }, [deviceMetrics, selectedMetric]);

    const chartData = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return [];

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .map(([key], index) => {
                const actualKey = key.replace("show_", "");
                const metricValue = Number(deviceMetrics[actualKey]?.[`${selectedMetric}_kpi`] || 0);
                const percentage = totalMetricValue > 0 ? ((metricValue / totalMetricValue) * 100).toFixed(1) : 0;

                return {
                    name: deviceMetrics[actualKey]?.device_name || "Unknown",
                    value: metricValue,
                    percentage,
                    color: COLORS[index % COLORS.length],
                };
            });
    }, [deviceMetrics, selectedMetric, totalMetricValue]);

    const tableData = useMemo(() => {
        if (!deviceMetrics || Object.keys(deviceMetrics).length === 0) return [];

        return Object.entries(deviceMetrics)
            .filter(([key]) => key.startsWith("show_") && deviceMetrics[key])
            .map(([key]) => {
                const actualKey = key.replace("show_", "");
                const device = deviceMetrics[actualKey] || {};
                const formatValue = (metric) => {
                    if (["cost_kpi", "generated_kpi", "cpc_kpi"].includes(metric)) {
                        return `${deviceMetrics.currency_symbol} ${device[metric] || 0}`;
                    }
                    return device[metric] || 0;
                };

                return {
                    name: device.device_name || "Unknown",
                    metric1: formatValue(tableMetrics.metric1),
                    metric2: formatValue(tableMetrics.metric2),
                    metric3: formatValue(tableMetrics.metric3),
                    color: COLORS[Object.keys(deviceMetrics).indexOf(key) % COLORS.length],
                };
            });
    }, [deviceMetrics, tableMetrics]);

    return (
        <Box className="device-reporting-component">
            <Grid container spacing={2} className="metrics-container">
                <TableDevice
                    selectedOption={selectedOption}
                    handleDropdownChange={handleDropdownChange}
                    tableMetrics={tableMetrics}
                    handleTableMetricChange={handleTableMetricChange}
                    tableData={tableData}
                />

                <PieChartDevice
                    deviceMetrics={deviceMetrics}
                    selectedMetric={selectedMetric}
                    handleMetricChange={handleMetricChange}
                    chartData={chartData}
                />
            </Grid>
        </Box>
    );
};

export default DeviceComponent;
