import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./budgetCapModal.scss";
import CustomButton from "components/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { createSearchParams } from "react-router-dom";
import { APP_METASEARCH_BUDGET_API_ENDPOINT } from "constants/api_urls";

const BudgetCapModal = ({ open, onClose, budget }) => {
  const token = sessionStorage.getItem("token") || "";
  const API_URL =
    APP_METASEARCH_BUDGET_API_ENDPOINT + budget?.budget_value_edit_path;
  const months = [
    {
      month_name: "JAN",
      month_index: 1,
    },
    {
      month_name: "FEB",
      month_index: 2,
    },
    {
      month_name: "MAR",
      month_index: 3,
    },
    {
      month_name: "APR",
      month_index: 4,
    },
    {
      month_name: "MAY",
      month_index: 5,
    },
    {
      month_name: "JUN",
      month_index: 6,
    },
    {
      month_name: "JUL",
      month_index: 7,
    },
    {
      month_name: "AUG",
      month_index: 8,
    },
    {
      month_name: "SEP",
      month_index: 9,
    },
    {
      month_name: "OCT",
      month_index: 10,
    },
    {
      month_name: "NOV",
      month_index: 11,
    },
    {
      month_name: "DEC",
      month_index: 12,
    },
  ];

  const [payload, setPayload] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  useEffect(() => {
    const budgetParams = budget?.budget_api_params;
    const initialPayload = {
      hotel_id: Number(budgetParams?.hotel_id),
      market_id: budgetParams?.market_id,
      month: 0,
      budget: 0,
      check_only_validation: true,
    };
    const allPayloads = months.map((month) => ({
      ...initialPayload,
      month: month.month_index,
    }));

    setPayload([initialPayload, ...allPayloads]);
  }, []);

  const handleApply = () => {
    const allRequests = payload.map(async (pl) => {
      return axios
        .put(
          `${API_URL}&${createSearchParams(pl)}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => ({ success: true, response: res }))
        .catch((err) => ({ success: false, error: err }));
    });

    Promise.allSettled(allRequests).then((results) => {
      console.log(results, "Results");
      const failedRequests = results.filter((r) => r.value.success === false);

      if (failedRequests.length) {
        console.log(
          failedRequests[0].value?.error?.response?.data?.[0]?.error_message,
          failedRequests[0].value?.error
        );
        setToastMessage(
          failedRequests[0].value?.error?.response?.data?.[0]?.error_message ||
            "Something went wrong"
        );
        setToastType("error");
      } else {
        setToastMessage("Budget Updated.");
        setToastType("success");
      }

      setShowToast(true);
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "420px",
            height: "400px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span className="dialogTitle">{budget?.title}</span>
          <span>
            {" "}
            <IconWrapper onClick={onClose} icon={close} />{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="budget-info">
            Minimum budget required: ${budget?.minimum_budget_value}
          </div>
          <TextInput
            sx={{ width: "140px", marginBottom: "24px", fontSize: "12px", fontFamily: "Inter"}}
            placeholder="Enter value"
            numberUnit={budget?.budget_symbol || ""}
            min={Number(budget?.minimum_budget_value)}
            onBlur={(e) => {
              const value = e.target.value;
              const budgetParams = budget?.budget_api_params;

              if (value) {
                if (Number(value) >= Number(budget?.minimum_budget_value)) {
                  const initialPayload = {
                    hotel_id: Number(budgetParams?.hotel_id),
                    market_id: budgetParams?.market_id,
                    month: 0,
                    budget: value,
                    check_only_validation: true,
                  };

                  setPayload((prev) => {
                    const index = prev.findIndex((p) => p.month === 0);
                    if (index !== -1) {
                      prev.splice(index, 1, {
                        ...prev[index],
                        budget: Number(value),
                      });

                      return prev;
                    } else {
                      return prev.concat(initialPayload);
                    }
                  });
                } else {
                  setToastMessage(
                    `Default value should be greater than or equal to ${budget?.minimum_budget_value}.`
                  );
                  setToastType("error");
                  setShowToast(true);
                }
              }
            }}
            onChange={(e) => {}}
          />
          <div className="budgetSubTitle">MONTHLY BUDGET</div>
          <div className="monthsGrid">
            {months.map((month) => {
              return (
                <div
                  className="months"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "24px",
                  }}
                >
                  <div className="monthLabel">{month?.month_name}</div>
                  <TextInput
                    sx={{ width: "70px", fontSize: "12px", fontFamily: "Inter" }}
                    min={Number(budget?.minimum_budget_value)}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (value) {
                        if (
                          Number(value) > Number(budget?.minimum_budget_value)
                        ) {
                          const initialPayload = {
                            // hotel_id: Number(budgetParams?.hotel_id),
                            // market_id: budgetParams?.market_id,
                            month: month.month_index,
                            budget: value,
                            check_only_validation: true,
                          };

                          setPayload((prev) => {
                            const index = prev.findIndex(
                              (p) => p.month === month.month_index
                            );
                            if (index !== -1) {
                              prev.splice(index, 1, {
                                ...prev[index],
                                budget: Number(value),
                              });

                              return prev;
                            } else {
                              return prev.concat(initialPayload);
                            }
                          });
                        } else {
                          setToastMessage(
                            `Value should be greater than or equal to ${budget?.minimum_budget_value} for ${month.month_name}`
                          );
                          setToastType("error");
                          setShowToast(true);
                        }
                      }
                    }}
                    numberUnit=""
                  />
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "flex-start",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          <CustomButton
            title="Apply"
            variant={"filled"}
            onClick={handleApply}
          />
          <CustomButton title="Cancel" variant={"outlined"} onClick={onClose} />
        </DialogActions>
      </Dialog>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={7000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default BudgetCapModal;
