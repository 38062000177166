import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ColumnsChangeIcon from "assets/images/ColumnsChangeIcon.svg";
import { Alert, Box, Chip, IconButton, Snackbar } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ColumnSelectionMenu from "./ColumnSelectionMenu";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CollapseArrow from "assets/images/collapseArrow.svg";
import CheckboxMenu from "components/CheckboxMenu";
import {
  allPlatformsSegmentOptions,
  segmentMenuOptions,
} from "mock/filterOptions";
import IconWrapper from "components/IconWrapper";
import edit from "assets/images/edit.svg";
import { CloseOutlined } from "@mui/icons-material";
import { updateSegmentList } from "store/slices/metaSearch/metaSearch";
import { useDispatch, useSelector } from "react-redux";
const Table = ({
  page,
  rows,
  columns,
  selectedColumns,
  handleColumnSelectionChange,
  isChartCollapsed,
  setIsChartCollapsed,
  maxHeight,
  onEditClick,
  isPaginationEnabled = false,
  paginationData,
  handlePagination,
  handleSorting,
  initialPageIndex = 0,
  initialPageSize = 15,
  isLoading = false,
}) => {
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);
  const tableContainerRef = useRef();
  const stickyDivRef = useRef();
  const segmentMenuRef = useRef(null);
  const [segmentSelection, setSegmentSelection] = useState([]);
  const [openSegmentMenu, setOpenSegmentMenu] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const { platform = [] } = useSelector((state) => state?.metaSearch);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const table = useMaterialReactTable({
    columns: selectedColumns
      ?.filter((item) => item.isSelected)
      ?.map((column) => {
        if (column?.type === "SEGMENT") {
          return {
            ...column,
            muiTableBodyCellProps: ({ cell }) => {
              return {
                sx: {
                  fontSize: cell.row.original.id === "0" ? "11px" : "10px",
                  fontFamily: "inter",
                  fontWeight: cell.row.original.id === "0" ? "700" : "400",
                  color: "#545454",
                  textAlign: "start",
                },
              };
            },
          };
        }
        return {
          ...column,
          muiTableBodyCellProps: ({ cell }) => {
            return {
              sx: {
                fontSize: cell.row.original.id === "0" ? "11px" : "10px",
                fontFamily: "inter",
                fontWeight: cell.row.original.id === "0" ? "700" : "400",
                color: "#545454",
                textAlign: "end",
              },
            };
          },
        };
      }),
    data: rows,
    getSubRows: (row) => row.subRows,
    onPaginationChange: (paginationFn) => {
      setPagination((prev) => {
        console.log(paginationFn(prev), "Pagination");
        const newPagination = paginationFn(prev);
        if (
          newPagination?.pageIndex !== 0 &&
          newPagination?.pageSize !== prev?.pageSize
        ) {
          newPagination.pageIndex = 0;
        }
        handlePagination(newPagination);
        return newPagination;
      });
    },
    pageCount: paginationData?.total_number_of_page,
    rowCount: paginationData?.total_number_of_rows,
    initialState: {
      expanded: true,
      rowPinning: { top: [] },
      density: "compact",
    },
    state: {
      pagination: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
      },
      isLoading: isLoading,
      showSkeletons: isLoading,
      sorting: sorting,
    },
    isMultiSortEvent: () => true,
    enableMultiSort: true,
    enableSorting: true,
    onSortingChange: (sortingFn) => {
      setSorting((prev) => {
        console.log(sorting, sortingFn(prev), columns, "SORTING");
        handleSorting(
          sortingFn(prev)?.map((sort) => ({
            ...sort,
            id: columns?.find((col) => col?.id === sort?.id)?.accessorKey,
          }))
        );
        return sortingFn(prev);
      });
    },
    muiTableBodyProps: () => {
      return {
        sx: {
          '& .MuiTableRow-root td[data-pinned="true"]:before': {
            boxShadow: "unset",
            opacity: 1,
          },
          "& .MuiTableRow-root td:after": {
            backgroundColor: "#f1f1f1",
          },
        },
      };
    },

    muiTableHeadCellProps: ({ column }) => {
      console.log(column, "COlumns");
      return {
        sx: {
          textAlign: column?.columnDef?.type === "SEGMENT" ? "left" : "end",
          paddingBlock: "10px",
          "&:hover": {
            "& .MuiTableSortLabel-icon": {
              opacity: "1 !important",
            },
          },
          "& .Mui-TableHeadCell-Content": {
            display: "flex",
            justifyContent:
              column?.columnDef?.type === "SEGMENT" ? "flex-start" : "flex-end",
          },
          "& .Mui-TableHeadCell-Content-Labels": {
            flexDirection:
              column?.columnDef?.type === "SEGMENT" ? "row" : "row-reverse",
            display: "inline-flex",
            width: "max-width",
          },
          "& .Mui-TableHeadCell-Content-Wrapper": {
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "inter",
            color: "#545454",
            display: "inline-block",
            whiteSpace: "nowrap",
            lineHeight: "15px",
          },
          "& .MuiTableSortLabel-icon": {
            flex: 1,
            opacity: "0 !important",
          },
        },
      };
    },

    muiTableHeadProps: {
      ref: stickyDivRef,
      sx: {
        "& .MuiTableRow-root": {
          boxShadow: "unset !important",
        },
        '& .MuiTableCell-root[data-pinned="true"]:before': {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: "white",
        },
      },
    },

    muiTableBodyRowProps: ({ row }) => {
      return {
        hover: true,
      };
    },
    muiExpandAllButtonProps: {
      sx: {
        display: "none",
      },
    },
    muiTopToolbarProps: {
      sx: {
        alignItems: "center",
        "& .MuiBox-root": {
          paddingBlock: "0px !important",
        },
      },
    },
    enableTopToolbar: false,
    enableColumnActions: false,
    getRowId: (row) => {
      return row.id;
    },
    enableExpanding: true,
    enableExpandAll: true,
    positionExpandColumn: "last",
    enableColumnPinning: true,
    enableRowPinning: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    rowPinningDisplayMode: "top-and-bottom",

    icons: {
      ExpandMoreIcon: () => (
        <ArrowDropDownIcon
          sx={{
            fontSize: 20,
            paddingInline: "0px",
            margin: "0px",
            alignItems: "center",
          }}
        />
      ),
      ViewColumnIcon: () => (
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 30 }}
        >
          {" "}
          <span style={{ fontSize: "10px", color: "black" }}>Columns</span>{" "}
          <img style={{ maxHeight: "27px" }} src={ColumnsChangeIcon} alt="" />{" "}
        </div>
      ),
    },
    defaultColumn: { maxSize: 60 },
    displayColumnDefOptions: {
      "mrt-row-pin": {
        muiTableHeadCellProps: {
          sx: {
            display: "none",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "none",
          },
        },
      },
      "mrt-row-expand": {
        maxSize: 15,
        muiTableBodyCellProps: ({ cell }) => {
          return {
            sx: {
              '& td[data-pinned="true"]:before': {
                //  backgroundColor: cell.row.depth === 0 ? "#f5f5f5" : "#ffff",
              },
              "& button": {
                padding: "4px",
                display:
                  cell.row.depth !== 0 || cell.row.id === "0" ? "none" : "none",
              },
            },
          };
        },
      },
    },

    enableTableFooter: true,
    enablePagination: isPaginationEnabled,
    manualPagination: true,
    manualSorting: true,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      color: "secondary",
    },
    paginationDisplayMode: "pages",
    muiTableContainerProps: {
      sx: {
        marginLeft: "4px",
        width: "100%",
        height: maxHeight,
        maxHeight: maxHeight,

        "&::-webkit-scrollbar": {
          width: "3px",
          height: "3px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },

        "&::-webkit-scrollbar-thumb": {
          background: "#acabab",
          borderRadius: "4px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
          height: "10px",
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
        borderRadius: "0",
        height: "100%",
      },
    },
  });
  const handleSegmentDelete = (id) => {
    const tempData = [...segmentSelection];
    const index = tempData.findIndex((i) => i.id === id);
    tempData.splice(index, 1);
    if (index !== -1) {
      setSegmentSelection(tempData);
      dispatch(updateSegmentList(tempData.map((s) => s.id)));
    }
  };

  useEffect(() => {
    if (platform) {
      setSegmentSelection([]);
      dispatch(updateSegmentList([]));
    }
  }, [platform]);

  return (
    <>
      <div
        style={{
          backgroundColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "16px",
          paddingBlock: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                color: "#545454",
                fontSize: "10px",
                marginBottom: "5px",
              }}
            >
              View By
            </div>
            <div style={{ display: "flex" }}>
              {segmentSelection?.map((item) => {
                return (
                  <div
                    onClick={(e) => {
                      segmentMenuRef.current = e.target;
                      setOpenSegmentMenu((prev) => !prev);
                    }}
                  >
                    <Chip
                      variant="outlined"
                      sx={{
                        //backgroundColor: "#61D5A9",
                        fontFamily: "inter",
                        fontSize: "8px",
                        fontWeight: "400",
                        height: "22px",
                        marginRight: "8px",
                        ":hover": {
                          backgroundColor: "#E9E9E9",
                          cursor: "pointer",
                          border: "1px solid #587A78",
                        },
                      }}
                      label={item.label}
                      deleteIcon={
                        <CloseOutlined
                          sx={{
                            fontSize: "14px",
                            height: "14px",
                            width: "14px",
                            marginTop: "4px",
                          }}
                        />
                      }
                      onDelete={() => {
                        handleSegmentDelete(item.id);
                      }}
                    >
                      {item.label}
                    </Chip>
                  </div>
                );
              })}
              <div
                onClick={(e) => {
                  segmentMenuRef.current = e.target;
                  setOpenSegmentMenu(!openSegmentMenu);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "0 8px 0 0",
                }}
                ref={segmentMenuRef}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#E5E5E5")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "inherit")
                }
              >
                <AddCircleOutlineOutlinedIcon
                  sx={{ color: "#BAB7B7", fontSize: 14, marginRight: "5px" }}
                />{" "}
                <span style={{ color: "#BAB7B7", fontSize: "10px" }}>
                  Add segment
                </span>
              </div>
            </div>
          </Box>
        </Box>

        {openSegmentMenu && (
          <CheckboxMenu
            options={(platform?.length > 1 || platform?.length === 0
              ? allPlatformsSegmentOptions
              : segmentMenuOptions?.[platform?.[0]?.id]
            )?.map((smo) =>
              segmentSelection.find((item) => item.id === smo.id)
                ? { ...smo, isSelected: true }
                : { ...smo, isSelected: false }
            )}
            open={openSegmentMenu}
            onClose={() => {
              setOpenSegmentMenu(false);
            }}
            onApply={(selection) => {
              if (selection?.length <= 3) {
                setSegmentSelection(selection);
                dispatch(updateSegmentList(selection.map((s) => s.id)));
              } else {
                setToastMessage("More than 3 segments cannot be selected.");
                setToastType("error");
                setShowToast(true);
              }
            }}
            anchorEl={segmentMenuRef.current}
            filterType="Segment"
          />
        )}
        <div style={{ display: "flex" }}>
          {page === "metaSearch" && (
            <IconWrapper icon={edit} onClick={onEditClick} />
          )}

          <IconButton
            disableRipple
            onClick={() => {
              setIsColumnMenuOpen(true);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <img src={ColumnsChangeIcon} alt="" />{" "}
            </div>
          </IconButton>

          <IconButton
            disableRipple
            sx={{
              marginRight: "10px",
              marginLeft: "-4px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
            onClick={() => {
              setIsChartCollapsed(!isChartCollapsed);
            }}
          >
            <img
              src={CollapseArrow}
              style={{
                transform: isChartCollapsed ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </div>
      </div>
      <div ref={tableContainerRef}>
        <MaterialReactTable
          table={table}
          // enableRowVirtualization
        />
      </div>
      <ColumnSelectionMenu
        open={isColumnMenuOpen}
        columns={columns}
        handleClose={() => {
          setIsColumnMenuOpen(false);
        }}
        onApply={(columns) => {
          handleColumnSelectionChange(columns);
          setIsColumnMenuOpen(false);
        }}
      />
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={7000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Table;
