import { Grid } from "@mui/material";
import Table from "components/Table";
import { COLUMN_DEFINITIONS } from "constants/ColumnDefinitions";
import { tableData } from "mock/tableData";
import GoogleAds from "assets/images/GoogleHotelAds.svg";
import TripAdvisor from "assets/images/TripAdvisor.svg";
import Trivago from "assets/images/Trivago.svg";
import SkyScanner from "assets/images/SkyScanner.svg";
import Kayak from "assets/images/Kayak.svg";
import BingAds from "assets/images/BingAds.svg";
import { useState } from "react";

const TableCellImage = ({ channelId, cell }) => {
  const ChannelImageMapper = {
    googleHotelAds: GoogleAds,
    tripadvisor: TripAdvisor,
    trivago: Trivago,
    skyscanner: SkyScanner,
    kayak: Kayak,
    bingAds: BingAds,
  };
  if (Object.keys(ChannelImageMapper).find((ch) => ch === channelId)) {
    return <img src={ChannelImageMapper[channelId]} alt="" />;
  }
  return channelId;
};
const TableContainer = ({ isChartCollapsed, setIsChartCollapsed }) => {
  const columns = COLUMN_DEFINITIONS?.map((item) => {
    if (item.accessorKey === "channel") {
      return {
        ...item,
        isSelected: true,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx: {
              '& [data-pinned="true"]:before': {
                backgroundColor: row.depth === 0 ? "#f5f5f5" : "#ffff",
              },
            },
          };
        },
        Cell: ({ cell }) => (
          <TableCellImage channelId={cell.getValue()} cell={cell} />
        ),
      };
    }
    return {
      ...item,
      isSelected: true,
    };
  });
  const [selectedColumns, setSelectedColumns] = useState(columns);
  return (
    <>
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%" }}>
        <Table
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={isChartCollapsed ? "calc(100vh - 335px)" : "358px"}
        />
      </Grid>
    </>
  );
};

export default TableContainer;
