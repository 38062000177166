import "./searchadspage.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import { Box, Button, Chip, Typography, Snackbar, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  platformOptions,
  platformDesignationOptions,
} from "mock/filterOptions";
import SearchAdsChartContainer from "features/searchads/chartContainer";
import TableContainer from "features/searchads/TableContainer";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGroupId,
  updateHoteId,
  updateLevel,
  updateCampaign,
} from "store/slices/searchAds/searchAds";
import axios from "axios";
import HotelsFilterMenu from "components/hotelFilterMenu";
import {
  APP_GOOGLE_SEM_API_ENDPOINT,
  APP_HOTELS_API_ENDPOINT,
} from "constants/api_urls";
import { useNavigate } from "react-router-dom";
import EditCampaignPanel from "features/google-search-ads-campaign/EditCampaignPanel";
import CampaignFilterMenu from "components/CampaignFilterMenu";
import PageTitles from "../../components/PageTitles";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "../../components/DmpIcons/icons";

const SearchAdsPage = () => {
  const token = sessionStorage.getItem("token") || "";
  const platform = useSelector((state) => state?.searchAds?.campaign);
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const hotelButtonRef = useRef(null);
  const campaignButtonRef = useRef(null);
  const [openCampaignModal, setOpenCampaignModal] = useState(false);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [hotelFilters, setHotelFilters] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [campaignFilters, setCampaignFilters] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPanelOpen, setPanelOpen] = useState(false);

  const handleCreateCampaign = () => {
    if (hotelFilters.length) {
      navigate("/search-ads-campaign/create");
    } else {
      setToastMessage("Please select hotel.");
      setToastType("error");
      setShowToast(true);
    }
  };

  const togglePanel = () => {
    setPanelOpen((prev) => !prev);
  };

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      axios
        .get(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/filters/campaign-list/?account_id=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const campaigns = res?.data?.campaign_list?.map((campaign) => ({
            id: campaign?.campaign_id,
            label: campaign?.campaign_name,
          }));

          console.log(campaigns, res?.data);
          setCampaignList(campaigns || []);
        });

      axios
        .get(
          `${APP_HOTELS_API_ENDPOINT}/api/hotels/list?id_account=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setHotelList(res?.data?.data || []);
        });

      // axios
      //   .get(
      //     `${APP_HOTELS_API_ENDPOINT}/api/group/list?id_account=${account_id}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     setGroupList(res?.data?.group_list || []);
      //   });
    }
  }, [account_id]);

  useEffect(() => {
    dispatch(
      updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
    );
  }, []);

  return (
    <div className="metasearch">
      <DefaultLayout page={isPanelOpen ? null : "SearchAdsPage"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: "18px",
          }}
        >
          <PageTitles pageTitle="SearchAds" pageSubTitle="Campaign Manager" />
          <DatePicker />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: 2,
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      hotelFilters.length === 0
                        ? "HOTELS"
                        : (selectedHotelOrGroupType === "hotels" &&
                            hotelFilters.length === hotelList.length) ||
                          (selectedHotelOrGroupType === "groups" &&
                            hotelFilters.length === groupList.length)
                        ? selectedHotelOrGroupType === "hotels"
                          ? "ALL HOTELS"
                          : "ALL GROUPS"
                        : hotelFilters.length === 1
                        ? hotelList?.find(
                            (hotel) =>
                              hotel.hotel_id === hotelFilters[0]?.hotel_id
                          )?.hotel_name
                        : `${hotelFilters.length} ${
                            selectedHotelOrGroupType === "hotels"
                              ? "hotels"
                              : "groups"
                          } selected`
                    }
                    sx={{
                      backgroundColor:
                        hotelFilters.length > 0 ? "#61D5A9" : "inherit",
                      fontFamily: "inter",
                      fontSize: "10px",
                      fontWeight: "400",
                      marginRight: "4px",
                      cursor: "pointer",
                      height: "20px",
                      border:
                        hotelFilters.length > 0 ? "none" : "1px solid grey",
                      ":hover": {
                        backgroundColor: "#E9E9E9",
                        cursor: "pointer",
                        border: "1px solid #587A78",
                      },
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenCampaignModal(true);
                  }}
                  ref={campaignButtonRef}
                >
                  <Chip
                    label={
                      campaignFilters.length === 0
                        ? "CAMPAIGNS"
                        : campaignFilters.length === 1
                        ? campaignFilters[0].label
                        : campaignFilters.length === campaignList.length
                        ? "ALL CAMPAIGNS"
                        : `${campaignFilters.length} campaigns selected`
                    }
                    sx={{
                      backgroundColor:
                        campaignFilters.length > 0 ? "#61D5A9" : "inherit",
                      fontFamily: "inter",
                      fontSize: "10px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "20px",
                      cursor: "pointer",
                      border:
                        campaignFilters.length > 0 ? "none" : "1px solid grey",
                      ":hover": {
                        backgroundColor: "#E9E9E9",
                        cursor: "pointer",
                        border: "1px solid #587A78",
                      },
                    }}
                  />
                </div>
                {openCampaignModal && (
                  <CampaignFilterMenu
                    anchorEl={campaignButtonRef.current}
                    open={openCampaignModal}
                    options={campaignList?.map((po) =>
                      campaignFilters.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    filterType={"Campaigns"}
                    selectAllTitle={"Campaigns"}
                    onApply={(selected) => {
                      setCampaignFilters(selected);
                      dispatch(updateCampaign(selected));
                    }}
                    onClose={() => {
                      setOpenCampaignModal(false);
                    }}
                  />
                )}
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotelFilters.find((hf) => hf.hotel_id === ho.hotel_id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    allGroups={groupList?.map((ho) =>
                      hotelFilters.find((hf) => hf.id === ho.id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    isSelectAllRequired
                    onApply={(selected) => {
                      if (selected.length > 1) {
                        setShowToast(true);
                        setToastMessage("Please select only one hotel.");
                        setToastType("error");
                      } else {
                        setHotelFilters(selected);
                        if (selectedHotelOrGroupType === "hotels") {
                          dispatch(
                            updateHoteId(
                              selected.map((item) => item?.["hotel_id"])
                            )
                          );
                        } else {
                          dispatch(
                            updateGroupId(selected.map((item) => item?.["id"]))
                          );
                        }
                      }
                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                      dispatch(
                        updateLevel(type === "hotels" ? "HOTEL" : "GROUP")
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "8px",
            }}
          >
            <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#61D5A9"
              width="135px"
              height="30px"
              titleColor="#373F41"
              borderRadius="20px"
              title="Click to create a new alert"
              icon={DmpIcons.ActionsIcons.AddIconBlack}
              iconBorder="0.5px solid #373F41"
              textStyle={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "14.52px",
                textAlign: "center",
              }}
              onClick={togglePanel}
            >
              Edit Campaign
            </CustomButtonWithIcon>
            <CustomButtonWithIcon
              color="#b0acac"
              hoverColor="#61D5A9"
              width="135px"
              height="30px"
              titleColor="#373F41"
              borderRadius="20px"
              title="Click to create a new alert"
              icon={DmpIcons.ActionsIcons.AddIconBlack}
              iconBorder="0.5px solid #373F41"
              textStyle={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "14.52px",
                textAlign: "center",
              }}
              onClick={handleCreateCampaign}
            >
              New Campaign
            </CustomButtonWithIcon>
          </div>
        </Box>
        <Box sx={{}} ref={scrollContainerRef}>
          <div
            style={{
              display: isChartCollapsed ? "none" : "block",
            }}
          >
            <SearchAdsChartContainer backgroundColor="transparent" />
          </div>
          <TableContainer
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
                return;
              }
            }}
          />
        </Box>{" "}
        <EditCampaignPanel isOpen={isPanelOpen} onClose={togglePanel} />
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default SearchAdsPage;
