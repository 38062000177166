import { ArrowDropDown, Search } from "@mui/icons-material";
import {
  Menu,
  Chip,
  OutlinedInput,
  Box,
  Button,
  Typography,
} from "@mui/material";
import CustomCheckbox from "components/Checkbox";
import { useCallback, useMemo, useState } from "react";
import TextInput from "components/TextInput";

const MarketsFilterMenu = ({
  allMarkets,
  onApply,
  isSelectAllRequired = false,
}) => {
  const [selectedMarkets, setSelectedMarkets] = useState(allMarkets);
  const [appliedMarkets, setAppliedMarkets] = useState([]);
  const [marketAnchorEl, setMarketAnchorEl] = useState(null);

  const selected = selectedMarkets;

  const [searchTerm, setSearchTerm] = useState("");

  const isSelectAllChecked = useMemo(() => {
    return (
      selectedMarkets.filter((item) => item.isSelected).length ===
      allMarkets.length
    );
  }, [selectedMarkets, allMarkets]);

  const toggleSelectAll = () => {
    const selectedCount = selectedMarkets.filter(
      (item) => item.isSelected
    ).length;
    if (selectedCount === allMarkets.length) {
      setSelectedMarkets(
        selected.map((item) => {
          return { ...item, isSelected: false };
        })
      );
    } else {
      setSelectedMarkets(
        selected.map((item) => {
          return { ...item, isSelected: true };
        })
      );
    }
  };

  const handleClose = useCallback(() => {
    setMarketAnchorEl(null);
  }, []);

  const selectedMarketsLength = useMemo(
    () => appliedMarkets.filter((item) => item.isSelected).length,
    [appliedMarkets]
  );

  const clearFilters = () => {
    setSelectedMarkets((prev) =>
      prev.map((item) => {
        return { ...item, isSelected: false };
      })
    );
  };

  return (
    <>
      <div className="mul-form-label">Markets</div>
      <TextInput
        placeholder={" Select a value "}
        type={"text"}
        readOnly
        sx={{
          backgroundColor: "white",fontSize:'12px',fontWeight:'400',fontFamily:'inter',paddingLeft:'4px'
        }}
        value={
          selectedMarketsLength
            ? selectedMarketsLength === 1
              ? selectedMarkets[0].name
              : `${selectedMarketsLength} Markets selected`
            : ""
        }
        endAdornment={<ArrowDropDown />}
        onClick={(e) => {
          setMarketAnchorEl(e.target);
        }}
      />
      <Menu
        sx={{ padding: 0 }}
        slotProps={{
          paper: {
            sx: { borderRadius: "10px", maxWidth: "258px", minWidth: "140px" },
          },
        }}
        anchorEl={marketAnchorEl}
        open={Boolean(marketAnchorEl)}
        onClose={handleClose}
      >
        <div
          style={{
            paddingInline: "17px",
            paddingTop: "0px",
            paddingBottom: "7px",
          }}
        >
          <div
            style={{
              fontFamily: "inter",
              color: "#C4C4C4",
              fontSize: "10px",
              marginBottom: "10px",
            }}
          >
            Select Markets
          </div>
          <div>
            <OutlinedInput
              sx={{
                borderRadius: "10px",
                borderColor: "#CADEDD",
                width: "195px",
                height: "28px",
              }}
              size="small"
              placeholder="Search Markets"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={
                <Search sx={{ color: "#CADEDD", fontSize: "18px" }} />
              }
            />
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={toggleSelectAll}
              >
                <span>
                  <CustomCheckbox
                    fontSize="10px"
                    size={"small"}
                    checked={isSelectAllChecked}
                    labelStyles={{ color: "#C4C4C4" }}
                    title={"All Markets"}
                  />
                </span>
              </div>
              <ul
                style={{
                  listStyleType: "none",
                  marginBlock: 0,
                  padding: 0,
                  paddingLeft: "4px",
                  width: "100%",
                  maxHeight: "250px",
                  overflow: "auto",
                }}
              >
                {selectedMarkets
                  .filter((market) =>
                    market.name.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((market) => {
                    return (
                      <li
                        key={market.id}
                        style={{
                          borderLeft: "1px solid #CADEDD",
                          paddingLeft: "10px",
                          height: "10%",
                          paddingBlock: "7px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <span>
                            <CustomCheckbox
                              fontSize="12px"
                              size={"small"}
                              checked={market.isSelected}
                              title={market.label || market.name}
                              onChange={() => {
                                setSelectedMarkets(
                                  selected?.map((i) => {
                                    return {
                                      ...i,
                                      isSelected:
                                        i?.market_id === market.market_id
                                          ? !i?.isSelected
                                          : i?.isSelected,
                                    };
                                  })
                                );
                              }}
                            />
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
              paddingBottom: "0px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#C4C4C4",
                cursor: "pointer",
              }}
              onClick={clearFilters}
            >
              Clear Filters
            </Typography>
            <Button
              variant="outlined"
              sx={{
                color: "black",
                backgroundColor: "#61D5A9",
                borderRadius: "20px",
                fontSize: "10px",
                width: "100px",
                height: "22px",
                paddingInline: "8px",
              }}
              onClick={() => {
                onApply(selected.filter((item) => item.isSelected));
                setAppliedMarkets(selected.filter((item) => item.isSelected));
                handleClose();
              }}
            >
              Apply
            </Button>
          </Box>
        </div>
      </Menu>
    </>
  );
};
export default MarketsFilterMenu;
