import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  platform: [],
  level: null,
  hotel_id: [],
  group_id: [],
  segment_list: [],
};
export const metaSearchSlice = createSlice({
  name: "metaSearchSlice",
  initialState: initialState,
  reducers: {
    updatePlatform: (state, action) => {
      state.platform = action.payload;
    },
    updateLevel: (state, action) => {
      state.level = action.payload;
    },
    updateHoteId: (state, action) => {
      state.hotel_id = action.payload;
    },
    updateGroupId: (state, action) => {
      state.group_id = action.payload;
    },
    updateSegmentList: (state, action) => {
      state.segment_list = action.payload;
    },
  },
});

export const {
  updatePlatform,
  updateGroupId,
  updateHoteId,
  updateLevel,
  updateSegmentList,
} = metaSearchSlice.actions;
export default metaSearchSlice.reducer;
