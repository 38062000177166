// src/components/Utils/UngroupSelectionModal.jsx
import React from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import './Modal.scss';
import CustomButtonWithIcon from "../../../../../components/CustomButtonWithIcon/Buttons";

const UngroupSelectionModal = ({ open, onClose, onConfirm, loading, error }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle sx={{ textTransform: 'uppercase', fontWeight:'500', fontFamily:'inter', fontSize:'14px' }}>UnGroup Hotels</DialogTitle>
            <DialogContent sx={{ fontWeight:'400', fontFamily:'inter', fontSize:'12px' }}>
                <DialogContentText>
                    Are you sure you want to ungroup the selected hotels? This will remove them from the group and reset their group association.
                </DialogContentText>

                {/* Error message in red, if any */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </DialogContent>
            <DialogActions>
                {/* Show loader instead of buttons while loading */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <div
                            className="button-container"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '16px',
                            }}>
                            <CustomButtonWithIcon
                                color="#b0acac"
                                hoverColor="#12794F"
                                titleColor="#373F41"
                                hoverTitleColor={"white"}
                                width={"85px"}
                                height={"24px"}
                                borderRadius="20px"
                                textStyle={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '14.52px',
                                    textAlign: 'center',
                                }}
                                onClick={onClose}
                            >
                                Cancel
                            </CustomButtonWithIcon>
                            <CustomButtonWithIcon
                                color="#b0acac"
                                hoverColor="#12794F"
                                titleColor="#373F41"
                                hoverTitleColor={"white"}
                                width={"85px"}
                                height={"24px"}
                                borderRadius="20px"
                                textStyle={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '14.52px',
                                    textAlign: 'center',
                                }}
                                onClick={onConfirm}
                            >
                                Ungroup
                            </CustomButtonWithIcon>
                        </div>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UngroupSelectionModal;
