import React, { useState, useEffect } from 'react';
import 'react-sliding-side-panel/lib/index.css';
import './BiddingSelectionForm.scss';
import SlidingPanel from "../SlidingPanel/index";
import Icons from "components/DmpIcons/icons";
import BiddingRuleManager from "../BiddingRule/BiddingRuleManager";

const CheckboxGroup = ({ options, onSelectionChange, onClosePlatform, selectedOption }) => {

    const handleCheckboxChange = (optionValue) => {
        if (selectedOption === optionValue) {
            onSelectionChange('');
        } else {
            onSelectionChange(optionValue);
            onClosePlatform();
        }
    };

    return (
        <div className="checkbox-grid">
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`custom-button ${selectedOption === option.value ? 'selected' : ''}`}
                    onClick={() => handleCheckboxChange(option.value)}
                    aria-pressed={selectedOption === option.value}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

const BiddingSelectionForm = ({ isOpen, onClose, action, title }) => {
    const biddingOptions = [
        { label: 'Google Hotel Ads', value: 'GOOGLE_BIDDING_RULE' },
        { label: 'Tripadvisor', value: 'TRIPADVISOR_BIDDING_RULE' },
        { label: 'Trivago', value: 'TRIVAGO_BIDDING_RULE' },
    ];

    const [selectedBiddingRule, setSelectedBiddingRule] = useState('');
    const [isPlatformVisible, setIsPlatformVisible] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(true);

    const handleFormVisibility = () => {
        setIsFormVisible((prev) => !prev);
    };

    const closePlatformSection = () => {
        setIsPlatformVisible(false);
    };

    const headerStyle = {
        backgroundColor: !selectedBiddingRule ? 'white' : '#61D5A9'
    };

    const selectedBiddingRuleLabel = biddingOptions.find(
        option => option.value === selectedBiddingRule)?.label + " Bidding" || '';

    // Fonction pour réinitialiser les états
    const resetPanel = () => {
        setSelectedBiddingRule('');
        setIsPlatformVisible(true);
        setIsFormVisible(true);
    };

    const togglePlatformSection = () => {
        setIsPlatformVisible((prev) => !prev);
    };

    // Utiliser un effet pour réinitialiser lorsque le panneau s'ouvre
    useEffect(() => {
        if (!isOpen) {
            resetPanel(); // Réinitialiser les états lorsque le panneau se ferme
        }
    }, [isOpen]);

    const handleClose = () => {
        onClose(); // Fermer le panneau
    };

    return (
        <SlidingPanel isOpen={isOpen} onClose={handleClose}>
            <div className="bidding-panel-container">
                <div className="platform-container" style={{ width: '100%' }}>
                    <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div
                            onClick={handleClose}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '28px',
                                height: '28px',
                                borderRadius: '50%',
                                border: '1px solid #000',
                                padding: '5px',
                                cursor: 'pointer',
                                marginRight: '10px',
                                boxSizing: 'border-box'
                            }}
                        >
                            <img
                                src={Icons.ActionsIconsSVG.ClosePanelIconSVG}
                                alt="Close"
                            />
                        </div>
                        <h2 style={{
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 400,
                            fontFamily: 'Quincy CF, serif',
                        }}>
                            {title}
                        </h2>
                    </div>
                    <div className="bidding-center-platform-selection">
                        <div
                            className={`bidding-center-collapsible-header ${isPlatformVisible ? 'expanded' : ''}`}
                            style={headerStyle}
                            onClick={togglePlatformSection}
                        >
                            <h2 className="platform-title">1. SELECT ALERT TYPE</h2>
                            <span className="arrow-icon">
                                <img
                                    src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                    alt="Arrow Down"
                                    className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                                />
                              </span>
                        </div>
                        {isPlatformVisible && (
                            <div className="collapsible-section">
                                <CheckboxGroup
                                    options={biddingOptions}
                                    onSelectionChange={setSelectedBiddingRule}
                                    onClosePlatform={closePlatformSection}
                                    selectedOption={selectedBiddingRule}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {selectedBiddingRule && (
                    <div className="form-container">
                        <div className="platform-container">
                            <div className="collapsible-form">
                                <div
                                    className={`bidding-center-collapsible-header ${isFormVisible ? 'expanded' : ''}`}
                                    onClick={handleFormVisibility}
                                >
                                    <h2 className="platform-title">2. CREATE | {selectedBiddingRuleLabel.toUpperCase()}</h2>
                                    <span className="arrow-icon">
                                        <img
                                            src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                            alt="Arrow Down"
                                            className={isFormVisible ? 'rotate-up' : 'rotate-down'}
                                        />
                                    </span>
                                </div>
                                {isFormVisible && (
                                    <BiddingRuleManager
                                        action={action}
                                        onClose={handleClose}
                                        biddingRuleData={{ bidding_rule_type: selectedBiddingRule, status: 'ENABLED' }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </SlidingPanel>
    );
};

export default BiddingSelectionForm;
