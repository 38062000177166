import React, {useState} from 'react';
import {Box, TextField, InputAdornment} from '@mui/material';
import {ReactComponent as IconSearch} from "../../assets/images/IconSearch.svg";
import SearchTableInput from "../../components/SearchTableInput/SearchTableInput";

const ResSearch = ({onSearch, resetPage}) => {
    const [nameHotel, setNameHotel] = useState('');
    const [codeResa, setCodeResa] = useState('');

    const handleSearchByNameHotel = (event) => {
        const value = event.target.value;
        setNameHotel(value);
        onSearch({nameHotel: value, codeResa});
        resetPage(0);
    };

    const handleSearchByCodeResa = (event) => {
        const value = event.target.value;
        setCodeResa(value);
        onSearch({nameHotel, codeResa: value});
        resetPage(0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '14px',
            }}>
            <SearchTableInput placeholder={"Search by Name"} value={nameHotel} onChange={handleSearchByNameHotel}/>
            <SearchTableInput placeholder={"Search by Code"} value={codeResa} onChange={handleSearchByCodeResa}/>
        </Box>
    );
};

export default ResSearch;