import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../../constants/api_urls";

export const getCampaignDetails = async (idCampaign) => {
    const jwtToken = sessionStorage.getItem('token');

    try {
        const response = await axios.get(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/get-campaign-details/`,
            {
                params: { campaign_id: idCampaign },
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return { success: true, data: response.data };
    } catch (error) {
        const errorMessage =
            error?.response?.data?.errorMessage || "Failed to fetch campaign details";
        return { success: false, error: errorMessage };
    }
};

