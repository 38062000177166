import React, { useMemo, useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AlertTableDesigned from "./BiddingOfflineHistoryTable";
import SlidingPanel from "../SlidingPanel/index";
import Icons from "components/DmpIcons/icons";
import { APP_BIDDING_OFFLINE_API_ENDPOINT } from "constants/api_urls";
import "./history.scss";
import { TextField, Chip, Stack } from '@mui/material';

const DetailsView = ({ details }) => {
  if (!details) return null;

    return (
        <div className="details-container">
            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="ID"
                    value={details.id}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="DATE"
                    value={new Date(details.date).toLocaleString()}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ display: "flex", gap: "10px", marginBottom: '15px' }}>
                <label>USER :</label>
                <Chip label={details.username}
                      size="small"
                      sx={{ fontSize: '12px', maxHeight: '15px', fontFamily: 'Inter, Serif' }}
                />
            </div>
            {details.breakdown && (
            <div className="details-section" style={{ display: "flex", gap: "10px", marginBottom: '15px' }}>
                <label>BREAKDOWN :</label>
                <Stack direction="row" spacing={1}>
                    {details.breakdown.map((item, index) => (
                        <Chip key={index} label={item}
                              size="small"
                              sx={{ fontSize: '12px', maxHeight: '15px', fontFamily: 'Inter, Serif' }}
                        />
                    ))}
                </Stack>
            </div>
            )}
            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="META SEARCH"
                    value={details.metaSearch}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="TYPE"
                    value={details.type}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="LEVEL"
                    value={details.level}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ marginBottom: '15px' }}>
                <TextField
                    label="STATUS"
                    value={details.status}
                    variant="standard"
                    disabled
                    fullWidth
                    sx={{
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '12px',
                        },
                    }}
                />
            </div>

            <div className="details-section" style={{ marginBottom: '15px' }}>
                <label>DOWNLOAD FILE : </label>
                <a href={details.fileDownloadLink} target="_blank" rel="noopener noreferrer">
                    Download
                </a>
            </div>
            {details.reportDownloadLink && (
            <div className="details-section" style={{ marginBottom: '15px' }}>
                <label>DOWNLOAD REPORT : </label>
                <a href={details.reportDownloadLink} target="_blank" rel="noopener noreferrer">
                    Download
                </a>
            </div>
            )}
        </div>
    );
};

export default function History() {
  const [rows, setRows] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
  const token = sessionStorage.getItem('token');
  const { idaccount } = useParams();

  const handleDetails = useCallback(async (rowData) => {
      console.log("Row Data:", rowData);
    try {
      const response = await axios.get(`${APP_BIDDING_OFFLINE_API_ENDPOINT}/api/bidding-offline/detail/`, {
        params: { history_id: rowData.id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSelectedDetails(response.data);
      setIsSlidingPanelOpen(true);
    } catch (err) {
      console.error("Erreur lors de la récupération des détails :", err);
    }
  }, [token]);

  const columns = useMemo(() => [
    {
      accessorKey: "count",
      header: <span>&nbsp;</span>,
      enableColumnActions: false,
      enableSorting: false,
      enableHiding: false,
      size: 40,
      sortable: true,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "date",
      header: "Date",
      sortable: true,
      Cell: ({ value }) => <span>{new Date(value).toLocaleString()}</span>,
    },
    {
      accessorKey: "status",
      header: "Status",
      sortable: true,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "type",
      header: "Type",
      sortable: true,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "metaSearch",
      header: "Meta search",
      sortable: true,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "level",
      header: "Level",
      sortable: true,
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "details",
      header: "Details",
      sortable: false,
      Cell: ({ row }) => (
          <img
              src={Icons.ActionsIconsSVG.SettingsIconSVG}
              alt="Details"
              style={{ cursor: 'pointer' }}
              onClick={() => handleDetails(row)}
          />
      ),
    },
  ], [handleDetails]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${APP_BIDDING_OFFLINE_API_ENDPOINT}/api/bidding-offline/list/`, {
          params: { account_id: idaccount },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRows(response.data.history_list);
      } catch (err) {
        console.error("Erreur lors de la récupération des données :", err);
      }
    };

    fetchData();
  }, [idaccount, token]);

  return (
      <div className="bidding-offline-table-container">
        <AlertTableDesigned columns={columns} data={rows} />
        <SlidingPanel
            isOpen={isSlidingPanelOpen}
            onClose={() => setIsSlidingPanelOpen(false)}
        >
            <div className="bidding-offline-panel-container" >
          <div className="panel-header" style={{ display: 'flex', alignItems: 'center' }}>
            <div
                onClick={() => setIsSlidingPanelOpen(false)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '28px',
                  height: '28px',
                  borderRadius: '50%',
                  border: '1px solid #000',
                  padding: '5px',
                  cursor: 'pointer',
                  marginRight: '10px',
                  boxSizing: 'border-box'
                }}
            >
              <img
                  src={Icons.ActionsIconsSVG.ClosePanelIconSVG}
                  alt="Close"
              />
            </div>
            <h2 style={{
              fontSize: '20px',
              textAlign: 'left',
              fontWeight: 400,
              fontFamily: 'Quincy CF, serif',
            }}>
              Details
            </h2>
          </div>
            <div className="details-header">
                <h2 style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Inter' }}>DETAILED REPORT</h2>
            </div>
          <div className="panel-detailed-content">
            <DetailsView details={selectedDetails} />
          </div>
            </div>
        </SlidingPanel>
      </div>
  );
}
