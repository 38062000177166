import React, {useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Grid, Typography, IconButton, Box} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import AccountsTable from "../../Table/Accounts/AccountsTable";

const AccountsAccessAccordion = ({
                                     expanded,
                                     handleChange,
                                     accountsData,
                                     availableAccounts,
                                     onEditAccount,
                                     onDeleteAccount,
                                     onAddAccount,
                                     onAccountClick,
                                     selectedAccount,
                                     hotelsData,
                                     loading
                                 }) => {
    const [newAccount, setNewAccount] = useState(null);

    const handleAddNewAccount = (event) => {
        event.stopPropagation();
        setNewAccount({id: null, name: '', readwriteOption: 'READ', allHotels: 'SOME'});
    };

    const handleAccountSelection = (selectedAccount) => {
        if (selectedAccount) {
            setNewAccount(null);
            onAddAccount(selectedAccount);
        }
    };

    const handleCancelNewAccount = () => {
        setNewAccount(null);
    };

    return (
        <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            disableGutters
            sx={{
                border: '1px solid #61D5A9', borderRadius: '8px !important', marginBottom: '10px',
                '&::before': expanded === 'panel2' ? {} : {content: 'none'},
                boxShadow: 'none',
                ...(expanded === 'panel2' && {
                    borderBottomLeftRadius: '8px !important',
                    borderBottomRightRadius: '8px !important',
                })
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{
                borderBottom: expanded === 'panel2' ? '1px solid #61D5A9' : 'none',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                height: '42px',
                minHeight: '42px',
                '&.Mui-expanded': {
                    minHeight: '42px',
                    height: '42px',
                },
                '.MuiAccordionSummary-content': {
                    margin: 0,
                }
            }}>
                <Grid container alignItems="center" sx={{padding: '4px'}}>
                    <Grid item xs>
                        <Typography sx={{fontSize:'12px',fontWeight: '400', fontFamily: 'Inter',
                            lineHeight: '14.52px',textTransform:'uppercase',color:"#373F41"}}>
                            Accounts Access
                        </Typography>
                    </Grid>

                    {expanded === 'panel2' ?
                        <Grid item>
                            <IconButton
                                color="primary"
                                onClick={handleAddNewAccount}
                                disabled={availableAccounts.length === 0}
                                sx={{
                                    backgroundColor: availableAccounts.length === 0 ? '#f0f0f0' : 'white',
                                    '&:hover': {backgroundColor: availableAccounts.length === 0 ? '#f0f0f0' : '#f0f0f0'},
                                    borderRadius: '50%',
                                    padding: '2px',
                                    marginRight: '5px',
                                    color: availableAccounts.length === 0 ? "#BAB7B7":'#000000',
                                    border: availableAccounts.length === 0 ? "1px solid #BAB7B7":'1px solid #BAB7B7',
                                }}
                            >
                                <AddIcon fontSize="small"/>
                            </IconButton>
                        </Grid> : ''
                    }
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{
                borderBottomLeftRadius: expanded === 'panel2' ? '8px' : 0,
                borderBottomRightRadius: expanded === 'panel2' ? '8px' : 0,
                backgroundColor: '#F5F5F5',
                height: '100%',
            }}>
                <Grid container spacing={2} sx={{padding: '8px', justifyContent: 'center', alignItems: 'center'}}>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                        <Box sx={{maxWidth: '750px', width: '100%', textAlign: 'center', mx: 'auto'}}>
                            <AccountsTable
                                accountsData={accountsData}
                                availableAccounts={availableAccounts}
                                newAccount={newAccount}
                                onAccountSelection={handleAccountSelection}
                                onEditAccount={onEditAccount}
                                onDeleteAccount={onDeleteAccount}
                                handleAccountClick={onAccountClick}
                                onCancelNewAccount={handleCancelNewAccount}
                                selectedAccount={selectedAccount}
                                hotelsData={hotelsData}
                                loading={loading}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccountsAccessAccordion;
