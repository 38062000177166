export const bingMultiplierFormFields = [
  {
    id: "multiplier",
    label: "Multiplier",
    type: "textField",
    placeholder: "Please enter a value",
  },
  {
    id: "MAP_RESULTS",
    label: "Map Results",
    type: "textField",
    placeholder: "Bid multiplier",
  },
  {
    id: "bidMultiplier",
    label: "Bid Multiplier",
    type: "textField",
    placeholder: "Bid multiplier",
  },
  {
    id: "LOCAL_UNIVERSE",
    label: "Local Universe",
    type: "textField",
    placeholder: "Bid multiplier",
  },

  {
    id: "numberOfNights",
    label: "Number of nights",
    type: "range",
    placeholder: "Please enter a value",
  },
  {
    id: "multiplierValue",
    label: "Multiplier Value",
    type: "textField",
    placeholder: "Please enter a value",
    isAdder: true,
  },

  {
    id: "monday",
    label: "Monday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "tuesday",
    label: "Tuesday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "wednesday",
    label: "Wednesday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "thursday",
    label: "Thursday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "friday",
    label: "Friday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "saturday",
    label: "Saturday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "sunday",
    label: "Sunday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "date",
    label: "Date",
    type: "date",
    placeholder: "Date",
  },
];

export const bingButtons = [
  {
    id: "market",
    label: "Market",
    value: "MARKET",
  },
  {
    id: "bingSite",
    label: "Bing site",
    value: "BING_SITE",
  },
  {
    id: "lengthOfStay",
    label: "Length of stay",
    value: "LENGTH_OF_STAY",
  },
  {
    id: "checkInDay",
    label: "Check in day",
    value: "CHECKIN_DAY",
  },
];

export const allFields = {
  lengthOfStay: [
    "device_identifier",
    "numberOfNights",
    "empty",
    "multiplierValue",
  ],
  checkInDay: [
    "device_identifier",
    "empty",
    "monday",
    "friday",
    "tuesday",
    "saturday",
    "wednesday",
    "sunday",
    "thursday",
  ],
  market: ["bidMultiplier"],
  bingSite: ["LOCAL_UNIVERSE", "MAP_RESULTS"],
};
