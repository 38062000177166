import React, { useEffect, useState } from "react";
import PrimarySearchAppBar from "../../components/Navbar";
import Sidebar from "../../components/Navbar/Sidebar";
import {
  homepageMenuItems,
  homepageSideMyMenuItems,
  homepageSideMoreMenuItems,
  searchAdspageMenuItems,
} from "../../constants/productmenu";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const DefaultLayout = ({ page, children }) => {
  const { activeSection = "" } = useSelector((state) => state?.sidebar);
  const [currentSection, setCurrentSection] = useState();

  useEffect(() => {
    if (activeSection) {
      setCurrentSection(activeSection);
    }
  }, [activeSection]);

  return (
    <>
      <PrimarySearchAppBar />

      <Box
        className="mainBox"
        sx={{ display: "flex", height: "95.5vh", overflow: "hidden" }}
      >
        <Sidebar
          page={page}
          menuItems={
            page === "SearchAdsPage"
              ? searchAdspageMenuItems
              : homepageMenuItems
          }
          moreItems={homepageSideMoreMenuItems}
          initialItems={homepageSideMyMenuItems}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            flex: 1,
            paddingTop: 2,
            width: "calc(100vw - 180px)",
            height: "100%",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export default DefaultLayout;
