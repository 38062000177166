export const exportToCsv = (filename, rows) => {
    const processRow = (row) => {
        return row.map(String).map(v => v.replace(/"/g, '""')).join(',');
    };
    const csvContent = 'data:text/csv;charset=utf-8,'
        + rows.map(processRow).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for Firefox

    link.click();
    document.body.removeChild(link);
};
