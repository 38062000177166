import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BiddingOptions from "./biddingOptions";
import Multipliers from "./multipliers";
import BiddingRules from "./biddingRules";
import { useSelector } from "react-redux";
import ExtraBid from "./extraBid";
import IconWrapper from "components/IconWrapper";
import CloseIcon from "assets/images/close.svg";
import KayakMultipliers from "./kayak/multipliers";

export default function EditBidContainer({ setOpenEditBidding }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const platform = useSelector((state) => state?.metaSearch?.platform);
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        style={{ position: "relative" }}
        {...other}
      >
        <div
          style={{
            position: "absolute",
            top: index === tabs.length - 1 ? "25px" : "0px",
            right: "18px",
            padding: 0,
            zIndex: 10,
          }}
        >
          <IconWrapper
            icon={CloseIcon}
            onClick={() => {
              setOpenEditBidding(false);
            }}
          />
        </div>
        {value === index && <Box sx={{ padding: "18px" }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [tabs, setTabs] = React.useState([]);
  const [layouts, setLayouts] = React.useState([]);
  React.useEffect(() => {
    switch (platform?.[0]?.id) {
      case "bing":
        setTabs(["Bidding options", "Multipliers"]);
        setLayouts([<BiddingOptions />, <Multipliers />]);
        break;
      case "kayak":
        setTabs(["Bidding options", "Extra Bid", "Multipliers"]);
        setLayouts([<BiddingOptions />, <ExtraBid />, <KayakMultipliers />]);
        break;
      case "skyScanner":
        setTabs(["Bidding options"]);
        setLayouts([<BiddingOptions />]);
        break;
      case "trivagoSponsoredListing":
        setTabs(["Bidding options", "Bidding rules"]);
        setLayouts([<BiddingOptions />, <BiddingRules />]);
        break;
      case "wego":
        setTabs(["Status"]);
        setLayouts([<BiddingOptions />]);
        break;

      default:
        setTabs(["Bidding options", "Bidding rules", "Multipliers"]);
        setLayouts([<BiddingOptions />, <BiddingRules />, <Multipliers />]);
        break;
    }
    setValue(0);
  }, [platform]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTabs-flexContainer": {
            justifyContent: "flex-end",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          sx={{
            height: "26px",
            minHeight: "26px",
            "& .MuiButtonBase-root": {
              fontSize: "12px",
              fontWeight: 400,
              textTransform: "none",
              backgroundColor: "#BAB7B7",
              borderRadius: "10px 10px 0px 0px",
              marginRight: "3px",
              height: "26px !important",
              width: "130px!important",
              color: "white",
            },
            "& .Mui-selected": {
              backgroundColor: "#61D5A9",
              color: "white !important",
            },
          }}
        >
          {tabs.map((tab, i) => {
            return (
              <Tab
                sx={{ minHeight: "26px", height: "26px" }}
                label={tab}
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
      </Box>
      {layouts.map((layout, i) => {
        return (
          <CustomTabPanel value={value} index={i}>
            {layout}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}
