import React from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import { Button } from '@mui/material';
import './SlidingPanel.scss';

const SlidingPanelComponent = ({ isOpen, onClose, children, size, backdropDisabled }) => {
    return (
        <SlidingPanel
            type="right"
            isOpen={isOpen}
            size={size || 50}
            noBackdrop={backdropDisabled || false}
            backdropClicked={onClose}
        >
            <div className="panel-container">
                {children}
            </div>
        </SlidingPanel>
    );
};

export default SlidingPanelComponent;
