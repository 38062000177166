import { Box, Chip, Typography } from "@mui/material";

import BiddingOffline from "features/bidding-offline";

import DefaultLayout from "layout/defaultLayout";
import React, { useRef, useState } from "react";
import PageTitles from "../../components/PageTitles";

const BiddingOfflinePage = () => {
  const hotelButtonRef = useRef(null);
  const platformButtonRef = useRef(null);
  const [setOpenPlatformModal] = useState(false);
  const [setOpenHotelModal] = useState(false);
  return (
    <DefaultLayout>
        <div className={"bidding-offline"}>
            <PageTitles pageTitle="Metasearch" pageSubTitle="Bidding offline" />
          <div style={{ paddingInline: "16px", paddingTop: "14px" }}>
                <BiddingOffline />
          </div>
        </div>
    </DefaultLayout>
  );
};
export default BiddingOfflinePage;
