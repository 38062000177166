import React, { useState } from "react";
import {
    Box,
    Typography,
    MenuItem,
    InputAdornment,
    Grid,
} from "@mui/material";

import { AD_LOCATIONS } from "../../../constants/adLocations";
import CustomFormControl from "../../../../alert-center/Dropdown/CustomFormControl";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

import { BIDDING_STRATEGIES } from "../../../constants/biddingStrategies";
import { useSelector } from "react-redux";
import {
    validateBiddingStrategy,
    validateCampaignName,
} from "../../Utils/validators";

const CampaignSettingsDetails = ({
                                     biddingStrategy,
                                     setBiddingStrategy,
                                     campaignName,
                                     setCampaignName,
                                     targetCpa,
                                     setTargetCpa,
                                     targetRoas,
                                     setTargetRoas,
                                     maxCpcLimit,
                                     setMaxCpcLimit,
                                     impressionShare,
                                     setImpressionShare,
                                     adLocation,
                                     setAdLocation,
                                     errors,
                                     isEdit = false
                                 }) => {
    const accountId = useSelector((state) => state?.account?.account?.account_id);
    const { hotel_id = [] } = useSelector((state) => state?.searchAds);
    const currency = "€"; //@TODO: Load currency from the API

    const [campaignNameStatus, setCampaignNameStatus] = useState(null);
    const [biddingStrategyStatus, setBiddingStrategyStatus] = useState({});

    const handleCampaignNameBlur = async (text) => {
        if (!text.trim()) return;
        const result = await validateCampaignName(
            { campaign_name: text },
            accountId,
            hotel_id?.[0]
        );
        setCampaignNameStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid campaign name" }
        );
    };

    const handleBiddingStrategyBlur = async (id, text) => {
        if (!text.trim()) return;
        const result = await validateBiddingStrategy({
            bidding_strategy_option: biddingStrategy,
            bidding_value: text,
            bidding_max_value: id === "impressionShare" || id === "maxCpcLimit" ? maxCpcLimit : undefined,
            ad_location: adLocation ?? undefined,
        });
        setBiddingStrategyStatus((prev) => ({
            ...prev,
            [id]: result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid bid value" },
        }));
    };

    const handleBiddingStrategyChange = (event) => {
        setBiddingStrategy(event.target.value);
    };

    // Strategy-specific top field
    const renderTopRightField = () => {
        switch (biddingStrategy) {
            case "MAXIMIZE_CONVERSIONS":
                return (
                    <CustomOutlinedInput
                        inputPaddingLeft={"0px"}
                        minWidth={"221px"}
                        placeholder="Target CPA"
                        value={targetCpa}
                        onChange={(e) => setTargetCpa(e.target.value)}
                        onBlur={(e) => handleBiddingStrategyBlur("targetCPA", e.target.value)}
                        className="no-border-focus"
                        
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 1 }}>
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                        {currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            biddingStrategyStatus?.["targetCPA"] &&
                            !biddingStrategyStatus?.["targetCPA"].success
                        }
                        helperText={
                            biddingStrategyStatus?.["targetCPA"]
                                ? biddingStrategyStatus?.["targetCPA"].error || "Target CPA"
                                : "Target CPA"
                        }
                        success={
                            biddingStrategyStatus?.["targetCPA"] &&
                            biddingStrategyStatus?.["targetCPA"].success
                        }
                    />
                );
            case "MAXIMIZE_CONVERSION_VALUE":
                return (
                    <CustomOutlinedInput
                        minWidth={"221px"}
                        placeholder="Target ROAS"
                        value={targetRoas}
                        onChange={(e) => setTargetRoas(e.target.value)}
                        onBlur={(e) => handleBiddingStrategyBlur("targetROAS", e.target.value)}
                        className="no-border-focus"
                        
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        error={
                            biddingStrategyStatus?.["targetROAS"] &&
                            !biddingStrategyStatus?.["targetROAS"].success
                        }
                        helperText={
                            biddingStrategyStatus?.["targetROAS"]
                                ? biddingStrategyStatus?.["targetROAS"].error || "Target ROAS"
                                : "Target ROAS"
                        }
                        success={
                            biddingStrategyStatus?.["targetROAS"] &&
                            biddingStrategyStatus?.["targetROAS"].success
                        }
                    />
                );
            case "MAXIMIZE_CLICKS":
                return (
                    <CustomOutlinedInput
                        inputPaddingLeft={"0px"}
                        minWidth={"221px"}
                        placeholder="Maximum CPC Bid Limit"
                        value={maxCpcLimit}
                        onChange={(e) => setMaxCpcLimit(e.target.value)}
                        onBlur={(e) => handleBiddingStrategyBlur("maximizeClicks", e.target.value)}
                        className="no-border-focus"
                        
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 1 }}>
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                     {currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            biddingStrategyStatus?.["maximizeClicks"] &&
                            !biddingStrategyStatus?.["maximizeClicks"].success
                        }
                        helperText={
                            biddingStrategyStatus?.["maximizeClicks"]
                                ? biddingStrategyStatus?.["maximizeClicks"].error ||
                                "Maximum CPC Bid Limit"
                                : "Maximum CPC Bid Limit"
                        }
                        success={
                            biddingStrategyStatus?.["maximizeClicks"] &&
                            biddingStrategyStatus?.["maximizeClicks"].success
                        }
                    />
                );
            case "MANUAL_CPC":
            case "MANUAL_CPC_ENHANCED":
                return (
                    <CustomOutlinedInput
                        inputPaddingLeft={"0px"}
                        minWidth={"221px"}
                        placeholder="CPC"
                        value={maxCpcLimit}
                        onChange={(e) => setMaxCpcLimit(e.target.value)}
                        onBlur={(e) => handleBiddingStrategyBlur("cpc", e.target.value)}
                        className="no-border-focus"
                        
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ mr: 1 }}>
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                      {currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            biddingStrategyStatus?.["cpc"] &&
                            !biddingStrategyStatus?.["cpc"].success
                        }
                        helperText={
                            biddingStrategyStatus?.["cpc"]
                                ? biddingStrategyStatus?.["cpc"].error || "CPC"
                                : "CPC"
                        }
                        success={
                            biddingStrategyStatus?.["cpc"] &&
                            biddingStrategyStatus?.["cpc"].success
                        }
                    />
                );
            case "TARGET_IMPRESSION_SHARE":
                return (
                    <CustomOutlinedInput
                        minWidth={"221px"}
                        placeholder="Percent Impression Share"
                        value={impressionShare}
                        onChange={(e) => setImpressionShare(e.target.value)}
                        onBlur={(e) => handleBiddingStrategyBlur("impressionShare", e.target.value)}
                        className="no-border-focus"
                        
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        error={
                            biddingStrategyStatus?.["impressionShare"] &&
                            !biddingStrategyStatus?.["impressionShare"].success
                        }
                        helperText={
                            biddingStrategyStatus?.["impressionShare"]
                                ? biddingStrategyStatus?.["impressionShare"].error ||
                                "Percent Impression Share"
                                : "Percent Impression Share"
                        }
                        success={
                            biddingStrategyStatus?.["impressionShare"] &&
                            biddingStrategyStatus?.["impressionShare"].success
                        }
                    />
                );
            default:
                return null;
        }
    };

    const renderAdLocationField = () => (
        <CustomFormControl
            minWidth={"221px"}
            value={adLocation}
            onChange={(e) => setAdLocation(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
                if (!selected) {
                    return (
                        <span
                            style={{
                                fontSize: "12px",
                                color: "#bab7b7",
                            }}
                        >
                            Choose an Ad Location
                        </span>
                    );
                }
                return selected;
            }}
            error={errors.adLocationError}
            helperText={
                biddingStrategyStatus?.["adLocation"]
                    ? biddingStrategyStatus?.["adLocation"].error || "Ad Location"
                    : "Ad Location"
            }
            helperTextFontSize="8px"
            onBlur={() => handleBiddingStrategyBlur("adLocation", adLocation)}
            
        >
            <MenuItem value="" disabled>
                <span
                    style={{
                        fontSize: "12px",
                        color: "#bab7b7",
                    }}
                >
                    Choose an Ad Location
                </span>
            </MenuItem>
            {Object.entries(AD_LOCATIONS).map(([key, value]) => {
                const formattedValue = key.toUpperCase();
                return (
                    <MenuItem key={formattedValue} value={formattedValue}>
                        <span
                            style={{
                                fontSize: "12px",
                                color: "#373F41",
                            }}
                        >
                            {value}
                        </span>
                    </MenuItem>
                );
            })}
        </CustomFormControl>
    );

    const renderMaxCpcLimitFieldForTIS = () => (
        <CustomOutlinedInput
            inputPaddingLeft={"0px"}
            minWidth={"221px"}
            placeholder="Maximum CPC Bid Limit"
            value={maxCpcLimit}
            onChange={(e) => setMaxCpcLimit(e.target.value)}
            onBlur={(e) => handleBiddingStrategyBlur("maxCpcLimit", e.target.value)}
            className="no-border-focus"
            type="number"
            
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ mr: 1 }}>
                        <Typography variant="body2" sx={{ fontSize: "12px" }}>
                           {currency}
                        </Typography>
                    </InputAdornment>
                ),
            }}
            error={
                biddingStrategyStatus?.["maxCpcLimit"] &&
                !biddingStrategyStatus?.["maxCpcLimit"].success
            }
            helperText={
                biddingStrategyStatus?.["maxCpcLimit"]
                    ? biddingStrategyStatus?.["maxCpcLimit"].error ||
                    "Maximum CPC Bid Limit"
                    : "Maximum CPC Bid Limit"
            }
            success={
                biddingStrategyStatus?.["maxCpcLimit"] &&
                biddingStrategyStatus?.["maxCpcLimit"].success
            }
        />
    );

    return (
        <Box className="campaignSettingsDetails">
            <Box display="flex">
                <Typography className="sectionTitle">
                    Create Campaign Name and Select Your Bidding Strategy
                </Typography>
            </Box>
            <Box p={2}>
                <Grid container gap={"30px"}>
                    <Grid item>
                        <CustomOutlinedInput
                            minWidth={"221px"}
                            placeholder="Campaign Name"
                            variant="outlined"
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            onBlur={(e) => handleCampaignNameBlur(e.target.value)}
                            FormHelperTextProps={{
                                className: "custom-helper-text",
                            }}
                            helperText={
                                campaignNameStatus
                                    ? campaignNameStatus.error || "Campaign Name"
                                    : "Campaign Name"
                            }
                            error={campaignNameStatus && !campaignNameStatus.success}
                            success={campaignNameStatus && campaignNameStatus.success}
                            
                        />
                    </Grid>

                    <Grid item >
                        <CustomFormControl
                            minWidth={"221px"}
                            value={biddingStrategy}
                            onChange={handleBiddingStrategyChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return (
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                color: "#bab7b7",
                                            }}
                                        >
                                            Choose a bidding strategy
                                        </span>
                                    );
                                }
                                return BIDDING_STRATEGIES[selected];
                            }}
                            required
                            error={errors.alertPeriod}
                            helperText={errors.alertPeriod ? "This field is required" : "Bidding Strategy"}
                            helperTextFontSize={"10px"}
                            
                        >
                            <MenuItem disabled value="">
                                <span
                                    style={{
                                        fontSize: "12px",
                                        color: "#bab7b7",
                                        marginLeft: "4px",
                                    }}
                                >
                                    Choose a bidding strategy
                                </span>
                            </MenuItem>
                            {Object.entries(BIDDING_STRATEGIES).map(([key, value]) => (
                                <MenuItem key={key} value={key}>
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            color: "#373F41",
                                            marginLeft: "4px",
                                        }}
                                    >
                                        {value}
                                    </span>
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                    </Grid>

                    <Grid item>
                        {renderTopRightField()}
                    </Grid>

                    {biddingStrategy === "TARGET_IMPRESSION_SHARE" && (
                        <>
                            {isEdit && (
                                <Grid item width={"221px"}></Grid>
                            )
                            }

                            <Grid item>
                                {renderMaxCpcLimitFieldForTIS()}
                            </Grid>

                            <Grid item>
                                {renderAdLocationField()}
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </Box>
    );
};

export default CampaignSettingsDetails;
