import { Button } from "@mui/material";

const CustomButton = ({ title, onClick, variant, sx }) => {
  return (
    <Button
      variant={variant}
      sx={{
        backgroundColor: variant === "filled" ? "#61D5A9" : "transparent",
        height: "22px",
        paddingInline: "40px",
        borderRadius: "10px",
        fontSize: "10px",
        color: "black",
        ...sx,
      }}
      title={title}
      onClick={onClick}
    >
      {" "}
      {title}{" "}
    </Button>
  );
};
export default CustomButton;
