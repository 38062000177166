import React, { useState, useEffect } from 'react';
import 'react-sliding-side-panel/lib/index.css';
import './AlertSelectionForm.scss';
import AlertManager from "../AlertForm/AlertManager";
import SlidingPanel from "../AlertForm/SlidingPanel";
import Icons from "components/DmpIcons/icons";

const CheckboxGroup = ({ title, options, onSelectionChange, onClosePlatform, selectedOption }) => {

    const handleCheckboxChange = (optionValue) => {
        if (selectedOption === optionValue) {
            onSelectionChange('');
        } else {
            onSelectionChange(optionValue);
            onClosePlatform();
        }
    };

    return (
        <div className="checkbox-group">
            <label className="group-title">{title}</label>
            <div className="checkbox-grid">
                {options.map((option) => (
                    <button
                        key={option.value}
                        className={`custom-button ${selectedOption === option.value ? 'selected' : ''}`}
                        onClick={() => handleCheckboxChange(option.value)}
                        aria-pressed={selectedOption === option.value}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

const AlertSelectionForm = ({ isOpen, onClose, onAlertTypeSelect }) => {
    const groupedAlertOptions = [
        {
            title: 'Metasearch Performance Alerts',
            options: [
                { label: 'Google Hotel Ads', value: 'ALERT_METASEARCH_GOOGLE_HOTEL_ADS_ALL_ADS_TYPE' },
                { label: 'Google Hotel Ads - Meta', value: 'ALERT_GOOGLE_HOTELS_ADS_META' },
                { label: 'Google Hotel Ads - PPA', value: 'ALERT_GOOGLE_HOTELS_ADS_PPA' },
                { label: 'Tripadvisor - Meta', value: 'ALERT_METASEARCH_TRIPADVISOR_META' },
                { label: 'Tripadvisor - SP', value: 'ALERT_METASEARCH_TRIPADVISOR_SP' },
                { label: 'Trivago - Meta', value: 'ALERT_METASEARCH_TRIVAGO_META' },
                { label: 'Trivago - SL', value: 'ALERT_METASEARCH_TRIVAGO_SL' },
                { label: 'Bing', value: 'ALERT_METASEARCH_BING' },
                { label: 'Kayak', value: 'ALERT_METASEARCH_KAYAK' },
                { label: 'Skyscanner', value: 'ALERT_METASEARCH_SKYSCANNER' },
                { label: 'Wego', value: 'ALERT_METASEARCH_WEGO_META' },
            ],
        },
        {
            title: 'Budget Alerts',
            options: [
                { label: 'Metasearch Budget', value: 'ALERT_METASEARCH_BUDGET' },
                { label: 'Google Search Ads Budget', value: 'ALERT_GOOGLE_SEARCH_ADS_BUDGET' },
            ],
        },
    ];

    const [selectedAlert, setSelectedAlert] = useState('');
    const [isPlatformVisible, setIsPlatformVisible] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

    const handleTogglePanel = () => {
        setIsEditPanelOpen((prev) => !prev);
    }

    const handleFormVisibility = () => {
        setIsFormVisible((prev) => !prev);
    };

    const headerStyle = {
        backgroundColor: !selectedAlert ? 'white' : '#61D5A9'
    };

    const togglePlatformSelection = () => {
        setIsPlatformVisible((prev) => !prev);
    };

    const selectedAlertLabel = groupedAlertOptions.flatMap(group => group.options)
        .find(option => option.value === selectedAlert)?.label + " Alert" || '';

    // Fonction pour réinitialiser les états
    const resetPanel = () => {
        setSelectedAlert('');
        setIsPlatformVisible(true);
        setIsFormVisible(true);
    };

    useEffect(() => {
        if (!isOpen) {
            resetPanel();
        }
    }, [isOpen]);

    return (
        <SlidingPanel isOpen={isOpen} onClose={onClose}>
            <div className="alert-panel-container">
                <div className="platform-container" style={{ width: '100%' }}>
                    <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div
                            onClick={onClose}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '28px',
                                height: '28px',
                                borderRadius: '50%',
                                border: '1px solid #000',
                                padding: '5px',
                                cursor: 'pointer',
                                marginRight: '10px',
                                boxSizing: 'border-box'
                            }}
                        >
                            <img
                                src={Icons.ActionsIconsSVG.ClosePanelIconSVG}
                                alt="Close"
                            />
                        </div>
                        <h2 style={{
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 400,
                            fontFamily: 'Quincy CF, serif',
                        }}>
                            New Alert
                        </h2>
                    </div>
                    <div className={`platform-selection ${isPlatformVisible ? 'expanded' : ''}`}>
                        <div className={`collapsible-header ${isPlatformVisible ? 'expanded' : ''} ${selectedAlert ? 'selected' : ''} `} style={headerStyle} onClick={togglePlatformSelection}>
                            <h2 className="platform-title">1. SELECT ALERT TYPE</h2>
                            <span className="arrow-icon">
                                <img
                                    src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                    alt="Arrow Down"
                                    className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                                />
                              </span>
                        </div>
                        {isPlatformVisible && (
                            <div className="collapsible-section">
                                {groupedAlertOptions.map((group, index) => (
                                    <CheckboxGroup
                                        key={index}
                                        title={group.title}
                                        options={group.options}
                                        onSelectionChange={setSelectedAlert}
                                        onClosePlatform={togglePlatformSelection}
                                        selectedOption={selectedAlert}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {selectedAlert && (
                    <div className="alert-form-container">
                        <div className={`platform-container ${isFormVisible ? 'expanded' : ''}`} >
                            <div className="collapsible-form">
                                <div className={`collapsible-header ${isFormVisible ? 'expanded' : ''}`} onClick={handleFormVisibility}>
                                    <h2 className="platform-title">2. CREATE | {selectedAlertLabel.toUpperCase()}</h2>
                                    <span className="arrow-icon">
                                        <img
                                            src={Icons.AccessibilityIconsSVG.ThinDownArrowIconSVG}
                                            alt="Arrow Down"
                                            className={isPlatformVisible ? 'rotate-up' : 'rotate-down'}
                                        />
                              </span>
                                </div>
                                {isFormVisible && (
                                    <AlertManager onClose={onClose} alertData={{ alertType: selectedAlert, status: 'ENABLED' }} />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </SlidingPanel>
    );

};

export default AlertSelectionForm;
