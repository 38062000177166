import React, {useState} from 'react';
import AlertBaseForm from './MetricsMetasearch';
import {useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";

const AlertMetasearchGoogleMetaForm = ({alertData, actionType, onClose}) => {

    const { idaccount } = useParams();
    const availableSegments = ["DEVICE", "MARKET"];
    let method = 'POST';
    let apiActionEndpoint = '';
    const apiEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/google-hotel-ads-meta/metrics-list`;
    if(actionType === 'edit') {
        method = 'PUT';
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/google-hotel-ads-meta/edit/${idaccount}/` + alertData.id;
    }
    else {
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-metrics/google-hotel-ads-meta/add/${idaccount}`;
    }
    const jwtToken = sessionStorage.getItem('token');

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const submitGoogleMetaAlert = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                setSnackbarMessage('Error saving alert: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    return (
        <div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AlertBaseForm
                apiEndpoint={apiEndpoint}
                alert={alertData}
                jwtToken={jwtToken}
                submitCallback={submitGoogleMetaAlert}
                availableSegments={availableSegments}
                onClose={onClose}
            />
        </div>
    );
};

export default AlertMetasearchGoogleMetaForm;
