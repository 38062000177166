import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import ResetPasswordForm from "./ResetPasswordForm";
import NewPasswordForm from "../NewPassword/NewPasswordForm";
import Layout from "../Layout";
import {
  APP_LOST_PASSWORD_ENDPOINT,
} from "../../../constants/api_urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [passwordUpdating, setPasswordUpdating] = useState(false); // State variable to track password update status
  const token = sessionStorage?.getItem("token") || "";
  const [error, setError] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      setEmailSent(true);
      setPasswordUpdating(false);
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${APP_LOST_PASSWORD_ENDPOINT}`, {
        email,
      });
      if (response.status === 200) {
        setEmailSent(true);
        navigate("/email-sent");
      } else {
        console.log("message", response);
        setError(response?.data?.error_message);
      }
    } catch (error) {
      setError(error.response.data.error_message);
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      <div className="box-2">
        <div style={{ marginBottom: "20px" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              textAlign: "left",
              width: "535px",
              height: "85px",
              flexShrink: "0",
              color: "#373F41",
              fontFamily: "Quincy CF",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "20px",
              marginBottom: "-70px",
            }}
          >
            Reset Password
          </Typography>
        </div>
        {!emailSent ? (
          <>
            {" "}
            <ResetPasswordForm
              setEmail={setEmail}
              error={error}
              handleSubmit={handleSubmit}
            />
          </>
        ) : (
          <>
            {passwordUpdating ? (
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  width: "209px",
                  color: "var(--Grey, #373F41)",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                Your password has been successfully changed.
              </Typography>
            ) : (
              <NewPasswordForm
                email={email}
                setPasswordUpdating={setPasswordUpdating}
              />
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ResetPassword;
