import React, { useState } from "react";
import {Box, Typography, TextField, InputAdornment} from "@mui/material";
import { validateBudget } from "../../Utils/validators/campaignSettingsValidators";
import { MONTHS } from "../../../constants/months";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";

const MonthlyBudgetCap = ({
                              monthlyBudget = 0,
                              setMonthlyBudget,
                              targetValue,
                              setTargetValue,
                              errors,
                              isEdit = false
                          }) => {
    const [validationErrors, setValidationErrors] = useState({});
    const currency = "€"; //@TODO load currency from the api

    const handlePMaxMonthlyBudgetChange = (month, value) => {
        if (/^\d*$/.test(value)) {
            setMonthlyBudget((prev) => ({
                ...prev,
                [month]: value,
            }));
        }
    };

    const handleMonthlyBudgetBlur = async (month) => {
        const payload = {
            defaultBudget: targetValue,
            monthlyBudgets: { [`budget_month_${MONTHS.indexOf(month) + 1}`]: monthlyBudget[month] },
        };

        const result = await validateBudget(payload);

        if (result.success) {
            setValidationErrors((prev) => ({ ...prev, [month]: null }));
        } else {
            setValidationErrors((prev) => ({
                ...prev,
                [month]: result.errors[`budget_month_${MONTHS.indexOf(month) + 1}`],
            }));
        }
    };

    const handleTargetValueChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setTargetValue(value);
        }
    };

    const handleTargetValueBlur = async () => {
        const payload = {
            defaultBudget: targetValue,
            monthlyBudgets: Object.fromEntries(
                MONTHS.map((month, index) => [`budget_month_${index + 1}`, monthlyBudget[month] || null])
            ),
        };

        const result = await validateBudget(payload);

        if (result.success) {
            setValidationErrors((prev) => ({ ...prev, targetValue: null }));
        } else {
            setValidationErrors((prev) => ({
                ...prev,
                targetValue: result.errors["default_budget"],
            }));
        }
    };

    const monthsPerRow = isEdit ? 3 : 4;

    return (
        <Box className="monthlyBudgetCap">
            <Box display="flex" alignItems="flex-start" flexDirection="column">
                <Typography className="sectionTitle"> Monthly Budget Cap</Typography>
                <Typography className="sectionSubtitle" variant="body2" color="textSecondary">
                    Minimum budget required: €1700
                </Typography>
            </Box>

            <Box display="flex" alignItems="flex-start" p={2}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{ paddingRight: "15px" }}
                >
                    <CustomOutlinedInput
                        placeholder="Enter Monthly Budget Cap"
                        value={targetValue}
                        onChange={handleTargetValueChange}
                        onBlur={handleTargetValueBlur}
                        className="no-border-focus"
                        type="number"
                        minWidth="221px"
                        inputPaddingLeft="0px"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        error={Boolean(errors.defaultBudget) || Boolean(validationErrors.targetValue)}
                        helperText={
                            errors.defaultBudget
                                ? errors.defaultBudget
                                : validationErrors.targetValue
                                    ? validationErrors.targetValue
                                    : ""
                        }
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                        {currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />

                </Box>

                <Box
                    display="grid"
                    gridTemplateColumns={`repeat(${monthsPerRow}, 1fr)`}
                >
                    {MONTHS.map((month) => (
                        <Box
                            key={month}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            sx={{ paddingRight: "16px" }}
                            className="monthField"
                        >
                            <Typography
                                variant="body2"
                                sx={{ display: "flex", textAlign: "right", color: "#373F41", fontSize: "10px", paddingBottom: "35px", paddingLeft: "5px" }}
                                className="monthLabel"
                            >
                                {month}
                            </Typography>
                            <CustomOutlinedInput
                                value={monthlyBudget?.[month] || ""}
                                type="number"
                                minWidth={isEdit ? "150px" : "200"}
                                inputPaddingLeft="0px"
                                className="no-border-focus monthInput"
                                onChange={(e) => handlePMaxMonthlyBudgetChange(month, e.target.value)}
                                onBlur={() => handleMonthlyBudgetBlur(month)}
                                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                error={!!validationErrors[month]}
                                helperText={validationErrors[month] || ""}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                                {currency}
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default MonthlyBudgetCap;
