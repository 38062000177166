import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./ResSingleSelect.scss";

export default function ResSingleSelect({
        value = undefined,
        options,
        onChange,
        sx,
        placeholder,
    }) {
    return (
        <FormControl size="small">
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                displayEmpty
                value={value}
                sx={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                    '& .MuiSelect-icon': {
                        color: '#BAB7B7',
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
                        padding: "3px 0 3px",
                    },
                    ...sx,
                }}
                onChange={onChange}
                renderValue={(selected) => {
                    if (!selected) {
                        return <span
                            style={{
                                fontFamily:"inter",
                                fontWeight:'400',
                                fontSize: "12px",
                                color: "#bab7b7",
                                marginLeft: "10px",
                            }}
                        >{placeholder}</span>;
                    }
                    return (
                        <span
                            style={{
                                fontFamily:"inter",
                                fontWeight:'400',
                                fontSize: "12px",
                                marginLeft: "10px",
                            }}
                        >
                    {options.find((option) => option.value === selected)?.label}
                </span>
                    );
                }}
            >
                <MenuItem disabled value="">
                    <span
                        style={{
                            fontSize:'0.75rem',
                            color: "#bab7b7",
                            marginLeft: "4px",
                        }}
                    >{placeholder}</span>
                </MenuItem>
                {options.map((option) => {
                    return <MenuItem sx={{fontSize:'0.75rem'}} value={option.value}>{option.label}</MenuItem>;
                })}
            </Select>
        </FormControl>
    );
}
