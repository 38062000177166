import { format } from "date-fns";

/**
 * Creates a payload for API requests using the provided calendar state and account ID from the URL.
 *
 * @function createPayload
 * @param {string} idAccount - The calendar state object from Redux store.
 * @param {Object} calender - The calendar state object from Redux store.
 * @param {Object} calender.currentDateRange - The current date range object.
 * @param {string} calender.currentDateRange.startDate - The current start date.
 * @param {string} calender.currentDateRange.endDate - The current end date.
 * @param {Object} calender.comparitionDateRange - The comparison date range object.
 * @param {string} calender.comparitionDateRange.startDate - The comparison start date.
 * @param {string} calender.comparitionDateRange.endDate - The comparison end date.
 *
 * @returns {Object|null} The constructed payload object, or `null` if `account_id` or `calender` is missing.
 */
export const createAccountDatePayload = (idAccount, calender) => {
    const account_id = idAccount;
    if (!account_id || !calender) return null;

    const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);

    return {
        account_id: parseInt(account_id, 10),
        start_date: formatDate(calender.currentDateRange.startDate),
        end_date: formatDate(calender.currentDateRange.endDate),
        compare_start_date: formatDate(calender.comparitionDateRange.startDate),
        compare_end_date: formatDate(calender.comparitionDateRange.endDate),
    };
};