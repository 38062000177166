import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableSortLabel,
    Box
} from "@mui/material";
import './ReportingMetricsTable.scss';

const ReportingMetricsTable = ({ metrics }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(null);

    const formatNumber = (num) => {
        return num;
    };

    const safeGet = (data, key) => {
        return data && key in data ? data[key] : 0;
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        const valueA = a.data ? safeGet(a.data, orderBy) : null;
        const valueB = b.data ? safeGet(b.data, orderBy) : null;

        if (valueB < valueA) {
            return -1;
        }
        if (valueB > valueA) {
            return 1;
        }
        return 0;
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const rows = [
        {
            name: "Metasearch Ads",
            data: metrics?.metasearch_metrics || {},
        },
        {
            name: "Destination Ads",
            data: metrics?.destination_metrics || {},
        },
        {
            name: "Search Ads",
            data: metrics?.search_ads_metrics || {},
        },
        {
            name: "PMax",
            data: metrics?.pmax_metrics || {},
        },
    ];

    const sortedRows = stableSort(rows, getComparator(order, orderBy));

    return (
        <Box className="metasearch-reporting-component-table">
            <TableContainer component={Paper} className="metasearch-reporting-component-table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {" "}
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === "clicks_kpi"}
                                    direction={orderBy === "clicks_kpi" ? order : "asc"}
                                    onClick={() => handleSortRequest("clicks_kpi")}
                                >
                                    <Typography className="table-metasearch-reporting-header">Clicks</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === "cost_kpi"}
                                    direction={orderBy === "cost_kpi" ? order : "asc"}
                                    onClick={() => handleSortRequest("cost_kpi")}
                                >
                                    <Typography className="table-metasearch-reporting-header">Cost</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === "conversion_kpi"}
                                    direction={orderBy === "conversion_kpi" ? order : "asc"}
                                    onClick={() => handleSortRequest("conversion_kpi")}
                                >
                                    <Typography className="table-metasearch-reporting-header">Conv.</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === "generated_kpi"}
                                    direction={orderBy === "generated_kpi" ? order : "asc"}
                                    onClick={() => handleSortRequest("generated_kpi")}
                                >
                                    <Typography className="table-metasearch-reporting-header">Generated</Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right">
                                <TableSortLabel
                                    active={orderBy === "roas_kpi"}
                                    direction={orderBy === "roas_kpi" ? order : "asc"}
                                    onClick={() => handleSortRequest("roas_kpi")}
                                >
                                    <Typography className="table-metasearch-reporting-header">ROAS</Typography>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell className="table-metasearch-reporting-body-left" >{row.name}</TableCell>
                                <TableCell className="table-metasearch-reporting-body" align="right">{formatNumber(safeGet(row.data, "clicks_kpi"))}</TableCell>
                                <TableCell className="table-metasearch-reporting-body" align="right">{metrics?.currency_symbol} {formatNumber(safeGet(row.data, "cost_kpi"))}</TableCell>
                                <TableCell className="table-metasearch-reporting-body" align="right">{formatNumber(safeGet(row.data, "conversion_kpi"))}</TableCell>
                                <TableCell className="table-metasearch-reporting-body" align="right">{metrics?.currency_symbol} {formatNumber(safeGet(row.data, "generated_kpi"))}</TableCell>
                                <TableCell className="table-metasearch-reporting-body" align="right">{formatNumber(safeGet(row.data, "roas_kpi"))}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell className="table-metasearch-reporting-bottom total" >
                                Total
                            </TableCell>
                            <TableCell align="right" className="table-metasearch-reporting-bottom">
                                {formatNumber(safeGet(metrics?.total || {}, "clicks_kpi"))}
                            </TableCell>
                            <TableCell align="right" className="table-metasearch-reporting-bottom">
                                {metrics?.currency_symbol} {formatNumber(safeGet(metrics?.total || {}, "cost_kpi"))}
                            </TableCell>
                            <TableCell align="right" className="table-metasearch-reporting-bottom">
                                {formatNumber(safeGet(metrics?.total || {}, "conversion_kpi"))}
                            </TableCell>
                            <TableCell align="right" className="table-metasearch-reporting-bottom">
                                {metrics?.currency_symbol} {formatNumber(safeGet(metrics?.total || {}, "generated_kpi"))}
                            </TableCell>
                            <TableCell align="right" className="table-metasearch-reporting-bottom">
                                {formatNumber(safeGet(metrics?.total || {}, "roas_kpi"))}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ReportingMetricsTable;
