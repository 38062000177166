import React, { useState } from 'react';
import {IconButton, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import './ImageEditor.scss';
import {
    APP_CLOUDINARY_CLOUDNAME,
    APP_CLOUDINARY_UPLOAD_PRESET,
    APP_CLOUDINARY_URL_ENDPOINT,
} from "../../../../../constants/api_urls";
import { Helmet } from 'react-helmet';
import { CLOUDINARY_IMAGE_CATEGORIES, DEFAULT_RATIOS } from "../../../constants/cloudinaryImageCategory";
import { generateCroppedUrl, removeCropParameters } from "../../Utils/imageTools/imageCropper";
import { validateImage } from "../../Utils/validators/imageValidators";

const CloudinaryImageEditor = ({
    landscape,
    setLandscape,
    square,
    setSquare,
    portrait,
    setPortrait,
    logo,
    setLogo,
    landscapeLogo,
    setLandscapeLogo,
    errors = {}
}) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [fullScreenImage, setFullScreenImage] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    const openUploadWidget = (category) => {
        const defaultRatio = DEFAULT_RATIOS[category];
        if (!defaultRatio) return;

        window.cloudinary.openUploadWidget(
            {
                cloudName: APP_CLOUDINARY_CLOUDNAME,
                uploadPreset: APP_CLOUDINARY_UPLOAD_PRESET,
                multiple: false,
                sources: ['local', 'url'],
                cropping: false,
                croppingAspectRatio: defaultRatio,
                showSkipCropButton: false,
                transformation: [
                    {
                        aspect_ratio: `${Math.round(defaultRatio * 100)}:${100}`,
                        crop: 'fill',
                    },
                ],
            },
            async (error, result) => {
                if (!error && result?.event === 'success') {
                    const croppedUrl = generateCroppedUrl(result.info);
                    await Promise.all([
                        (async () => {
                            updateImageCategory(category, croppedUrl);
                        })(),
                        (async () => {
                            const validationResult = await validateImage(category, result.info.secure_url);
                            setValidationErrors((prev) => ({
                                ...prev,
                                [croppedUrl]: validationResult.success ? null : validationResult.error,
                            }));
                        })()
                    ]);
                }
            }
        );
    };

    const updateImageCategory = (category, url) => {
        switch (category) {
            case 'landscape':
                setLandscape((prev) => [...prev, url]);
                break;
            case 'square':
                setSquare((prev) => [...prev, url]);
                break;
            case 'portrait':
                setPortrait((prev) => [...prev, url]);
                break;
            case 'logo':
                setLogo((prev) => [...prev, url]);
                break;
            case 'landscapeLogo':
                setLandscapeLogo((prev) => [...prev, url]);
                break;
            default:
                break;
        }
    };

    const handleDelete = (index, category) => {
        const categoryArray = getCategoryArray(category);
        if (!categoryArray) return;

        const updatedArray = categoryArray.filter((_, i) => i !== index);
        setCategoryArray(category, updatedArray);
    };

    const getCategoryArray = (category) => {
        switch (category) {
            case 'landscape':
                return landscape;
            case 'square':
                return square;
            case 'portrait':
                return portrait;
            case 'logo':
                return logo;
            case 'landscapeLogo':
                return landscapeLogo;
            default:
                return null;
        }
    };

    const setCategoryArray = (category, updatedArray) => {
        switch (category) {
            case 'landscape':
                setLandscape(updatedArray);
                break;
            case 'square':
                setSquare(updatedArray);
                break;
            case 'portrait':
                setPortrait(updatedArray);
                break;
            case 'logo':
                setLogo(updatedArray);
                break;
            case 'landscapeLogo':
                setLandscapeLogo(updatedArray);
                break;
            default:
                break;
        }
    };

    const formatCategoryKey = (key) => {
        return key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/^[a-z]/, (char) => char.toUpperCase());
    };

    const renderCategory = (categoryKey) => {
        const categoryArray = getCategoryArray(categoryKey);
        if (!categoryArray) return null;

        return categoryArray.map((url, index) => {
            const categoryError = errors[`${categoryKey}Image`];

            const errorMessage =
                validationErrors[url] ||
                (typeof categoryError === "string"
                    ? categoryError
                    : categoryError?.[`index-${index}`]) ||
                null;

            const isHovered = hoveredIndex === index;
            return (
                <div
                    className={`image-card ${errorMessage != null ? 'error' : ''}`}
                    key={index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                >
                    <img src={url} alt="Uploaded" />
                    {(isHovered || (errorMessage != null)) && (
                        <div className={`image-overlay ${errorMessage != null ? 'has-error' : ''}`}>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                            <div className="image-actions">
                                <IconButton color="primary" onClick={() => setFullScreenImage(url)}>
                                    <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                    color="secondary"
                                    className="delete-icon"
                                    onClick={() => handleDelete(index, categoryKey)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    return (
        <div className="cloudinary-image-editor">
            <Helmet>
                <script src={APP_CLOUDINARY_URL_ENDPOINT} type="text/javascript" />
            </Helmet>

            <div className="image-grid">
                {CLOUDINARY_IMAGE_CATEGORIES.map((category) => (
                    <div className="cloudinary-category" key={category}>
                        <div className="category-header">
                            <h4>
                                {formatCategoryKey(category)}
                                {typeof errors[`${category}Image`] === "string" && (
                                    <Typography className="text-error-section">
                                        {errors[`${category}Image`]}
                                    </Typography>
                                )}
                                <AddCircleOutlineIcon
                                    className="add-icon"
                                    onClick={() => openUploadWidget(category)}
                                />
                            </h4>
                        </div>
                        <div className="image-category">{renderCategory(category)}</div>
                    </div>
                ))}
            </div>

            {fullScreenImage && (
                <div className="full-screen-overlay" onClick={() => setFullScreenImage(null)}>
                    <img src={removeCropParameters(fullScreenImage)} alt="Full Screen" className="full-screen-image" />
                </div>
            )}
        </div>
    );
};

export default CloudinaryImageEditor;