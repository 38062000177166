import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: null,
};
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: initialState,
  reducers: {
    updateAccount: (state, action) => {
      state.account = action.payload;
    },
  },
});

export const { updateAccount } = accountSlice.actions;
export default accountSlice.reducer;
