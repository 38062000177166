import React, {useState} from "react";
import {Box, MenuItem, Pagination, Select, Stack, Typography} from "@mui/material";
import "./CustomPagination.scss";
const CustomPagination = ({pageCount,page,handleChangePage,rowsPerPage=null,handleRowsPerPageChange=null}) => {
    return (
        <>
            {pageCount > 1 && (
                <Box className="custom-pagination-container" sx={{ padding: '1px 8px 0 8px' }}>
                    {rowsPerPage != null && handleRowsPerPageChange != null ? (
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" alignItems="center" className="rows-per-page-container">
                                <Typography className="rows-per-page-label">Rows per page:</Typography>
                                <Select
                                    value={rowsPerPage}
                                    onChange={handleRowsPerPageChange}
                                    size="small"
                                    className="rows-per-page-select"
                                >
                                    <MenuItem sx={{ fontSize: '12px' }} value={10}>10</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} value={20}>20</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} value={50}>50</MenuItem>
                                    <MenuItem sx={{ fontSize: '12px' }} value={100}>100</MenuItem>
                                </Select>
                            </Stack>
                            <Stack className="pagination-container">
                                <Pagination
                                    count={pageCount}
                                    page={page}
                                    onChange={handleChangePage}
                                    siblingCount={1}
                                    boundaryCount={1}
                                    showFirstButton
                                    showLastButton
                                    size="small"
                                />
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack className="pagination-container">
                            <Pagination
                                count={pageCount}
                                page={page}
                                onChange={handleChangePage}
                                siblingCount={1}
                                boundaryCount={1}
                                showFirstButton
                                showLastButton
                                size="small"
                            />
                        </Stack>
                    )}
                </Box>
            )}
        </>
    );
};
export default CustomPagination;