import React from "react";
import { Grid, Box } from "@mui/material";
import { DeviceOptions } from "../../Utils/Options";
import { DeviceAvailableMetrics } from "../../Utils/AvailableMetrics";

const TableDevice = ({ selectedOption, handleDropdownChange, tableMetrics, handleTableMetricChange, tableData }) => (
    <Grid item xs={8}>
        <Grid container>
            <Grid item xs={12}>
                <Box className="dropdown-container">
                    <select
                        id="deviceDropdown"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                        className="dropdown"
                    >
                        {DeviceOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        ))}
                    </select>
                </Box>
            </Grid>

            <Grid item xs={12} className="metrics-table">
                <table>
                    <thead>
                    <tr>
                        <th>{" "}</th>
                        {Object.keys(tableMetrics).map((metricKey, index) => (
                            <th key={index}>
                                <select
                                    value={tableMetrics[metricKey]}
                                    onChange={(event) => handleTableMetricChange(event, metricKey)}
                                    className="dropdown"
                                >
                                    {Object.entries(DeviceAvailableMetrics).map(([metric, label]) => (
                                        <option key={metric} value={metric}>{label}</option>
                                    ))}
                                </select>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.length > 0 ? (
                        tableData.map((row, index) => (
                            <tr key={index}>
                                <td>
                                <span
                                    className="device-color"
                                    style={{ backgroundColor: row.color }}
                                ></span>
                                    {row.name}
                                </td>
                                <td align="right">{row.metric1}</td>
                                <td align="right">{row.metric2}</td>
                                <td align="right">{row.metric3}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={tableData.length + 1} align="center">
                                No data available
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Grid>
        </Grid>
    </Grid>
);

export default TableDevice;