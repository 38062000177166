import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";

const BASE_URL = `${APP_REPORT_CENTER_API_ENDPOINT}/api`;

const handleError = (errMessage, setErrors) => {
    if (setErrors) setErrors(errMessage);
  };
  
  // Fetch Timezones
  export const fetchTimezones = async (fetchService, setErrors) => {
    try {
      const response = await fetchService(`${BASE_URL}/timezone/list`);
      if (response.success) {
        return response.data;
      } else {
        handleError('Failed to load timezones', setErrors);
        return [];
      }
    } catch (err) {
      handleError('Failed to load timezones', setErrors);
      return [];
    }
  };
  
  // Fetch FTP Data
  export const fetchFtp = async (fetchService, idaccount, setErrors) => {
    try {
      const url = `${BASE_URL}/ftp/list?account=${idaccount}`;
      const response = await fetchService(url);
      if (response.success) {
        return response.data;
      } else {
        handleError('Failed to load FTP data', setErrors);
        return [];
      }
    } catch (err) {
      handleError('Failed to load FTP data', setErrors);
      return [];
    }
  };
  
  // Fetch Hotels
  export const fetchHotels = async (fetchService, idaccount, setErrors) => {
    try {
      const url = `${BASE_URL}/hotel/list?account=${idaccount}`;
      const response = await fetchService(url);
      if (response.success) {
        return response.data;
      } else {
        handleError('Failed to load Hotels data', setErrors);
        return [];
      }
    } catch (err) {
      handleError('Failed to load Hotels data', setErrors);
      return [];
    }
  };
  
  // Fetch Groups
  export const fetchGroups = async (fetchService, idaccount, setErrors) => {
    try {
      const url = `${BASE_URL}/groups/list?account=${idaccount}`;
      const response = await fetchService(url);
      if (response.success) {
        return response.data;
      } else {
        handleError('Failed to load Groups data', setErrors);
        return [];
      }
    } catch (err) {
      handleError('Failed to load Groups data', setErrors);
      return [];
    }
  };
  
  // Fetch User Info
  export const fetchUserInfo = async (fetchService, setErrors) => {
    try {
      const url = `${BASE_URL}/userinfo`;
      const response = await fetchService(url);
      if (response.success) {
        return response.data[0]; // Assuming the first user info is needed
      } else {
        handleError('Failed to load User Info', setErrors);
        return null;
      }
    } catch (err) {
      handleError('Failed to load User Info', setErrors);
      return null;
    }
  };
  
  // Fetch White Label Email
  export const fetchWhiteLabelEmail = async (fetchService, idaccount, setErrors) => {
    try {
      const url = `${BASE_URL}/whitelable-email/list?account=${idaccount}`;
      const response = await fetchService(url);
      if (response.success) {
        return response.data;
      } else {
        handleError('Failed to load White Label Email data', setErrors);
        return [];
      }
    } catch (err) {
      handleError('Failed to load White Label Email data', setErrors);
      return [];
    }
  };