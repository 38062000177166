import {getCampaignDetails} from "../getCampaignDetails";

export const assignDetails = async (props) => {
    const {
        isOpen, idCampaign, setDrawerOpen, setCampaignName, setBiddingStrategy,
        setTargetCpa, setTargetRoas, setTargetValue, setMonthlyBudget, setSelectedLanguage,
        setSelectedCountries, setFinalURL, setBusinessName, setLongHeadlines, setHeadlines,
        setDescriptions, setIsCampaignPaused, setLandscape, setSquare, setPortrait, setLogo,
        setLandscapeLogo,
    } = props;

    if (isOpen && idCampaign) {
        setDrawerOpen(true);
        const response = await getCampaignDetails(idCampaign);

        if (response.success) {
            const campaign = response.data;

            setCampaignName(campaign.campaign_settings?.campaign_name || "");
            setBiddingStrategy(campaign.campaign_settings?.bidding_strategy?.bidding_strategy_option || "");

            const biddingValue = campaign.campaign_settings?.bidding_strategy?.bidding_value || "";
            setTargetCpa(biddingValue);
            setTargetRoas(biddingValue);

            setTargetValue(campaign.campaign_settings?.budget?.default_budget || "");

            setMonthlyBudget({
                Jan: campaign.campaign_settings?.budget?.budget_month_1 || 0,
                Feb: campaign.campaign_settings?.budget?.budget_month_2 || 0,
                Mar: campaign.campaign_settings?.budget?.budget_month_3 || 0,
                Apr: campaign.campaign_settings?.budget?.budget_month_4 || 0,
                May: campaign.campaign_settings?.budget?.budget_month_5 || 0,
                Jun: campaign.campaign_settings?.budget?.budget_month_6 || 0,
                Jul: campaign.campaign_settings?.budget?.budget_month_7 || 0,
                Aug: campaign.campaign_settings?.budget?.budget_month_8 || 0,
                Sep: campaign.campaign_settings?.budget?.budget_month_9 || 0,
                Oct: campaign.campaign_settings?.budget?.budget_month_10 || 0,
                Nov: campaign.campaign_settings?.budget?.budget_month_11 || 0,
                Dec: campaign.campaign_settings?.budget?.budget_month_12 || 0,
            });

            setSelectedLanguage(campaign.campaign_settings?.campaign_lang || "");
            setSelectedCountries(campaign.campaign_settings?.point_of_sales || []);
            setFinalURL(campaign.campaign_ads_creative?.final_url?.url || "");
            setBusinessName(campaign.campaign_ads_creative?.business_name?.assetContent || "");
            setIsCampaignPaused(campaign.campaign_status !== "ENABLED");

            setLongHeadlines(
                campaign.campaign_ads_creative?.long_headline?.map((item, index) => ({
                    id: index,
                    text: item.assetContent,
                })) || []
            );

            setHeadlines(
                campaign.campaign_ads_creative?.headline?.map((item, index) => ({
                    id: index,
                    text: item.assetContent,
                })) || []
            );

            setDescriptions(
                campaign.campaign_ads_creative?.description?.map((item, index) => ({
                    id: index,
                    text: item.assetContent,
                })) || []
            );

            setLandscape(campaign.campaign_ads_creative?.marketing_image?.map(item => item.assetContent) || []);
            setSquare(campaign.campaign_ads_creative?.square_marketing_image?.map(item => item.assetContent) || []);
            setPortrait(campaign.campaign_ads_creative?.portrait_marketing_image?.map(item => item.assetContent) || []);
            setLogo(campaign.campaign_ads_creative?.logo_image?.map(item => item.assetContent) || []);
            setLandscapeLogo(campaign.campaign_ads_creative?.landscape_logo_image?.map(item => item.assetContent) || []);
        }
        setDrawerOpen(false);
    }
};