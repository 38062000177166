import { isNotEmpty, isValidDateRange } from '../../../utils/functions/validation';

export const validateReportForm = (data) => {
  const errors = {};

  // Validate name
  if (!isNotEmpty(data.name)) {
    errors.name = 'Report name is required';
  }

  // Validate level
  if (!isNotEmpty(data.level)) {
    errors.level = 'Report level is required';
  }

  // Validate date range
  if (!isNotEmpty(data.date_range)) {
    errors.date_range = 'Date range is required';
  } else if (data.date_range === 'CUSTOM') {
    if (!isValidDateRange(data.start_date, data.end_date)) {
      errors.custom_date = 'Start and End date are required and must be valid for custom date range';
    }
  }

  // Validate delivery frequency
  if (!isNotEmpty(data.delivery_frequency)) {
    errors.delivery_frequency = 'Delivery frequency is required';
  }

  return errors;
};
