const ERROR_MESSAGES = {
    ERROR_NO_LEVEL_ACCESS: 'No Access',
    ERROR_INVALID_LEVEL: 'Invalid Level Selected',
    ERROR_MISSING_REQUIRED_FIELD: 'This field is required',
    ERROR_INVALID_DELIVERY_FREQUENCY: 'Invalid Delivery Selected',
    ERROR_MISSING_SUBJECT:'Subject field is required',
    ERROR_MISSING_EXPORT_NAME: 'Export name is required',
    ERROR_INVALID_DATE_RANGE:'Select an appropriate value',
    ERROR_INVALID_EXPORT_FILE_TYPE :'Invalid Export File',
    ERROR_INVALID_GROUP_DATA_BY_TIME:'Invalid Group Data',
    ERROR_INVALID_EXPORT_METHOD:'Invalid Export Type'
  };
  
  export default ERROR_MESSAGES;
  