import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './PMaxCampaignAccordion.scss';
import CustomButtonWithIconComponent from "../../../../components/CustomButtonWithIcon/Buttons";
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";

const PMaxCampaignAccordion = ({
                                   title,
                                   children,
                                   expanded,
                                   onChange,
                                   onNext = null,
                                   disabled,
                                   visited,
                                   isCreateButton = false,
                                   isSaveButton = false,
                                   onSave = null,
                                   getSuggestion = null,
                                   setDrawerOpen = null,
                                   noNextButton= false
                               }) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleGetSuggestion = async () => {
        if (getSuggestion) {
            setDrawerOpen(true);
            await getSuggestion();
            setDrawerOpen(false);
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleConfirmGetSuggestion = async () => {
        setDialogOpen(false);
        await handleGetSuggestion();
    };

    const handleSave = async () => {
        try {
            setDrawerOpen(true);
            if (onSave) {
                await onSave();
            }
        } catch (error) {
            console.error("Error during Save action:", error);
        } finally {
            setDrawerOpen(false);
        }
    };

    return (
        <>
            <Accordion
                className="pmax-google-form-accordion"
                expanded={expanded}
                onChange={!disabled ? onChange : () => {}}
                sx={{
                    pointerEvents: disabled ? 'none' : 'auto',
                    opacity: disabled ? 0.6 : 1,
                }}
            >
                <AccordionSummary
                    className="accordion-summary"
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        height: '42px',
                        maxHeight: '42px',
                        minHeight: '42px',
                        backgroundColor: visited && !expanded ? '#61D5A9' : 'inherit',
                        borderBottom: expanded ? '1px solid #61D5A9' : 'none',
                        cursor: disabled ? 'default' : 'pointer',
                        color: visited && !expanded ? 'black' : 'inherit',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '&.Mui-expanded': {
                            height: '42px',
                            maxHeight: '42px',
                            minHeight: '42px',
                        },
                    }}
                >
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>{title}</Typography>
                    {expanded && getSuggestion && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={handleDialogOpen}
                            sx={{
                                marginLeft: 'auto',
                                marginRight: '15px',
                            }}
                        >
                            Get Ads Creative Suggestions
                        </Button>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    className="accordion-details"
                    sx={{
                        backgroundColor: '#F2F2F2',
                    }}
                >
                    {children}
                    <Box sx={{display: "flex", flexDirection: "row", gap: "15px"}}>
                        {onNext && !noNextButton && (
                            <CustomButtonWithIcon
                                color="#b0acac"
                                hoverColor="#12794F"
                                titleColor="#373F41"
                                hoverTitleColor={"white"}
                                width={"120px"}
                                height={"24px"}
                                borderRadius="20px"
                                textStyle={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '14.52px',
                                    textAlign: 'center',
                                }}
                                onClick={async () => {
                                    setDrawerOpen(true);
                                    await onNext();
                                    setDrawerOpen(false);
                                }}

                            >
                                {isCreateButton ? "Create" : "Next"}
                            </CustomButtonWithIcon>
                        )}
                        {isSaveButton && onSave && (
                            <CustomButtonWithIcon
                                color="#b0acac"
                                hoverColor="#12794F"
                                titleColor="#373F41"
                                hoverTitleColor={"white"}
                                width={"120px"}
                                height={"24px"}
                                borderRadius="20px"
                                textStyle={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '14.52px',
                                    textAlign: 'center',
                                }}
                                onClick={handleSave}
                            >
                                Save
                            </CustomButtonWithIcon>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>

            {/* Confirmation Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title"> <span style={{fontSize: "12px", fontFamily: "Inter"}} >CONFIRM OVERWRITE</span></DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        <span style={{fontSize: "12px", fontFamily: "Inter"}} > Are you sure you want to overwrite all the data in the "Ads Creative" section? This action cannot be undone. </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButtonWithIconComponent
                        title="Cancel"
                        onClick={handleDialogClose}
                        textStyle={{ fontSize: '14px', fontWeight: 'bold' }}
                        color="grey"
                    >
                        Cancel
                    </CustomButtonWithIconComponent>
                    <CustomButtonWithIconComponent
                        title="Confirm"
                        onClick={handleConfirmGetSuggestion}
                        textStyle={{ fontSize: '14px', fontWeight: 'bold' }}
                        color="#61D5A9"
                    >
                        Confirm
                    </CustomButtonWithIconComponent>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PMaxCampaignAccordion;
