import React, { useState, useEffect } from 'react';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useCallback, useRef } from 'react'
import '@yaireo/tagify/dist/tagify.css'
import '../AlertForm.scss';
import {useParams} from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";

const AlertBaseForm = ({alertData, actionType, onClose }) => {

    const { idaccount } = useParams();
    let method = 'POST';
    let apiActionEndpoint = '';
    if(actionType === 'edit') {
        method = 'PUT';
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/metasearch/edit/${idaccount}/` + alertData.id;
    }
    else {
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/metasearch/add/${idaccount}`;
    }
    const jwtToken = sessionStorage.getItem('token');

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [alertName, setAlertName] = useState(alertData?.name || '');
    const [alertRecipients, setAlertalertRecipients] = useState(alertData?.recipients || []);
    const [alertCondition, setAlertCondition] = useState(alertData?.alertCondition || '');
    const tagifyRef = useRef(null);

    const [errors, setErrors] = useState({
        alertName: false,
        alertRecipients: false,
        alertCondition: false,
    });


    useEffect(() => {
        if (alertData) {
            setAlertName(alertData.name || '');
            setAlertalertRecipients(alertData.recipients || []);
            setAlertCondition(alertData.alertCondition || '');
        }
    }, [alertData]);


    const submitBudgetMetasearchAlert = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                                setSnackbarMessage('Error saving alert: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                const newErrors = {
                    alertName: !requestBody.alert_name,
                    alertRecipients: requestBody.alert_recipients.length === 0,
                    alertCondition: !requestBody.alert_condition,
                };
                setErrors(newErrors);
            });
    };

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value); // Parser la chaîne JSON
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value);
        setAlertalertRecipients(updatedRecipients);
    }, []);

    const handleSubmit = async () => {
        const body = {
            alert_name: alertName,
            alert_recipients: alertRecipients,
            alert_condition: alertCondition,
        };
        console.log('Alert data: ', JSON.stringify(body));
        submitBudgetMetasearchAlert(body);
    };

    return (
        <div className="alert-form">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className="top-side">
                <TextField
                    placeholder="Alert Name:"
                    variant="standard"
                    value={alertName}
                    onChange={(e) => setAlertName(e.target.value)}
                    required
                    error={errors.alertName}
                    helperText={errors.alertName ? 'This field is required' : ''}
                    sx={{
                        width: '55%',
                        '& ::placeholder': { fontSize: '12px' },
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                        },
                }}
                />
            </div>

            <TextField
                placeholder="Alert me when my budget has reached"
                variant="standard"
                type="number"
                value={alertCondition}
                onChange={(e) => setAlertCondition(e.target.value)}
                required
                error={errors.alertCondition}
                helperText={errors.alertCondition ? 'This field is required' : ''}
                sx={{
                    '& ::placeholder': { fontSize: '12px' },
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                    },
                    width: '55%',
            }}
            />


            <div className="bottom-side">
                <label>RECIPIENTS</label>
                <Tags
                    ref={tagifyRef}
                    whitelist={alertRecipients}
                    placeholder='Recipients:'
                    settings={{
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    }}
                    onChange={handleRecipientsChange}
                    defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                    className={errors.alertRecipients ? 'error-tagify' : ''}
                />
                {errors.alertRecipients && <span style={{ color: '#d32f2f', fontSize: '12px' }}>This field is required</span>}
            </div>

            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Alert
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>
        </div>
    );
};

export default AlertBaseForm;
