import CustomCheckbox from "components/Checkbox";
import { Button, Typography } from "@mui/material";
import iconupload from "assets/images/iconupload.svg";
import IconWrapper from "components/IconWrapper";
import "./import.scss";
import React, { useState, useRef } from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import {APP_BIDDING_OFFLINE_API_ENDPOINT} from "../../../constants/api_urls";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "../../../components/DmpIcons/icons";
import Icons from "../../../components/DmpIcons/icons";

export default function Import() {

  const account = useSelector((state) => state.account.account);


  const { idaccount } = useParams();

  const [importLevel, setImportLevel] = useState('HOTEL');
  const handleChangeImportLevel = (event) => {
    setImportLevel(event.target.value);
  };

  const [importPlatform, setImportPlatform] = useState('');
  const handleChangeImportPlatform = (event) => {
    setImportPlatform(event.target.value);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [exportApiMessage, setExportApiMessage] = useState({ message:'',severity:'success',showMessage:false});
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

   const fileInputRef = useRef(null);
   const [importSeparator, setImportSeparator] = useState(';');
  const handleChangeImportSeparator = (event) => {
    setImportSeparator(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  // Fonction de gestion de la sélection de fichier
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

    const handleRemoveFile = () => {
        setSelectedFile(null); // Clears the selected file
    };


  const handleSubmitImport = async () => {
    if (!selectedFile) {
      setSnackbarMessage("Please select a file.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }
    //console.log(axios);

    const jwtToken = sessionStorage.getItem('token');

    const biddingOfflineImport = new FormData();
    biddingOfflineImport.append('file', selectedFile); // Ajoute le fichier
    biddingOfflineImport.append('import_level', importLevel); // Ajoute les autres champs
    biddingOfflineImport.append('import_type', importPlatform);
    biddingOfflineImport.append('import_separator', importSeparator);
    biddingOfflineImport.append('id_account', idaccount);

    let axiosInstance = axios.create({
      baseURL: APP_BIDDING_OFFLINE_API_ENDPOINT,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${jwtToken}`,
      },
    });




    try {
      // Envoie la requête POST avec fetch
      const response = await axiosInstance.post('/api/bidding-offline/import/', biddingOfflineImport);
    } catch (error) {

    }

  };


  return (
    <div className="bidding-offline-container">

      <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <div className="export-header">
        <span>IMPORT BIDDING OFFLINE</span>
        <span>{account?.account_name ?? ""}</span>
      </div>
      <div className="export-fields-container">
          <div className="row" style={{alignItems: "center"}}>
          <div className="row-label">IMPORT LEVEL</div>
          <FormControl>
            <RadioGroup
                row
                name="row-radio-buttons-group"
                value={importLevel}
                onChange={handleChangeImportLevel}

            >
              <FormControlLabel
                  value="HOTEL"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Hotel"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
              <FormControlLabel
                  value="GROUP"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Group"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
            </RadioGroup>
          </FormControl>
        </div>
          <div className="row" style={{alignItems: "center"}}>
          <div className="row-label">PLATFORM</div>
          <FormControl>
            <RadioGroup
                row
                name="row-radio-buttons-group"
                value={importPlatform}
                onChange={handleChangeImportPlatform}

            >
              <FormControlLabel
                  value="GOOGLE"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Google Hotel Ads"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
              <FormControlLabel
                  value="TRIPADVISOR"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Tripadvisor"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />

              <FormControlLabel
                  value="TRIVAGO"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Trivago"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
              <FormControlLabel
                  value="KAYAK"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Kayak"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
              <FormControlLabel
                  value="BING"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label="Bing"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
            </RadioGroup>
          </FormControl>
        </div>
          <div className="row" style={{alignItems: "center"}}>
          <div className="row-label"> COLUMNS SEPARATOR</div>
          <FormControl>
            <RadioGroup
                row
                name="row-radio-buttons-group"
                value={importSeparator}
                onChange={handleChangeImportSeparator}

            >
              <FormControlLabel
                  value=","
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label=","
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />
              <FormControlLabel
                  value=";"
                  control={<Radio sx={{'& .MuiSvgIcon-root': {fontSize: 12}}}/>}
                  label=";"
                  sx={{'& .MuiFormControlLabel-label': {fontSize: 10, fontFamily: 'Inter', fontWeight: 400}}}
              />

            </RadioGroup>
          </FormControl>
        </div>
          <div className="row" style={{alignItems: "center"}}>
          <div className="row-label">BINDING FILE</div>
          <div className="row-item-container">
              <input
                  type="file"
                  id="file-upload"
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                  ref={fileInputRef}
              />
              <CustomButtonWithIcon
                  color="white"
                  borderColor={"#BAB7B7"}
                  hoverColor="#61D5A9"
                  width="120px"
                  height="30px"
                  titleColor="#373F41"
                  borderRadius="20px"
                  title="Upload"
                  icon={Icons.ActionsIcons.AddIconBlack}
                  iconBorder="0.5px solid #373F41"
                  textStyle={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '14.52px',
                      textAlign: 'center',
                  }}
                  onClick={() => fileInputRef.current.click()}
              >
                  Upload
              </CustomButtonWithIcon>



          </div>
        </div>
        {selectedFile && (
            <div className="row" style={{alignItems: "center"}}>
              <div className="row-label">FILE</div>
              <div className="row-item-container-file">
                {selectedFile.name}
                  <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleRemoveFile}
                      sx={{ marginLeft: 2, padding: '2px 4px', fontSize: '10px' }}
                  >
                      Remove
                  </Button>
              </div>
            </div>
        )}


        <div>
            <CustomButtonWithIcon
                color="#b0acac"
                hoverColor="#12794F"
                titleColor="#373F41"
                hoverTitleColor={"white"}
                width={"120px"}
                height={"24px"}
                borderRadius="20px"
                textStyle={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '14.52px',
                    textAlign: 'center',
                }}
                onClick={handleSubmitImport}
                disabled={!selectedFile || !importPlatform || !importLevel}
            >
                Import
            </CustomButtonWithIcon>
        </div>



      </div>
    </div>
  );
}
