import "./Login.scss";
import React from "react";
import {Typography, Container, Grid, Box} from "@mui/material";
// images
import { ReactComponent as IconMetaSearch } from "../../assets/images/IconMetaSearch.svg";
import { ReactComponent as IconSearchAds } from "../../assets/images/IconSearchAds.svg";
// import { ReactComponent as IconSocialAds } from "../../assets/images/IconSocialAds.svg";
import { ReactComponent as IconPMax } from "../../assets/images/IconPMax.svg";
// import { ReactComponent as IconOtaAds } from "../../assets/images/IconOtaAds.svg";
import { ReactComponent as IconSeo } from "../../assets/images/IconSeo.svg";
import { ReactComponent as IconCms } from "../../assets/images/IconCms.svg";
import { ReactComponent as IconRateMatch } from "../../assets/images/IconRateMatch.svg";
import { ReactComponent as IconPushTech } from "../../assets/images/IconPushTech.svg";
import logo from "../../assets/images/logo.png";

const Layout = ({ children }) => {
  return (
    <div className="custom-container">
      <img src={logo} alt="logo" className="logo" />
      <Container className="internal-block">
        <Grid container spacing={2}>
          <Grid item xs={5} container direction="column">
            <div className="box-1">
              <Typography
                className="quency-cf-normal"
                variant="h4"
                gutterBottom
                sx={{ textAlign: "left", marginTop: "20px" }}
              >
                Digital Marketing Platform
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  textAlign: "left",
                  width: "193px",
                  height: "138px",
                  flexShrink: "0",
                  color: "#587A78",
                  fontFamily: "Quincy CF",
                  fontSize: "14px",
                  fontStyle: "italic",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginBottom: "-70px",
                }}
              >
                The full suite solution for hotel marketing
              </Typography>
              <div className="container">
                  <Box display="flex" flexDirection="row" alignItems="center" gap={2} >
                    <IconMetaSearch />
                    <IconSearchAds />
                    <IconPMax />
                  </Box>
              </div>
              {/*<div className="container">*/}
              {/*  <IconSeo />*/}
              {/*  <IconCms />*/}
              {/*  <IconRateMatch />*/}
              {/*  <IconPushTech />*/}
              {/*</div>*/}
            </div>
          </Grid>
          <Grid item xs={6}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Layout;
