export const COLUMNS_HEADER = [
  { id: 'id',  label:'ID' },
  { id: 'name', label:'Name' },
  { id: 'action',  label:'Action' },
  { id: 'report_type',  label:'Report Type' },
  { id: 'metasearch',  label:'Metasearch' },
  { id: 'created_date',  label:'Created Date' },
  { id: 'frequency',  label:'Frequency' },
  { id: 'status',  label:'Status' },
  { id: 'user', label:'User' }
];


export const TOOLTIP_TEXT = [
  { id: 'id',  label:'ID' },
  { id: 'name', label:'Name' },
  { id: 'action',  label:'Action' },
  { id: 'report_type',  label:'Report Type' },
  { id: 'metasearch',  label:'Metasearch' },
  { id: 'created_date',  label:'Created Date' },
  { id: 'frequency',  label:'Frequency' },
  { id: 'status',  label:'Status' },
  { id: 'user', label:'User' }
  ];