import React, { useState, useEffect } from 'react';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useCallback, useRef } from 'react'
import '@yaireo/tagify/dist/tagify.css'
import '../AlertForm.scss';
import HotelListTable from "../../HotelsListTable";
import SlidingPanel from "../SlidingPanel";
import {useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Icons from "components/DmpIcons/icons";
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
import CustomButtonWithIcon from "../../../../components/CustomButtonWithIcon/Buttons";
import {Chip, Stack} from "@mui/material";

const AlertBaseForm = ({alertData, actionType, onClose }) => {
    
    const { idaccount } = useParams();
    let method = 'POST';
    let apiActionEndpoint = '';
    if(actionType === 'edit') {
        method = 'PUT';
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/edit/${idaccount}/` + alertData.id;
    }
    else {
        apiActionEndpoint = `${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/add/${idaccount}`;
    }
    const jwtToken = sessionStorage.getItem('token');

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [alertName, setAlertName] = useState(alertData?.name || '');
    const [alertRecipients, setAlertalertRecipients] = useState(alertData?.recipients || []);
    const [alertCondition, setAlertCondition] = useState(alertData?.alertCondition || '');
    const [hotelId, setHotelId] = useState(alertData?.hotelId || '');
    const [alertSubject, setAlertSubject] = useState(alertData?.alertSubject || '');
    const [alertLevel, setAlertLevel] = useState(alertData?.alertLevel || '');
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const tagifyRef = useRef(null);

    const [errors, setErrors] = useState({
        alertName: false,
        alertRecipients: false,
        alertCondition: false,
        alertSubject: false,
    });

    const handleHotelSelect = useCallback((selectedHotels) => {
        setSelectedHotels(selectedHotels);
    }, []);

    useEffect(() => {
        if (alertData) {
            setAlertName(alertData.name || '');
            setAlertalertRecipients(alertData.recipients || []);
            setAlertCondition(alertData.alertCondition || '');
            setHotelId(alertData.hotelId || []);
            setAlertSubject(alertData.alertSubject || '');
            setAlertLevel(alertData.alertLevel || '');
        }
    }, [alertData]);


    const submitBudgetGoogleSearchAds = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                onClose();
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                setSnackbarMessage('Error saving alert: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                const newErrors = {
                    alertName: !requestBody.alert_name,
                    alertRecipients: requestBody.alert_recipients.length === 0,
                    alertCondition: !requestBody.alert_condition,
                    alertSubject: !requestBody.alert_subject,
                };
                setErrors(newErrors);
            });
    };

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value); // Parser la chaîne JSON
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value);
        setAlertalertRecipients(updatedRecipients);
    }, []);

    const handleRemoveHotel = (hotelId) => {
        setSelectedHotels(prevHotels => prevHotels.filter(h => h.id !== hotelId));
    };

    useEffect(() => {
        console.log("Updated hotels list after removal:", selectedHotels);
    }, [selectedHotels]);

    const handleSubmit = async () => {
        console.log('Google Budget Alert Data: ' + "Alert name : " + alertName + "Alert Recipients : " + alertRecipients + "Alert Condition : " + alertCondition + "Hotel Id : " + hotelId + "Alert Subject : " + alertSubject + "Alert Level : " + alertLevel)
        const body = {
            alert_name: alertName,
            alert_recipients: alertRecipients,
            alert_condition: alertCondition,
            hotel_id: selectedHotels.map(hotel => hotel.id),
            group_id: [],
            alert_level: "HOTEL",
            alert_subject: alertSubject
        };
        console.log('Alert data: ', JSON.stringify(body));
        submitBudgetGoogleSearchAds(body);
    };

    return (
        <div className="alert-form">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className="top-side">
                <div className="button-container">
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#12794F"
                        titleColor="#373F41"
                        hoverTitleColor={"white"}
                        Icon={Icons.ActionsIconsSVG.AddIconSVG}
                        iconSize={"14px"}
                        width={"120px"}
                        height={"24px"}
                        borderRadius="20px"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={() => setIsSlidingPanelOpen(true)}>
                        Add Hotel
                    </CustomButtonWithIcon>
                </div>
                {selectedHotels.length > 0 && (
                    <div className="selected-hotels">
                        <label>Selected hotels</label>
                        <Stack direction="row" spacing={1} flexWrap="wrap">
                            {selectedHotels.filter(hotel => hotel).map((hotel) => (
                                <Chip
                                    key={hotel.id}
                                    label={hotel.name}
                                    onDelete={() => handleRemoveHotel(hotel.id)}
                                    deleteIcon={<Icons.ActionsIcons.DeleteIcon
                                        style={{ width: "12px", height: "12px" }}
                                    />}
                                    color="primary"
                                    style={{ margin: '4px', fontSize: "12px", fontFamily: "Inter", fontWeight: 400 }}
                                />
                            ))}
                        </Stack>
                    </div>
                )}

                <SlidingPanel size={40} isOpen={isSlidingPanelOpen} onClose={() => setIsSlidingPanelOpen(false)}>
                    <HotelListTable
                        idaccount={idaccount}
                        jwtToken={jwtToken}
                        onHotelSelect={handleHotelSelect}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        onClose={() => setIsSlidingPanelOpen(false)}
                    />
                </SlidingPanel>
            </div>
                <div className="top-side">
                    <TextField
                        placeholder="Alert Name:"
                        variant="standard"
                        value={alertName}
                        onChange={(e) => setAlertName(e.target.value)}
                        required
                        error={errors.alertName}
                        helperText={errors.alertName ? "This field is required" : ""}
                        sx={{
                            '& ::placeholder': { fontSize: '12px' },
                            width: '55%',
                        }}
                    />
                </div>

            <TextField
                placeholder="Alert me when my budget has reached"
                variant="standard"
                type="number"
                value={alertCondition}
                onChange={(e) => setAlertCondition(e.target.value)}
                required
                error={errors.alertCondition}
                helperText={errors.alertCondition ? "This field is required" : ""}
                sx={{
                    '& ::placeholder': { fontSize: '12px' },
                    width: '55%',
                }}
            />
            <TextField
                placeholder="Subject"
                variant="standard"
                value={alertSubject}
                onChange={(e) => setAlertSubject(e.target.value)}
                required
                error={errors.alertSubject}
                helperText={errors.alertSubject ? "This field is required" : ""}
                sx={{
                    '& ::placeholder': { fontSize: '12px' },
                    width: '55%',
                }}
            />

            <div className="bottom-side">
                <label>RECIPIENTS</label>
                <Tags
                    ref={tagifyRef}
                    whitelist={alertRecipients}
                    placeholder='Recipients:'
                    settings={{
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    }}
                    onChange={handleRecipientsChange}
                    defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                    className={errors.alertRecipients ? 'error-tagify' : ''}
                />
                {errors.alertRecipients && <span style={{ color: '#d32f2f', fontSize: '12px' }}>This field is required</span>}
            </div>
            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Alert
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>

        </div>
    );
};

export default AlertBaseForm;
