export const bingBiddingOptionsFormFields = [
  {
    id: "bidStrategy",
    label: "Bid strategy",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "CPC Percentage of Room Price",
        label: "CPC Percentage of Room Price",
        value: "CPC_PERCENTAGE_ROOM_PRICE",
      },
      {
        id: "CPC Fixed bid per night",
        label: "CPC Fixed bid per night",
        value: "CPC_FIXED_BID_PER_NIGHT",
      },
    ],
  },
  {
    id: "baseBid",
    label: "Bid Amount",
    type: "textField",
    placeholder: "Please enter a value",
  },
];
