import React from "react";
import { Box } from "@mui/material";
import CampaignSettingsDetails from "./CampaignSettingsDetails";
import MonthlyBudgetCap from "./MonthlyBudgetCap";
import LanguageSelection from "./LanguageSelection";
import TargetLocations from "./TargetLocations";
import "./CampaignSettingsAccordion.scss";

const CampaignSettingsAccordion = ({
     biddingStrategy,
     setBiddingStrategy,
     campaignName,
     setCampaignName,
     targetCpa,
     setTargetCpa,
     targetRoas,
     setTargetRoas,
     selectedLanguage,
     setSelectedLanguage,
     selectedCountries,
     setSelectedCountries,
     targetValue,
     setTargetValue,
     monthlyBudget,
     setMonthlyBudget,
     errors,
     isEdit = false,
     idaccount,
     idhotel,
     idCampaign = null
}) => {
    return (
        <Box className="pmax-google-form-campaign-settings">
            <CampaignSettingsDetails
                biddingStrategy={biddingStrategy}
                setBiddingStrategy={setBiddingStrategy}
                campaignName={campaignName}
                setCampaignName={setCampaignName}
                targetCpa={targetCpa}
                setTargetCpa={setTargetCpa}
                targetRoas={targetRoas}
                setTargetRoas={setTargetRoas}
                errors={errors}
                idaccount={idaccount}
                idhotel={idhotel}
                idCampaign={idCampaign}
            />

            <MonthlyBudgetCap
                monthlyBudget={monthlyBudget}
                setMonthlyBudget={setMonthlyBudget}
                targetValue={targetValue}
                setTargetValue={setTargetValue}
                errors={errors}
                isEdit={isEdit}
            />

            <LanguageSelection
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                errors={errors}
            />

            <TargetLocations
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
            />
        </Box>
    );
};

export default CampaignSettingsAccordion;
