import React, {useCallback, useEffect, useState} from 'react';
import {Builder, Query, Utils as QbUtils} from '@react-awesome-query-builder/ui';
import {MuiConfig} from '@react-awesome-query-builder/mui';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import "./TemplateForm.scss";
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Icons from "components/DmpIcons/icons";
import {APP_BIDDING_CENTER_API_ENDPOINT} from "../../../constants/api_urls";
import QueryBuilderConfig from "../BiddingRule/Metasearch/QueryBuilderConfig";
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";

const NewTemplateForm = ({ onClose, idplf }) => {
    const [tree, setTree] = useState(null);

    const jwtToken = sessionStorage.getItem('token');
    let apiEndpoint;
    if(idplf === 1){
        apiEndpoint=`${APP_BIDDING_CENTER_API_ENDPOINT}/api/tripadvisor-metrics-bidding-rules`;
    }else if(idplf === 4){
        apiEndpoint=`${APP_BIDDING_CENTER_API_ENDPOINT}/api/google-hotel-ads-metrics-bidding-rules`;
    }else if(idplf === 6){
        apiEndpoint=`${APP_BIDDING_CENTER_API_ENDPOINT}/api/trivago-metrics-bidding-rules`;
    }


    const [templateName, setTemplateName] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const [config, setConfig] = useState(QueryBuilderConfig);

    useEffect(() => {
        console.log("tree initialization");
        const emptyTree = QbUtils.checkTree(QbUtils.loadTree({
            id: QbUtils.uuid(),
            type: 'group',
            properties: {
                conjunction: 'AND',
                not: false,
            }
        }), config);
        console.log("emptyTree : ", emptyTree);
        setTree(emptyTree); // Initialiser un arbre vide avec un opérateur de groupe défini
    }, [config]);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();

                const fields = generateFieldsFromMetrics(result);
                setConfig(prevConfig => ({
                    ...QueryBuilderConfig,
                    fields: fields,
                    settings: {
                        ...prevConfig.settings,
                        showNot: false,
                        canReorder: false,
                        groupOperators: ['AND', 'OR'],
                        forceShowConj: true,
                    },
                }));
            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchMetrics();
    }, [apiEndpoint, jwtToken]);


    const generateFieldsFromMetrics = (metrics) => {
        const fields = {};
        metrics.forEach((metric) => {
            fields[metric.id] = {
                label: metric.label,
                type: 'number',
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
                operators: metric.operators,
                widgets: {
                    number: {
                        operators: metric.operators,
                    }
                }
            };
        });
        console.log('Fields:', fields);
        return fields;
    };

    const submitNewTemplate = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(`${APP_BIDDING_CENTER_API_ENDPOINT}/api/add-template`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                onClose();
            })
            .catch((error) => {
                console.error('Error saving template:', error); // Log the error for debugging
                setSnackbarMessage('Error saving template: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSubmit = useCallback(async () => {
        const sqlFormat = QbUtils.sqlFormat(tree, config);

        const body = {
            template_name: templateName,
            idplf: idplf,
            rules_json: "",
            rules_sql: sqlFormat,
        };

        if (!body.template_name || !body.idplf || !body.rules_sql) {
            setSnackbarMessage("Please fill all required fields (*)");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Please fill all required fields (*)");
        } else {
            console.log('Bidding Rule data : ', JSON.stringify(body));
            submitNewTemplate(body);
        }
    }, [tree, config]);


    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        console.log("Updated Tree:", JSON.stringify(QbUtils.getTree(immutableTree), null, 2));
    };


    return (
        <div className="template-form">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <div className="bidding-rule-template-name-container">
                <div>
                    <label >ADD A NEW TEMPLATE</label>
                </div>
                <div className="bidding-rule-name-row">
                    <TextField
                        placeholder="Template Name:"
                        variant="standard"
                        value={templateName}
                        required
                        sx={{
                            '& ::placeholder':{fontSize:'12px'}
                        }}
                        onChange={(e) => setTemplateName(e.target.value)}
                    />
                </div>
            </div>

            <div className="query-builder-container">
                <label>METRIC ALERT RULES</label>
                <div className="query-builder">
                    {tree && (
                        <Query
                            {...config}
                            value={tree || null}
                            onChange={onChange}
                            renderBuilder={(props) => <Builder {...props} />}
                        />
                    )}
                </div>
            </div>


            <div className="button-container">
                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={handleSubmit}

                >
                    Save Template
                </CustomButtonWithIcon>

                <CustomButtonWithIcon
                    color="#b0acac"
                    hoverColor="#12794F"
                    titleColor="#373F41"
                    hoverTitleColor={"white"}
                    width={"120px"}
                    height={"24px"}
                    borderRadius="20px"
                    textStyle={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14.52px',
                        textAlign: 'center',
                    }}
                    onClick={onClose}

                >
                    Cancel
                </CustomButtonWithIcon>
            </div>
        </div>
    );
};

export default NewTemplateForm;
