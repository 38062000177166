import React, {useState} from 'react';
import {Box, Button, InputAdornment, TextField} from '@mui/material';
import './HotelsList.scss';
import HotelsTable from '../Utils/Table/HotelsTable';
import MoveSelectionModal from '../Utils/Modal/MoveSelectionModal/MoveSelectionModal';
import SearchTableInput from "../../../components/SearchTableInput/SearchTableInput";

const HotelsList = ({
                        mergedHotels,
                        selectedHotelsInThird,
                        setSelectedHotelsInThird,
                        moveHotelsToGroup,
                        selectedGroup,
                        loading,
                        error
                    }) => {
    const [showModal, setShowModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredHotels = mergedHotels.filter(group =>
        group.name && group.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleGroupSelection = () => {
        setShowModal(true);
    };

    const handleCheckboxChange = (hotelId) => {
        setSelectedHotelsInThird((prevSelected) =>
            prevSelected.includes(hotelId)
                ? prevSelected.filter(id => id !== hotelId)
                : [...prevSelected, hotelId]
        );
    };

    const handleConfirmMove = async (selectedOption) => {
        try {
            await moveHotelsToGroup(selectedOption);
            setShowModal(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="hotels-list">
            <Box className="groupTableHeaderTop">
                <h3>Available Hotels ({filteredHotels.length})</h3>
                <SearchTableInput
                    placeholder={"Search"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Box>
            <div className="hotels-table-wrapper">
                <HotelsTable
                    filteredHotels={filteredHotels}
                    selectedHotels={selectedHotelsInThird}
                    handleCheckboxChange={handleCheckboxChange}
                    showGroupName={true}
                    isThirdComponent={true}
                />

                <div style={{display: 'flex', justifyContent: 'flex-start', paddingLeft:'14px',marginBottom:'4px'}}>
                    <Button
                        variant="outlined"
                        onClick={handleGroupSelection}
                        disabled={selectedHotelsInThird.length === 0}
                        className={`${selectedHotelsInThird.length === 0 ? 'disabledGroupBtn' : 'activeGroupBtn'}`}
                    >
                        Add To Group
                    </Button>
                </div>
            </div>

            {/* Move Selection HotelTable */}
            <MoveSelectionModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleConfirmMove}
                groupName={selectedGroup?.name || 'Selected Group'}
                loading={loading}
                error={error}  // Show error from parent
            />
        </div>
    );
};

export default HotelsList;
