import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Table,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./bidPopover.scss";
import CustomButton from "components/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import { api_urls_charts } from "../chartContainer/utils";
import { useSelector } from "react-redux";

const BidPopover = ({ bid }) => {
  const { platform = [] } = useSelector((state) => state?.metaSearch);
  const token = sessionStorage.getItem("token") || "";
  const bidHistoryURL = `${api_urls_charts[platform?.[0]?.id]}${
    bid?.bid_history_path
  }`;

  console.log(bid, "Bidddd");

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [bidEditModal, setbidEditModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (bidEditModal) {
      axios
        .get(bidHistoryURL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res?.data;
          setColumns(data?.columns_list);
          setTableData(data?.history_list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [bid]);

  return (
    <>
      <span>
        <span
          className={"bid-cell"}
          onClick={(e) => {
            e.stopPropagation();
            setbidEditModal(true);
            setAnchorEl(e.currentTarget);
          }}
        >
          {bid?.bid_label}
        </span>
      </span>
      <Popover
        open={bidEditModal}
        anchorEl={anchorEl}
        onClose={() => {
          setbidEditModal(false);
        }}
        PaperProps={{
          sx: {
            position: "relative",
            width: "300px",
            maxHeight: "400px",
            height: "fit-content",
            boxShadow: "0px 5px 8px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            paddingBottom: "12px",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 24px 0px",
          }}
        >
          <span className="dialogTitle">{bid?.bid_edit_in_place_title}</span>
          <span>
            {" "}
            <IconWrapper
              onClick={() => {
                setbidEditModal(false);
              }}
              icon={close}
            />{" "}
          </span>
        </DialogTitle>
        <DialogContent>
          {bid?.bid_editable && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <TextInput
                sx={{
                  width: "100px",
                  height: "22px",
                  borderRadius: "10px",
                  padding: "4px",
                  flexShrink: 0,
                }}
                numberUnit={bid?.bid_symbol || ""}
                placeholder="Enter value"
              />
              <DialogActions
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  flexShrink: 0,
                  marginLeft: "10px",
                }}
              >
                <CustomButton
                  sx={{ width: "80px" }}
                  title="Apply"
                  variant={"filled"}
                  onClick={() => {}}
                />
              </DialogActions>
            </Box>
          )}
          <Table>
            {tableData?.length ? (
              <>
                {" "}
                <tr>
                  {columns
                    ?.filter((c) => c?.column_id !== "status")
                    ?.map((col) => (
                      <th key={col?.column_id}>{col?.column_label}</th>
                    ))}
                </tr>
                {tableData.slice(0, 8).map((item) => {
                  const row = item?.history_data;
                  return (
                    <tr>
                      {columns
                        ?.filter((c) => c?.column_id !== "status")
                        ?.map((col) => (
                          <td>{row?.[col.column_id]}</td>
                        ))}
                    </tr>
                  );
                })}
              </>
            ) : (
              <div className="bidHistoryMessage">No bid history available</div>
            )}
          </Table>
        </DialogContent>
      </Popover>
    </>
  );
};
export default BidPopover;
