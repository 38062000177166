import React from "react";
import { Box } from "@mui/material";
import SelectMenu from "../select_menu";
import CustomSwitch from "../../../../components/Switch";
import { FilledCircleIcon, CircleIcon } from "../../../../assets/images/Icons";

export default function MetricSelector({
    option,
    setOption,
    compareOption,
    setCompareOption,
    menuOptions,
    excludeOptions,
    color,
}) {
    return (
        <Box className="menu">
            <Box className="icon">
                {option === "noMetric" ? (
                    <CircleIcon />
                ) : (
                    <FilledCircleIcon color={color} />
                )}
            </Box>

            <SelectMenu
                options={[
                    {
                        id: "noMetric",
                        label: "No metric",
                    },
                    ...menuOptions?.filter(
                        (item) => !excludeOptions.includes(item?.id)
                    ),
                ]}
                value={option}
                handleChange={(value) => {
                    setOption(value);
                    if (compareOption !== "noMetric") {
                        setCompareOption(value);
                    }
                }}
                action={
                    <Box sx={{ fontSize: 12 }}>
                        <CustomSwitch
                            checked={compareOption !== "noMetric"}
                            disabled={option === "noMetric"}
                            onChange={() => {
                                setCompareOption(
                                    compareOption !== "noMetric" ? "noMetric" : option
                                );
                            }}
                        />
                        Show Compare
                    </Box>
                }
            />
        </Box>
    );
}
