import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import "./EditBudgetForm.scss";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import { MONTHS } from "features/google-search-ads-campaign/constants/months";

export const initialMonthlyBudget = {
  budget_month_1: 0,
  budget_month_2: 0,
  budget_month_3: 0,
  budget_month_4: 0,
  budget_month_5: 0,
  budget_month_6: 0,
  budget_month_7: 0,
  budget_month_8: 0,
  budget_month_9: 0,
  budget_month_10: 0,
  budget_month_11: 0,
  budget_month_12: 0,
};

const EditBudgetForm = ({ row, handleFieldChange, onCampaignLoaded }) => {
  const token = sessionStorage.getItem("token");
  const [selectedRow, setSelectedRow] = useState(row);
  const [budgetName, setBudgetName] = useState("");
  const [dailyBudgetCap, setDailyBudgetCap] = useState(false);
  const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
  const [targetValue, setTargetValue] = useState("");

  useEffect(() => {
    axios
      .get(
        `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/shared-budget/details/?shared_budget_id=${row?.shared_budget_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedRow(res?.data);
        setTargetValue(res?.data?.budget?.default_budget);
        setBudgetName(res?.data?.shared_budget_name);
        setDailyBudgetCap(res?.data?.budget?.is_daily_budget);
        setMonthlyBudget(
          Object.keys(res?.data?.budget)
            .filter((key) => key.includes("month"))
            .reduce((acc, curr) => {
              acc[curr] = res?.data?.budget?.[curr];
              return acc;
            }, {})
        );
        onCampaignLoaded({
          campaign_list: res?.data?.campaign_list,
          campaign_movable: res?.data?.campaign_movable,
        });
      })
      .catch((err) => {
        console.error(err?.message);
      });
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box display="flex" width={"100%"} gap={"20px"}>
        <Box display="flex" flexDirection="column" gap={"8px"}>
          <Typography variant="caption">BUDGET NAME</Typography>
          <CustomOutlinedInput
            sx={{
              minWidth: "300px",
            }}
            inputProps={{
              sx: {
                minHeight: "28px",
                height: "28px",
              },
            }}
            value={budgetName}
            helperText={"Shared Budget Name"}
            onChange={(e) => {
              setBudgetName(e.target.value);
              handleFieldChange("shared_budget_name", e.target.value);
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={"6px"}>
          <Typography variant="caption">HOTEL NAME</Typography>
          <Typography variant="body1">{row?.hotel_name || ""}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box display="flex" flexDirection="column" gap={"8px"}>
          <Typography variant="caption">MONTHLY BUDGET CAP</Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{
              paddingRight: "15px",
            }}
          >
            <CustomOutlinedInput
              placeholder="Enter Monthly Budget Cap"
              value={targetValue}
              onChange={(e) => {
                setTargetValue(e.target.value);
                handleFieldChange("default_budget", Number(e.target.value));
              }}
              className="no-border-focus"
              type="number"
              fullWidth
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              sx={{
                maxWidth: "140px",
              }}
              helperText={"Enter Monthly Budget Cap"}
            />

            <FormControlLabel
              sx={{
                marginLeft: "1px",
                "& .MuiTypography-root": {
                  fontSize: "10px",
                },
              }}
              control={
                <Checkbox
                  size="small"
                  sx={{
                    color: "#BAB7B7",
                    "&.Mui-checked": {
                      color: "#61D5A9",
                    },
                    "& .MuiSvgIcon-root": {
                      height: "14px",
                      width: "14px",
                    },
                  }}
                  onChange={(e) => {
                    setDailyBudgetCap(e.target.checked);
                    handleFieldChange("is_daily_budget", e.target.checked);
                  }}
                  checked={dailyBudgetCap}
                  value={"DEVICE"}
                />
              }
              label="Daily Budget Cap"
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={"8px"}>
          <Typography variant="caption">MONTHLY VARIABLES</Typography>
          <Box
            display="grid"
            gridTemplateColumns={`repeat(${4}, 1fr)`}
            columnGap={"12px"}
            rowGap={"4px"}
          >
            {MONTHS.map((month, idx) => (
              <Box
                key={month}
                display="flex"
                flexDirection="row"
                alignItems="center"
                sx={{
                  gap: 1,
                }}
                className="monthField"
              >
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "right",
                    color: "#373f41",
                    fontSize: "10px",
                    fontFamily: "Inter",
                    fontWeight: 400,
                  }}
                  className="monthLabel"
                >
                  {month}
                </Typography>
                <CustomOutlinedInput
                  minWidth={"60px"}
                  sx={{
                    width: "60px",
                  }}
                  value={monthlyBudget?.[`budget_month_${idx + 1}`] || ""}
                  className="no-border-focus monthInput"
                  type="number"
                  fullWidth
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  onChange={(e) => {
                    setMonthlyBudget((prev) => ({
                      ...prev,
                      [`budget_month_${idx + 1}`]: e.target.value,
                    }));
                    handleFieldChange(
                      `budget_month_${idx + 1}`,
                      Number(e.target.value)
                    );
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditBudgetForm;
