import { createTheme } from "@mui/material";

const palette = {
  primary: {
    main: "#61D5A9",
    dark: "#12794F",
    light: "#CAFFEB",
  },
  grey: {
    100: "#CCC",
  },
};

export const theme = createTheme({
  palette: palette,
  typography: {
    fontFamily: "inter",
  },
});
