import {
  FilledCircleIcon,
  CircleIcon,
  SettingsIcon,
} from "../../../assets/images/Icons";
import { Box } from "@mui/material";
import SelectMenu from "./select_menu";
import { useMemo, useRef, useState } from "react";
import "./chartContainer.scss";
import CustomSwitch from "../../../components/Switch";
import { ExpandCircleDownOutlined } from "@mui/icons-material";
import GraphOptions from "./graph_options";
import { comparitionData, sampleChartData } from "../../../mock/chartData";
import { sampleAxisData } from "../../../mock/chartSampleAxisData";
import BiAxialLineChart from "../../../components/BiAxialLineChart";

export default function ChartContainer({ backgroundColor = "white" }) {
  const menuOptions = [
    {
      id: "spent",
      label: "Spent",
    },
    {
      id: "generated",
      label: "Generated",
    },
    {
      id: "clicks",
      label: "Clicks",
    },
    {
      id: "cpc",
      label: "CPC",
    },
    {
      id: "revenue_perclick",
      label: "Revenue per Click",
    },
    {
      id: "bookingcount",
      label: "Bookings",
    },
    {
      id: "conversion",
      label: "Conversion",
    },
    {
      id: "roi",
      label: "ROAS",
    },
  ];
  const [option1, setOption1] = useState(menuOptions[0].id);
  const [option2, setOption2] = useState(menuOptions[1].id);
  const [option3, setOption3] = useState("noMetric");
  const [option4, setOption4] = useState("noMetric");
  const [compareOption1, setCompareOption1] = useState("noMetric");
  const [compareOption2, setCompareOption2] = useState("noMetric");
  const [compareOption3, setCompareOption3] = useState("noMetric");
  const [compareOption4, setCompareOption4] = useState("noMetric");

  const filterRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [filter, setFilter] = useState("daily");

  const chartData = [];
  const getColors = useMemo(() => {
    const colors = ["#12794F", "#4470C1", "#EA9196", "#46508D"];
    [option1, option2, option3, option4]?.map((i, index) => {
      if (i === "noMetric") {
        colors[index] = null;
      }
    });

    return colors.filter((i) => i !== null);
  }, [option1, option2, option3, option4]);
  Object.entries(sampleChartData[filter])?.forEach((item, index) => {
    const lines = [option1, option2, option3, option4]
      .filter((op) => op !== "noMetric")
      ?.reduce((acc, i) => {
        return (acc = {
          ...acc,
          [i]: sampleChartData?.[filter]?.[i]?.[index] ?? undefined,
        });
      }, {});
    const lines2 = [
      compareOption1,
      compareOption2,
      compareOption3,
      compareOption4,
    ]
      .filter((op) => op !== "noMetric")
      ?.reduce((acc, i) => {
        const key = "compare" + i;
        return (acc = {
          ...acc,
          [key]: comparitionData?.[filter]?.[i]?.[index] ?? undefined,
        });
      }, {});
    if (Object.values(lines).every((i) => i === undefined)) {
      return;
    }
    chartData.push({
      name: sampleAxisData[filter][index],
      ...lines,
      ...lines2,
    });
  });

  return (
    <>
      <Box
        sx={{
          marginTop: "21px",
          backgroundColor: backgroundColor,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBlock: "10px",
          }}
        >
          {isChartCollapsed ? (
            <div className="quency-cf-normal title"> Graph </div>
          ) : (
            ""
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-end",
              marginLeft: "auto",
            }}
          >
            <Box className="menu">
              <Box className="icon">
                {option1 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon color={"#12794F"} />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option2 &&
                      item?.id !== option3 &&
                      item?.id !== option4
                  ),
                ]}
                value={option1}
                handleChange={(v) => {
                  setOption1(v);
                  if (compareOption1 !== "noMetric") {
                    setCompareOption1(v);
                  }
                }}
                action={
                  <div>
                    {" "}
                    <Box sx={{ fontSize: 12 }}>
                      {" "}
                      <CustomSwitch
                        checked={compareOption1 !== "noMetric"}
                        disabled={option1 === "noMetric"}
                        onChange={() => {
                          if (compareOption1 !== "noMetric") {
                            setCompareOption1("noMetric");
                          } else {
                            setCompareOption1(option1);
                          }
                        }}
                      />{" "}
                      Show Compare{" "}
                    </Box>
                  </div>
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option2 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon color={"#4470C1"} />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option1 &&
                      item?.id !== option3 &&
                      item?.id !== option4
                  ),
                ]}
                value={option2}
                handleChange={(v) => {
                  setOption2(v);
                  if (compareOption2 !== "noMetric") {
                    setCompareOption2(v);
                  }
                }}
                action={
                  <>
                    <Box sx={{ fontSize: 12 }}>
                      {" "}
                      <CustomSwitch
                        checked={compareOption2 !== "noMetric"}
                        disabled={option2 === "noMetric"}
                        onChange={() => {
                          if (compareOption2 !== "noMetric") {
                            setCompareOption2("noMetric");
                          } else {
                            setCompareOption2(option2);
                          }
                        }}
                      />{" "}
                      Show Compare{" "}
                    </Box>
                  </>
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option3 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon
                    color={option3 === "noMetric" ? "transparent" : "#EA9196"}
                  />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option1 &&
                      item?.id !== option2 &&
                      item?.id !== option4
                  ),
                ]}
                value={option3}
                handleChange={(v) => {
                  setOption3(v);
                  if (compareOption3 !== "noMetric") {
                    setCompareOption3(v);
                  }
                }}
                action={
                  <>
                    <Box sx={{ fontSize: 12 }}>
                      {" "}
                      <CustomSwitch
                        checked={compareOption3 !== "noMetric"}
                        disabled={option3 === "noMetric"}
                        onChange={() => {
                          if (compareOption3 !== "noMetric") {
                            setCompareOption3("noMetric");
                          } else {
                            setCompareOption3(option3);
                          }
                        }}
                      />{" "}
                      Show Compare{" "}
                    </Box>
                  </>
                }
              />
            </Box>
            <Box className="menu">
              <Box className="icon">
                {option4 === "noMetric" ? (
                  <CircleIcon />
                ) : (
                  <FilledCircleIcon
                    color={option4 === "noMetric" ? "transparent" : "#46508D"}
                  />
                )}
              </Box>

              <SelectMenu
                options={[
                  {
                    id: "noMetric",
                    label: "No metric",
                  },
                  ...menuOptions?.filter(
                    (item) =>
                      item?.id !== option2 &&
                      item?.id !== option3 &&
                      item?.id !== option1
                  ),
                ]}
                value={option4}
                handleChange={(v) => {
                  setOption4(v);
                  if (compareOption4 !== "noMetric") {
                    setCompareOption4(v);
                  }
                }}
                action={
                  <>
                    <Box sx={{ fontSize: 12 }}>
                      {" "}
                      <CustomSwitch
                        checked={compareOption4 !== "noMetric"}
                        disabled={option4 === "noMetric"}
                        onChange={() => {
                          if (compareOption4 !== "noMetric") {
                            setCompareOption4("noMetric");
                          } else {
                            setCompareOption4(option4);
                          }
                        }}
                      />{" "}
                      Show Compare{" "}
                    </Box>
                  </>
                }
              />
            </Box>

            <Box className="filters">
              <Box
                onClick={() => {
                  setIsFilterMenuOpen(!isFilterMenuOpen);
                }}
                ref={filterRef}
              >
                <SettingsIcon color="#12794F" />
                <GraphOptions
                  open={isFilterMenuOpen}
                  anchorEl={filterRef}
                  onClose={() => {
                    setIsFilterMenuOpen(false);
                  }}
                  onChange={(value) => {
                    setFilter(value);
                  }}
                />
              </Box>
            </Box>


          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "197px",
            display: isChartCollapsed ? "none" : "block",
          }}
        >
          <BiAxialLineChart data={chartData} colors={getColors} />
        </Box>
      </Box>
    </>
  );
}
