const generateDateRange = (startDateOffsetYears, endDateOffsetYears) => {
    const currentDate = new Date();
    const startDate = new Date();
    const endDate = new Date();

    // Set start date based on the offset years
    startDate.setFullYear(currentDate.getFullYear() + startDateOffsetYears);
    // Set end date based on the offset years
    endDate.setFullYear(currentDate.getFullYear() + endDateOffsetYears);

    const dateRange = [];

    // Iterate from the end date to the start date (descending order)
    while (endDate >= startDate) {
        const year = endDate.getFullYear();
        const month = String(endDate.getMonth() + 1).padStart(2, '0'); // Ensure month is two digits
        const monthName = endDate.toLocaleString('default', { month: 'short' });
        const date_label = `${year}-${monthName}`;
        const date_value = `${year}-${month}`;
        dateRange.push({ label: date_label, value: date_value });

        // Move to the previous month
        endDate.setMonth(endDate.getMonth() - 1);
    }

    // Add 'Any' as the first option
    dateRange.unshift({ label: 'Any', value: '' });

    return dateRange;
};

export const BOOKING_DATE_RANGE = generateDateRange(-2, 0);  // 2 years ago to current date
export const CHECKOUT_DATE_RANGE = generateDateRange(-1, 1); // 1 years ago to 1 year future date
export const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};