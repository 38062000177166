export const trivagoBiddingRulesFormFields = [
  {
    id: "bidding_rule_id",
    label: "Bidding Rules",
    type: "select",
    placeholder: "SELECT A BIDDING RULE",
    options: [
      {
        id: "0",
        label: "No bidding rule",
        value: "No bidding rule",
      },
    ],
  },
];
