import { Grid } from "@mui/material";
import Table from "components/Table";
import GoogleAds from "assets/images/GoogleHotelAds.svg";
import TripAdvisor from "assets/images/TripAdvisor.svg";
import Trivago from "assets/images/Trivago.svg";
import SkyScanner from "assets/images/SkyScanner.svg";
import Kayak from "assets/images/Kayak.svg";
import BingAds from "assets/images/BingAds.svg";
import { useEffect, useState } from "react";
import TextInput from "components/TextInput";
import BudgetCapModal from "../budgetCapModal";
import TableValueEditor from "../tableValueEditor";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import "./TableContainer.scss";
import BidPopover from "../BidPopover/BidPopover";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";

const TableCellImage = ({ channelId, cell }) => {
  const ChannelImageMapper = {
    googleHotelAds: GoogleAds,
    tripadvisor: TripAdvisor,
    trivago: Trivago,
    skyscanner: SkyScanner,
    kayak: Kayak,
    bingAds: BingAds,
  };
  if (Object.keys(ChannelImageMapper).find((ch) => ch === channelId)) {
    return <img src={ChannelImageMapper[channelId]} alt="" />;
  }
  return channelId;
};
const KeywordsTableContainer = ({
  isChartCollapsed,
  setIsChartCollapsed,
  onEditClick,
}) => {
  const [openMonthlyBudgetCapModal, setOpenMonthlyBudgetCapModal] =
    useState(false); //test1
  const [selectedBudgetRow, setSelectedBudgetRow] = useState();
  const [bidEditModal, setbidEditModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(false);
  const [paginationObject, setPaginationObject] = useState({});
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortingState, setSortingState] = useState([]);
  const [loading, setLoading] = useState(true);

  const token = sessionStorage.getItem("token") || "";
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const { currentDateRange = {} } = useSelector((state) => state?.calender);
  const {
    level = "",
    hotel_id = [],
    segment_list = [],
    campaign = [],
  } = useSelector((state) => state?.searchAds);

  useEffect(() => {
    const params = {
      account_id,
      level,
      "campaign_id[]": campaign?.map((c) => c.id),
      "segment_list[]": segment_list,
      start_date: format(
        currentDateRange?.startDate || new Date(),
        "yyyy-MM-dd"
      ),
      end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
      "hotel_id[]": hotel_id,
    };

    if (account_id) {
      setLoading(true);
      axios
        .get(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/keywords/reporting/?${createSearchParams(
            params
          )}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          const sortedColumns = [
            ...data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type === "SEGMENT"
            ),
            ...data?.columns?.filter(
              (col) => col?.type !== "METRICS" && col?.type !== "SEGMENT"
            ),
            ...data?.columns?.filter((col) => col?.type === "METRICS"),
          ];
          const mappedColumns = sortedColumns?.map((col, index) => {
            let renderCell = ({ cell }) => (
              <span key={index}>{cell.getValue()}</span>
            );

            if (col?.label === "Budget") {
              renderCell = ({ row }) => {
                return (
                  <>
                    {row?.original?.budget?.budget_editable && (
                      <span key={row.id}>
                        <TextInput
                          sx={{ width: "69px", fontSize: "10px" }}
                          numberUnit=""
                          value={row?.original?.budget?.budget_value}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedBudgetRow(row?.original);
                            setOpenMonthlyBudgetCapModal(true);
                          }}
                        />
                      </span>
                    )}
                  </>
                );
              };
            }

            if (col?.label === "Bids" || col?.label === "Base Bids") {
              renderCell = ({ row }) => {
                const updateBidData = row?.original?.bid;

                return <BidPopover bid={updateBidData} />;
              };
            }
            return {
              id: index.toString(),
              accessorKey: col.id,
              header: col?.label,
              Cell: renderCell,
              isSelected: true,
              type: col?.type,
            };
          });

          const formatRows = (row, id) => {
            const metricsRows = Object.keys(row?.metrics_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.metrics_list?.[curr]?.value ?? "-",
                };
              },
              {}
            );

            const segmentsRows = Object.keys(row?.segment_list || {}).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.segment_list?.[curr]?.label ?? "-",
                  [curr + "_id"]: row?.segment_list?.[curr]?.value ?? "-",
                };
              },
              {}
            );

            return {
              id,
              ...segmentsRows,
              ...metricsRows,
              bid: row?.bid,
              budget: row?.budget,
              budget_spent: "-",
            };
          };

          const rowsToDisplay = data?.row_list?.map((row, ind) =>
            formatRows(row, ind + 1)
          );

          setColumns(mappedColumns);
          setLoading(false);
          setSelectedColumns(mappedColumns);
          setTableData([
            {
              [mappedColumns?.[0]?.accessorKey]: "TOTAL",
              ...formatRows(data?.total_row, "0"),
            },
            ...rowsToDisplay,
          ]);
        });
    }
  }, [
    JSON.stringify(campaign),
    token,
    account_id,
    JSON.stringify(currentDateRange),
    level,
    JSON.stringify(sortingState),
    JSON.stringify(segment_list),
    pageSize,
    pageIndex,
    JSON.stringify(hotel_id),
  ]);

  return (
    <>
      {bidEditModal && (
        <TableValueEditor
          open={bidEditModal}
          bid={selectedBudgetRow?.bid}
          onClose={() => setbidEditModal(false)}
        />
      )}
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%" }}>
        <Table
          page="searchAds"
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={isChartCollapsed ? "calc(100vh - 255px)" : "355px"}
          onEditClick={() => {
            onEditClick();
          }}
          paginationData={paginationObject}
          isPaginationEnabled={isPaginationEnabled}
          handlePagination={(pagination) => {
            setPageIndex(pagination?.pageIndex);
            setPageSize(pagination?.pageSize);
          }}
          initialPageSize={pageSize}
          initialPageIndex={pageIndex}
          handleSorting={(sorting) => {
            setSortingState(sorting);
          }}
          isLoading={loading}
        />
      </Grid>
      {openMonthlyBudgetCapModal && (
        <BudgetCapModal
          open={openMonthlyBudgetCapModal}
          budget={selectedBudgetRow?.budget}
          onClose={() => {
            setSelectedBudgetRow(null);
            setOpenMonthlyBudgetCapModal(false);
          }}
        />
      )}
    </>
  );
};

export default KeywordsTableContainer;
