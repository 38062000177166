export const kayakBiddingOptionsFormFields = [
  {
    id: "bidding_type_desktop",
    label: "Bid Strategy (Desktop)",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No Bid",
        label: "No Bid",
        value: "NO_BID",
      },
      {
        id: "Auto Bid 2nd Pos",
        label: "Auto Bid 2nd Pos",
        value: "AUTO_BID_2ND_POS",
      },
      {
        id: "Minimum Bid",
        label: "Minimum Bid",
        value: "MIN_BID",
      },
      {
        id: "Minimum Bid + Extra Bid",
        label: "Minimum Bid + Extra Bid",
        value: "MIN_BID_EXTRA_BID",
      },
      {
        id: "Minimum Bid To Be Above Threshold",
        label: "Minimum Bid To Be Above Threshold",
        value: "MINIMUM_BID_TO_BE_ABOVE_THRESHOLD",
      },
      {
        id: "75% Bid To be Above Threshold",
        label: "75% Bid To be Above Threshold",
        value: "BID_TO_BE_ABOVE_THRESHOLD_75",
      },
      {
        id: "Minimum Bid To Be At Select Button",
        label: "Minimum Bid To Be At Select Button",
        value: "MINIMUM_BID_TO_BE_AT_SELECT_BUTTON",
      },
      {
        id: "75% Bid To Be At Select Button",
        label: "75% Bid To Be At Select Button",
        value: "MINIMUM_BID_TO_BE_ABOVE_THRESHOLD",
      },
      {
        id: "75% Bid To Be Top Core Provider",
        label: "75% Bid To Be Top Core Provider",
        value: "BID_TO_BE_TOP_CORE_PROVIDER_75",
      },

      {
        id: "Minimum Bid To Be Top Core Provider",
        label: "Minimum Bid To Be Top Core Provider",
        value: "MINIMUM_BID_TO_BE_TOP_CORE_PROVIDER",
      },
    ],
  },
  {
    id: "bidding_value_desktop",
    label: "Bid Amount(Desktop)",
    type: "textField",
    placeholder: "Please enter a value",
    option: [],
  },
  {
    id: "bidding_type_mobile",
    label: "Bid Strategy (Mobile)",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No Bid",
        label: "No Bid",
        value: "NO_BID",
      },
      {
        id: "Auto Bid 2nd Pos",
        label: "Auto Bid 2nd Pos",
        value: "AUTO_BID_2ND_POS",
      },
      {
        id: "Minimum Bid",
        label: "Minimum Bid",
        value: "MIN_BID",
      },
      {
        id: "Minimum Bid + Extra Bid",
        label: "Minimum Bid + Extra Bid",
        value: "MIN_BID_EXTRA_BID",
      },
      {
        id: "Minimum Bid To Be Above Threshold",
        label: "Minimum Bid To Be Above Threshold",
        value: "MINIMUM_BID_TO_BE_ABOVE_THRESHOLD",
      },
      {
        id: "75% Bid To be Above Threshold",
        label: "75% Bid To be Above Threshold",
        value: "BID_TO_BE_ABOVE_THRESHOLD_75",
      },
      {
        id: "Minimum Bid To Be At Select Button",
        label: "Minimum Bid To Be At Select Button",
        value: "MINIMUM_BID_TO_BE_AT_SELECT_BUTTON",
      },
      {
        id: "75% Bid To Be At Select Button",
        label: "75% Bid To Be At Select Button",
        value: "MINIMUM_BID_TO_BE_ABOVE_THRESHOLD",
      },
      {
        id: "75% Bid To Be Top Core Provider",
        label: "75% Bid To Be Top Core Provider",
        value: "BID_TO_BE_TOP_CORE_PROVIDER_75",
      },

      {
        id: "Minimum Bid To Be Top Core Provider",
        label: "Minimum Bid To Be Top Core Provider",
        value: "MINIMUM_BID_TO_BE_TOP_CORE_PROVIDER",
      },
    ],
  },
  {
    id: "bidding_value_mobile",
    label: "Bid Amount(Mobile)",
    type: "textField",
    placeholder: "Please enter a value",
    option: [],
  },
];
export const COMMONFORMFIELDS = [
  {
    id: "hotel",
    label: "Hotel",
    type: "multiSelect",
    placeholder: "Select a hotel",
  },

  {
    id: "market",
    label: "Market",
    type: "multiSelect",
    placeholder: "Select a value",
  },
];
