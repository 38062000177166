import {BOOKING_DATE_RANGE, CHECKOUT_DATE_RANGE} from "features/reservations/reservationDateRange";
import {PLATFORM_OPTIONS,BILLING_MODE_OPTIONS,BOOKING_STATUS_OPTIONS} from "features/reservations/reservationFilterOptions";
export const reservationFilterOptions = [
    {
        id: "idPlatForm",
        label: "Metasearch",
        type: "select",
        placeholder: "Select Platform",
        options: [...PLATFORM_OPTIONS]
    },
    {
        id: "bookingMonth",
        label: "Booking Month",
        type: "select",
        placeholder: "Select Month",
        options: [...BOOKING_DATE_RANGE]
    },
    {
        id: "checkOutMonth",
        label: "Check-out Month",
        type: "select",
        placeholder: "Select Month",
        options: [...CHECKOUT_DATE_RANGE]
    },
    {
        id: "bookingStatus",
        label: "Status",
        type: "select",
        placeholder: "Status",
        options: [...BOOKING_STATUS_OPTIONS]
    },
    {
        id: "billingMode",
        label: "Billing",
        type: "select",
        placeholder: "Select Mode",
        options: [...BILLING_MODE_OPTIONS]
    },
];