export const RESERVATION_COLUMNS = [
  { id: 'name_hotel', label: 'Hotel Name' },
  { id: 'code_resa', label: 'Reservation' },
  { id: 'metaclick_datetime', label: 'Last Click' },
  { id: 'booking_datetime', label: 'Booking' },
  { id: 'check_in_date', label: 'Check-In', editable: true },
  { id: 'check_out_date', label: 'Check-Out', editable: true },
  { id: 'nights', label: 'Nights' },
  { id: 'amount', label: 'Amount', editable: true },
  { id: 'amount_account_currency', label: 'Amount' },
  { id: 'booking_status', label: 'Status', editable: true },
  { id: 'metasearch', label: 'Metasearch' },
  { id: 'ads_type', label: 'Ads Type' },
  { id: 'billing_mode', label: 'Billing mode' },
  { id: 'meta_commission_rate', label: 'Commission' },
  { id: 'market_code', label: 'Market' },
];


export const TOOLTIP_TEXT = [
  { id: 'name_hotel', label: 'Hotel Name' },
  { id: 'code_resa', label: 'Code of reservation' },
  { id: 'metaclick_datetime', label: 'Date of the click on the metasearch that leads to the reservation(when available)' },
  { id: 'booking_datetime', label: 'Date of booking' },
  { id: 'check_in_date', label: 'Arrival date' },
  { id: 'check_out_date', label: 'Departure date'},
  { id: 'nights', label: 'Length of stay' },
  { id: 'amount', label: 'Amount of the reservation in reservation currency'},
  { id: 'amount_account_currency', label: 'Amount of reservation in account currency' },
  { id: 'booking_status', label: 'Status of reservation'},
  { id: 'metasearch', label: 'Name of the metasearch that leads to the reservation' },
  { id: 'ads_type', label: 'Type of ad' },
  { id: 'billing_mode', label: 'Billing mode' },
  { id: 'meta_commission_rate', label: 'Metasearch Commission(Excluding any WIHP commitions)' },
  { id: 'market_code', label: 'Country code the market that leads to the reservation' },
];