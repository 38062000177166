import "./AccountDropdown.scss";
import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Menu, TextField} from "@mui/material";
import {APP_ACCOUNT_API_ENDPOINT} from "../../../constants/api_urls";
import axios from "axios";
import DownArrowBig from "assets/images/DownArrowBig.svg";
import {useDispatch, useSelector} from "react-redux";
import {updateAccount} from "store/slices/account/accountSlice";
import {useNavigate, useLocation, createSearchParams} from "react-router-dom";
import querystring from "query-string";
import {useEffect, useState} from "react";

export default function AccountDropdown({label}) {
    const selectedAccount = useSelector((state) => state?.account?.account || {});
    const [selectedValues, setSelectedValues] = useState(null);
    const [hasUserSelected, setHasUserSelected] = useState(false);
    const [accounts, setAccounts] = React.useState([]);
    const [searchedAccounts, setSearchedAccounts] = React.useState([]);
    const token = sessionStorage.getItem("token") || "";
    const navigate = useNavigate();
    const {search, pathname} = useLocation();

    const dispatch = useDispatch();
    React.useEffect(() => {
        axios
            .get(`${APP_ACCOUNT_API_ENDPOINT}/api/accounts/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setAccounts(res.data.data);
                setSearchedAccounts(res.data.data);
                console.log("Res", res);
            });
    }, []);

    React.useEffect(() => {
        const searchedAccountId = querystring.parse(search)?.account_id;
        if (searchedAccountId && searchedAccountId !== "undefined") {
            console.log(
                typeof searchedAccountId,
                searchedAccountId && searchedAccountId !== "undefined",
                "Id"
            );
            const searchedAccount = accounts?.find(
                (ac) => ac.account_id == searchedAccountId
            );

            if (searchedAccount) {
                setSelectedValues(searchedAccount);
                dispatch(updateAccount(searchedAccount));
            }
        }
    }, [search, accounts]);

    React.useEffect(() => {
        console.log(selectedAccount, "Selected Account");
        if (Object.keys(selectedAccount).length) {
            setSelectedValues(selectedAccount);
            navigate({
                pathname,
                search: createSearchParams({
                    account_id: selectedAccount.account_id,
                }).toString(),
            });
        }
    }, [selectedAccount]);

    const handleSearch = (e) => {
        const search = e.target.value.toLowerCase();
        if (search === "a" || search === "A") {
            e.preventDefault();
        }
        if (search.length === 0) {
            setSearchedAccounts(accounts);
            return;
        }

        const tempData = [...accounts];
        setSearchedAccounts(
            tempData?.filter((item) => {
                const accountId = item?.account_id?.toString().toLowerCase();
                const accountName = item?.account_name?.toString().toLowerCase();
                console.log(item);
                if (accountId?.includes(search) || accountName?.includes(search)) {
                    return true;
                }
                return false;
            })
        );
    };
    const [isOpen, setIsOpen] = React.useState(false);
    const accountRef = React.useRef(null);
    const [hoveredItem, setHoveredItem] = useState("");

    useEffect(() => {
        if (searchedAccounts.length > 0 && !hasUserSelected && !selectedAccount) {
            const lastAccount = searchedAccounts[searchedAccounts.length - 1];
            setSelectedValues(lastAccount);
            setHasUserSelected(true);
            dispatch(updateAccount(lastAccount));
            navigate({
                pathname,
                search: createSearchParams({
                    account_id: lastAccount.account_id,
                }).toString(),
            });
        }
    }, [searchedAccounts, hasUserSelected]);

    return (
        <Box sx={{minWidth: 120}} className={"account"}>
            <FormControl sx={{minWidth: 170}} className="accountDropdown">
                <div
                    className="selected"
                    ref={accountRef}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {" "}
                    <div className="selected-label"
                         onMouseEnter={() => setHoveredItem('accountDropDown')}
                         onMouseLeave={() => setHoveredItem("")}
                    >
                        <div className="accountHoverState"
                             style={{backgroundColor: hoveredItem === 'accountDropDown'? "#50B498" : "#50C498"}}>
                            <div style={{paddingLeft:'10px'}}>
                                <img width="10px" height="9px" src={DownArrowBig} style={{margin: "0 9px 0px 0"}}/>
                                {selectedValues?.account_name ?? "Account"}
                            </div>
                        </div>

                    </div>
                    {" "}
                </div>
                <Menu
                    labelId="select-label"
                    open={isOpen}
                    id="menu"
                    anchorEl={accountRef.current}
                    value={selectedValues}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    IconComponent={(props) => <img src={DownArrowBig} {...props} />}
                    PaperProps={{
                        sx: {
                            marginTop: "-25px",
                            minWidth: 190,
                            width:414,
                            borderRadius: "12px",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            color: "#000000",
                            fontFamily: "Inter",
                        },
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >

                    <div aria-disabled className="accountHoverState" style={{backgroundColor: "#fff", marginBottom:'10px'}}>
                        <div style={{paddingLeft: '10px', fontSize:'14px',fontWeight:'500'}}>
                            <img width="10px" height="9px" src={DownArrowBig} style={{margin: "0 9px 0px 0"}}/>
                            {selectedValues?.account_name ?? "Account"}
                        </div>
                    </div>

                    <TextField
                        sx={{
                            "& ::placeholder": {
                                fontSize: '10px'
                            },
                        }}
                        variant="standard"
                        onChange={(e) => {
                            handleSearch(e);
                        }}
                        placeholder="Search"
                        className="select-search"
                        inputProps={{
                            autoComplete: "off",
                        }}
                    />
                    <Box
                        sx={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            marginRight: "5px",
                            overflowX: "hidden",
                        }}
                    >
                        {[
                            ...searchedAccounts,
                        ]?.map((account) => {
                            return (
                                <MenuItem
                                    sx={{fontSize: "12px", fontWeight:'500',fontFamily:'inter',color:'#000000'}}
                                    value={account.account_id}
                                    onClick={(e) => {
                                        setSelectedValues(account);
                                        setHasUserSelected(true);
                                        dispatch(updateAccount(account));
                                        navigate({
                                            pathname,
                                            search: createSearchParams({
                                                account_id: account.account_id,
                                            }).toString(),
                                        });
                                        setIsOpen(false);
                                    }}
                                >
                                    {" "}
                                    {account.account_name}{" "}
                                </MenuItem>
                            );
                        })}
                    </Box>
                </Menu>
            </FormControl>
        </Box>
    );
}
