export const trivagoBiddingOptionsFormFields = [
  {
    id: "bidStrategy",
    label: "Bid strategy",
    type: "select",
    placeholder: "Select a strategy",
    options: [
      {
        id: "No Bid",
        label: "No Bid",
        value: "NO_BID",
      },
      {
        id: "Manual Bid",
        label: "Manual Bid",
        value: "MANUAL_BID",
      },
    ],
  },
  {
    id: "baseBid",
    label: "Bid Amount",
    type: "textField",
    placeholder: "Please enter a value",
    option: [],
  },
];
export const COMMONFORMFIELDS = [
  {
    id: "hotel",
    label: "Hotel",
    type: "multiSelect",
    placeholder: "Select a hotel",
  },

  {
    id: "market",
    label: "Market",
    type: "multiSelect",
    placeholder: "Select a value",
  },
];
