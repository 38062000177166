import {Box} from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import React, {useState, useEffect} from "react";
import BiddingList from "../../features/bidding-center/BiddingList/biddingList";
import BiddingSelectionForm from "../../features/bidding-center/BiddingSelectionForm/BiddingSelectionForm";
import axios from "axios";
import {useParams} from "react-router-dom";
import Icons from "components/DmpIcons/icons";
import CustomButtonWithIcon from "../../components/CustomButtonWithIcon/Buttons";
import PageTitles from "../../components/PageTitles";
import {APP_BIDDING_CENTER_API_ENDPOINT} from "../../constants/api_urls";


const BiddingCenter = () => {
    const [openBiddingRuleSelectionPanel, setOpenBiddingRuleSelectionPanel] = useState(false);
    const [biddingRules, setBiddingRules] = useState([]);
    const [panelTitle, setPanelTitle] = useState("New Bidding Rule");
    const [action, setAction] = useState(null);

    const { idaccount } = useParams();
    console.log("ID Account:", idaccount);
    console.log("API Base URL:", `${APP_BIDDING_CENTER_API_ENDPOINT}/api/bidding-rules/${idaccount}`);
    const token = sessionStorage.getItem('token');

    const updatePanelTitle = (title) => {
        setPanelTitle(title);
    }
    useEffect(() => {
        document.body.classList.add('bidding-center-page');

        const fetchBiddingRules = async () => {
            try {
                const response = await axios.get(`${APP_BIDDING_CENTER_API_ENDPOINT}/api/bidding-rules/${idaccount}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setBiddingRules(response.data);
                console.log('Bidding Rules:', response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des règles de soumission:', error);
            }
        };

        fetchBiddingRules();
        return () => {
            document.body.classList.remove('bidding-center-page');
        };
    }, []);

    useEffect(() => {
        console.log("La valeur de openBiddingRuleSelectionPanel a changé:", openBiddingRuleSelectionPanel);
    }, [openBiddingRuleSelectionPanel]);

    return (
        <DefaultLayout>
            <PageTitles pageTitle="Metasearch" pageSubTitle="Bidding Center" />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: 2,
                        paddingRight: "16px"
                    }}
                >
                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#61D5A9"
                        width="120px"
                        height="30px"
                        titleColor="#373F41"
                        borderRadius="20px"
                        title=""
                        icon={Icons.ActionsIcons.AddIconBlack}
                        iconBorder="0.5px solid #373F41"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={() => {
                            setAction("rule-action");
                            setOpenBiddingRuleSelectionPanel(true);
                            updatePanelTitle("New Bidding Rule");
                        }}
                    >
                        Bidding Rule
                    </CustomButtonWithIcon>

                    <CustomButtonWithIcon
                        color="#b0acac"
                        hoverColor="#61D5A9"
                        width="120px"
                        height="30px"
                        titleColor="#373F41"
                        borderRadius="20px"
                        title=""
                        icon={Icons.ActionsIcons.AddIconBlack}
                        iconBorder="0.5px solid #373F41"
                        textStyle={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '14.52px',
                            textAlign: 'center',
                        }}
                        onClick={() => {
                            setAction("template-action");
                            setOpenBiddingRuleSelectionPanel(true);
                            updatePanelTitle("New Template");
                        }}
                    >
                        Template
                    </CustomButtonWithIcon>


            </Box>

            <BiddingList biddingRules={biddingRules} />

            <BiddingSelectionForm
                title={panelTitle}
                isOpen={openBiddingRuleSelectionPanel}
                onClose={() => setOpenBiddingRuleSelectionPanel(false)}
                action={action}
            />
        </DefaultLayout>
    );
};

export default BiddingCenter;
