import React, {useState} from "react";
import {Box, Button, Snackbar} from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import AddIcon from "@mui/icons-material/Add";
import PageTitles from "../../components/PageTitles";
import {useNavigate, useParams} from "react-router-dom";
import GooglePMaxSlideOutPanel from "../../features/google-pmax-campaign/GooglePMaxSlideOutPanel";
import {Alert} from "@mui/lab";

const PMaxCampaignManager = () => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const { idaccount } = useParams();
    const idhotel = 185167; // TODO : change when panel with graph done
    const navigate = useNavigate();
    const [selectedCampaignId, setSelectedCampaignId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [message, setMessage] = useState("");

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const triggerAlert = (type, msg) => {
        setAlertSeverity(type);
        setMessage(msg);
        setSnackbarOpen(true);
    };

    const handleCreateCampaign = () => {
        navigate(`/pmax-campaign-manager/create/${idaccount}/${idhotel}`);
    };

    const handleEditCampaign = (campaignId) => {
        setSelectedCampaignId(campaignId);
        setPanelOpen(true);
    };

    const togglePanel = () => {
        setPanelOpen((prev) => !prev);
    };

    return (
        <DefaultLayout>
            <PageTitles
                pageTitle="PMax"
                pageSubTitle="Campaign Manager"
            />
            <Box className="searchAdsContainer">
                <Box className="headerContainer">
                    <Button
                        onClick={() => handleEditCampaign(3)}  // Simulate selecting a campaign, TODO change when graph is here
                        variant="contained"
                        className="editCampaignButton"
                    >
                        <AddIcon fontSize="small" />
                        Edit Pmax test Campaign
                    </Button>
                    <Button
                        onClick={handleCreateCampaign}
                        variant="contained"
                        className="newCampaignButton"
                    >
                        <AddIcon fontSize="small" />
                        Add New PMAX Campaign
                    </Button>
                </Box>
            </Box>
            <GooglePMaxSlideOutPanel
                isOpen={isPanelOpen}
                onClose={togglePanel}
                idAccount={idaccount}
                idHotel={idhotel}
                idCampaign={selectedCampaignId}
                triggerAlert={triggerAlert}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </DefaultLayout>
    );
};

export default PMaxCampaignManager;
