import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import TopHotelsTable from "./TopHotelsTable";
import TopHotelsChart from "./TopHotelsChart";
import { TopHotelsOptions } from "../Utils/Options";
import { TopHotelsAvailableMetrics } from "../Utils/AvailableMetrics";
import { COLORS } from "../Utils/Colors";
import "./TopHotelsComponent.scss";

function TopHotelsComponent({ selectedOption, handleDropdownChange, topHotelsMetrics = {} }) {
    const [tableMetrics, setTableMetrics] = useState(["clicks_kpi", "generated_kpi", "roas_kpi"]);
    const [lastOption, setLastOption] = useState("clicks");

    const handleTableMetricChange = (event, index) => {
        const newMetric = event.target.value;
        const newMetrics = [...tableMetrics];
        newMetrics[index] = newMetric;
        setTableMetrics(newMetrics);
    };

    const formatValue = (metric, hotel) => {
        if (["cost_kpi", "generated_kpi", "cpc_kpi"].includes(metric)) {
            return `${topHotelsMetrics.currency_symbol || "€"} ${hotel[metric] || 0}`;
        }
        return hotel[metric] || 0;
    };

    const selectedHotels = topHotelsMetrics[`top_hotel_by_${lastOption}`] || [];

    const getDomain = topHotelsMetrics[`total_${lastOption}_kpi`] || 0;

    const chartData = selectedHotels.map((hotel, index) => ({
        name: hotel.hotel_name,
        value: parseFloat(hotel[`${lastOption}_kpi`] || 0),
        fill: COLORS[index % COLORS.length]
    }));

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p>{`Hotel: ${payload[0].payload.name}`}</p>
                    <p>{`Value: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Box className="top-hotels-component">
            <Grid container spacing={2} className={`top-hotels-container ${Object.keys(topHotelsMetrics).length === 0 ? 'loading' : ''}`}>
                <Grid item xs={8}>
                    <Grid item xs={12} className="dropdown-container">
                        <select
                            value={selectedOption}
                            onChange={handleDropdownChange}
                            className="dropdown"
                        >
                            {TopHotelsOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            ))}
                        </select>
                    </Grid>
                    <TopHotelsTable
                        selectedHotels={selectedHotels}
                        tableMetrics={tableMetrics}
                        formatValue={formatValue}
                        handleTableMetricChange={handleTableMetricChange}
                        TopHotelsAvailableMetrics={TopHotelsAvailableMetrics}
                        COLORS={COLORS}
                    />
                </Grid>
                <TopHotelsChart
                    topHotelsMetrics={topHotelsMetrics}
                    chartData={chartData}
                    CustomTooltip={CustomTooltip}
                    setLastOption={setLastOption}
                    domain={getDomain}
                />
            </Grid>
        </Box>
    );
}

export default TopHotelsComponent;