import React from "react";
import { Box, Typography } from "@mui/material";
import { DecreaseIcon, IncreaseIcon } from "../../../assets/images/Icons";
import { mapper } from "components/DatePicker/DatePicker";

const MetricsComponent = ({ metrics, calender }) => {
    const isComparisonEnabled = Boolean(calender?.compareWith);

    return (
        <Box sx={{ backgroundColor: "#F5F5F5", paddingInline: "20px" }}>
            <Box sx={{ display: "flex", marginTop: 2 }}>
                {metrics.map((item, i) => (
                    <Box
                        key={i}
                        sx={{
                            marginRight: i === metrics.length - 1 ? "0px" : "18px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            flex: 1,
                            position: "relative",
                            minHeight: "97px"
                        }}
                    >
                        <Box
                            style={{
                                borderRadius: "0px 0px 10px 10px",
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                height: "5px",
                                backgroundColor: item.color,
                            }}
                        ></Box>
                        <Box style={{ padding: "10px", paddingInline: "20px" }}>
                            <Typography className="score-title"> {item.label} </Typography>
                            <Typography className="score-value"> {item.value} </Typography>
                            {isComparisonEnabled && (
                                <Box>
                                    <span className="score-direction">
                                        {item?.direction === "up" ? <IncreaseIcon /> : <DecreaseIcon />}
                                    </span>
                                    <span
                                        className={
                                            item?.direction === "up"
                                                ? "score-percentage score-increase"
                                                : "score-percentage score-decrease"
                                        }
                                    >
                                        {item.percentage}%{" "}
                                    </span>
                                    {i < 2 && (
                                        <span style={{fontSize: "8px"}}>
                                            vs {mapper[calender?.comparitionSelection] ?? ""}
                                        </span>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default MetricsComponent;