export const PLATFORM_OPTIONS = [
  {label:"Any", value:0 },
  {label:"Tripadvisor", value:"1" },
  {label:"Wego", value:"3" },
  {label:"Google Hotel Adds", value:"4" },
  {label:"Trivago", value:"6" },
  {label:"Kayak", value:"11" },
  {label:"Skyscanner", value:"22" },
  {label:"Bing", value:"31" },
];

export const BILLING_MODE_OPTIONS = [
  {value:"", label:"Any" },
  {value:"ABO", label:"ABO : Direct Booking" },
  {value:"BA", label:"BA : Tripadvisor Business Advantage" },
  {value:"CPC", label:"CPC : Cost Per Click" },
  {value:"CPA", label:"COM : Commission (Net of Cancellation), CONV : Pay Per Conversion" },
  {value:"FRE", label:"FREE : Free Campaign" },
  {value:"PPS", label:"PPS : Google Pay Per Stay" }
];

export const BOOKING_STATUS_OPTIONS = [
  {label:"Any", value:"" },
  {label:"Stayed", value:"S" },
  {label:"Canceled", value:"C" },
  {label:"No Show", value:"N" }
];