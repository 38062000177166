import React, { useState } from "react";
import {
    Box,
    Typography,
    Checkbox,
    FormControlLabel,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION from "../../../constants/search-ads-criterion/googleSearchAdsLanguageCriterion";
import CheckBoxBlankIcon from "../../../../../components/CheckBoxBlankIcon";
import CheckBoxCheckedIcon from "../../../../../components/CheckBoxCheckedIcon";
import { validateLang } from "../../Utils/validators";
import CustomFormControl from "features/alert-center/Dropdown/CustomFormControl"

const LanguageSelection = ({ selectedLanguage, setSelectedLanguage }) => {
    const primaryLanguageIds = [11, 15, 43, 16, 24];
    const primaryLanguages = GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION.filter((lang) =>
        primaryLanguageIds.includes(lang.id_lang)
    );
    const otherLanguages = GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION.filter(
        (lang) => !primaryLanguageIds.includes(lang.id_lang)
    );

    const [languageStatus, setLanguageStatus] = useState(null);

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setSelectedLanguage(value);
    };

    const handleSelectChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    const handleLanguageBlur = async () => {
        const result = await validateLang([selectedLanguage?.toString()]);
        setLanguageStatus(
            result.success
                ? { success: true, error: null }
                : { success: false, error: result.error || "Invalid language" }
        );
    };

    return (
        <Box className="languageSelection">
            <Box display="flex" alignItems="center">
                <Typography className="sectionTitle">
                    Select Your Target Audience Language
                </Typography>
            </Box>

            <Box display="flex" alignItems="center" paddingLeft={3} gap={2}>
                <Box>
                    {primaryLanguages.map((language) => (
                        <FormControlLabel
                            key={language.id_lang}
                            control={
                                <Checkbox
                                    size="small"
                                    sx={{
                                        color: "#BAB7B7",
                                        "&.Mui-checked": {
                                            color: "#61D5A9",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            height: "14px",
                                            width: "14px",
                                        },
                                    }}
                                    checked={selectedLanguage === language.id_lang.toString()}
                                    onChange={handleCheckboxChange}
                                    value={language.id_lang}
                                />
                            }
                            onBlur={() => handleLanguageBlur()}
                            label={language.language_name}
                        />
                    ))}
                </Box>

        <CustomFormControl
          className="languageSelect"
          sx={{ width: "auto", minWidth: "125px" }}
          value={selectedLanguage}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={(selected) => {
            const language = GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION.find(
              (lang) => lang.id_lang.toString() === selected
            );
            if (!selected) {
              return (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#bab7b7",
                  }}
                >
                  Other
                </span>
              );
            }

            return language ? language.language_name : selected;
          }}
        >
          <MenuItem value="" disabled>
            <span
              style={{
                fontSize: "12px",
                color: "#bab7b7",
              }}
            >
              Other
            </span>
          </MenuItem>
          {otherLanguages.map((language) => (
            <MenuItem key={language.id_lang} value={language.id_lang}>
              <span
                style={{
                  fontSize: "12px",
                  color: "#373F41",
                }}
              >
                {language.language_name}
              </span>
            </MenuItem>
          ))}
        </CustomFormControl>
      </Box>
    </Box>
  );
};

export default LanguageSelection;
