import Layout from "../Layout";

const AfterEmailSent = () => {
  return (
    <>
      <Layout>
        <p
          className="quency-cf-normal"
          style={{ marginTop: "50px", fontSize: "1.5rem", fontWeight: 400 }}
        >
          Reset password
        </p>
        <p
          style={{
            color: "#373F41",
            fontSize: "14px",
          }}
        >
          A link has been sent to you by email. Click the link to open a window
          where you can enter a new password.
        </p>
      </Layout>
    </>
  );
};
export default AfterEmailSent;
