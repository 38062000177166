// TextEditor.js
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's default styling

const TextEditor = ({ value, onChange }) => {
  const toolbarOptions = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'align': [] }],
    ['clean']                                         // remove formatting button
  ];

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={{
        toolbar: toolbarOptions,
      }}
    />
  );
};

export default TextEditor;