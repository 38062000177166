import "./extraBid.scss";
import { Button } from "@mui/material";
import { COMMONFORMFIELDS } from "constants/formFields/biddingOptions";
import FormFieldRenderer from "components/FormFieldRenderer";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { kayakExtraBidFormFields } from "constants/formFields/extraBid";
import KayakExtraBid from "../kayak/extraBid";

const ExtraBid = () => {
  const platform = useSelector((state) => state?.metaSearch?.platform);

  const currentPlatform = platform[0]?.id;

  switch (currentPlatform) {
    case "kayak":
      return <KayakExtraBid />;
    default:
      return null;
  }
};
export default ExtraBid;
