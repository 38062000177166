import React, { useState } from 'react';
import './GroupsLists.scss';
import Modal from '../Utils/Modal/ActionModal/Modal';
import {Box, Button, CircularProgress} from '@mui/material';
import SearchTableInput from "../../../components/SearchTableInput/SearchTableInput";
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";

const GroupsLists = ({ groups, setGroups, onSelectGroup, createGroup, createGroupError, loading, showModal,setShowModal }) => {
    const [groupName, setGroupName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    // Filter groups based on the search term
    const filteredGroups = groups.filter(group =>
        group.name && group.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCloseModal = () => {
        setShowModal(false);
        setGroupName('');
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error message

        if (groupName.trim() === '') {
            setErrorMessage('Group name is required');
            return;
        }

        const success = await createGroup(groupName);
        if (success) {
            handleCloseModal(); // Close the modal if the group creation is successful
        } else {
            setErrorMessage(createGroupError || 'Failed to create group. Please try again.');
        }
    };

    const handleGroupClick = (group) => {
        if (onSelectGroup && group) {
            onSelectGroup(group);
        }
    };

    return (
        <div className="groups-list-container">
            <Box className="groupTableHeaderTop">
                <h3>Groups</h3>
            </Box>

            <div className="filter-group" style={{display:'flex', justifyContent:'center',marginTop:"14px"}}>
                <SearchTableInput
                    placeholder={"Search Groups"}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            <ul className="groups-list">
                {filteredGroups.length === 0 ? (
                    <li>No groups found</li>
                ) : (
                    filteredGroups.map((group, index) => (
                        <li key={group.id || index} onClick={() => handleGroupClick(group)}>
                            {group.name || 'Unnamed Group'}
                        </li>
                    ))
                )}
            </ul>

            {showModal && (
                <Modal onClose={handleCloseModal}>
                    <form className="group-form" onSubmit={handleSubmit}>
                        <h3>Create New Hotel Group</h3>
                        <input
                            placeholder="Group Name"
                            autoComplete="off"
                            type="text"
                            id="groupName"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                            style={{
                                border: errorMessage ? '1px solid #D32F2F' : '',
                                outline: 'none',
                            }}
                        />

                        {/* Error message shown in red when there is an error */}
                        {errorMessage && <span style={{marginTop:'-8px', color: '#D32F2F', fontSize:"12px", fontFamily:"inter" }}>{errorMessage}</span>}

                        <div className="modal-actions">
                            {/* Show loader while waiting for API call */}
                            {loading ? (
                                <div className="loader-modal">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    <div
                                        className="button-container"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '16px', // Adjust gap size as needed
                                        }}
                                    >
                                        <CustomButtonWithIcon
                                            color="#b0acac"
                                            hoverColor="#12794F"
                                            titleColor="#373F41"
                                            hoverTitleColor={"white"}
                                            width={"85px"}
                                            height={"24px"}
                                            borderRadius="20px"
                                            textStyle={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '14.52px',
                                                textAlign: 'center',
                                            }}
                                            onClick={handleCloseModal}
                                        >
                                            Cancel
                                        </CustomButtonWithIcon>
                                        <CustomButtonWithIcon
                                            color="#b0acac"
                                            hoverColor="#12794F"
                                            titleColor="#373F41"
                                            hoverTitleColor={"white"}
                                            width={"85px"}
                                            height={"24px"}
                                            borderRadius="20px"
                                            textStyle={{
                                                fontFamily: 'Inter',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                lineHeight: '14.52px',
                                                textAlign: 'center',
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            Create
                                        </CustomButtonWithIcon>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default GroupsLists;
