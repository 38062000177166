import React, { useState }  from "react";
import PMaxCampaignAccordion from "../googlePmaxFormComponent/PMaxCampaignAccordion";
import {Box, CircularProgress, Drawer, Grid, Typography} from "@mui/material";
import CloudinaryImageEditor from "../googlePmaxFormComponent/AdsCreative/CloudinaryImageEditor";
import './GooglePmaxCampaignCreator.scss';
import PMaxAdsCreativeAccordion from "../googlePmaxFormComponent/AdsCreative/PMaxAdsCreativeAccordion";
import CampaignSettingsAccordion from "../googlePmaxFormComponent/CampaignSettings/CampaignSettingsAccordion";
import {assignedSuggestion, getSuggestions} from "../googlePmaxFormComponent/Utils/suggestions";

const GooglePMaxCampaignCreator = ({
    expandedPanel,
    setExpandedPanel,
    visitedPanels,
    handleNext,
    selectedCountries,
    setSelectedCountries,
    selectedLanguage,
    setSelectedLanguage,
    targetValue,
    setTargetValue,
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    monthlyBudget,
    setMonthlyBudget,
    headlines,
    setHeadlines,
    descriptions,
    setDescriptions,
    finalURL,
    setFinalURL,
    businessName,
    setBusinessName,
    longHeadlines,
    setLongHeadlines,
    idaccount,
    idhotel,
    landscape,
    setLandscape,
    square,
    setSquare,
    portrait,
    setPortrait,
    logo,
    setLogo,
    landscapeLogo,
    setLandscapeLogo,
    errors
}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleNextWithValidation = async (currentPanel) => {
        setDrawerOpen(true);
        await handleNext(currentPanel);
        setDrawerOpen(false);
    };

    const handleSuggestion = async (
        params,
        setDescriptions,
        setFinalURL,
        setHeadlines,
        setLongHeadlines,
        setLandscape,
        setLandscapeLogo,
        setLogo,
        setPortrait,
        setSquare,
        setBusinessName
    ) => {
        const result = await getSuggestions(params);
        assignedSuggestion(
            result,
            setDescriptions,
            setFinalURL,
            setHeadlines,
            setLongHeadlines,
            setLandscape,
            setLandscapeLogo,
            setLogo,
            setPortrait,
            setSquare,
            setBusinessName
        );
    }

    return (
        <>
            <PMaxCampaignAccordion
                title="1. Campaign Settings"
                expanded={expandedPanel === "settings"}
                onChange={() => setExpandedPanel("settings")}
                onNext={() => handleNextWithValidation("settings")}
                disabled={!visitedPanels.includes("settings")}
                visited={visitedPanels.includes("settings")}
                setDrawerOpen={setDrawerOpen}
            >
                <CampaignSettingsAccordion
                    biddingStrategy={biddingStrategy}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas}
                    setTargetRoas={setTargetRoas}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    monthlyBudget={monthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    errors={errors}
                    idaccount={idaccount}
                    idhotel={idhotel}
                />
            </PMaxCampaignAccordion>

            <PMaxCampaignAccordion
                title="2. Ads Creative"
                expanded={expandedPanel === "adsCreative"}
                onChange={() => setExpandedPanel("adsCreative")}
                onNext={() => handleNext("adsCreative")}
                disabled={!visitedPanels.includes("adsCreative")}
                visited={visitedPanels.includes("adsCreative")}
                isCreateButton={true}
                getSuggestion={() => handleSuggestion({
                        account_id: idaccount,
                        hotel_id: idhotel,
                        campaign_lang: selectedLanguage
                    },
                    setDescriptions,
                    setFinalURL,
                    setHeadlines,
                    setLongHeadlines,
                    setLandscape,
                    setLandscapeLogo,
                    setLogo,
                    setPortrait,
                    setSquare,
                    setBusinessName
                )}
                setDrawerOpen={setDrawerOpen}
            >
                <Grid container spacing={2}>
                    <Grid item xs={5} direction="column">
                        <PMaxAdsCreativeAccordion
                            headlines={headlines}
                            setHeadlines={setHeadlines}
                            descriptions={descriptions}
                            setDescriptions={setDescriptions}
                            finalURL={finalURL}
                            setFinalURL={setFinalURL}
                            businessName={businessName}
                            setBusinessName={setBusinessName}
                            longHeadlines={longHeadlines}
                            setLongHeadlines={setLongHeadlines}
                            errors={errors}
                        />
                    </Grid>
                    <Grid item xs={7} direction="column">
                        <Box className="pmax-media-library" mb={4}>
                            <Box display="flex" alignItems="center" className="header-box">
                                <Typography variant="subtitle1">MEDIA LIBRARY</Typography>
                            </Box>
                            <Box display="flex" className="content-pmax-images">
                                <CloudinaryImageEditor
                                    landscape={landscape}
                                    setLandscape={setLandscape}
                                    square={square}
                                    setSquare={setSquare}
                                    portrait={portrait}
                                    setPortrait={setPortrait}
                                    logo={logo}
                                    setLogo={setLogo}
                                    landscapeLogo={landscapeLogo}
                                    setLandscapeLogo={setLandscapeLogo}
                                    errors={errors}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </PMaxCampaignAccordion>

            <Drawer
                anchor="top"
                open={drawerOpen}
                PaperProps={{
                    sx: { background: 'rgba(0, 0, 0, 0.6)', backdropFilter: 'blur(5px)' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress size={60} color="primary" />
                </Box>
            </Drawer>
        </>
    );
};

export default GooglePMaxCampaignCreator;
