export const DeviceOptions = [
    { key: "BING OVERVIEW", value: "BING" },
    { key: "GHA FBL OVERVIEW", value: "GHA_FBL" },
    { key: "GHA META OVERVIEW", value: "GHA_META" },
    { key: "GHA PPA OVERVIEW", value: "GHA_PPA" },
    { key: "KAYAK OVERVIEW", value: "KAYAK" },
    { key: "SKYSCANNER OVERVIEW", value: "SKYSCANNER" },
    { key: "TRIPADVISOR META OVERVIEW", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP OVERVIEW", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO META OVERVIEW", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL OVERVIEW", value: "TRIVAGO_SL" },
    { key: "PMAX OVERVIEW", value: "PMAX" },
    { key: "GHA SEM OVERVIEW", value: "GHA_SEM" },
];

export const TopHotelsOptions = [
    { key: "BING TOP PERFORMING HOTELS", value: "BING" },
    { key: "GHA FBL TOP PERFORMING HOTELS", value: "GHA_FBL" },
    { key: "GHA META TOP PERFORMING HOTELS", value: "GHA_META" },
    { key: "GHA PPA TOP PERFORMING HOTELS", value: "GHA_PPA" },
    { key: "KAYAK TOP PERFORMING HOTELS", value: "KAYAK" },
    { key: "SKYSCANNER TOP PERFORMING HOTELS", value: "SKYSCANNER" },
    { key: "TRIPADVISOR META TOP PERFORMING HOTELS", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP TOP PERFORMING HOTELS", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO META TOP PERFORMING HOTELS", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL TOP PERFORMING HOTELS", value: "TRIVAGO_SL" },
    { key: "PMAX TOP PERFORMING HOTELS", value: "PMAX" },
    { key: "GHA SEM TOP PERFORMING HOTELS", value: "GHA_SEM" },
];

export const TopMarketsOptions = [
    { key: "BING TOP MARKETS", value: "BING" },
    { key: "GHA FBL TOP MARKETS", value: "GHA_FBL" },
    { key: "GHA META TOP MARKETS", value: "GHA_META" },
    { key: "GHA PPA TOP MARKETS", value: "GHA_PPA" },
    { key: "KAYAK TOP MARKETS", value: "KAYAK" },
    { key: "SKYSCANNER TOP MARKETS", value: "SKYSCANNER" },
    { key: "TRIPADVISOR META TOP MARKETS", value: "TRIPADVISOR_META" },
    { key: "TRIPADVISOR SP TOP MARKETS", value: "TRIPADVISOR_SP" },
    { key: "TRIVAGO META TOP MARKETS", value: "TRIVAGO_META" },
    { key: "TRIVAGO SL TOP MARKETS", value: "TRIVAGO_SL" },
    { key: "PMAX TOP MARKETS", value: "PMAX" },
    { key: "GHA SEM TOP MARKETS", value: "GHA_SEM" },
];
