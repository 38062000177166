import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./SingleSelect.scss";
export default function SingleSelect({
  value = undefined,
  options,
  onChange,
  sx,
  placeholder,
  valueKey = "value",
  labelKey = "label",
  menuProps = '',
}) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  return (
    <FormControl size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        placeholder={placeholder}
        value={selectedValue}
        displayEmpty
        renderValue={(val) => {
          console.log(val, "Val");

          if (!val) {
            return (
              <span
                style={{
                  fontSize: "12px",
                  color: "#bab7b7",
                  marginLeft: "8px",
                }}
              >
                {placeholder}
              </span>
            );
          }
          return (
            <span
              style={{
                fontSize: "12px",
                marginLeft: "8px",
              }}
            >
              {options.find((opt) => opt[valueKey] === val)?.[labelKey]}
            </span>
          );
        }}
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "3px 0 3px",
          },
          ...sx,
        }}
        onChange={(e) => {
          console.log(e.target.value);
          setSelectedValue(e.target.value);
          onChange(e.target.value);
        }}

        MenuProps={menuProps}
      >
        <MenuItem sx={{fontSize:"12px", fontWeight:'400',fontFamily:'inter'}} value="">
          {placeholder}
        </MenuItem>
        {options?.length
          ? options.map((option) => {
              return (
                <MenuItem sx={{fontSize:"12px", fontWeight:'400',fontFamily:'inter'}} value={option[valueKey]}>{option[labelKey]}</MenuItem>
              );
            })
          : []}
      </Select>
    </FormControl>
  );
}
