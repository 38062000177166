import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import ResetPassword from "./components/Login/ResetPassword/ResetPassword";
import Homepage from "./pages/homepage";
import NewHomepage from "./pages/newHomepage";
import MetaSearch from "./pages/metasearch";
import LostPassword from "./components/Login/LostPassword";
import AfterEmailSent from "./components/Login/ResetPassword/AfterEmailSent";
import BiddingCenter from "pages/biddingCenter/BiddingCenterPage";
import Groups from "pages/groups";
import BiddingOfflinePage from "pages/bidddingOffline";
import Reservations from "./pages/reservations";
import SignUpSemPage from "pages/productSignUpPage/SignUpSemPage";
import SignUpPmaxPage from "pages/productSignUpPage/SignUpPmaxPage";
import AlertCenterPage from "pages/alertCenter/AlertCenterPage";
import SubUsersManagement from "./pages/subUsersManagement";
import SearchAdsPage from "./pages/searchadspage";
import ReportCenter from "./pages/reportCenter";
import SamplePage from "./pages/samplePages";
import SearchAdsCampaign from "./pages/searchAdsCampaign/searchAdsCampaign";
import SearchAdsCampaignCreator from "./pages/searchAdsCampaign/searchAdsCampaignCreator";
import PMaxCampaignManager from "./pages/pMaxCampaignManager";
import PMaxCampaignCreator from "./pages/pMaxCampaignCreator";
import KeywordsReportingPage from "pages/keywordsReporting";
import SearchTermReportingPage from "pages/searchTermReporting";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-sent" element={<AfterEmailSent />} />
          <Route path="/home/:idaccount" element={<NewHomepage />} />
          <Route path="/old-home/" element={<Homepage />} />
          <Route path="/metasearch" element={<MetaSearch />} />
          <Route path="/groups/:idAccount" element={<Groups />} />
          <Route
            path="/bidding-offline/:idaccount"
            element={<BiddingOfflinePage />}
          />
          <Route path="/reservations/:idaccount" element={<Reservations />} />
          <Route path="/searchads" element={<SearchAdsPage />} />
          <Route path="/sign-up-sem" element={<SignUpSemPage />} />
          <Route path="/sign-up-pmax" element={<SignUpPmaxPage />} />
          <Route path="/lost-password" element={<LostPassword />} />
          <Route
            path="/alert-center/:idaccount"
            element={<AlertCenterPage />}
          />
          <Route
            path="/bidding-center/:idaccount"
            element={<BiddingCenter />}
          />
          <Route
            path="/sub-users-management"
            element={<SubUsersManagement />}
          />
          <Route path="/report-center/:idaccount" element={<ReportCenter />} />
          <Route path="/sample-page" element={<SamplePage />} />
          {/* <Route path="/search-ads-campaign" element={<SearchAdsCampaign />} /> */}
          <Route
            path="/search-ads-campaign/create"
            element={<SearchAdsCampaignCreator />}
          />
          <Route
            path="/pmax-campaign-manager/:idaccount"
            element={<PMaxCampaignManager />}
          />
          <Route
            path="/pmax-campaign-manager/create/:idaccount/:idhotel"
            element={<PMaxCampaignCreator />}
          />
          <Route
            path="/keywords-reporting"
            element={<KeywordsReportingPage />}
          />
          <Route
            path="/search-terms-reporting"
            element={<SearchTermReportingPage />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
