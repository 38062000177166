import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import "./SharedBudgetTable.scss";
import SharedTable from "../CustomSharedTable/SahredTable";
import { useSelector } from "react-redux";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import axios from "axios";
import EditIcon from "assets/images/ActionIcons/Edit.svg";

const SharedBudgetTable = ({ data, isEdit = false, onEditRow }) => {
  const [rowSelection, setRowSelection] = useState();
  const columns = useMemo(
    () => [
      {
        accessorKey: "shared_budget_name",
        header: "Shared Budget Name",
        maxSize: 220,
        align: "left",
      },
      {
        accessorKey: "hotel_name",
        header: "Hotel Name",
        maxSize: 40,
        align: "right",
      },
      {
        accessorKey: "campaign_count",
        header: "Campaign Count",
        maxSize: 40,
        align: "right",
        Cell: ({ row, value }) => {
          return row?.campaign_list?.length;
        },
      },
      {
        accessorKey: "edit",
        header: "Edit",
        maxSize: 40,
        align: "right",
        Cell: ({ row, value }) => (
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              onEditRow(row);
            }}
          >
            <img src={EditIcon} />
          </span>
        ),
      },
    ],
    []
  );

  return (
    <Box className="search-ads-form-campaign-settings">
      <SharedTable
        columns={columns}
        loading={false}
        data={data}
        selectedRows={rowSelection}
        setSelectedRows={setRowSelection}
        uniqueId="id"
      />
    </Box>
  );
};

export default SharedBudgetTable;
