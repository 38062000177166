export const COLUMN_DEFINITIONS = [
  {
    accessorKey: "channel",
    header: <span> &nbsp; </span>,
    enableColumnActions: false,
    enableSorting: false,
    enableHiding: false,
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "meta",
    header: "Channel",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "budgetSpent",
    header: "Budget Spent",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "cost",
    header: "Cost (EUR)",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "generated",
    header: "Generated (EUR)",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "clicks",
    header: "Clicks",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "cpc",
    header: "CPC (EUR)",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "revenuePerClick",
    header: "Revenue per Click (EUR)",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "bookings",
    header: "Bookings",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "conversionRate",
    header: "Conversion Rate%",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "roas",
    header: "ROAS (NET)",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },

];
