export const kayakExtraBidFormFields = [
  {
    id: "EXTRA_BID",
    label: "Extra Bid (Desktop)",
    type: "textField",
    placeholder: "Please enter value",
  },
  {
    id: "MAX_BID",
    label: "Max Bid (Desktop)",
    type: "textField",
    placeholder: "Please enter value",
  },
  {
    id: "EXTRA_BID",
    label: "Extra Bid (Mobile)",
    type: "textField",
    placeholder: "Please enter value",
  },
  {
    id: "MAX_BID",
    label: "Max Bid (Mobile)",
    type: "textField",
    placeholder: "Please enter value",
  },
];
