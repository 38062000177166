import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DownArrow from "../../../assets/images/DownArrow.svg";
import UpArrow from "../../../assets/images/UpArrow.svg";
import { Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { drawerItems } from "constants/productmenu";
import MenuIcon from "assets/images/MenuIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSection,
  updateOpenItems,
} from "store/slices/sidebar/sidebar";
import EditCampaignPanel from "features/google-search-ads-campaign/EditCampaignPanel";
import SharedBudgetPanel from "features/searchads/SharedBudgetPanel";

const drawerWidth = "100%",
  openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuItem = ({
  item,
  open,
  setOpenSideDrawer,
  handleClick,
  activeMenu,
  setActiveMenu,
  setOpenItems,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeChildMenu, setActiveChildMenu] = useState("");
  console.log(pathname, activeMenu, item, open, "Child Menu");

  return (
    <>
      <ListItem
        sx={{
          backgroundColor:
            activeMenu === item.id || pathname.includes(item.id)
              ? "#F1F1F1"
              : "",
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
        }}
        onClick={() => {
          handleClick(item.id);
        }}
      >
        <ListItemText primary={item.label} />
        {item.children ? (
          open ? (
            <img src={UpArrow} alt="Up Arrow" />
          ) : (
            <img src={DownArrow} alt="Down Arrow" />
          )
        ) : null}
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <div className="menuItem-child">
                {console.log("child--->", child)}
                <MenuItem
                  sx={{
                    backgroundColor:
                      activeChildMenu === child.id ? "#F1F1F1" : "",
                  }}
                  key={child.id}
                  item={child}
                  activeMenu={activeChildMenu}
                  open={open}
                  handleClick={() => {
                    console.log(child.id, "Chil");
                    setActiveMenu(item.id);
                    setActiveChildMenu(child.id);
                    child?.link == "sharedBudget"
                      ? setOpenSideDrawer(true)
                      : navigate(child?.link);
                    // setOpenItems(item.id);
                  }}
                />
              </div>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default function CustomDrawer({
  page,
  menuItems,
  initialItems,
  currentSection,
  setCurrentSection,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const storeOpenItems = useSelector((state) => state?.sidebar?.openItems);
  const activePage = drawerItems.find((item) => item.action === page);
  const [openDrawer, setOpenDrawer] = useState(true);

  console.log(currentSection, "CUrrent Sect");

  useEffect(() => {
    if (pathname.includes(activePage?.url)) {
      dispatch(setActiveSection(activePage?.action));
    }
  }, [pathname]);

  const handleMenuItemClick = (name, url) => {
    navigate(url);
    setCurrentSection(name === "META SEARCH" ? "Campaign Manager" : name);
    dispatch(
      setActiveSection(name === "META SEARCH" ? "Campaign Manager" : name)
    );
  };
  console.log("menuItems", menuItems);
  const [hoveredItem, setHoveredItem] = useState("");
  const [activeMenu, setActiveMenu] = useState(
    pathname.includes(activePage?.url) ? "meta" : ""
  );
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [openItems, setOpenItems] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeOpenItems) {
      setOpenItems(storeOpenItems);
    }
  }, [storeOpenItems]);

  console.log(openItems, "Items Opened");

  const toggleItem = (id) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
    dispatch(
      updateOpenItems({ ...storeOpenItems, [id]: !storeOpenItems?.[id] })
    );
  };

  const handleCloseDrawer = () => {
    setOpenSideDrawer(false);
  };
  console.log(openSideDrawer, "Active State");

  //--Update PMax/SEM URL as per account setting value
  const selectedAccount = useSelector((state) => state?.account?.account || {});
  const getNavigateUrl = (item, selectedAccount) => {
    if (item.name === "PMAX" && selectedAccount["is_pmax_enabled "] === false) {
      return "/sign-up-pmax";
    }
    if (item.name === "SEM" && selectedAccount["is_google_sem_enabled"] === false) {
      return "/sign-up-sem";
    }
    return item.url;
  };

  return (
    <Box
      className={"sidebar"}
      sx={{
        display: "flex",
        width: openDrawer ? "180" : "41px",
      }}
    >
      <Box>
        <List>
          {currentSection === null && (
            <>
              <div
                className="my-products-title"
                style={{ marginBottom: "16px" }}
              >
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    color: "#BAB7B7",
                    margin: "0 18px 0 20px",
                  }}
                  className={"underline"}
                >
                  Products
                </div>
              </div>

              {initialItems.map(({ name, icon, url }) => (
                <div
                  onClick={() => handleMenuItemClick(name, url)}
                  key={name}
                  className={"list-menu"}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "#587A78",
                    margin: "4px 0 0 0",
                    cursor: "pointer",
                    padding: "0 45px 1px 20px",
                    backgroundColor: hoveredItem === name ? "#F1F1F1" : "",
                  }}
                  onMouseEnter={(e) => setHoveredItem(name)}
                  onMouseLeave={(e) => setHoveredItem("")}
                >
                  <div>
                    <img
                      style={{
                        display: "inline",
                        height: "24px",
                        width: "24px",
                        marginTop: "6px",
                        marginBottom: "4px",
                      }}
                      alt={name}
                      src={icon}
                      className={"menu-icon"}
                    />
                  </div>
                  <div className={"list-menu-name"}>{name}</div>
                </div>
              ))}
            </>
          )}
          {currentSection !== null && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                maxWidth: "100%",
                cursor: "pointer",
                height: "100vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  textAlign: "center",
                  cursor: "pointer",
                  width: "41px",
                  boxShadow: "4px 0 8px -4px rgba(0, 0, 0, 0.1)",
                  marginTop: "-25px",
                }}
              >
                <img
                  src={MenuIcon}
                  style={{
                    height: "18px",
                    textAlign: "center",
                    marginTop: "18px",
                  }}
                  onClick={() => {
                    setOpenDrawer(!openDrawer);
                  }}
                />
                <div>
                  {drawerItems?.map((item) => {
                    return (
                        <div
                            className={
                              item.action === currentSection ? "activeMenuBG" : ""
                            }
                            style={{
                              margin: "8px 0 8px 0",
                              padding: "4px 0 4px 0",
                              cursor: "pointer",
                              backgroundColor:
                                  hoveredItem === item.name ? "#F1F1F1" : "",
                            }}
                            onMouseEnter={() => setHoveredItem(item.name)}
                            onMouseLeave={() => setHoveredItem("")}
                        >
                          <div
                              onClick={() => {
                                const navigateUrl = getNavigateUrl(item, selectedAccount);
                                navigate(navigateUrl); // Navigate to the determined URL
                                setCurrentSection(item.action);
                                dispatch(setActiveSection(item.action));
                              }}
                          >
                            <img
                                style={{
                                  display: "inline",
                                  height: "18px",
                                  width: "18px",
                                }}
                                alt={item.name}
                                src={item.icon}
                                className={"menu-icon"}
                            />
                          </div>
                          <div
                              style={{
                                height: "100%",
                                fontSize: "8px",
                                fontWeight: "400",
                                color: "#373F41",
                              }}
                              className={"list-menu-name"}
                          >
                            {item.name}
                          </div>
                        </div>
                    );
                  })}
                </div>
              </div>

              <Box
                  className="expanded-container"
                  style={{
                    display: openDrawer ? "block" : "none",
                    boxShadow: "4px 0 8px -4px rgba(0, 0, 0, 0.1)",
                    marginTop: "-25px",
                    width: "150px",
                  }}
              >
                <div
                    onClick={() => {
                      setActiveMenu("meta");
                      navigate(activePage?.url);
                    }}
                    className="title"
                    style={{
                      margin: "30px 0 0 0",
                      padding: "10px 8px 9px 10px",
                      backgroundColor:
                          hoveredItem === "meta" || activeMenu === "meta"
                              ? "#F1F1F1"
                              : "",
                  }}
                  onMouseEnter={() => setHoveredItem("meta")}
                  onMouseLeave={() => setHoveredItem("")}
                >
                  Campaign Manager
                </div>

                {menuItems.map((item) => (
                  <MenuItem
                    item={item}
                    open={openItems?.[item.id]}
                    setOpenSideDrawer={setOpenSideDrawer}
                    handleClick={() => {
                      setActiveMenu(item.id);
                      navigate(item?.link);
                      if (item.children.length) {
                        toggleItem(item.id);
                      }
                    }}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    setOpenItems={setOpenItems}
                    className="menu-item"
                  />
                ))}
              </Box>
            </div>
          )}
        </List>
      </Box>
      {openSideDrawer && (
        <SharedBudgetPanel
          isOpen={openSideDrawer}
          onClose={handleCloseDrawer}
        />
      )}
    </Box>
  );
}
