export const tripAdvisorMultiplierFormFields = [
    {
        id: "device_identifier",
        label: "Device",
        type: "select",
        placeholder: "Select a device",
        options: [
            {
                id: "mobile",
                label: "Mobile",
                value: "mobile",
            },
            {
                id: "desktop",
                label: "Desktop",
                value: "desktop",
            },
        ],
    },
    {
        id: "numberOfNights",
        label: "Number of nights",
        type: "range",
        placeholder: "Please enter a value",
    },
    {
        id: "empty",
        label: "",
        type: "empty",
        placeholder: "",
    },
    {
        id: "multiplierValue",
        label: "Multiplier Value",
        type: "textField",
        isAdder: true,
        placeholder: "Please enter a value",
    },
    {
        id: "MONDAY",
        label: "Monday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "TUESDAY",
        label: "Tuesday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "WEDNESDAY",
        label: "Wednesday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "THURSDAY",
        label: "Thursday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "FRIDAY",
        label: "Friday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "SATURDAY",
        label: "Saturday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "SUNDAY",
        label: "Sunday",
        type: "textField",
        subType: "number",
        placeholder: "Please enter a value",
    },
    {
        id: "days",
        label: "Days",
        type: "select",
        placeholder: "Select a day",
        options: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8-14",
            "15-19",
            "30+",
        ].map((val) => ({id: val, label: val, value: val})),
    },
    {
        id: "bidMultiplier",
        label: "Bid Multiplier",
        type: "textField",
        isAdder: true,
        placeholder: "Please enter a value",
    },
    {
        id: "default",
        label: "Default",
        type: "textField",
        placeholder: "Please enter a value",
    },
    {
        id: "user_selected",
        label: "User selected",
        type: "textField",
        placeholder: "Please enter a value",
    },
];

export const tripadvisorButtons = [
    {
        id: "tripAdvisorDateType",
        label: "Date type",
        value: "DATE_TYPE",
    },
    {
        id: "tripAdvisorCheckInDays",
        label: "Check in day",
        value: "CHECKIN_DAY",
    },
    {
        id: "tripAdvisorDaysInArrival",
        label: "Days to arrival",
        value: "DAYS_TO_ARRIVAL",
    },
    {
        id: "tripAdvisorLengthOfStay",
        label: "Length of stay",
        value: "LENGTH_OF_STAY",
    },
];

export const allFields = {
    tripAdvisorLengthOfStay: [
        "device_identifier",
        "numberOfNights",
        "empty",
        "multiplierValue",
    ],
    tripAdvisorCheckInDays: [
        "device_identifier",
        "empty",
        "MONDAY",
        "FRIDAY",
        "TUESDAY",
        "SATURDAY",
        "WEDNESDAY",
        "SUNDAY",
        "THURSDAY",
    ],
    tripAdvisorDaysInArrival: [
        "device_identifier",
        "empty",
        "days",
        "bidMultiplier",
    ],
    tripAdvisorDateType: [
        "device_identifier",
        "empty",
        "default",
        "user_selected",
    ],
};
