/**
 * Utility to handle image cropping and URL manipulation for Cloudinary.
 */

/**
 * Removes cropping parameters from a Cloudinary URL.
 * Specifically removes `/c_crop,x_,y_,w_,h_` from the URL, reverting to the original upload URL.
 *
 * @param {string} url - The Cloudinary image URL with cropping parameters.
 * @returns {string} - The original Cloudinary URL without cropping parameters.
 */
export const removeCropParameters = (url) => {
    return url.replace(/\/upload\/c_crop,[^/]+\//, '/upload/');
};

/**
 * Generates a cropped Cloudinary image URL based on the provided cropping coordinates.
 *
 * @param {Object} info - The image information object returned by Cloudinary.
 * @param {string} info.secure_url - The original secure Cloudinary URL of the image.
 * @param {Object} info.coordinates - The coordinates for cropping.
 * @param {Array<number>} info.coordinates.custom - The cropping rectangle: [x, y, width, height].
 * @returns {string} - The cropped Cloudinary image URL with cropping parameters.
 */
export const generateCroppedUrl = (info) => {
    const { secure_url, coordinates } = info;
    if (!coordinates?.custom || coordinates.custom.length === 0) {
        return secure_url;
    }
    const [x, y, cropWidth, cropHeight] = coordinates.custom[0];
    const croppedUrl = secure_url.replace('/upload/', `/upload/c_crop,x_${x},y_${y},w_${cropWidth},h_${cropHeight}/`);
    return croppedUrl;
};
