import React from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { CircularProgress, Grid } from "@mui/material";

function TopMarketsMap({ topMarketsMetrics, selectedMarkets, worldMap }) {
    return (
        <Grid item xs={12} className="top-market-map-container">
            {Object.keys(topMarketsMetrics).length === 0 ? (
                <CircularProgress className="loader" />
            ) : (
                <ComposableMap
                    projection="geoMercator"
                    projectionConfig={{
                        scale: 150
                    }}
                    style={{
                        width: "100%",
                        height: "350px"
                    }}
                >
                    <Geographies geography={worldMap}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const matchingMarket = selectedMarkets.find(
                                    (market) => market.market_code === geo.id
                                );

                                const fillColor = matchingMarket ? "#61D5A9" : "#E0E0E0";

                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={fillColor}
                                        stroke="#000"
                                        style={{
                                            default: { fill: fillColor, outline: "none" },
                                            hover: { fill: fillColor, outline: "none" },
                                            pressed: { fill: fillColor, outline: "none" },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
            )}
        </Grid>
    );
}

export default TopMarketsMap;