import React, { useState } from "react";
import { Box, Snackbar, Alert } from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import "./SearchAdsCampaign.scss";
import GoogleSearchAdsCampaignCreator from "../../features/google-search-ads-campaign/GoogleSearchAdsCampaignCreator";
import { initialMonthlyBudget } from "./utils/initialState";
import {
  validateSettings,
  validateSettingsKeyword,
  validateAdsCreative,
} from "./utils/validators";
import PageTitles from "../../components/PageTitles";
import { useSelector } from "react-redux";
import { validateFullAdsCreative } from "features/google-search-ads-campaign/googleSearchAdsFormComponents/Utils/validators";
import axios from "axios";
import { APP_GOOGLE_SEM_API_ENDPOINT } from "constants/api_urls";
import { useSnackbar } from "notistack";
import { CloseRounded } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";

const SearchAdsCampaignCreator = () => {
  const token = sessionStorage.getItem("token");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { hotel_id = [] } = useSelector((state) => state?.searchAds);
  const account = useSelector((state) => state?.account?.account);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("error");
  const [expandedPanel, setExpandedPanel] = useState("objective");
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [visitedPanels, setVisitedPanels] = useState(["objective"]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [biddingStrategy, setBiddingStrategy] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [targetCpa, setTargetCpa] = useState("");
  const [targetRoas, setTargetRoas] = useState("");
  const [maxCpcLimit, setMaxCpcLimit] = useState("");
  const [impressionShare, setImpressionShare] = useState("");
  const [adLocation, setAdLocation] = useState("");
  const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
  const [keywords, setKeywords] = useState([]);
  const [negativeKeywords, setNegativeKeywords] = useState([]);
  const [headlines, setHeadlines] = useState([
    { id: "headline1", text: "" },
    { id: "headline2", text: "" },
    { id: "headline3", text: "" },
    { id: "headline4", text: "" },
    { id: "headline5", text: "" },
    { id: "headline6", text: "" },
  ]);
  const [descriptions, setDescriptions] = useState([
    { id: "description1", text: "" },
    { id: "description2", text: "" },
    { id: "description3", text: "" },
  ]);
  const [siteLinks, setSiteLinks] = useState([]);
  const [callOuts, setCallOuts] = useState([]);
  const [structuredSnippets, setStructuredSnippets] = useState([]);
  const [finalURL, setFinalURL] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dailyBudgetCap, setDailyBudgetCap] = useState(false);
  const [calls, setCalls] = useState([
    { id: "default-call", phoneNumber: "", countryCode: "", irremovable: true },
  ]);
  const [displayPaths, setDisplayPaths] = useState([
    { id: "path1", text: "", irremovable: true },
    { id: "path2", text: "", irremovable: true },
  ]);
  const [displayErrors, setDisplayErrors] = useState({});

  const handleNext = async (currentPanel) => {
    if (currentPanel === "objective") {
      if (!selectedObjective) {
        setMessage("Please select an objective before proceeding.");
        setSnackbarOpen(true);
        return;
      }
      setExpandedPanel("settings");
      setVisitedPanels((prev) => [...prev, "settings"]);
    } else if (currentPanel === "settings") {
      if (
        !validateSettings({
          selectedCountries,
          selectedLanguage,
          targetValue,
          campaignName,
          biddingStrategy,
          targetCpa,
          targetRoas,
          maxCpcLimit,
          impressionShare,
          adLocation,
          setMessage,
          setSnackbarOpen,
        })
      )
        return;

      setExpandedPanel("keywords");
      setVisitedPanels((prev) => [...prev, "keywords"]);
    } else if (currentPanel === "keywords") {
      if (
        !validateSettingsKeyword({
          keywords,
          negativeKeywords,
          setMessage,
          setSnackbarOpen,
        })
      )
        return;

      setExpandedPanel("adsCreative");
      setVisitedPanels((prev) => [...prev, "adsCreative"]);
    } else if (currentPanel === "adsCreative") {
      const results = await validateFullAdsCreative({
        final_url: { url: finalURL },
        business_name: {
          assetContent: businessName,
        },
        headline: headlines.map((h) => ({ assetContent: h.text })),
        description: descriptions.map((d) => ({ assetContent: d.text })),
        site_link: siteLinks.map((sitelink) => ({
          siteLinkText: sitelink?.sitelinkText,
          siteLinkDescription1: sitelink?.description1,
          siteLinkDescription2: sitelink?.description2,
          siteLinkFinalUrl: sitelink?.finalURL,
        })),
        callout: callOuts.map((callout) => ({ assetContent: callout?.text })),
        snippet: structuredSnippets.map((snippet) => ({
          assetContent: snippet?.text,
        })),
        phone_call: {
          phoneCountryCode: calls?.[0].countryCode,
          phoneNumber: calls?.[0].phoneNumber,
        },
        display_path: displayPaths.reduce((acc, curr) => {
          acc[curr.id] = curr.text;
          return acc;
        }, {}),
      });
      if (
        !validateAdsCreative({
          finalURL,
          businessName,
          headlines,
          descriptions,
          siteLinks,
          callOuts,
          structuredSnippets,
          calls,
          displayPaths,
          setMessage,
          setSnackbarOpen,
        }) &&
        !results.success
      )
        return;

      if (
        Object.keys(results?.data).filter(
          (key) => results?.data?.[key]?.[0]?.errorMessage
        )?.length
      ) {
        const errors = Object.keys(results?.data)
          .filter((key) => results?.data?.[key]?.[0]?.errorMessage)
          .reduce((acc, key) => {
            acc[key] = {
              message: results?.data?.[key]?.[0]?.errorMessage,
              open: true,
            };
            return acc;
          }, {});

        console.log(errors, "Erro");

        const action = (snackbarId) => {
          return (
            <span
              onClick={() => {
                closeSnackbar(snackbarId);
              }}
            >
              <CloseRounded />
            </span>
          );
        };

        Object.keys(errors).forEach((err) => {
          enqueueSnackbar(`${errors[err]?.message}`, {
            variant: "error",
            action,
          });
        });
      }

      handleCreateAction();
    }
  };

  const handleCreateAction = () => {
    if (
      validateSettings({
        selectedCountries,
        selectedLanguage,
        targetValue,
        campaignName,
        biddingStrategy,
        targetCpa,
        targetRoas,
        maxCpcLimit,
        impressionShare,
        adLocation,
        setMessage,
        setSnackbarOpen,
      }) &&
      validateSettingsKeyword({
        keywords,
        negativeKeywords,
        setMessage,
        setSnackbarOpen,
      }) &&
      validateAdsCreative({
        finalURL,
        businessName,
        headlines,
        descriptions,
        siteLinks,
        callOuts,
        structuredSnippets,
        calls,
        displayPaths,
        setMessage,
        setSnackbarOpen,
      })
    ) {
      const bidValues = {
        MAXIMIZE_CONVERSIONS: targetCpa,
        MAXIMIZE_CONVERSION_VALUE: targetRoas,
        MAXIMIZE_CLICKS: maxCpcLimit,
        MANUAL_CPC: maxCpcLimit,
        MANUAL_CPC_ENHANCED: maxCpcLimit,
        TARGET_IMPRESSION_SHARE: impressionShare,
      };
      const payload = {
        account_id: account?.account_id,
        hotel_id: hotel_id?.[0],
        campaign_settings: {
          campaign_type: selectedObjective,
          campaign_name: campaignName,
          bidding_strategy: {
            bidding_strategy_option: biddingStrategy,
            bidding_value: bidValues?.[biddingStrategy],
            bidding_max_value:
              biddingStrategy === "TARGET_IMPRESSION_SHARE"
                ? maxCpcLimit
                : undefined,
            ad_location: adLocation ?? undefined,
          },
          budget: {
            default_budget: Number(targetValue),
            is_daily_budget: dailyBudgetCap,
            ...Object.keys(monthlyBudget).reduce((acc, curr, index) => {
              acc[`budget_month_${index + 1}`] = Number(monthlyBudget[curr]);
              return acc;
            }, {}),
          },
          campaign_lang: selectedLanguage,
          point_of_sales: selectedCountries,
        },
        campaign_ads_creative: {
          final_url: { url: finalURL },
          business_name: {
            assetContent: businessName,
          },
          headline: headlines.map((h) => ({ assetContent: h.text })),
          description: descriptions.map((d) => ({ assetContent: d.text })),
          site_link: siteLinks.map((sitelink) => ({
            siteLinkText: sitelink?.sitelinkText,
            siteLinkDescription1: sitelink?.description1,
            siteLinkDescription2: sitelink?.description2,
            siteLinkFinalUrl: sitelink?.finalURL,
          })),
          callout: callOuts.map((callout) => ({ assetContent: callout?.text })),
          snippet: structuredSnippets.map((snippet) => ({
            assetContent: snippet?.text,
          })),
          phone_call: {
            phoneCountryCode: calls?.[0].countryCode,
            phoneNumber: calls?.[0].phoneNumber,
          },
          display_path: displayPaths.reduce((acc, curr) => {
            acc[curr.id] = curr.text;
            return acc;
          }, {}),
        },
        campaign_keywords: {
          keywords: keywords.map((keyword) => ({
            keywordText: keyword.keyword,
            matchType: keyword?.matchType,
          })),
          keywords_negative: negativeKeywords.map((keyword) => ({
            keywordText: keyword.keyword,
            matchType: keyword?.matchType,
          })),
        },
      };

      axios
        .put(
          `${APP_GOOGLE_SEM_API_ENDPOINT}/api/google-sem/add-campaign/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setToastType("success");
          setMessage("Campaign created successfully!");
          setSnackbarOpen(true);
          navigate("/searchads");
        })
        .catch((err) => {
          setToastType("error");
          setMessage(err?.message || "Something went wrong!");
          setSnackbarOpen(true);
        });
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <DefaultLayout page={"SearchAdsPage"}>
      <PageTitles pageTitle="Search Ads" pageSubTitle="Campaign Creator" />

      <Box className="searchAdsContainer">
        <GoogleSearchAdsCampaignCreator
          expandedPanel={expandedPanel}
          setExpandedPanel={setExpandedPanel}
          selectedObjective={selectedObjective}
          setSelectedObjective={setSelectedObjective}
          visitedPanels={visitedPanels}
          handleNext={handleNext}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          targetValue={targetValue}
          setTargetValue={setTargetValue}
          biddingStrategy={biddingStrategy}
          setBiddingStrategy={setBiddingStrategy}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          targetCpa={targetCpa}
          setTargetCpa={setTargetCpa}
          targetRoas={targetRoas}
          setTargetRoas={setTargetRoas}
          maxCpcLimit={maxCpcLimit}
          setMaxCpcLimit={setMaxCpcLimit}
          impressionShare={impressionShare}
          setImpressionShare={setImpressionShare}
          adLocation={adLocation}
          setAdLocation={setAdLocation}
          monthlyBudget={monthlyBudget}
          setMonthlyBudget={setMonthlyBudget}
          keywords={keywords}
          setKeywords={setKeywords}
          negativeKeywords={negativeKeywords}
          setNegativeKeywords={setNegativeKeywords}
          headlines={headlines}
          setHeadlines={setHeadlines}
          descriptions={descriptions}
          setDescriptions={setDescriptions}
          siteLinks={siteLinks}
          setSiteLinks={setSiteLinks}
          callOuts={callOuts}
          setCallOuts={setCallOuts}
          structuredSnippets={structuredSnippets}
          setStructuredSnippets={setStructuredSnippets}
          finalURL={finalURL}
          setFinalURL={setFinalURL}
          businessName={businessName}
          setBusinessName={setBusinessName}
          calls={calls}
          setCalls={setCalls}
          displayPaths={displayPaths}
          setDisplayPaths={setDisplayPaths}
          dailyBudgetCap={dailyBudgetCap}
          setDailyBudgetCap={setDailyBudgetCap}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={toastType}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
        {/* {Object.keys(displayErrors).map((key) => (
          <Snackbar
            open={displayErrors?.[key]?.open}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={"error"}
              sx={{ width: "100%" }}
            >
              {displayErrors?.[key]?.message}
            </Alert>
          </Snackbar>
        ))} */}
      </Box>
    </DefaultLayout>
  );
};

export default SearchAdsCampaignCreator;
