import axios from "axios";
import { APP_GOOGLE_PMAX_API_ENDPOINT } from "../../../../constants/api_urls";

/**
 * Generates a unique ID.
 * @returns {string} A unique identifier.
 */
export const generateId = () => Date.now() + Math.random().toString(36).substr(2, 9);

/**
 * Fetches campaign suggestions from the API.
 *
 * @param {Object} params - The parameters for the API request.
 * @param {string} params.account_id - The account ID.
 * @param {string} params.hotel_id - The hotel ID.
 * @param {string} params.campaign_lang - The language of the campaign.
 * @returns {Promise<SuggestionResult>} The API response containing suggestion data.
 */
export const getSuggestions = async (params) => {
    const jwtToken = sessionStorage.getItem('token');
    try {
        const response = await axios.post(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/get-campaign-suggestions/`,
            params,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );

        return { success: true, data: response.data };
    } catch (error) {
        return {
            success: false,
            data: null
        };
    }
};

/**
 * Processes the result of a suggestion API call and updates relevant state variables.
 *
 * @param {SuggestionResult} result - The result object from the suggestion API.
 * @param {Function} setDescriptions - Function to update the descriptions state.
 * @param {Function} setFinalURL - Function to update the final URL state.
 * @param {Function} setHeadlines - Function to update the headlines state.
 * @param {Function} setLongHeadlines - Function to update the long headlines state.
 * @param {Function} setLandscape - Function to update the landscape image state.
 * @param {Function} setLandscapeLogo - Function to update the landscape logo image state.
 * @param {Function} setLogo - Function to update the logo image state.
 * @param {Function} setPortrait - Function to update the portrait image state.
 * @param {Function} setSquare - Function to update the square image state.
 * @param {Function} setBusinessName - Function to update the business name state.
 */
export const assignedSuggestion = (
    result,
    setDescriptions,
    setFinalURL,
    setHeadlines,
    setLongHeadlines,
    setLandscape,
    setLandscapeLogo,
    setLogo,
    setPortrait,
    setSquare,
    setBusinessName
) => {
    if (result.success) {
        const data = result.data;

        if (data?.is_suggestion_successfull) {
            setDescriptions(
                data.descriptions_list.map((text) => ({
                    id: generateId(),
                    text,
                }))
            );
            setFinalURL(data.final_url || '');
            setHeadlines(
                data.headlines_list.map((text) => ({
                    id: generateId(),
                    text,
                }))
            );
            setLongHeadlines(
                data.long_headlines_list.map((text) => ({
                    id: generateId(),
                    text,
                }))
            );
            setLandscape(data.landscape_img);
            setLandscapeLogo(data.landscape_logo_img);
            setLogo(data.logo_img);
            setPortrait(data.portrait_img);
            setSquare(data.square_img);
            setBusinessName(data.business_name || '');
        }
    }
};


