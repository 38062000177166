import axios from "axios";
import {APP_GOOGLE_PMAX_API_ENDPOINT} from "../../../../constants/api_urls";

/**
 * Create Campaign Settings Payload
 * @param {object} params - Campaign settings data
 * @returns {object} payload - Formatted payload for the API request
 */
export const createCampaignSettingsPayload = ({
    idCampaign,
    campaignName,
    biddingStrategy,
    targetCpa,
    targetRoas,
    targetValue,
    monthlyBudget,
    selectedLanguage,
    selectedCountries
}) => ({
    campaign_id: idCampaign,
    campaign_settings: {
        campaign_name: campaignName,
        bidding_strategy: {
            bidding_strategy_option: biddingStrategy,
            bidding_value: biddingStrategy === "MAXIMIZE_CONVERSIONS" ? targetCpa : targetRoas,
        },
        budget: {
            default_budget: targetValue,
            is_daily_budget: false,
            budget_month_1: monthlyBudget.Jan || null,
            budget_month_2: monthlyBudget.Feb || null,
            budget_month_3: monthlyBudget.Mar || null,
            budget_month_4: monthlyBudget.Apr || null,
            budget_month_5: monthlyBudget.May || null,
            budget_month_6: monthlyBudget.Jun || null,
            budget_month_7: monthlyBudget.Jul || null,
            budget_month_8: monthlyBudget.Aug || null,
            budget_month_9: monthlyBudget.Sep || null,
            budget_month_10: monthlyBudget.Oct || null,
            budget_month_11: monthlyBudget.Nov || null,
            budget_month_12: monthlyBudget.Dec || null,
        },
        campaign_lang: selectedLanguage,
        point_of_sales: selectedCountries,
    },
});

/**
 * Save Campaign Settings API Call
 * @param {object} payload - API request payload
 * @returns {Promise<object>} - API response
 */
export const saveCampaignSettings = async (payload) => {
    const jwtToken = sessionStorage.getItem("token");

    try {
        const response = await axios.put(
            `${APP_GOOGLE_PMAX_API_ENDPOINT}/api/google-pmax/edit-campaign/settings/`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        const errors = {};

        const responseData = error.response?.data?.edit_campaign_settings || {};

        if (responseData.campaign_name?.errorMessage) {
            errors.campaignName = responseData.campaign_name.errorMessage;
        }

        if (Array.isArray(responseData.campaign_lang) && responseData.campaign_lang[0]?.errorMessage) {
            errors.campaignLang = responseData.campaign_lang[0].errorMessage;
        }

        if (Array.isArray(responseData.budget?.default_budget) && responseData.budget.default_budget[0]?.errorMessage) {
            errors.defaultBudget = responseData.budget.default_budget[0].errorMessage;
        }

        if (
            Array.isArray(responseData.bidding_strategy?.bidding_strategy_option) &&
            responseData.bidding_strategy.bidding_strategy_option[0]?.errorMessage
        ) {
            errors.biddingStrategy = responseData.bidding_strategy.bidding_strategy_option[0].errorMessage;
        }

        if (
            Array.isArray(responseData.bidding_strategy?.bidding_value) &&
            responseData.bidding_strategy.bidding_value[0]?.errorMessage
        ) {
            errors.biddingValue = responseData.bidding_strategy.bidding_value[0].errorMessage;
        }

        throw {
            message: "Validation Error",
            errors,
            originalError: error,
        };
    }
};
