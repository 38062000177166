import React from "react";
import { Box, Typography } from "@mui/material";
import "./PageTitles.scss";

const PageTitles = ({ pageTitle, pageSubTitle }) => {
    return (
        <Box className="page-titles-container">
            <div className="title-wrapper">
                <Typography className="quency-cf-normal heading">
                    {pageTitle}
                </Typography>
                <div className="subtitle">
                    {pageSubTitle}
                </div>
            </div>
        </Box>
    );
};
export default PageTitles;