import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";

export const REPORT_TYPE_LABELS = {
  PERFORMANCE_GLOBAL: 'Performance Global',
  RESERVATIONS: 'Reservations',
  BILLING: 'Billing',
  PERFORMANCE_GOOGLE_FBL:'Google FBL',
  PERFORMANCE_METASEARCH:'Metasearch',
  PERFORMANCE_PMAX:'Google PMax',
  SP_FUNNEL_TRIPADVISOR:'SP Funnel TripAdvisor',
  PRICE_COMPETITIVENESS:'Price Cometitiveness',
  PERFORMANCE_MARKET_PMAX:'Google PMax',
  PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY:'Google Missed Opportunity',
};

export const DELIVERY_FREQUENCY_LABELS = {
  ONCE:'Once',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

export const STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Paused',
  COMPLETED: 'Completed',
  RUNNING:'Active'
};

export const REPORT_TYPE_URLS = {
    PERFORMANCE_GLOBAL: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-global/details",
    PERFORMANCE_GOOGLE_FBL: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-google-fbl/details",
    PERFORMANCE_METASEARCH:APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-metasearch/details",
    PERFORMANCE_PMAX: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-pmax/details",
    SP_FUNNEL_TRIPADVISOR: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/details",
    PRICE_COMPETITIVENESS: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/price-competitiveness/details",
    PERFORMANCE_MARKET_PMAX: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-market-google-pmax/details",
    BILLING: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/details",
    RESERVATIONS: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/reservation/details",
    PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-google-missed-opportunity/details",
};
