import React, { useEffect, useMemo, useState } from 'react';
import CustomTable from './AlertTableDesignedWithCheckboxes';
import { APP_ALERT_CENTER_API_ENDPOINT } from '../../../constants/api_urls';
import './index.scss';
import Icons from "../../../components/DmpIcons/icons";

const HotelListTable = ({ idaccount, jwtToken, onHotelSelect, rowSelection, setRowSelection, onClose }) => {
    const [hotels, setHotels] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = useMemo(() => [
        { accessorKey: 'id', header: 'ID', maxSize: 40 },
        { accessorKey: 'name', header: 'Name', maxSize: 220 },
        { accessorKey: 'country', header: 'Country', maxSize: 40 },
    ], []);

    useEffect(() => {
        const fetchHotels = async () => {
            try {
                const response = await fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/list-hotels/${idaccount}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                setHotels(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchHotels();
    }, [idaccount, jwtToken]);

    useEffect(() => {
        const selectedHotels = hotels.filter(hotel => rowSelection[hotel.id]);
        onHotelSelect(selectedHotels);
    }, [rowSelection, hotels, onHotelSelect]);

    return (
        <div className="hotel-table-container">
            <br />
            <div className="alert-table-hotels-title">
                <div
                    onClick={onClose}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '28px',
                        height: '28px',
                        borderRadius: '50%',
                        border: '1px solid #000',
                        padding: '5px',
                        cursor: 'pointer',
                        marginRight: '10px',
                        boxSizing: 'border-box'
                    }}
                >
                    <img
                        src={Icons.ActionsIconsSVG.ClosePanelIconSVG}
                        alt="Close"
                    />
                </div>
                <h2 style={{
                    fontSize: '20px',
                    textAlign: 'left',
                    fontWeight: 400,
                    fontFamily: 'Quincy CF, serif',
                }}>
                    Select hotels
                </h2>
            </div>
            <CustomTable
                columns={columns}
                data={hotels}
                loading={loading}
                selectedRows={rowSelection}
                setSelectedRows={setRowSelection}
                uniqueId="id"
            />
        </div>
    );
};

export default HotelListTable;
