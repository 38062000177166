export const getUserDetailsFromToken = (token) => {
    const UserDetails = { username: 'N/A', roles: 'N/A', isMainUser: false };
    if (!token) {
        console.error("Token is required");
        return { UserDetails };
    }
    try {
        const payload = token.split('.')[1];
        const decodedPayload = JSON.parse(atob(payload));
        UserDetails.username = decodedPayload.username || 'N/A';
        UserDetails.roles = decodedPayload.roles[0]?.toString() || 'N/A';
        UserDetails.isMainUser = Array.isArray(decodedPayload.roles) &&
            decodedPayload.roles[0]?.toString() === 'ROLE_MAIN_USER';
        return { UserDetails };
    } catch (error) {
        console.error("Failed to decode the token:", error);
        return { UserDetails };
    }
};